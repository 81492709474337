import { bindable, autoinject } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { ModalShiftReport } from "../modal-shift-report";
import * as Fhir from "../../classes/FhirModules/Fhir";
import { LOINC } from '../../classes/Codes';

const moment = require("moment");
import { fhirEnums } from "../../classes/fhir-enums";
import { RuntimeInfo } from "../../classes/RuntimeInfo";
import { FhirService } from "../../services/FhirService";
import { NitTools } from "../../classes/NursitTools";
import { ConfigService } from "../../services/ConfigService";
import { I18N } from "aurelia-i18n";

@autoinject
export class PatientShiftReport {
    @bindable patient;
    reports = [];
    showAddButton: boolean = true;
    showEditButton: boolean = true;
    daysHistory: number = 3;

    constructor(private dialogService: DialogService, private fhirService: FhirService, protected i18n: I18N) {
        const cfg = ConfigService.GetFormSettings('shift-report');
        if (cfg && cfg.settings) {
            this.showAddButton = typeof cfg.settings.allowAddNewEntry === 'boolean' ? cfg.settings.allowAddNewEntry : true;
            this.showEditButton = typeof cfg.settings.allowEditEntries === 'boolean' ? cfg.settings.allowEditEntries : true;
            this.daysHistory = typeof cfg.settings.daysHistory === 'number' ? cfg.settings.daysHistory : this.daysHistory;
        }
    }

    patientChanged(patient) {
        if (!patient) return 0;
        const dateFrom = moment().subtract(this.daysHistory, 'day').startOf('day').toJSON();
        // if (RuntimeInfo.DataProxy && RuntimeInfo.DataProxy.enabled && window.location.href.indexOf('sessionId') > -1)
        this.fhirService.fetch(fhirEnums.ResourceType.observation
            + '?encounter=' + patient.encounterId
            + '&status=registered,final'
            + '&code=' + LOINC.SYSTEM + '|' + LOINC.CODES.REPORT.code
            + '&date=ge' + dateFrom).then((result: any[]) => {
                if (result != null && NitTools.IsArray(result)) {
                    try {
                        this.reports = result.map(res => this.parseReport(res)).sort((a, b) => {
                            if (moment(a.datetime).isAfter(moment(b.datetime))) return -1;
                            if (moment(b.datetime).isAfter(moment(a.datetime))) return 1;

                            return 0;
                        });
                    } catch (e) {
                        console.warn(e.message || e);
                        this.reports = [];
                    }
                }
            });
    }

    parseReport(data) {
        if (!data) return undefined;
        try {
            if (data.category?.[0]?.coding?.[0]?.code === 'drains') {
                const parts = []

                parts.push(data.code?.coding?.[0]?.display)

                if (data.bodySite?.coding?.[0]?.display) {
                    parts.push(data.bodySite.coding[0].display)
                }

                if (data.valueQuantity) {
                    parts.push(`${data.valueQuantity.value} ${data.valueQuantity.unit}`)
                }

                if (data.component) {
                    for (const component of data.component) {
                        const concept = component.valueCodeableConcept
                        if (
                            concept?.text === 'evaluation' ||
                            concept?.text === 'suction' ||
                            concept?.text === 'height'
                        ) {
                            concept.coding?.[0]?.display &&
                                parts.push(concept.coding[0].display)
                        }
                        if (component.valueQuantity) {
                            parts.push(
                                `${component.valueQuantity.value} ${component.valueQuantity.unit}`
                            )
                        }
                    }
                }

                const extensionMap = {
                    'container-change-completed': 'container_change_completed',
                    'hourly-barometer-cleared': 'hourly_barometer_cleared',
                    drained: 'drained'
                }

                if (data.extension) {
                    for (const ext of data.extension) {
                        const urlPart = ext.url.split('/').pop()
                        if (extensionMap[urlPart] && ext.valueBoolean === true) {
                            parts.push(
                                `${this.i18n.tr(extensionMap[urlPart])}: ${this.i18n.tr('yes')}`
                            )
                        }
                    }
                }

                return {
                    id: data.id,
                    datetime: data.effectiveDateTime ? moment(data.effectiveDateTime).toDate() : undefined,
                    performer: data.performer?.[0].display,
                    status: data.status,
                    reportText: parts.join(','),
                    comment: (FhirService.FhirVersion > 3 ? data.note && data.note[0].text || '' : data.comment),

                }
            }

            return {
                id: data.id,
                datetime: data.effectiveDateTime ? moment(data.effectiveDateTime).toDate() : undefined,
                shift: this.findComponent('shift', data),
                markSupplement: this.findComponent('mark-supplement', data, true),
                nursingTransfer: this.findComponent('nursing-transfer', data, true),
                additionalInfo: this.findComponent('additional-info', data, true),
                status: data.status,
                reportText: data.valueString,
                comment: (FhirService.FhirVersion > 3 ? data.note && data.note[0].text || '' : data.comment),
                performer: data.performer?.[0].display
            };
        } catch (e) {
            console.warn(e.message || e);

            return undefined;
        }
    }

    findComponent(name, data, isBoolean = false) {
        const val = data.component ? data.component.find((cmp) => cmp.code.text === name) : null;

        if (val) {
            return isBoolean ? val.valueString === 'true' : val.valueString;
        } else {
            return isBoolean ? false : null;
        }
    }

    addShiftReport() {
        this.dialogService.open({
            viewModel: ModalShiftReport,
            model: {
                mode: 'add',
                patient: this.patient
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                this.reports.unshift(this.parseReport(result.output));
            }
        });
    }

    editShiftReport(idx) {
        this.dialogService.open({
            viewModel: ModalShiftReport,
            model: {
                mode: 'edit',
                patient: this.patient,
                report: this.reports[idx]
            }
        }).whenClosed((result) => {
            if (!result.wasCancelled) {
                this.reports.splice(idx, 1, this.parseReport(result.output));
            }
        });
    }
}
