import { IdLogikService } from "../services/IdLogikService";
import { AnalyzeService } from '../services/analyzeService';
import { DialogService } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { autoinject, bindable, TaskQueue } from "aurelia-framework";
import { PatientItem } from "../classes/Patient/PatientItem";
import { IPromptOptions, Prompt } from "./prompt";
import { FhirService } from "../services/FhirService";
import { UserService } from "../services/UserService";
import { ConfigService } from "../services/ConfigService";
import { IFormSetting } from "../classes/IFormSettings";
import { PatientChangeNotifier } from "resources/services/PatientChangeNotifier";
import { IReportList, ReportService } from "resources/services/ReportService";
import { PatientService } from "resources/services/PatientService";
import { IQuestionnaireList, QuestionnaireService } from "resources/services/QuestionnaireService";
import { DialogMessages } from "resources/services/DialogMessages";
import { fhirEnums } from "../classes/fhir-enums";
import { NitTools } from "../classes/NursitTools";
import * as Fhir from "../classes/FhirModules/Fhir";
import { Questionnaire, QuestionnaireResponse } from "../classes/FhirModules/Fhir";
import { questionnaireInfomations } from "./questionnaire-infomations";
import { RuntimeInfo } from "../classes/RuntimeInfo";
import SystemHeaders from "../classes/SystemHeaders";
import { PermissionService } from "resources/services/PermissionService";
import { HttpClient } from "aurelia-http-client";
import { LogoutService } from "../services/LogoutService";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

const moment = require("moment");

@autoinject
export class BasicForm {
    public static pageTitle: string = undefined;
    @bindable encounterId: string;
    _route: string = undefined;
    __hasChanges: boolean = false;
    __selectedId: string = undefined;
    __isLoading: boolean = false;
    __response: any = undefined;
    __responses: any[] = [];
    forcedReadonly: boolean = false;
    readonly: boolean = false;
    analyzeService: AnalyzeService;
    fhirService: FhirService;
    router: Router;
    taskQueue: TaskQueue;
    notifier: PatientChangeNotifier;
    dialogService: DialogService;
    previousResponse: any = undefined;
    maxAge: number = 12;
    userService: UserService;
    questionnaireName: string;
    mayBeEdited: boolean = true;
    showEditButtons: boolean = true;
    tooOld: boolean = false;
    reportService: ReportService;
    showSaveButton: boolean = true;

    m_questionnaire: any;
    get questionnaire(): any {
        return this.m_questionnaire;
    }
    set questionnaire(value: any) {
        this.m_questionnaire = value;

        this.recalcFields();
    }

    responseBackup: any[] = undefined;
    report: string = undefined;
    showPrintButton: boolean = false;
    showPdfButton: boolean = false;
    oldLength = -1;
    isOlderQuestionnaireVersion: boolean = false;
    invertQuestionnaireResponseOrder: boolean = false;
    setting: IFormSetting = undefined;
    extraDebugResponseInfo: string;
    questionnaireService: QuestionnaireService;
    useQuestionnaireStatusForSave: boolean;
    pageTitle: string;
    designing: boolean = false;
    forceNoChange: boolean = false;
    reportVersion: any;

    get responses(): any[] {
        return this.__responses;
    }

    set responses(newValue: any[]) {
        this.__responses = newValue.sort((a, b) => {
            try {
                let dateA = new Date(a.authored);
                let dateB = new Date(b.authored);

                return this.invertQuestionnaireResponseOrder ? dateA.valueOf() - dateB.valueOf() : dateB.valueOf() - dateA.valueOf();
            } catch (e) {
                console.warn(e.message);
                return 0;
            }
        });

        if (newValue && this.oldLength !== newValue.length) {
            this.oldLength = newValue.length;

            this.selectLastResponse();
        }
    }

    get response(): any {
        return this.__response;
    }

    set response(newResponse: any) {
        QuestionnaireResponse.EnsureStructuralHullIntegrity(this.questionnaire, newResponse);

        this.__response = newResponse;
        this.previousResponse = undefined;

        if (newResponse && this.responses) {
            let idx = this.responses.indexOf(this.responses.find(o => o.id === newResponse.id)); // 1st is now 0 !!
            if (idx < this.responses.length - 1) {
                QuestionnaireService.GetResponseById(this.patient, this.responses[idx + 1].id)
                    .then((result: any) => {
                        this.previousResponse = result;
                    });
            }

            this.checkQuestionnaireVersion()
                .then(async () => {
                    // ensure the existence of the attached responses
                    await this.checkForAutoGeneratedResponses();
                });
        }

        this.afterResponseChanged(newResponse);
    }

    get route(): string {
        return this._route;
    }

    private _patient: PatientItem;
    get patient(): PatientItem {
        return this._patient || PatientItem.LastLoadedPatient || PatientItem.SelectedPatient;
    }

    set patient(value: PatientItem) {
        this._patient = value;
    }

    get selectedid() {
        return this.__selectedId;
    }

    set selectedid(newVal: string) {
        if (newVal !== this.__selectedId) {
            this.isLoading = true;
            this.__selectedId = newVal;
            this.responseBackup = undefined;
            this.loadResponse();
        }
    }

    get selectedId(): string {
        return this.selectedid;
    }

    set selectedId(value: string) {
        this.selectedid = value;
    }

    set route(value: string) {
        this._route = value;
        this.refreshFormName();
    }

    get qList(): IQuestionnaireList {
        return QuestionnaireService.__listResult;
    }

    get _isLoading(): boolean {
        return this.__isLoading;
    }

    set _isLoading(value: boolean) {
        this.__isLoading = value;
    }

    get isLoading(): boolean {
        return this.__isLoading;
    }

    set isLoading(value: boolean) {
        if (!value) {
            // when false, then set the value with a bit offset to allow the renderer to process everything
            this.taskQueue.queueTask(() => {
                window.setTimeout(() => {
                    this.__isLoading = false;
                    RuntimeInfo.IsLoading = false;
                }, 250);
            });
        } else {
            // when true, set the value at once
            this.__isLoading = value;
            RuntimeInfo.IsLoading = true;
        }
    }

    get hasChanges(): boolean {
        let result = this.__hasChanges;
        if (!this.response || !this.response.item) return false;
        if (this.forceNoChange) {
            //this.forceNoChange = false;
            result = false;
        } else {
            if (result && this.responseBackup && JSON.stringify(this.responseBackup) == JSON.stringify(this.response.item))
                result = false;
        }
        // this.forceNoChange = false;
        return result;
    }

    set hasChanges(newValue: boolean) {
        if (newValue === this.__hasChanges) return;

        if (this.isLoading) newValue = false; // yes, this IS dirty, but prevents from false changing messages
        this.__hasChanges = newValue;
    }

    constructor(protected i18n: I18N, router: Router, queue: TaskQueue,
        notifier: PatientChangeNotifier,
        dialogService: DialogService,
        analyzeService: AnalyzeService,
        protected patientService: PatientService,
        protected dialogMessages: DialogMessages,
        idLogikService: IdLogikService,
        permissionService: PermissionService,
        protected logoutService: LogoutService) {
        this.analyzeService = analyzeService;
        this.questionnaireService = new QuestionnaireService();
        this.router = router;
        this.dialogService = dialogService;
        this.reportService = new ReportService();
        this.taskQueue = queue;
        this.notifier = notifier;
        this.fhirService = new FhirService();
        this.reportService.dialogService = this.dialogService;
        this.reportService.i18n = this.i18n;
        this.userService = new UserService(router, i18n, dialogService, dialogMessages, idLogikService, permissionService);
        this.useQuestionnaireStatusForSave = true;
        this.analyzeService.icdService.clear();
    }

    async beforeSave(): Promise<any> {
        // Is this needed?: await this.checkForAutoGeneratedResponses()
        this.createLatestDocumentFlag();
    }

    createLatestDocumentFlag() {
        if (!this.patient || !this.patient.flags) return;

        // only ADD Flags, because we can not be sure that every response is loaded yet!
        QuestionnaireService.Questionnaires.forEach(q => {
            let qr = QuestionnaireService.GetLatestResponseOfType(this.patient, q.id,
                [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
            // if (qr) {
            let systemEnd = `latest${q.name}Date`;
            let coding = this.patient.flags.code.coding.find(o => o.system.endsWith(systemEnd));
            if (!coding) {
                coding = {
                    system: NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase) + '/' + systemEnd
                };

                this.patient.flags.code.coding.push(coding);
            }

            if (coding && !qr) { // don't forget to remove the coding, if the qr does not exist. May happen when the response has been stopped
                this.patient.flags.code.coding.splice(this.patient.flags.code.coding.indexOf(coding), 1);
            } else {  // if coding is there and a qr, update the coding
                coding.code = new Date(qr.authored).toJSON();
            }
            // }
        });
    }

    checkForAutoGeneratedResponses(): Promise<any> {
        return new Promise<void>(async (resolve) => {
            if (!this.patient) {
                return resolve();
            }

            const cfg = await ConfigService.LoadConfigOverride(this.patient.ward, this.patient);
            if (!this.questionnaire || !this.response || !cfg) return resolve();

            const formSetting: IFormSetting = cfg.forms.find(o => (o.questionnaireName && o.questionnaireName.toUpperCase() === this.questionnaire.name.toUpperCase()) || (o.route === this.route));
            if (!formSetting || !formSetting.autoGenerateNewResponses) return resolve();

            // remove deprecated meta tags
            if (this.patient?.encounter?.meta?.tag) {
                for (const tag of this.patient.encounter.meta.tag) {
                    if (tag.system.indexOf('http://nursit.institute') === 0) {
                        console.debug("Removing deprecated tag: ", tag);
                        await this.fhirService.tags.delete(this.patient.encounter, tag);
                    }
                }
            }

            const arr = [];

            // iterate through the configured responses and create a new one if none found
            if (this.response.status === 'in-progress') {
                for (const generate of formSetting.autoGenerateNewResponses.filter(o => o.questionnaire)) {
                    const attachedQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(generate.questionnaire);
                    if (typeof attachedQuestionnaire === "undefined") {
                        console.warn(`Questionnaire with name "${generate.questionnaire}" not found!\nConfigured in route:assessment -> autoGenerateNewResponses`);
                        continue;
                    }

                    let attachedResponse = await QuestionnaireResponse.GetAttachedResponse(this.patient, this.response, attachedQuestionnaire.name);

                    if (typeof attachedResponse === "undefined") {
                        attachedResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, attachedQuestionnaire.id, fhirEnums.QuestionnaireResponseStatus.inProgress);

                        QuestionnaireResponse.LinkResponsesMutual(this.patient, attachedResponse, this.response);

                        arr.push(attachedResponse);
                    }
                }
            }


            // create the missing QRs
            if (arr.length > 0) {
                if (arr.indexOf(this.response) === -1)
                    arr.push(this.response);

                let resultBundle = await this.fhirService.bundle(arr, HTTPVerb.put);
                resultBundle.entry.forEach(bundleEntry => {
                    if (bundleEntry.response) {
                        if (bundleEntry.response.status.indexOf('5') === -1) {
                            if (bundleEntry.resource) {
                                this.patientService.addQuestionnaireResponse(this.patient, <any>bundleEntry.resource);
                            }
                        }
                    }
                });
            }

            return resolve();
        });
    }

    _form;
    get form() { return this._form; };
    set form(f) {
        this._form = f;
        this.recalcFields();
    }

    recalcFields() {
        if (this._form?.calculateFieldValues && this.response && this.questionnaire) {
            this._form.calculateFieldValues();
        }
    }

    // noinspection JSUnusedLocalSymbols
    async afterResponseChanged(response: any) {
        this.forceNoChange = false;
        this.mayBeEdited = this.response ? this.response.status === fhirEnums.QuestionnaireResponseStatus.inProgress : false;
        let maxAge = this.setting?.expiration?.default || 24;

        if (this.setting && response && maxAge > 0) {
            let age = moment(new Date()).diff(response.authored, "hours");
            if (age >= maxAge) {
                this.tooOld = response.status !== 'in-progress';
            } else {
                this.tooOld = false;
            }
        } else {
            this.tooOld = false;
            this.mayBeEdited = true;
        }

        this.readonly = response && response.status !== 'in-progress' && !this.tooOld;
        if (!this.setting) {
            this.readonly = false;
        }

        if (ConfigService.Debug && ConfigService.cfg?.loadQuestionnairesFromJson) {
            try {
                const qId = Fhir.Tools.StripId(response.questionnaire);
                const httpResponse = await new HttpClient().get(`Questionnaires/${qId}.json`);
                if (httpResponse.statusCode === 200) {
                    this.questionnaire = JSON.parse(httpResponse.response);
                    const exisiting = QuestionnaireService.__questionnaires.findIndex(o => o.id === this.questionnaire.id);
                    if (exisiting > -1) {
                        QuestionnaireService.__questionnaires[exisiting] = this.questionnaire;
                    }

                    console.warn("Using local Questionnaire " + qId + " :", this.questionnaire);
                }
            }
            catch (e) {

            }
        }

        if (!this.questionnaire && response) {
            // this.questionnaire = this.questionnaireService.getQuestionnaireByIdDirect(FhirService.FhirVersion > 3 ? String(response.questionnaire) : response.questionnaire.reference);
            this.questionnaire = this.questionnaireService.getQuestionnaireDirect(response.questionnaire);
        }

        this.hasChanges = false;
        if (this.questionnaire && response) {
            Fhir.Questionnaire.EnsureStructuredResponse(this.questionnaire, this.response);
            this.responseBackup = NitTools.Clone(response ? response.item : undefined);

            this.hasChanges = false;
        } else {
            this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined);
        }

        // overwrite readonly from setting
        if (typeof this.setting?.settings?.readonly === "boolean") {
            this.readonly = this.forcedReadonly = this.forceNoChange = this.setting.settings.readonly;
            this.showSaveButton = !this.readonly;

            console.warn('SET READONLY FROM SETTING:', this.readonly);
        }

        this.recalcFields();
    }

    // version check is not yet implemented
    async checkQuestionnaireVersion(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            return resolve(true);
        });
    }

    validateResponse(): any {
        function findPos(form, obj) {
            let curTop = 0;
            if (obj.offsetParent) {
                do {
                    curTop += obj.offsetTop;
                    if (obj.offsetParent === form) return curTop;
                } while (obj = obj.offsetParent);
                return [curTop];
            }
        }

        if (!this.response || !this.questionnaire) return { valid: true, missing: [] };

        const neededFields = [];

        let ids: string[] = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire);
        let items: any[] = [];
        ids.forEach(id => {
            let item = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, id);
            if (item) items.push(item);
        });

        items = items.filter(o => o.required);
        if (items.length === 0) return { valid: true, missing: [] };

        let result = true;
        items.forEach(item => {
            try {
                let responseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, item.linkId, false);
                let label = <HTMLLabelElement>document.querySelector(`label[for="${item.linkId}"]`);
                let currentResult = true;
                if (!responseItem || !responseItem.answer || responseItem.answer.length === 0) {
                    currentResult = false;
                } else {
                    let value = QuestionnaireResponse.GetResponseAnswerValue(responseItem.answer[0]);
                    if ("NaN" === String(value) || typeof value === "undefined" || value === '' || value.endsWith('_nil')) {
                        currentResult = false;
                    }
                }

                if (label) {
                    if (currentResult) {
                        label.classList.remove('text-danger');
                    } else {
                        label.classList.add('text-danger');
                    }
                }

                // check if the field is visible
                if (!currentResult) {
                    //const itm = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, responseItem.linkId);

                    if (!Fhir.Tools.IsEnableWhenSatisfied(item, this.response, true)) {
                        currentResult = true;
                    }

                    if (!currentResult) {
                        // check if the parent element is hidden if it exists (should mostly be kind of group
                        const parent = Fhir.Questionnaire.FindParent(this.questionnaire, item);
                        if (parent) { // if parent (group) found ...
                            if (!Fhir.Tools.IsEnableWhenSatisfied(parent, this.response)) // .. and enableWhen is not not satisfied,
                                currentResult = true;   // .. the field is hidden, so it has to be valid
                        }
                    }

                    if (!currentResult) neededFields.push(' - ' + item.text);
                }

                result = result && currentResult;
            } catch (e) {
                console.warn(e.message || e);
            }
        });

        return { valid: result, missing: neededFields };
    }

    selectResponseId(id: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            // this.hasChanges = this.checkForChanges();
            // if (ConfigService.Debug) console.debug('Select response id reached');

            if (this.readonly || !this.hasChanges) {
                this.selectedId = id;
                resolve(true);
                return;
            }

            if (!this.isLoading && this.hasChanges
                && (!this.responseBackup || (this.responseBackup && JSON.stringify(this.responseBackup) != JSON.stringify(this.response.item)))
            ) {        // this is a bit confusing; yes=abort, no=confirm. This is done because we want the Yes-Button to be the dangerous one
                this.dialogMessages.dialog(this.i18n.tr("confirm_discard_unsaved_changes"), this.i18n.tr("confirm"), this.i18n.tr("no"), this.i18n.tr("yes"), true)
                    .whenClosed(result => {
                        if (!result.wasCancelled) {
                            // alert("No Change");
                            // the user clicked on abort, so stay on this page
                            resolve(false);
                        } else {
                            // rollback all existing changes to the last saved original and notify through notifier about rollback
                            this.response.item = NitTools.Clone(this.responseBackup);
                            this.patientService.addQuestionnaireResponse(this.patient, this.response, true);
                            this.notifier.notify(this.patient);
                            RuntimeInfo.ShowInfo(this.i18n.tr("info_text_response_rollback"));
                            this.selectedId = id;

                            resolve(true);
                        }
                    });
            }
        });
    }

    checkForPrintButton() {
        try {
            if (!ReportService.ReportServer || !this.report) {
                this.showPrintButton = false;
                return;
            }

            ReportService.Fetch()
                .then((result: IReportList) => {
                    let rU = this.report.toUpperCase();
                    if (rU.indexOf(".FRX") === -1) {
                        rU += ".FRX";
                    }

                    let idx = result.items.indexOf(rU);
                    this.showPrintButton = idx > -1;
                });
        } catch (e) {
            console.warn(e);
        }
    }

    loadResponse() {
        this.isLoading = true;
        if (!this.selectedid || !this.patient) {
            this.response = undefined;
            this.responseBackup = undefined;
            this.hasChanges = false;
            this.isLoading = false;
            console.warn("Exiting loadResponse");
            return;
        }

        // only load once please
        this.response = <any>this.patient.questionnaireResponses.find(o => o.id === this.selectedid);
        this.checkForPrintButton();
        this.afterLoaded();
    }

    afterLoaded() {
        this.responses.forEach(response => {
            Fhir.Questionnaire.EnsureStructuredResponse(this.questionnaire, response);
        });

        this.responseBackup = this.response ? NitTools.Clone(this.response.item) : undefined;
        this.__hasChanges = false;

        this.isLoading = false;
        this.updateSempaBirthField(this.patient);
    }

    protected updateSempaBirthField(patient: PatientItem) {
        if (!this.questionnaire || !patient || !patient.birthDate || !this.response || this.response.status !== 'in-progress') return;

        const date = moment(patient.birthDate);
        const years = Math.abs(date.diff(new Date(), 'years', false));
        if (typeof patient.days != "number" || isNaN(patient.days))
            patient.days = Math.abs(date.diff(new Date(), 'days', false));
        const days = patient.days;

        // map birthday to anamnesis form days
        const CareITAX_Sempa_Kind_1001: any =
            Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, 'CareITAn_Sempa_Kind_1001')
            || Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, 'CareITAs_Sempa_Kind_1001');

        const RCareITAX_Sempa_Kind_1001: any =
            Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, 'CareITAn_Sempa_Kind_1001')
            || Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, 'CareITAs_Sempa_Kind_1001');

        if (RCareITAX_Sempa_Kind_1001 && CareITAX_Sempa_Kind_1001) {
            let code: string = undefined;
            if (days === 0) // Geburtshilfe
                code = CareITAX_Sempa_Kind_1001.option[0].valueCoding.code; // code = "CareITAn_Sempa_Kind_1001_01";
            else if (days <= 14) // 0-14 Tage
                code = CareITAX_Sempa_Kind_1001.option[1].valueCoding.code; // code = "CareITAn_Sempa_Kind_1001_02";
            else if (days <= 364) // 15 Tage - 1 Jahr
                code = CareITAX_Sempa_Kind_1001.option[2].valueCoding.code; // code = "CareITAn_Sempa_Kind_1001_03";
            else if (days <= 365 * 5) // 1-5 Jahre
                code = CareITAX_Sempa_Kind_1001.option[3].valueCoding.code; // code = "CareITAn_Sempa_Kind_1001_04";
            else if (days <= 365 * 12) // 5-12 Jahre
                code = CareITAX_Sempa_Kind_1001.option[4].valueCoding.code; // code = "CareITAn_Sempa_Kind_1001_05";
            else if (days >= 365 * 12) // 12-18 Jahre
                code = CareITAX_Sempa_Kind_1001.option[5].valueCoding.code; // code = "CareITAn_Sempa_Kind_1001_06";

            if (code) {
                const txt = QuestionnaireResponse.GetOptionText(code, CareITAX_Sempa_Kind_1001);
                RCareITAX_Sempa_Kind_1001.answer = [{
                    valueCoding: {
                        code: code,
                        display: txt
                    }
                }];

                if (ConfigService.Debug)
                    console.debug('Mapped CareITAn_Sempa_Kind_1001 as: ', RCareITAX_Sempa_Kind_1001.answer);
            }
        }

        // map birthday field to NIT_PAV
        const itemNIT_PAV_104: any = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, 'NIT_PAV_104');
        const NIT_PAV_104: any = QuestionnaireResponse.GetResponseItemByLinkId(this.response, 'NIT_PAV_104', true);
        if (itemNIT_PAV_104 && NIT_PAV_104) {
            let answer = 'NIT_PAV_104_00';
            if (years <= 59)
                answer = 'NIT_PAV_104_00';
            else if (years >= 60 && years <= 69)
                answer = 'NIT_PAV_104_01';
            else if (years >= 70 && years <= 79)
                answer = 'NIT_PAV_104_02';
            else if (years >= 80 && years <= 89)
                answer = 'NIT_PAV_104_03';
            else if (years >= 90 && years <= 99)
                answer = 'NIT_PAV_104_04';
            else if (years > 99)
                answer = 'NIT_PAV_104_05';

            const option = itemNIT_PAV_104.option.find(o => o.valueCoding && o.valueCoding.code === answer);

            NIT_PAV_104.answer = [{
                valueCoding: {
                    code: answer,
                    display: option && option.valueCoding ? option.valueCoding.display : String(years)
                }
            }];
        }
    }

    selectLastResponse() {
        if (this.responses.length > 0) {
            let lastItem = this.responses[0]; // this.responses.length - 1];
            this.selectedid = lastItem.id;
        }
    }

    deactivate() {
        // if (ConfigService.Debug) console.debug("BasicForm: this.deactivate() called");
    }

    getCompareArray(response: any, items: any[]): any[] {
        let tmp: any = {
            resourceType: 'QuestionnaireResponse',
            item: NitTools.Clone(items),
            status: 'in-progress',
            extension: response.extension
        };

        let arrResponseIds = Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire);// Fhir.Tools.GetAllResponseLinkIds(tmp);
        let arrResponse = [];
        arrResponseIds.forEach(linkId => {
            let qItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);
            let ignore = false;
            if ((qItem && qItem.type === 'group')) ignore = true;

            if (!ignore) {
                let item = QuestionnaireResponse.GetResponseItemByLinkId(tmp, linkId, false);
                if (item) {
                    let value = item && item.answer ? JSON.stringify(item.answer) : '[not set]';
                    arrResponse.push({ linkId: linkId, value: value });
                }
            }
        });

        return arrResponse;
    }

    checkForChanges(): boolean {
        if (this.isLoading) return false;

        let arr1 = this.getCompareArray(this.response, this.response.item);
        let arr2 = this.getCompareArray(this.response, this.responseBackup);
        if (arr1.length != arr2.length) return true;

        if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
            for (let i = 0; i < arr1.length; i++) {
                let v1 = arr1[i].value;
                let item2 = arr2.find(o => o.linkId === arr1[i].linkId);
                if (!item2) {
                    console.debug(`Item with linkId "${arr1[i].linkId}" not found in backup`);
                    return true;
                }

                let v2 = item2.value;
                if (v1 !== v2) {
                    return true;
                }
            }
        } else {
            console.debug('No Changes found in ', this.response);
            return false;
        }
    }

    async canDeactivate() {
        return new Promise((resolve, reject) => {
            try {
                if (this.hasChanges) {
                    this.dialogMessages.dialog(this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('warning'), this.i18n.tr("yes"), this.i18n.tr("no"), true)
                        .whenClosed(result => {
                            resolve(!result.wasCancelled);
                            return;
                        });
                } else {
                    resolve(true);
                }
            } catch (e) {
                let msg = e.message || JSON.stringify(e);
                console.warn(msg);
                reject(msg);
            }
        });
    }

    formChanged(sender: Questionnaire) {
        if (this.response && this.responseBackup && !this.isLoading && !this.readonly) {
            this.__hasChanges = true;
        }
    }

    showResponseInfo(response: any) {
        if (!response) {
            return;
        }

        let fhirLink = `${FhirService.Endpoint}/QuestionnaireResponse/${this.response?.id}`;
        let reportDataHref = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Data/${response?.id}`;
        let reportObservationsSourceHref = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Observations/${this.patient?.id}`;
        const curveConfig = ConfigService.GetFormSettings("curve");
        const curveReport = curveConfig?.report?.name || '';

        let medicationConfig = ConfigService.GetFormSettings("medications");
        const medicationsReport = medicationConfig?.report?.name || '';

        const practitionerId = UserService.Practitioner?.id || 'null';
        let reportObservationsPdfHref = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Report/Generate/${practitionerId}/${this.patient?.id}/${this.patient?.encounterId}/${this.response?.id}/${curveReport}`;
        let reportMedicationsPdfHref = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Report/Generate/${practitionerId}/${this.patient?.id}/${this.patient?.encounterId}/${this.response?.id}/${medicationsReport}`;
        let reportMedicationsSourceHref = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Medications/${this.patient?.encounterId}`;
        let reportGenerationUrl = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Report/Generate/${practitionerId}/${this.patient?.id}/${this.patient?.encounterId}/${this.response?.id}/${this.report}`;
        let practitionerSourceUrl = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Practitioner/${UserService.Practitioner?.id}`;
        let encounterSourceUrl = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer)}api/Encounter/${this.patient?.encounterId}`;

        let html = `<div class="document-info-debug" id="documentInfoDebug">
                        <div style="width: 100%"><b>DEBUG</b> <span style="display:block; float:right; font-size: 0.8em" onclick="document.getElementById('documentInfoDebug').remove();">Hide</span></div>
                        <div class="document-info-debug-content">
                            <div>
                                <label for="responseLink">response-Id</label>
                                <input class="form-control" onfocus="document.getElementById('responseLink').select();" id="responseLink" type="text" value="${response?.id}">
                            </div>
                            <div>
                                <a href="${fhirLink}" target="_blank">fhir-Link</a>
                                <input onfocus="document.getElementById('fhirLink').select();" id="fhirLink" type="text" class="form-control" value="${fhirLink}">
                            </div>
                            <div>
                                <a target="_blank" href="${reportDataHref}">report-Source</a>
                                <label style="display: inline-block; margin-left: 1em">Info: api/Data/<b style="color:red">RESPONSE-ID</b></label>
                                <input onfocus="document.getElementById('reportLink').select();" id="reportLink" class="form-control" type="text" value="${reportDataHref}">
                            </div>
                            <div>
                                <b>Encounter Source:</b>
                                <label style="display: inline-block; margin-left: 1em">Info: api/Encounter/<b style="color:red">Encounter-ID</b></label>
                                <input onfocus="document.getElementById('encounterSourceUrl').select();" id="encounterSourceUrl" class="form-control" type="text" value="${encounterSourceUrl}">
                            </div>
                            <div>
                                <b>Practitioner Source:</b>
                                <label style="display: inline-block; margin-left: 1em">Info: api/Practitioner/<b style="color:red">Practitioner-ID</b></label>
                                <input onfocus="document.getElementById('practitionerSourceUrl').select();" id="practitionerSourceUrl" class="form-control" type="text" value="${practitionerSourceUrl}">
                            </div>
                            <div>
                                <a style="display: inline-block" target="_blank" href="${reportObservationsSourceHref}">Observations-Source</a>
                                <a style="display: inline-block; margin-left: 1em" target="_blank" href="${reportObservationsPdfHref}">PDF</a>
                                <label style="display: inline-block; margin-left: 1em">Info: api/Observations/<b style="color:red">PATIENT-ID</b></label>
                                <input onfocus="document.getElementById('observationLink').select();" id="observationLink" class="form-control" type="text" value="${reportObservationsSourceHref}">
                            </div>
                            <div>
                                <a target="_blank" href="${reportMedicationsSourceHref}">Medications-Source:</a>
                                <a style="display: inline-block; margin-left: 1em" target="_blank" href="${reportMedicationsPdfHref}">PDF</a>
                                <label style="display: inline-block; margin-left: 1em">Info: api/Medications/<b style="color:red">ENCOUNTER-ID</b></label>                                
                                <input onfocus="document.getElementById('medicationsLink').select();" id="medicationsLink" class="form-control" type="text" value="${reportMedicationsSourceHref}">
                            </div>
                             <div>
                                <a target="_blank" href="${reportGenerationUrl}">PDF-Generation</a><br />
                                <label>${ReportService.ReportServer}/api/Report/Generate/[practitionerId]/[patientId||"null"]/[encounterId||"null"]/[responseId||"null"][/bodyPart?/][reportFileName]</label>
                                <input onfocus="document.getElementById('generateLink').select();" id="generateLink" class="form-control" type="text" value="${reportGenerationUrl}">
                                <!-- <input onfocus="document.getElementById('pdfGenerationLink').select()" id="pdfGenerationLink" class="form-control" type="text" value="${reportGenerationUrl}"> -->
                            </div>
                        </div>
                    </div>`;

        this.dialogService.open({
            viewModel: questionnaireInfomations,
            model: { questionnaire: this.questionnaire, response: this.response, report: this.report, debugInfo: html },
            centerHorizontalOnly: true,
            lock: true
        });
    }

    showStopDialog(response: any) {
        if (this.patient.isOffline) return;
        let options: IPromptOptions = {
            message: this.i18n.tr("response_confirm_stop"),
            title: this.i18n.tr('confirm'),
            yesText: this.i18n.tr('yes'),
            noText: this.i18n.tr('no'),
            noButtonClass: 'btn-default',
            yesButtonClass: 'btn-default',
            headerClass: 'warning'
        };

        this.dialogService.open({
            viewModel: Prompt,
            model: options,
            ignoreTransitions: true,
            lock: true
        })
            .whenClosed(result => {
                if (result.wasCancelled) return;
                this.showStopDialog2(response);
            });
    }

    afterResponseStopped(response: any) {
        this.notifier.notify(this.patient, this.patient.flags);
    }

    showStopDialog2(responseItem: any) {
        let options: IPromptOptions = {
            message: this.i18n.tr("response_reason_stop"),
            title: this.i18n.tr('confirm'),
            yesText: this.i18n.tr("response_reason_in_error"),
            noText: this.i18n.tr("response_reason_discarted"),
            noButtonClass: 'btn-warning',
            yesButtonClass: 'btn-primary',
            headerClass: 'danger'
        };

        this.dialogService.open({
            viewModel: Prompt,
            model: options,
            ignoreTransitions: true,
            lock: true
        })
            .whenClosed(result => {
                this.isLoading = true;
                let status = result.wasCancelled ? fhirEnums.QuestionnaireResponseStatus.stopped : fhirEnums.QuestionnaireResponseStatus.enteredInError;

                responseItem.status = status;
                const bundleItems: any[] = [responseItem];
                let index = this.patient.questionnaireResponses.findIndex(o => o.id === responseItem.id);

                if (index > -1) {
                    this.patient.questionnaireResponses[index].status = status;
                    this.patient.questionnaireResponses.splice(index, 1);
                }

                if (this.patient.flags) {
                    this.createLatestDocumentFlag();
                    bundleItems.push(this.patient.flags);
                }

                this.fhirService.bundle(bundleItems, HTTPVerb.put, BundleType.transaction)
                    .then(() => {
                        this.afterResponseStopped(responseItem);
                        this.isLoading = false;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        this.dialogMessages.showHttpError(error);
                    });
            });
    }

    headerDateChanged(responseId: string, newDate: Date) {
    }

    async refreshFormName() {
        if (this.route) {
            this.setting = ConfigService.GetFormSettings(this.route, this.patient);

            if (this.setting) {
                this.maxAge = this.setting.expiration ? this.setting.expiration.default || 24 : 48;
                if (this.setting.title)
                    BasicForm.pageTitle = this.i18n.tr(this.setting.title);
                this.questionnaireName = this.setting.questionnaireName;
                if (this.setting.report?.name) {
                    this.report = this.setting.report.name;
                } else {
                    this.report = this.questionnaireName + '.FRX';
                }
            }

            // this.report = await PatientItem.GetReportName(this.patient, this.route);
            // this.questionnaireName = PatientItem.GetQuestionnaireName(this.patient, this.route, this.setting?.questionnaireName);
            //console.debug(`using QuestionnaireName: "${this.questionnaireName}" with ReportName: "${this.report}"`)
        }
    }

    viewMode: string = 'combo';
    async attached() {
        await this.questionnaireService.fetch();
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        if (this._route) {
            await this.refreshFormName();

            const setting = ConfigService.GetFormSettings(this.route);
            this.viewMode = setting?.viewMode || 'combo';
            if (setting) {
                this.hasDefaultResponseItems = !!setting["defaultResponseItem"];
                if (this.hasDefaultResponseItems) {
                    this.defaultResponseItems = setting["defaultResponseItem"];
                }
            }
        }

        this.logoutService?.refreshLogoutTimer();
    }

    hasDefaultResponseItems: boolean = false;
    defaultResponseItems: { name: string, file: string }[] = undefined;

    async patientChanged(patient: PatientItem) {
        if (patient) this.readonly = this.readonly || patient.isOffline;
        if (patient && !patient.latestAssessment) {
            patient.assessmentName = patient.getAssessmentName();
            patient.anamnesisName = patient.getAnamnesisName();
            const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(patient.assessmentName);
            patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(patient, questionnaireAssessment.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
        }

        if (!this.patient || !this.notifier) return;
        this.notifier.notify(this.patient);
    }
}
