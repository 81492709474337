import {AnalyzerSEMPA} from "./sempa";
import {ISempaResult, ISempaRow} from "./isempa-result";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {ConfigService} from "../ConfigService";
import {QuestionnaireService} from "../QuestionnaireService";
import {fhirEnums} from "../../classes/fhir-enums";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {Questionnaire, QuestionnaireResponse} from "../../classes/FhirModules/Fhir";
import {isNaN} from "lodash";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {NitTools} from "../../classes/NursitTools";
import {PatientService} from "../PatientService";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import * as M from "minimatch";

const moment = require('moment');

export class AnalyzerSempaIntensiv extends AnalyzerSEMPA {
    _version = "SEMPA-Intensiv";

    //#region Vars and Helpers    
    /***
     * **overrides** the sempa-analyzer *sempaResult*-property
     * @protected
     */
    public sempaResult: ISempaResultIntensiv;

    public get resultHtml(): string {
        if (!this._resultHtml) {
            this._resultHtml = this.generateHtml(this.sempaResult);
        }

        return this._resultHtml;
    }

    ncsip?: INCSPResult;
    _gcsName: string = undefined; // backing field for the readonly gcsName property

    /***
     * Gets the name of the GCS-Questionnaire from the config (route: *CareIT_GCS*)
     */
    public get gcsName(): string {
        if (!this._gcsName) {
            this._gcsName = PatientItem.GetQuestionnaireName(this.patient, 'CareIT_GCS', 'CareITGCS');
        }

        return this._gcsName;
    }

    _bpsName: string = undefined; // backing field for the readonly bpsName property

    /***
     * Gets the name of the BPS-Questionnaire from the config (route: *CareIT_BPS*)
     */
    public get bpsName(): string {
        if (!this._bpsName) {
            this._bpsName = PatientItem.GetQuestionnaireName(this.patient, 'CareIT_BPS', 'CareITBPS');
        }

        return this._bpsName;
    }

    _bpsNIName: string;
    public get bpsNIName(): string {
        if (!this._bpsNIName) {
            this._bpsNIName = PatientItem.GetQuestionnaireName(this.patient, 'CareIT_BPSNI', 'CareITBPSNI');
        }

        return this._bpsNIName;
    }

    _nihName: string = undefined;
    public get nihName(): string {
        if (!this._nihName) {
            this._nihName = PatientItem.GetQuestionnaireName(this.patient, 'CareIT_NIH', 'CareITNIHStrokeScaleIP');
        }

        return this._nihName;
    }

    static GetOptionText = (value: string, item: any) => {
        return QuestionnaireResponse.GetOptionText(value, item);
    };

    static GetOrdinalValue = (value: string, item: any) => {
        return QuestionnaireResponse.GetOrdinalValue(value, item);
    };

    protected static SetResponseValue(questionnaire: any, responseItem: any, value) {
        if (!questionnaire || !responseItem) {
            console.warn(`not setting value in SetResponseValue() cause of ${questionnaire ? '' : 'missing Questionnaire'} ${responseItem ? '' : 'no ResponseItem'}`);
            return 0;
        }

        if (typeof value === "undefined") {
            responseItem.answer = [];
            return 0;
        }

        const qItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, responseItem.linkId);
        responseItem.answer = [
            {
                valueCoding: {
                    code: value,
                    display: AnalyzerSempaIntensiv.GetOptionText(value, qItem)
                }
            }
        ];

        return AnalyzerSempaIntensiv.GetOrdinalValue(value, qItem);
    }

    //#endregion

    /***
     * **Maps** values from the assessment to the provided gcs response
     * @param assessment the assessment to get the values for the mapping from
     * @param gcsResponse the gcs response to write the values to
     * @protected
     */
    protected mapGCSValues(assessment: any, gcsResponse: any): number {
        const gcsQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(gcsResponse.questionnaire);

        const val_NIT_SVAs_23 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_23');
        const val_NIT_SVAs_24 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_24');
        const val_NIT_SVAs_25 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_25');

        const ritem_NIT_GCS_100 = QuestionnaireResponse.GetResponseItemByLinkId(gcsResponse, 'NIT_GCS_100');
        const qitem_NIT_GCS_100 = Questionnaire.GetQuestionnaireItemByLinkId(gcsQuestionnaire, 'NIT_GCS_100');

        const ritem_NIT_GCS_101 = QuestionnaireResponse.GetResponseItemByLinkId(gcsResponse, 'NIT_GCS_101');
        const qitem_NIT_GCS_101 = Questionnaire.GetQuestionnaireItemByLinkId(gcsQuestionnaire, 'NIT_GCS_101');

        const ritem_NIT_GCS_102 = QuestionnaireResponse.GetResponseItemByLinkId(gcsResponse, 'NIT_GCS_102');
        const qitem_NIT_GCS_102 = Questionnaire.GetQuestionnaireItemByLinkId(gcsQuestionnaire, 'NIT_GCS_102');

        const ritem_NIT_GCS_103 = QuestionnaireResponse.GetResponseItemByLinkId(gcsResponse, 'NIT_GCS_103');

        // only map the values when the response is not completed/amended
        if (gcsResponse.status !== 'in-progress') {
            return QuestionnaireResponse.GetResponseItemValue(ritem_NIT_GCS_103, NaN);
        }

        let sum = 0;
        //#region Augenöffnen (NIT_SVAs_23 -> GCS_100)
        let value_GCS_100 = undefined;
        switch (val_NIT_SVAs_23) {
            case 'NIT_SVAs_23_00':
                value_GCS_100 = 'NIT_GCS_100_00'; // 4
                break;
            case 'NIT_SVAs_23_01':
                value_GCS_100 = 'NIT_GCS_100_01'; // 3
                break;
            case 'NIT_SVAs_23_02':
                value_GCS_100 = 'NIT_GCS_100_02'; // 2
                break;
            case 'NIT_SVAs_23_03':
                value_GCS_100 = 'NIT_GCS_100_03'; // 1
                break;
            default:
                value_GCS_100 = undefined;
                sum = -1000;
                break;
        }

        if (value_GCS_100) {
            sum += AnalyzerSempaIntensiv.GetOrdinalValue(value_GCS_100, qitem_NIT_GCS_100);
            ritem_NIT_GCS_100.answer = [
                {
                    valueCoding: {
                        code: value_GCS_100,
                        display: AnalyzerSempaIntensiv.GetOptionText(value_GCS_100, qitem_NIT_GCS_100)
                    }
                }
            ];
        }
        //#endregion

        //#region Beste sprachliche Antwort (NIT_SVAs_24 -> GCS_101)
        let value_GCS_101 = undefined;
        switch (val_NIT_SVAs_24) {
            case 'NIT_SVAs_24_00':
                value_GCS_101 = 'NIT_GCS_101_00'; // 5
                break;
            case 'NIT_SVAs_24_01':
                value_GCS_101 = 'NIT_GCS_101_01'; // 4
                break;
            case 'NIT_SVAs_24_02':
                value_GCS_101 = 'NIT_GCS_101_02'; // 3
                break;
            case 'NIT_SVAs_24_03':
                value_GCS_101 = 'NIT_GCS_101_03'; // 2
                break;
            case 'NIT_SVAs_24_04':
                value_GCS_101 = 'NIT_GCS_101_04'; // 1
                break;
            default:
                value_GCS_101 = undefined;
                sum = -1000;
                break;
        }

        if (value_GCS_101) {
            sum += AnalyzerSempaIntensiv.GetOrdinalValue(value_GCS_101, qitem_NIT_GCS_101);
            ritem_NIT_GCS_101.answer = [
                {
                    valueCoding: {
                        code: value_GCS_101,
                        display: AnalyzerSempaIntensiv.GetOptionText(value_GCS_101, qitem_NIT_GCS_101)
                    }
                }
            ];
        }
        //#endregion

        //#region beste motorische Antwort (NIT_SVAs_25 -> GCS_102)
        let value_GCS_102 = undefined;
        switch (val_NIT_SVAs_25) {
            case 'NIT_SVAs_25_00':
                value_GCS_102 = 'NIT_GCS_102_00'; // 6
                break;
            case 'NIT_SVAs_25_01':
                value_GCS_102 = 'NIT_GCS_102_01'; // 5
                break;
            case 'NIT_SVAs_25_02':
                value_GCS_102 = 'NIT_GCS_102_02'; // 4
                break;
            case 'NIT_SVAs_25_03':
                value_GCS_102 = 'NIT_GCS_102_03'; // 3
                break;
            case 'NIT_SVAs_25_04':
                value_GCS_102 = 'NIT_GCS_102_04'; // 2
                break;
            case 'NIT_SVAs_25_05':
                value_GCS_102 = 'NIT_GCS_102_05'; // 1
                break;
            default:
                value_GCS_102 = undefined;
                sum = -1000;
                break;
        }

        if (value_GCS_102) {
            sum += AnalyzerSempaIntensiv.GetOrdinalValue(value_GCS_102, qitem_NIT_GCS_102);
            ritem_NIT_GCS_102.answer = [
                {
                    valueCoding: {
                        code: value_GCS_102,
                        display: AnalyzerSempaIntensiv.GetOptionText(value_GCS_102, qitem_NIT_GCS_102)
                    }
                }
            ];
        }
        //#endregion

        if (sum >= 0) {
            ritem_NIT_GCS_103.answer = [
                {valueInteger: sum}
            ];
        }

        return sum;
    }

    /***
     * **Calculates** the Glasgow Coma Scala value from the given assessment
     * @param patient the patient to get the latest assessment/gcs for. Needed for the popup
     * @param assessment the assessment to take the values for the gcs mapping from for the calculation
     * @protected
     */
    public async calcGCS(patient: PatientItem, assessment: any) {
        // create standard result
        this.sempaResult.glasgow = {
            title: this.i18n.tr("sempa_glasgow_title"),
            text1: this.i18n.tr("not_classified"),
            text2: this.i18n.tr("not_existent_gcs"),
            isVisible: true
        };

        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        const formSettingsGcs = ConfigService.GetFormSettings('analysis').settings?.riskOverview?.find(o=>o.field === "gcs")?.visible;
        
        if (typeof formSettingsGcs === "boolean") {
            this.sempaResult.glasgow.isVisible = formSettingsGcs;
        }
                
        const gcsQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.gcsName);
        if (gcsQuestionnaire) {
            let gcsResponse = await QuestionnaireService.GetLatestResponseOfTypeAsync(this.patient, gcsQuestionnaire.id, [fhirEnums.QuestionnaireResponseStatus.inProgress, fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
            
            if (gcsResponse) {
                const num = QuestionnaireResponse.GetResponseItemValueIntByLinkId(gcsResponse, 'NIT_GCS_103', -1);
                
                if (num > 0) {
                    if (num <= 8 && num >= 0)
                        this.sempaResult.glasgow.text1 = this.i18n.tr("sempa_gcs_0");
                    else if (num >= 9 && num <= 12)
                        this.sempaResult.glasgow.text1 = this.i18n.tr("sempa_gcs_1");
                    else if (num >= 13 && num <= 15)
                        this.sempaResult.glasgow.text1 = this.i18n.tr("sempa_gcs_2");
 
                    this.sempaResult.glasgow.text2 = moment(gcsResponse?.authored).format(RuntimeInfo.DateTimeFormat);
                    this.sempaResult.glasgow.info = `(${num} / 15)`;
                    this.sempaResult.glasgow.sum = num;
                }

                window["gcsButtonClicked"] = async () => await this.createPopup(patient, assessment, gcsQuestionnaire.name, false);
                this.sempaResult.glasgow.text2 = this.getButtonText('gcsButtonClicked', gcsQuestionnaire.title, 'CareITGCS');
            }
        } else {
            console.warn(`No Questionnaire with name "${this.gcsName}" found!`);
        }
    }

    /***
     * **Maps** the values from the given assessment to the provided bpsResponse
     * @param assessment the assessment to get the values for mapping from
     * @param bpsResponse the BPS Response to write the mapped values to
     * @protected
     */
    protected mapBPSValues(assessment: any, bpsResponse: any): number {
        const responseItem_NIT_BPS_103 = QuestionnaireResponse.GetResponseItemByLinkId(bpsResponse, 'NIT_BPS_103', false);

        // only map the values when the response is not completed/amended
        if (bpsResponse.status === 'in-progress') {
            let sum = 0;
            const bpsQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.bpsName);
            const assignValue = (targetItem: any, value) => {
                if (!targetItem) {
                    console.warn(`No TargetItem for value "${value}" specified`);

                    if (ConfigService.Debug) {
                        debugger;
                    }
                } else {
                    if (value) {
                        sum += AnalyzerSempaIntensiv.SetResponseValue(bpsQuestionnaire, targetItem, value);
                    } else {
                        console.warn('Setting undefined value for:', targetItem);
                    }
                }
            };

            //#region Gesichtsausdruck (NIT_SVAs_207 -> NIT_BPS_100)
            const value_NIT_SVAs_207 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_207', undefined);
            const responseItem_NIT_BPS_100 = QuestionnaireResponse.GetResponseItemByLinkId(bpsResponse, 'NIT_BPS_100', false);
            let value_NIT_BPS_100: string = undefined;
            switch (value_NIT_SVAs_207) {
                case 'NIT_SVAs_207_01':
                    value_NIT_BPS_100 = 'NIT_BPS_100_01';
                    break;
                case 'NIT_SVAs_207_02':
                    value_NIT_BPS_100 = 'NIT_BPS_100_02';
                    break;
                case 'NIT_SVAs_207_03':
                    value_NIT_BPS_100 = 'NIT_BPS_100_03';
                    break;
                case 'NIT_SVAs_207_04':
                    value_NIT_BPS_100 = 'NIT_BPS_100_04';
                    break;
            }

            assignValue(responseItem_NIT_BPS_100, value_NIT_BPS_100);
            //#endregion

            //#region Obere Extremität (NIT_SVAs_208 -> NIT_BPS_101)
            const value_NIT_SVAs_208 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_208', undefined);
            const responseItem_NIT_BPS_101 = QuestionnaireResponse.GetResponseItemByLinkId(bpsResponse, 'NIT_BPS_101', false);
            let value_NIT_BPS_101: string = undefined;
            switch (value_NIT_SVAs_208) {
                case 'NIT_SVAs_208_01':
                    value_NIT_BPS_101 = 'NIT_BPS_101_01';
                    break;
                case 'NIT_SVAs_208_02':
                    value_NIT_BPS_101 = 'NIT_BPS_101_02';
                    break;
                case 'NIT_SVAs_208_03':
                    value_NIT_BPS_101 = 'NIT_BPS_101_03';
                    break;
                case 'NIT_SVAs_208_04':
                    value_NIT_BPS_101 = 'NIT_BPS_101_04';
                    break;
            }

            assignValue(responseItem_NIT_BPS_101, value_NIT_BPS_101);
            //#endregion

            //#region Anpassung an Beatmungsgerät (NIT_SVAs_209 -> NIT_BPS_102)
            const value_NIT_SVAs_209 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_209', undefined);
            const responseItem_NIT_BPS_102 = QuestionnaireResponse.GetResponseItemByLinkId(bpsResponse, 'NIT_BPS_102', false);

            let value_NIT_BPS_102: string = undefined;
            switch (value_NIT_SVAs_209) {
                case 'NIT_SVAs_209_01':
                    value_NIT_BPS_102 = 'NIT_BPS_102_01';
                    break;
                case 'NIT_SVAs_209_02':
                    value_NIT_BPS_102 = 'NIT_BPS_102_02';
                    break;
                case 'NIT_SVAs_209_03':
                    value_NIT_BPS_102 = 'NIT_BPS_102_03';
                    break;
                case 'NIT_SVAs_209_04':
                    value_NIT_BPS_102 = 'NIT_BPS_102_04';
                    break;
            }

            assignValue(responseItem_NIT_BPS_102, value_NIT_BPS_102);
            //#endregion

            // put the resulting sum into NIT_BPS_103
            responseItem_NIT_BPS_103.answer = [{
                valueInteger: sum
            }];
        }

        // always send the result as the value from NIT_BPS_103
        return QuestionnaireResponse.GetResponseItemValue(responseItem_NIT_BPS_103, NaN);
    }

    public async calcBPSNIValue(patient: PatientItem, assessment: any) {
        window["bpsNIButtonClicked"] = async () => await this.createPopup(patient, assessment, this.bpsNIName, false);
        this.sempaResult.bpsNI = {
            title: this.i18n.tr("sempa_bpsNI_title"),
            text1: this.i18n.tr("not_predictable"),
        };        

        let sum = 0;
        const bpsNIQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.bpsNIName);
        let bpsNIResponse = await this.ensureResponse(bpsNIQuestionnaire, patient, assessment);
        this.sempaResult.bpsNI.button = this.getButtonText('bpsNIButtonClicked', this.i18n.tr("sempa_bpsNI_button_text"), 'CareIT_BPSNI');

        assessment = QuestionnaireService.GetLatestResponseOfName(this.patient, this.assessmentName, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        console.warn(assessment?.item[3]);

        if (bpsNIResponse) {
            const responseItem_NIT_BPSNI_103 = QuestionnaireResponse.GetResponseItemByLinkId(bpsNIResponse, 'NIT_BPSNI_103', false);

            sum = QuestionnaireResponse.GetResponseItemValue(responseItem_NIT_BPSNI_103, NaN);
            if (typeof (sum) === "string") {
                sum = parseInt(sum);
            }

            if (isNaN(sum)) {
                sum = 0;
            }

            if (bpsNIResponse.status === 'in-progress') {
                const assignValue = (targetItem: any, value) => {
                    if (!targetItem) {
                        console.warn(`TargetItem for value "${value}" not found!`);
                    } else {
                        const add = AnalyzerSempaIntensiv.SetResponseValue(bpsNIQuestionnaire, targetItem, value);
                        if (!isNaN(add)) {
                            sum += add;
                        }
                    }
                };

                sum = 0;

                const responseItem_NIT_BPSNI_100 = QuestionnaireResponse.GetResponseItemByLinkId(bpsNIResponse, 'NIT_BPSNI_100', false);
                const responseItem_NIT_BPSNI_101 = QuestionnaireResponse.GetResponseItemByLinkId(bpsNIResponse, 'NIT_BPSNI_101', false);
                const responseItem_NIT_BPSNI_102 = QuestionnaireResponse.GetResponseItemByLinkId(bpsNIResponse, 'NIT_BPSNI_102', false);

                let value_NIT_BPSNI_100 = '';
                // Gesichtsausdruck/facial expression
                const response_SVAsNi_207 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsNI_207');
                switch (response_SVAsNi_207) {
                    case 'NIT_SVAs_207_01':
                    case 'NIT_SVAsNI_207_01':
                        value_NIT_BPSNI_100 = 'NIT_BPSNI_100_01';
                        break;
                    case 'NIT_SVAs_207_02':
                    case 'NIT_SVAsNI_207_02':
                        value_NIT_BPSNI_100 = 'NIT_BPSNI_100_02';
                        break;
                    case 'NIT_SVAs_207_03':
                    case 'NIT_SVAsNI_207_03':
                        value_NIT_BPSNI_100 = 'NIT_BPSNI_100_03';
                        break;
                    case 'NIT_SVAs_207_04':
                    case 'NIT_SVAsNI_207_04':
                        value_NIT_BPSNI_100 = 'NIT_BPSNI_100_04';
                        break;
                }
                assignValue(responseItem_NIT_BPSNI_100, value_NIT_BPSNI_100);

                let value_NIT_BPSNI_101 = '';
                // Obere Extremität / upper body
                const response_SVAsNI_208 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAsNI_208');
                switch (response_SVAsNI_208) {
                    case 'NIT_SVAs_208_01':
                    case 'NIT_SVAsNI_208_01':
                        value_NIT_BPSNI_101 = 'NIT_BPSNI_101_01';
                        break;
                    case 'NIT_SVAs_208_02':
                    case 'NIT_SVAsNI_208_02':
                        value_NIT_BPSNI_101 = 'NIT_BPSNI_101_02';
                        break;
                    case 'NIT_SVAs_208_03':
                    case 'NIT_SVAsNI_208_03':
                        value_NIT_BPSNI_101 = 'NIT_BPSNI_101_03';
                        break;
                    case 'NIT_SVAs_208_04':
                    case 'NIT_SVAsNI_208_04':
                        value_NIT_BPSNI_101 = 'NIT_BPSNI_101_04';
                        break;
                }
                assignValue(responseItem_NIT_BPSNI_101, value_NIT_BPSNI_101);

                let value_NIT_BPSNI_102 = '';
                // Vokalisation (bei NI-P. auszufüllen) / Vocalisation
                const response_NIT_SVAs_210 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_210');
                switch (response_NIT_SVAs_210) {
                    case 'NIT_SVAs_210_01':
                        value_NIT_BPSNI_102 = 'NIT_BPSNI_102_01';
                        break;
                    case 'NIT_SVAs_210_02':
                        value_NIT_BPSNI_102 = 'NIT_BPSNI_102_02';
                        break;
                    case 'NIT_SVAs_210_03':
                        value_NIT_BPSNI_102 = 'NIT_BPSNI_102_03';
                        break;
                    case 'NIT_SVAs_210_04':
                        value_NIT_BPSNI_102 = 'NIT_BPSNI_102_04';
                        break;
                }

                assignValue(responseItem_NIT_BPSNI_102, value_NIT_BPSNI_102);

                responseItem_NIT_BPSNI_103.answer = [
                    {valueInteger: sum}
                ];
            }

            if (!isNaN(sum))
                this.sempaResult.bpsNI.text1 = String(sum);
            else
                this.sempaResult.bpsNI.text1 = this.i18n.tr("not_predictable");

            this.sempaResult.bpsNI.sum = sum;
            this.sempaResult.bpsNI.text1 += this.getWarningMessage(bpsNIResponse);

            this.sempaResult.bpsNI.isValidated = bpsNIResponse.status !== 'in-progress';

            sum = isNaN(sum) ? 0 : sum;

            return sum;
        }
    }

    /*** Ensures the existence of the needed BPS-Response and creates if not existend. <br />
     * Afterwards fills the Sempa-Result.BPS with the calculated data.
     * @param patient the patient to process the BPS for
     * @param assessment the assessment to get the values for mappings from
     * @protected
     */
    public async calcBPSValue(patient: PatientItem, assessment: any) {
        this.sempaResult.bps = {
            title: this.i18n.tr("sempa_bps_title"),
            text1: this.i18n.tr("not_predictable"),
            button: '',
            text2: ''
        };

        let BPSSum: number = NaN;
        const bpsQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(this.bpsName);        
        let bpsResponse = await this.ensureResponse(bpsQuestionnaire, patient, assessment);

        if (bpsResponse) {
            const value_NIT_SVAs_206 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_206');
            if (value_NIT_SVAs_206 !== 'NIT_SVAs_206_00') { // when value is NO/Nein give the Info why there is no mapping
                BPSSum = this.mapBPSValues(assessment, bpsResponse);

                window["bpsButtonClicked"] = async () => await this.createPopup(patient, assessment, this.bpsName, false);

                this.sempaResult.bps.text1 = isNaN(BPSSum) ? this.i18n.tr("not_predictable") : String(BPSSum);
                this.sempaResult.bps.sum = isNaN(BPSSum) ? undefined : BPSSum;
                this.sempaResult.bps.text1 += this.getWarningMessage(bpsResponse);

                this.sempaResult.bps.button = this.getButtonText('bpsButtonClicked', this.i18n.tr("sempa_bps_button_text"), 'CareIT_BPS');
            } else {
                this.sempaResult.bps.text1 = this.i18n.tr("sempa_bps_no_pain");
            }

            this.sempaResult.bps.isValidated = bpsResponse.status !== 'in-progress';
        }
    }

    public async calcNIHValue(patient: PatientItem, assessment: any) {
        this.sempaResult.nih = {
            title: this.i18n.tr("sempa_nih_title"),
            text1: this.i18n.tr("sempa_nih_not_reviewed")
        };

        let nihResponse: any;
        // create NIH Response if not existent
        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        const nihSetting = ConfigService.GetFormSettings('CareIT_NIH');
        if (nihSetting && nihSetting.questionnaireName) {
            const questionnaireNIH = QuestionnaireService.GetQuestionnaireByNameDirect(nihSetting.questionnaireName);
            if (questionnaireNIH) {
                nihResponse = await this.ensureResponse(questionnaireNIH, patient, assessment);
            }
        } else {
            nihResponse = await QuestionnaireResponse.GetAttachedResponse(patient, assessment, this.nihName);
        }

        if (nihResponse) {
            // Pupillenreaktion (group: NIT_SVAs_17)
            // Rechts (NIT_SVAs_18)
            // Links (NIT_SVAs_19)
            // Überprüfung Reflexe (NIT_SVAs_12, when "JA" (=NIT_SVAs_12_01) show values, when "NO" (=NIT_SVAs_12_00) display hint text why not

            const value_NIT_SVAs_12 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_12', 'NIT_SVAs_12_NIL');

            if (typeof this.sempaResult?.nih === "object" && ['NIT_SVAS_12_00', 'NIT_SVAS_12_00_NIL'].indexOf(value_NIT_SVAs_12.toUpperCase()) === -1) {
                const value_NIT_SVAs_18 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_18'); // right
                const value_NIT_SVAs_19 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_19'); // left
                const questionnaireAssessment = QuestionnaireService.GetQuestionnaireDirect(assessment.questionnaire);

                let textRight = QuestionnaireResponse.GetOptionText(value_NIT_SVAs_18, Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_18'), '   ');
                let textLeft = QuestionnaireResponse.GetOptionText(value_NIT_SVAs_19, Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_19'), '   ');
                textLeft = textLeft[0].toUpperCase() + textLeft.substr(1);
                textRight = textRight[0].toUpperCase() + textRight.substr(1);

                this.sempaResult.nih.text1 = `L: ${textLeft} &nbsp;-&nbsp; R:${textRight}`;
                this.sempaResult.nih.text1 += this.getWarningMessage(nihResponse);

                window["nihButtonClicked"] = async () => await this.createPopup(patient, assessment, this.nihName, false);
                this.sempaResult.nih.button = this.getButtonText('nihButtonClicked', this.i18n.tr("sempa_nih_button_text"), 'CareIT_NIH');

                // get the value from "Gesamtpunktewert" (=NIT_NIH_216) when the NIH-Response has been validated
                if (nihResponse.status !== 'in-progress') {
                    this.sempaResult.nih.isValidated = true;

                    const value_NIT_NIH_216: number = QuestionnaireResponse.GetResponseItemValueByLinkId(nihResponse, 'NIT_NIH_216', NaN);
                    if (!isNaN(value_NIT_NIH_216)) {
                        if (value_NIT_NIH_216 <= 0) {
                            this.sempaResult.nih.text2 = this.i18n.tr("sempa_nih_0");
                            this.sempaResult.nih.sum = 0;
                        } else if (value_NIT_NIH_216 >= 1 && value_NIT_NIH_216 <= 4) {
                            this.sempaResult.nih.text2 = this.i18n.tr("sempa_nih_1");
                            this.sempaResult.nih.sum = 1;
                        } else if (value_NIT_NIH_216 >= 5 && value_NIT_NIH_216 <= 15) {
                            this.sempaResult.nih.text2 = this.i18n.tr("sempa_nih_2");
                            this.sempaResult.nih.sum = 2;
                        } else if (value_NIT_NIH_216 >= 16 && value_NIT_NIH_216 <= 20) {
                            this.sempaResult.nih.text2 = this.i18n.tr("sempa_nih_3");
                            this.sempaResult.nih.sum = 3;
                        } else if (value_NIT_NIH_216 >= 21) {
                            this.sempaResult.nih.text2 = this.i18n.tr("sempa_nih_4");
                            this.sempaResult.nih.sum = 4;
                        }

                        this.sempaResult.nih.sum = value_NIT_NIH_216;
                    } else {
                        this.sempaResult.nih.sum = undefined;
                    }
                }
            }
        }
    }

    public async calcNCSIP(patient: PatientItem, assessment: any): Promise<INCSPResult> {
        // const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(this.assessmentName);

        //#region NIT_SVAs_300 und NIT_SVAs_301 => sum_Block0
        let value_300 = -1;
        let value_301 = -1;

        //#region NIT_SVAs_300 : [choice] : "Körperpflege Oberkörper" => value_300
        const value_NIT_SVAs_300 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_300', undefined);
        // const item_NIT_SVAs_300 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_300');
        switch (value_NIT_SVAs_300) {
            default:
            case 'NIT_SVAs_300_00':
                value_300 = 0;
                break;
            case 'NIT_SVAs_300_01':
                value_300 = 1;
                break;
            case 'NIT_SVAs_300_02':
                value_300 = 2;
                break;
            case 'NIT_SVAs_300_03':
                value_300 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_301 : [choice] : "Körperpflege Unterkörper" => value_301
        const value_NIT_SVAs_301 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_301', undefined);
        // const item_NIT_SVAs_301 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_301');
        switch (value_NIT_SVAs_301) {
            default:
            case 'NIT_SVAs_301_00':
                value_301 = 0;
                break;
            case 'NIT_SVAs_301_01':
                value_301 = 1;
                break;
            case 'NIT_SVAs_301_02':
                value_301 = 2;
                break;
            case 'NIT_SVAs_301_03':
                value_301 = 3;
                break;
        }
        //#endregion

        const sum_Block0: number = Math.max(value_300, value_301);
        //#endregion


        //#region NIT_SVAs_100 => sum_Block1
        //#region NIT_SVAs_100 : [choice] : "Mobilität"
        const value_NIT_SVAs_100 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100', undefined);
        // const item_NIT_SVAs_100 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_100');
        let sum_Block1 = 0;
        switch (value_NIT_SVAs_100) {
            default:
            case 'NIT_SVAs_100_00':
                sum_Block1 = 0;
                break;
            case 'NIT_SVAs_100_01':
                sum_Block1 = 1;
                break;
            case 'NIT_SVAs_100_02':
                sum_Block1 = 2;
                break;
            case 'NIT_SVAs_100_03':
                sum_Block1 = 3;
                break;
        }
        //#endregion
        //#endregion


        //#region NIT_SVAs_600 und NIT_SVAs_601 => sum_Block2
        let value_600 = 0;
        let value_601 = 0;
        //#region NIT_SVAs_600 : [choice] : "An- und Auskleiden Oberkörper" => value_600
        const value_NIT_SVAs_600 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_600', undefined);
        // const item_NIT_SVAs_600 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_600');
        switch (value_NIT_SVAs_600) {
            default:
            case 'NIT_SVAs_600_00':
                value_600 = 0;
                break;
            case 'NIT_SVAs_600_01':
                value_600 = 1;
                break;
            case 'NIT_SVAs_600_02':
                value_600 = 2;
                break;
            case 'NIT_SVAs_600_03':
                value_600 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_601 : [choice] : "An- und Auskleiden Unterkörper" => value_601
        const value_NIT_SVAs_601 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_601', undefined);
        // const item_NIT_SVAs_601 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_601');
        switch (value_NIT_SVAs_601) {
            default:
            case 'NIT_SVAs_601_00':
                value_601 = 0;
                break;
            case 'NIT_SVAs_601_01':
                value_601 = 1;
                break;
            case 'NIT_SVAs_601_02':
                value_601 = 2;
                break;
            case 'NIT_SVAs_601_03':
                value_601 = 3;
                break;
        }
        //#endregion

        const sum_Block2 = Math.max(value_600, value_601);
        //#endregion

        //#region NIT_SVAs_202 und NIT_SVAs_207 und NIT_SVAs_208 und NIT_SVAs_209 und NIT_SVAs_210 => sum_Block3
        let sum_Block3 = 0;
        //#region NIT_SVAs_202 : [choice] : "Schmerzen" => value_202
        let value_202 = 0;
        const value_NIT_SVAs_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_202', undefined);
        // const item_NIT_SVAs_202 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_202');
        switch (value_NIT_SVAs_202) {
            default:
            case 'NIT_SVAs_202_00':
                value_202 = 0;
                break;
            case 'NIT_SVAs_202_01':
            case 'NIT_SVAs_202_02':
            case 'NIT_SVAs_202_03':
                value_202 = 1;
                break;
            case 'NIT_SVAs_202_04':
            case 'NIT_SVAs_202_05':
            case 'NIT_SVAs_202_06':
                value_202 = 2;
                break;
            case 'NIT_SVAs_202_07':
            case 'NIT_SVAs_202_08':
            case 'NIT_SVAs_202_09':
            case 'NIT_SVAs_202_10':
                value_202 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_207 : [choice] : "Gesichtsausdruck" => value_207
        let value_207 = 0;
        const value_NIT_SVAs_207 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_207', undefined);
        // const item_NIT_SVAs_207 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_207');
        switch (value_NIT_SVAs_207) {
            default:
            case 'NIT_SVAs_207_00':
                value_207 = 0;
                break;
            case 'NIT_SVAs_207_01':
                value_207 = 1;
                break;
            case 'NIT_SVAs_207_02':
                value_207 = 2;
                break;
            case 'NIT_SVAs_207_03':
                value_207 = 3;
                break;
            case 'NIT_SVAs_207_04':
                value_207 = 4;
                break;
        }
        //#endregion

        //#region NIT_SVAs_208 : [choice] : "Obere Extremität" => value_208
        let value_208 = 0;
        const value_NIT_SVAs_208 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_208', undefined);
        // const item_NIT_SVAs_208 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_208');
        switch (value_NIT_SVAs_208) {
            default:
            case 'NIT_SVAs_208_00':
                value_208 = 0;
                break;
            case 'NIT_SVAs_208_01':
                value_208 = 1;
                break;
            case 'NIT_SVAs_208_02':
                value_208 = 2;
                break;
            case 'NIT_SVAs_208_03':
                value_208 = 3;
                break;
            case 'NIT_SVAs_208_04':
                value_208 = 4;
                break;
        }
        //#endregion

        //#region NIT_SVAs_209 : [choice] : "Anpassung an Beatmungsgerät (bei beatmeten .." => value_209
        let value_209 = 0;
        const value_NIT_SVAs_209 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_209', undefined);
        // const item_NIT_SVAs_209 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_209');
        switch (value_NIT_SVAs_209) {
            default:
            case 'NIT_SVAs_209_00':
                value_209 = 0;
                break;
            case 'NIT_SVAs_209_01':
                value_209 = 1;
                break;
            case 'NIT_SVAs_209_02':
                value_209 = 2;
                break;
            case 'NIT_SVAs_209_03':
                value_209 = 3;
                break;
            case 'NIT_SVAs_209_04':
                value_209 = 4;
                break;
        }
        //#endregion

        //#region NIT_SVAs_210 : [choice] : "Vokalisation (bei NI-P. auszufüllen)" => value_210
        let value_210 = 0;
        const value_NIT_SVAs_210 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_210', undefined);
        // const item_NIT_SVAs_210 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_210');
        switch (value_NIT_SVAs_210) {
            default:
            case 'NIT_SVAs_210_00':
                value_210 = 0;
                break;
            case 'NIT_SVAs_210_01':
                value_210 = 1;
                break;
            case 'NIT_SVAs_210_02':
                value_210 = 2;
                break;
            case 'NIT_SVAs_210_03':
                value_210 = 3;
                break;
            case 'NIT_SVAs_210_04':
                value_210 = 4;
                break;
        }
        //#endregion

        const sum_subBlock3_1 = value_207 + value_208 + value_209;
        const sum_subBlock3_2 = value_207 + value_208 + value_210;
        const max_subBlock3_12: number = Math.max(sum_subBlock3_1, sum_subBlock3_2);
        switch (max_subBlock3_12) {
            case 0:
            case 1:
            case 2:
                sum_Block3 = 0;
                break;
            case 4:
            case 5:
            case 6:
                sum_Block3 = 1;
                break;
            case 7:
            case 8:
            case 9:
                sum_Block3 = 2;
                break;
            case 10:
            case 11:
            case 12:
                sum_Block3 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_400 und NIT_SVAs_403 und NIT_SVAs_404 und NIT_SVAs_405 => sum_Block4
        //#region NIT_SVAs_400 : [choice] : "Ernährungszustand" => value_400
        let value_400 = 0;
        const value_NIT_SVAs_400 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_400', undefined);
        // const item_NIT_SVAs_400 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_400');
        switch (value_NIT_SVAs_400) {
            default:
            case 'NIT_SVAs_400_00':
                break;
            case 'NIT_SVAs_400_01':
                value_400 = 1;
                break;
            case 'NIT_SVAs_400_02':
                value_400 = 2;
                break;
            case 'NIT_SVAs_400_03':
                value_400 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_403 : [choice] : "Essen" => value_403
        let value_403 = 0;
        const value_NIT_SVAs_403 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_403', undefined);
        // const item_NIT_SVAs_403 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_403');
        switch (value_NIT_SVAs_403) {
            default:
            case 'NIT_SVAs_403_00':
                break;
            case 'NIT_SVAs_403_01':
                value_403 = 1;
                break;
            case 'NIT_SVAs_403_02':
                value_403 = 2;
                break;
            case 'NIT_SVAs_403_03':
                value_403 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_404 : [choice] : "Ernährung parenteral oder über Sonde" => value_404
        let value_404 = 0;
        const value_NIT_SVAs_404 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_404', undefined);
        // const item_NIT_SVAs_404 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_404');
        switch (value_NIT_SVAs_404) {
            default:
            case 'NIT_SVAs_404_00':
                break;
            case 'NIT_SVAs_404_01':
                value_404 = 1;
                break;
            case 'NIT_SVAs_404_02':
                value_404 = 2;
                break;
            case 'NIT_SVAs_404_03':
                value_404 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_405 : [choice] : "Trinken - Flüssigkeitsaufnahme" => value_405
        let value_405 = 0;
        const value_NIT_SVAs_405 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_405', undefined);
        // const item_NIT_SVAs_405 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_405');
        switch (value_NIT_SVAs_405) {
            default:
            case 'NIT_SVAs_405_00':
                break;
            case 'NIT_SVAs_405_01':
                value_405 = 1;
                break;
            case 'NIT_SVAs_405_02':
                value_405 = 2;
                break;
            case 'NIT_SVAs_405_03':
                value_405 = 3;
                break;
        }
        //#endregion
        const sum_Block4 = Math.max(value_400, value_403, value_404, value_405);
        //#endregion

        //#region NIT_SVAs_500 und NIT_SVAs_503 und NIT_SVAs_506 => sum_Block5
        //#region  NIT_SVAs_500 : [choice] : "Toilettenbenutzung (ggf. Toilettenstuhlbenu.." => value_500
        let value_500 = 0;
        const value_NIT_SVAs_500 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_500', undefined);
        // const item_NIT_SVAs_500 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_500');
        switch (value_NIT_SVAs_500) {
            default:
            case 'NIT_SVAs_500_00':
                break;
            case 'NIT_SVAs_500_01':
                value_500 = 1;
                break;
            case 'NIT_SVAs_500_02':
                value_500 = 2;
                break;
            case 'NIT_SVAs_500_03':
                value_500 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_503 : [choice] : "Umgang mit der Harnausscheidung" => value_503
        let value_503 = 0;
        const value_NIT_SVAs_503 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_503', undefined);
        // const item_NIT_SVAs_503 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_503');
        switch (value_NIT_SVAs_503) {
            default:
            case 'NIT_SVAs_503_00':
                break;
            case 'NIT_SVAs_503_01':
                value_503 = 1;
                break;
            case 'NIT_SVAs_503_02':
                value_503 = 2;
                break;
            case 'NIT_SVAs_503_03':
                value_503 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_506 : [choice] : "Umgang mit der Stuhlausscheidung" => value_506
        let value_506 = 0;
        const value_NIT_SVAs_506 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_506', undefined);
        // const item_NIT_SVAs_506 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_506');
        switch (value_NIT_SVAs_506) {
            default:
            case 'NIT_SVAs_506_00':
                break;
            case 'NIT_SVAs_506_01':
                value_506 = 1;
                break;
            case 'NIT_SVAs_506_02':
                value_506 = 2;
                break;
            case 'NIT_SVAs_506_03':
                value_506 = 3;
                break;
        }
        //#endregion

        const sum_Block5 = Math.max(value_500, value_503, value_506);
        //#endregion

        //#region NIT_SVAs_201 und NIT_SVAs_205 und NIT_SVAs_161 und NIT_SVAs_162 => sum_Block6
        //#region NIT_SVAs_201 : [choice] : "Atmung" => value_201
        let value_201 = 0;
        const value_NIT_SVAs_201 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_201', undefined);
        // const item_NIT_SVAs_201 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_201');
        switch (value_NIT_SVAs_201) {
            default:
            case 'NIT_SVAs_201_00':
                break;
            case 'NIT_SVAs_201_01':
                value_201 = 1;
                break;
            case 'NIT_SVAs_201_02':
                value_201 = 2;
                break;
            case 'NIT_SVAs_201_03':
                value_201 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_205 : [choice] : "Tracheostoma" => value_205
        const value_NIT_SVAs_205 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_205', undefined);
        //const item_NIT_SVAs_205 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_205');
        let value_205 = value_NIT_SVAs_205 === 'NIT_SVAs_205_00' ? 0 : 1;
        //#endregion

        //#region NIT_SVAs_161 : [choice] : "Sediert?" => value_161
        const value_NIT_SVAs_161 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_161', undefined);
        // const item_NIT_SVAs_161 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_161');
        let value_161 = value_NIT_SVAs_161 === 'NIT_SVAs_161_00' ? 2 : 0;
        //#endregion

        //#region NIT_SVAs_162 : [choice] : "Beatmet?" => value_162
        const value_NIT_SVAs_162 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_162', undefined);
        // const item_NIT_SVAs_162 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_162');
        let value_162 = value_NIT_SVAs_162 === 'NIT_SVAs_162_00' ? 3 : 0;
        //#endregion

        const sum_Block6 = Math.max(value_201, value_205, value_161, value_162);
        //#endregion

        //#region NIT_SVAs_700 und NIT_SVAs_702 und NIT_SVAs_703 => sum_Block7
        //#region NIT_SVAs_700 : [choice] : "Ruhen und Schlafen" => value_700
        let value_700 = 0;
        const value_NIT_SVAs_700 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_700', undefined);
        // const item_NIT_SVAs_700 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_700');
        switch (value_NIT_SVAs_700) {
            default:
            case 'NIT_SVAs_700_00':
                break;
            case 'NIT_SVAs_700_01':
                value_700 = 1;
                break;
            case 'NIT_SVAs_700_02':
                value_700 = 2;
                break;
            case 'NIT_SVAs_700_03':
                value_700 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_702 : [choice] : "Wie ist das Verhältnis zwischen Nacht- und .." => value_702
        let value_702 = 0;
        const value_NIT_SVAs_702 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_702', undefined);
        // const item_NIT_SVAs_702 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_702');
        switch (value_NIT_SVAs_702) {
            default:
            case 'NIT_SVAs_702_00':
                break;
            case 'NIT_SVAs_702_01':
                value_702 = 1;
                break;
            case 'NIT_SVAs_702_02':
                value_702 = 2;
                break;
            case 'NIT_SVAs_702_03':
                value_702 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_703 : [choice] : "Wie ist die Schlafhygiene?" => value_703
        let value_703 = 0;
        const value_NIT_SVAs_703 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_703', undefined);
        //const item_NIT_SVAs_703 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_703');
        switch (value_NIT_SVAs_703) {
            default:
            case 'NIT_SVAs_703_00':
                break;
            case 'NIT_SVAs_703_01':
                value_703 = 1;
                break;
            case 'NIT_SVAs_703_02':
                value_703 = 2;
                break;
            case 'NIT_SVAs_703_03':
                value_703 = 3;
                break;
        }
        //#endregion

        const sum_Block7 = Math.max(value_700, value_702, value_703);
        //#endregion

        //#region NIT_SVAs_00 und NIT_SVAs_01 und NIT_SVAs_03 und NIT_SVAs_05 => sum_Block8
        //#region NIT_SVAs_00 : [choice] : "Hörfähigkeit" => value_00
        let value_00 = 0;
        const value_NIT_SVAs_00 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_00', undefined);
        // const item_NIT_SVAs_00 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_00');
        switch (value_NIT_SVAs_00) {
            default:
            case 'NIT_SVAs_00_00':
                break;
            case 'NIT_SVAs_00_01':
                value_00 = 1;
                break;
            case 'NIT_SVAs_00_02':
                value_00 = 2;
                break;
            case 'NIT_SVAs_00_03':
                value_00 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_01 : [choice] : "Sehfähigkeit" => value_01
        let value_01 = 0;
        const value_NIT_SVAs_01 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_01', undefined);
        // const item_NIT_SVAs_01 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_01');
        switch (value_NIT_SVAs_01) {
            default:
            case 'NIT_SVAs_01_00':
                break;
            case 'NIT_SVAs_01_01':
                value_01 = 1;
                break;
            case 'NIT_SVAs_01_02':
                value_01 = 2;
                break;
            case 'NIT_SVAs_01_03':
                value_01 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_03 : [choice] : "Fähigkeit, sich an einem Gespräch zu beteil.." => value_03
        let value_03 = 0;
        const value_NIT_SVAs_03 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_03', undefined);
        // const item_NIT_SVAs_03 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_03');
        switch (value_NIT_SVAs_03) {
            default:
            case 'NIT_SVAs_03_00':
                break;
            case 'NIT_SVAs_03_01':
                value_03 = 1;
                break;
            case 'NIT_SVAs_03_02':
                value_03 = 2;
                break;
            case 'NIT_SVAs_03_03':
                value_03 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_05 : [choice] : "Bewusstseinslage" => value_05
        let value_05 = 0;
        const value_NIT_SVAs_05 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_05', undefined);
        // const item_NIT_SVAs_05 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_05');
        switch (value_NIT_SVAs_05) {
            default:
            case 'NIT_SVAs_05_00':
                break;
            case 'NIT_SVAs_05_01':
                value_05 = 1;
                break;
            case 'NIT_SVAs_05_02':
                value_05 = 2;
                break;
            case 'NIT_SVAs_05_03':
            case 'NIT_SVAs_05_04':
                value_05 = 3;
                break;
        }
        //#endregion

        const sum_Block8 = Math.max(value_00, value_01, value_03, value_05);
        //#endregion

        //#region NIT_SVAs_902 und NIT_SVAs_1101 und NIT_SVAs_1203 => sum_Block9
        //#region NIT_SVAs_902 : [choice] : "Ist Nähe-Distanz-Empfinden krankheitsbeding.." => value_902
        let value_902 = 0;
        const value_NIT_SVAs_902 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_902', undefined);
        //const item_NIT_SVAs_902 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_902');
        switch (value_NIT_SVAs_902) {
            default:
            case 'NIT_SVAs_902_00':
                break;
            case 'NIT_SVAs_902_01':
                value_902 = 1;
                break;
            case 'NIT_SVAs_902_02':
                value_902 = 2;
                break;
            case 'NIT_SVAs_902_03':
                value_902 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_1101 : [choice] : "Soziale Rollen / Aktivitäten" => value_1101
        let value_1101 = 0;
        const value_NIT_SVAs_1101 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1101', undefined);
        //const item_NIT_SVAs_1101 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_1101');
        switch (value_NIT_SVAs_1101) {
            default:
            case 'NIT_SVAs_1101_00':
                break;
            case 'NIT_SVAs_1101_01':
                value_1101 = 1;
                break;
            case 'NIT_SVAs_1101_02':
                value_1101 = 2;
                break;
            case 'NIT_SVAs_1101_03':
                value_1101 = 3;
                break;
        }
        //#endregion

        //#region NIT_SVAs_1203 : [choice] : "Bestehende Beziehungen aufrechterhalten (Be.." => value_1203
        let value_1203 = 0;
        const value_NIT_SVAs_1203 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1203', undefined);
        //const item_NIT_SVAs_1203 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_1203');
        switch (value_NIT_SVAs_1203) {
            default:
            case 'NIT_SVAs_1203_00':
                break;
            case 'NIT_SVAs_1203_01':
                value_1203 = 1;
                break;
            case 'NIT_SVAs_1203_02':
                value_1203 = 2;
                break;
            case 'NIT_SVAs_1203_03':
                value_1203 = 3;
                break;
        }
        //#endregion

        const sum_Block9 = Math.max(value_902, value_1101, value_1203);
        //#endregion

        let sumNCSP = sum_Block0 + sum_Block1 + sum_Block2 + sum_Block3 + sum_Block4 +
            sum_Block5 + sum_Block6 + sum_Block7 + sum_Block8 + sum_Block9;
        let ncsp = 0;
        if (isNaN(sumNCSP)) {
            sumNCSP = -1;
        } else {
            // 0: 'NCS-IP 0,  Pflegeintensität: keine'
            if (sumNCSP >= 1 && sumNCSP <= 7)
                ncsp = 1; // 'NCS-IP 1,  Pflegeintensität: leicht';
            else if (sumNCSP >= 8 && sumNCSP <= 16)
                ncsp = 2; // 'NCS-IP 2,  Pflegeintensität: erhöht';
            else if (sumNCSP >= 17 && sumNCSP <= 25)
                ncsp = 3; // 'NCS-IP 3,  Pflegeintensität: hoch';
            else if (sumNCSP >= 26)
                ncsp = 4; // 'NCS-IP 4,  Pflegeintensität: sehr hoch';
        }

        const result: INCSPResult = {
            textAnalysisPage: this.i18n.tr(`sempa_ncsip_analysis_${ncsp}`),
            textDetailsPage: this.i18n.tr(`sempa_ncsip_details_0${ncsp}`),
            color: this.i18n.tr(`sempa_ncsip_color_${ncsp}`),
            sum: sumNCSP,
            ncsp: ncsp
        };

        const flagNCSIP: any = Fhir.Tools.GetOrCreateFlag(patient.flags, 'sempa_ncsip', true);
        flagNCSIP.code = String(result.sum);
        flagNCSIP.display = result.textAnalysisPage;

        const flagDetails: any = Fhir.Tools.GetOrCreateFlag(patient.flags, 'sempa_ncsip_details', true);
        flagDetails.code = String(result.sum);
        flagDetails.display = result.textDetailsPage;

        return result;
    }

    public getPainValue(patient: PatientItem, assessment: any): ISempaRow {
        this.sempaResult.pain = {
            button: '',
            color: undefined,
            info: 'Pain Value from Assessment',
            isRisk: false,
            isValidated: false,
            isVisible: true,
            level: 0,
            sum: NaN,
            tag: 'PAIN',
            title: 'Schmerzen',
            text1: this.i18n.tr('not_entered')
        };

        patient = patient || this.patient;
        assessment = assessment || patient?.latestAssessment;

        if (assessment && patient) {
            const questionnaire = QuestionnaireService.GetQuestionnaireDirect(assessment.questionnaire);

            if (questionnaire) {
                const formSum: string = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_202', 'NIT_SVAs_202_NIL');
                const painItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, 'NIT_SVAs_202');
                if (painItem) {
                    this.sempaResult.pain.text1 = QuestionnaireResponse.GetOptionText(formSum, painItem);

                    if (!formSum.toUpperCase().endsWith('NIL')) {
                        this.sempaResult.pain.sum = QuestionnaireResponse.GetOrdinalValue(formSum, painItem);
                    }

                    // get the title of the pain item from "NIT_SVAs_202" text property
                    if (painItem.text) {
                        this.sempaResult.pain.title = painItem.text;
                    }

                    // get the text value to display in text1 from the hint-text
                    let hint202 = Questionnaire.GetItemHintTextById(painItem, formSum);
                    if (hint202) {
                        this.sempaResult.pain.text1 = hint202;
                    }
                }
            }
        }

        if (this.sempaResult.pain.text1 && !isNaN(this.sempaResult.pain.sum)) {
            // attach the "when does the pain occur" multiselect values from NIT_SVAs_202_98
            const answer202_98 = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'NIT_SVAs_202_98', false);
            if (NitTools.IsArray(answer202_98?.answer)) {
                const stringArr = answer202_98.answer.map(o => o.valueCoding?.display);
                if (stringArr.length > 0) {
                    this.sempaResult.pain.text1 += ` (${stringArr.join(', ')})`;
                }
            }
        } else {
            this.sempaResult.pain.text1 = this.i18n.tr('not_entered'); // replace the text1 with "not entered" when nothing is selected
        }

        return this.sempaResult.pain;
    }

    private forceAssessmentUpdate: boolean = false;

    /**
     * ICDSC, RASS, GCS
     * @param patient 
     * @param assessment 
     * @returns 
     */
    public async getICDSCandRass(patient: PatientItem, assessment: any): Promise<ISempaRow> {
        let result: ISempaRow = {
            title: 'ICDSC',
            text1: this.i18n.tr('icdsc_na'), // not_calculable'),
            button: '',
            isVisible: true
        };

        const questionnaireAssessment = QuestionnaireService.GetQuestionnaireDirect(assessment.questionnaire);

        //#region process RASS
        const rassName = PatientItem.GetQuestionnaireName(patient, 'RASS', 'CareIT_RASS');
        const rassQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(rassName);
        if (!rassQuestionnaire) {
            result.text2 = `No RASS-Questionnaire with name "${rassName}" found`;
            console.error(result.text2);

            return  result;
        }

        const itemNIT_164 = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_164');
        if (itemNIT_164) {
            // update the title from the questionnaire
            if (itemNIT_164.text)
                result.title = itemNIT_164.text;

            const value164: string = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_164', 'NIT_SVAs_164_NIL');
            if (value164.toUpperCase().endsWith('_NIL')) {
                result.sum = NaN;
            } else {
                const [nit, svas, link, value] = value164.split('_');
                if (value) {
                    result.sum = parseInt(value);
                    const txt = Questionnaire.GetItemTextById(itemNIT_164, value164) || this.i18n.tr('n_a');
                    let hint = Questionnaire.GetItemHintTextById(itemNIT_164, value164);
                    if (hint === txt) {
                        hint = '';
                    } else if (typeof hint === "string") {
                        hint = `(${hint})`;
                    }

                    result.text1 = `${txt} ${hint}`;
                }
            }
        }

        let rassResponse = await QuestionnaireResponse.GetAttachedResponse(patient, assessment, rassName);
        if (!rassResponse) {
            console.warn('RASS Response not found. Consider adding it to the "autoGenerateNewResponses" property of the assessment. Creating a new RASS Response');
            try {
                rassResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, rassQuestionnaire.id, 'in-progress');
                QuestionnaireResponse.LinkResponsesMutual(patient, rassResponse, assessment);
                rassResponse.meta = {
                    lastUpdated: new Date().toJSON()
                };

                await Fhir.Rest.Update(rassResponse);

                // update the assessment in the pool of responses
                PatientService.AddQuestionnaireResponse(patient, assessment, true);
                // insert the new icdsc response
                PatientService.AddQuestionnaireResponse(patient, rassResponse);

                this.forceAssessmentUpdate = true;
            } catch (e) {
                console.error(e);
                rassResponse = undefined;
            }
        } // don't use else here, because creation could fail

        if (rassResponse) {
            const responseItemRass100 = QuestionnaireResponse.GetResponseItemByLinkId(rassResponse, 'NIT_RASS_100', true);
            const questionnaireItemRass100 = Questionnaire.GetQuestionnaireItemByLinkId(rassQuestionnaire, 'NIT_RASS_100');

            if (!isNaN(result.sum) && rassResponse.status === 'in-progress') {
                // map the only field in icdsc response, when it has not yet been finalized
                if (responseItemRass100) {
                    const valueRass100 = `NIT_RASS_100_${NitTools.ToString(result.sum, 2)}`;
                    responseItemRass100.answer = [{
                        valueCoding: {
                            code: valueRass100,
                            display: Questionnaire.GetItemTextById(questionnaireItemRass100, valueRass100)
                        }
                    }];
                }

                // don't forget to update the response in the patient
                PatientService.AddQuestionnaireResponse(patient, rassResponse, true);
            }
        }
        //#endregion

        //#region process Intensive Care Delirium Screening Checklist (ICDSC)
        const icdscName = PatientItem.GetQuestionnaireName(patient, 'ICDSC', 'CareITICDSC');
        const icdscQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(icdscName);
        if (!icdscQuestionnaire) {
            result.text2 = `Questionnaire with name "${icdscName}" not found!`;
            console.error(result.text2);

            return result;
        }

        let icdscResponse = await QuestionnaireResponse.GetAttachedResponse(patient, assessment, icdscName);
        if (!icdscResponse) {
            console.warn('ICDSC Response not found. Consider adding it to the "autoGenerateNewResponses" property of the assessment. Creating a new ICDSC Response');

            try {
                icdscResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, icdscQuestionnaire.id, 'in-progress');
                QuestionnaireResponse.LinkResponsesMutual(patient, icdscResponse, assessment);
                icdscResponse.meta = {
                    lastUpdated: new Date().toJSON()
                };

                await Fhir.Rest.Update(icdscResponse);

                // update the assessment in the pool of responses
                PatientService.AddQuestionnaireResponse(patient, assessment, true);
                // insert the new icdsc response
                PatientService.AddQuestionnaireResponse(patient, icdscResponse);
                this.forceAssessmentUpdate = true;
            } catch (e) {
                console.warn(e);
                icdscResponse = undefined;
            }
        }

        if (icdscResponse) {
            result.text1 += this.getWarningMessage(icdscResponse);
            if (icdscResponse.status === 'in-progress') {
                //#region map the values from the assessment to the icdsc response
                // no mapping defined yet
                //#endregion
                result.text2 = '';
                result.sum = NaN;
            } else {
                // get the value from NIT_ICDSC_108 to display
                const tmpNIT_ICDSC_108 = QuestionnaireResponse.GetResponseItemValueByLinkId(icdscResponse, 'NIT_ICDSC_108');
                let valueNIT_ICDSC_108: number = NaN;
                if (typeof tmpNIT_ICDSC_108 !== "undefined") {
                    result.sum = valueNIT_ICDSC_108 = parseInt(tmpNIT_ICDSC_108);
                    result.text2 = `${valueNIT_ICDSC_108} - `;

                    if (valueNIT_ICDSC_108 > 4) {
                        valueNIT_ICDSC_108 = 4;
                    }

                    switch (valueNIT_ICDSC_108) {
                        case 0:
                            result.text2 = this.i18n.tr('sempa_icdsc_0');
                            break;
                        case 1:
                        case 2:
                        case 3:
                            result.text2 = this.i18n.tr('sempa_icdsc_1');
                            break;
                        case 4:
                            result.text2 = this.i18n.tr('sempa_icdsc_4');
                            break;
                        default:
                            result.text2 = this.i18n.tr('n_a');
                            break;
                    }

                    result.text2 = (result.sum >= 4 ? '>=' : '') + `${valueNIT_ICDSC_108} Pkt. - ${result.text2}`;
                }
            }
        }

        window['icdscButtonClicked'] = async () => {
            await this.createPopup(patient, assessment, icdscName, false);
        };

        result.button = this.getButtonText('icdscButtonClicked', 'ICDSC', 'ICDSC');

        //#endregion

        this.sempaResult.icdsc = result;

        return result;
    }

    public async getMSA(patient: PatientItem, assessment: any): Promise<ISempaRow> {
        let result: ISempaRow = this.sempaResult.msa = {
            text1: this.i18n.tr('n_a'),
            button: '',
            title: this.i18n.tr("sempa_MSA_title"),
            tag: 'MSA',
            info: 'false'
        };

        const msaName = PatientItem.GetQuestionnaireName(patient, 'CareIT_MSA', 'CareITMSA');
        const msaQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(msaName);
        if (!msaQuestionnaire) {
            console.error(`No Questionnaire with name "${msaName}" found`);

            return result;
        }

        /* NIT_SVAs_406_00 : [option] : "Nein"
        NIT_SVAs_406_01 : [option] : "Ja"
        NIT_SVAs_406_02 : [option] : "Nicht beurteilbar" */

        const NIT_SVAs_406 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_406', 'NIT_SVAs_406_NIL');
        switch (NIT_SVAs_406) {
            case 'NIT_SVAs_406_00':
                result.text1 = this.i18n.tr('no');
                result.info = 'false';
                break;
            case 'NIT_SVAs_406_01':
                result.text1 = this.i18n.tr('yes');
                result.info = 'yes';
                break;
            case 'NIT_SVAs_406_02':
                result.text1 = this.i18n.tr('not_evaluable');
                result.info = 'undefined';
                break;
        }

        let msaResponse = QuestionnaireResponse.GetAttachedResponseDirect(patient, assessment, msaName);
        if (!msaResponse) {
            console.warn(`No response of type "${msaName}" found. Consider adding it to the autogenerate feature of the assessment. Generating new MSA Response`);

            try {
                msaResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, msaQuestionnaire.id, 'in-progress');
                QuestionnaireResponse.LinkResponsesMutual(patient, assessment, msaResponse);

                await Fhir.Rest.Update(msaResponse);

                // update the assessment in the pool of responses
                PatientService.AddQuestionnaireResponse(patient, assessment, true);
                // insert the new icdsc response
                PatientService.AddQuestionnaireResponse(patient, msaResponse);
                this.forceAssessmentUpdate = true;
            } catch (e) {
                console.warn(e);
                msaResponse = undefined;
            }
        }

        if (msaResponse) {
            result.button = this.getButtonText('msaButtonClicked', 'MSA', 'CareIT_MSA');
            window['msaButtonClicked'] = async () => {
                await this.createPopup(patient, assessment, msaName, false);
            };

            result.text1 += this.getWarningMessage(msaResponse);

            if (msaResponse.status === 'completed' || msaResponse.status === 'amended') {
                let textArr = [];
                const valueNIT_MSA_400 = QuestionnaireResponse.GetResponseItemValueByLinkId(msaResponse, 'NIT_MSA_400', 'NIT_MSA_400_NIL');
                textArr.push(this.i18n.tr(`sempa_${valueNIT_MSA_400}`));

                const valueNIT_MSA_402 = QuestionnaireResponse.GetResponseItemValueByLinkId(msaResponse, 'NIT_MSA_402', 'NIT_MSA_402_NIL');
                textArr.push(this.i18n.tr(`sempa_${valueNIT_MSA_402}`));

                result.text2 = textArr.join(',<br />');
            }
        }

        this.sempaResult.msa = result;

        return result;
    }

    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        if (!assessment && patient && patient.latestAssessment) assessment = patient.latestAssessment;
        const analyseResult = await super.analyse(patient, assessment, updateRiskAssessment, false);

        this.ncsip = await this.calcNCSIP(patient, this.currentAssessment);

        await this.getMSA(patient, assessment);
        await this.calcGCS(patient, assessment);
        await this.calcNIHValue(patient, assessment);
        await this.getICDSCandRass(patient, assessment);

        this.getPainValue(patient, assessment);

        // Vitalfunktionen -> Behavioral Pain Scale -> JA/NEIN. showBPS="JA (NIT_SVAs_206_01)"/"NEIN (NIT_SVAs_206_00)"
        let showBPS = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_206', undefined) === 'NIT_SVAs_206_01';

        // Vitalfunktionen -> Behavioral Pain Scale für Nicht-Intubierte (BPS-NI) -> JA/NEIN. showBPSNI="JA (NIT_SVAs_211_01)"/"NEIN (NIT_SVAs_211_00)"
        let showBPSNI = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_211', undefined) === 'NIT_SVAs_211_01';

        await this.calcBPSValue(patient, assessment);
        await this.calcBPSNIValue(patient, assessment);
        this.sempaResult.bps.isVisible = showBPS;
        this.sempaResult.bpsNI.isVisible = showBPSNI;

        this._resultHtml = this.generateHtml(this.sempaResult);

        if (this.forceAssessmentUpdate) {
            await Fhir.Rest.Update(assessment);

            this.forceAssessmentUpdate = false;
        }

        if (updateRiskAssessment) {
            if (this.sempaResult?.icdsc && !isNaN(this.sempaResult?.icdsc?.sum)) {
                this.updateRisk(patient, 'risk_icdsc_sum', this.sempaResult.icdsc.sum, this.sempaResult.icdsc.text2);
            }

            if (this.sempaResult.msa)
                this.updateRisk(patient, 'risk_msa', this.sempaResult.msa.info, this.sempaResult.msa.text1);

            if (this.sempaResult?.pain?.isVisible)
                this.updateRisk(patient, 'risk_pain_sum', this.sempaResult.pain.sum, this.sempaResult.pain.text1);
            
            if (this.sempaResult.glasgow?.isValidated && this.sempaResult.glasgow?.sum >= 0)
                this.updateRisk(patient, 'risk_gcs', this.sempaResult.glasgow.sum, this.sempaResult.glasgow.text1);
            else
                this.updateRisk(patient, 'risk_gcs', undefined, this.i18n.tr("not_evaluable"));

            if (this.sempaResult.nih && this.sempaResult.nih.isValidated)
                this.updateRisk(patient, 'risk_nih', this.sempaResult.nih.sum, this.sempaResult.nih.text2);
            else
                this.updateRisk(patient, 'risk_nih', undefined, this.sempaResult.nih ? this.sempaResult.nih.text1 : this.i18n.tr("not_evaluable"));

            if (this.sempaResult.bps && this.sempaResult.bps.isVisible && this.sempaResult.bps.isValidated)
                this.updateRisk(patient, 'risk_bps', this.sempaResult.bps.sum, String(this.sempaResult.bps.sum));
            else
                this.updateRisk(patient, 'risk_bps', undefined, this.i18n.tr("not_evaluable"));

            if (this.sempaResult.bpsNI && this.sempaResult.bpsNI.isVisible && this.sempaResult.bpsNI.isValidated)
                this.updateRisk(patient, 'risk_bpsNI', this.sempaResult.bpsNI.sum, String(this.sempaResult.bpsNI.sum));
            else
                this.updateRisk(patient, 'risk_bpsNI', undefined, this.i18n.tr("not_evaluable"));

            if (this.ncsip) {
                this.updateRisk(patient, 'risk_ncsIP', this.ncsip.sum, this.ncsip.textDetailsPage || this.ncsip.textAnalysisPage);
            }
            ////////////////////////////////////////////////////
            if (storeRiskAssessment) {
                const oldLoading = RuntimeInfo.IsLoading;
                try {
                    RuntimeInfo.IsLoading = true;

                    const bundleItems = [
                        patient.selectedAdditionalInfo,
                        patient.flags,
                        assessment,
                    ];

                    // TODO: Consider to add other responses to the bundle

                    await this.fhirService.bundle(bundleItems, HTTPVerb.put, BundleType.transaction);
                    await this.fhirService.update(patient.currentRisks);

                    try {
                        if (!ConfigService.IsTest) {
                            await this.fhirService.tags.update(patient.encounter, {
                                system: 'nursit-institute.com/structureDefinitions/tags/SPI',
                                code: String(patient.SPI)// resultSPI && typeof resultSPI.sum !== "undefined" ? resultSPI.sum.toString() : '-1'
                            });
                        }
                    } catch (e) {
                        console.warn("Setting $META failed!", e.message || e);
                    }
                } catch (e) {
                    console.warn(e.message || e);
                } finally {
                    RuntimeInfo.IsLoading = oldLoading;
                }
            }
            ///////////////////////////////////////////////////
        }

        return analyseResult;
    }

    protected generateGlasgowRow(row : ISempaRow) {
        if (!row) return '';
        // convert color to style. If color property is not a string then simply make the value bold
        // const colorStyle = row && row.color ? (typeof row.color === "string" ? `style="color: ${row.color};"` : `style="font-weight: bold;"`) : "";
        const colorStyle = row && row.color ? `style="font-weight: bold; font-style: italic;"` : "";
        const tag = row.tag || 'untagged';
        const result = `<div class="row" data-tag="${tag}"> 
                <div class="col-xs-3" data-tag="${tag}-title">${row.title || '&nbsp;'}</div>
                <div class="col-xs-3" data-tag="${tag}-text1" ${colorStyle}>${row.text1 || '&nbsp;'}</div>
                <div class="col-xs-3" data-tag="${tag}-text2" ${colorStyle}>${row.text2 || '&nbsp;'}</div>
                <div class="col-xs-3" data-tag="${tag}-info">${row.info || '&nbsp;'}</div>
            </div>`;

        return result;
    }

    protected generateHtmlRows(values: ISempaResultIntensiv): string {

        const checkVisibility = (module : string) => {
            const riskCfg : any[] = ConfigService.GetFormSettings("analysis")?.settings?.riskOverview;
            if (!riskCfg) return true;
            const cfg = riskCfg.find(o=>o.field === module);
            return typeof cfg?.visible === "boolean" ? cfg.visible : true;
        };
        
        // had to include this, because analyze-function does not always create the pain property - although it should!
        values.pain ??= this.getPainValue(this.patient, this.currentAssessment); 

        if (values.pain) values.pain.isVisible = checkVisibility("pain");
        if (values.bps) values.bps.isVisible = checkVisibility("bps");
        if (values.bpsNI) values.bpsNI.isVisible = checkVisibility("bpsni");
        if (values.glasgow) values.glasgow.isVisible = checkVisibility("gcs");
        if (values.icdsc) values.icdsc.isVisible = checkVisibility("icdsc");
        if (values.msa) values.msa.isVisible = checkVisibility("msa");
        if (values.nih) values.nih.isVisible = checkVisibility("nih");
        if (values.pav) values.pav.isVisible = checkVisibility("pav");
        if (values.diabetes) values.diabetes.isVisible = checkVisibility("diabetes");
        if (values.decu) values.decu.isVisible = checkVisibility("decubitus");
        if (values.fall) values.fall.isVisible = checkVisibility("fall");
        if (values.pneumo) values.pneumo.isVisible = checkVisibility("pneumo");
        if (values.vdd) values.vdd.isVisible = checkVisibility("demenz");
        if (values.mna) values.mna.isVisible = checkVisibility("malnutrition");
        if (values.nrs) values.nrs.isVisible = checkVisibility("nrs");
        if (values.demmi) values.demmi.isVisible = checkVisibility("demmi");
        if (values.barthelIndex) values.barthelIndex.isVisible = checkVisibility("barthel");
        if (values.barthelIndexEx) values.barthelIndexEx.isVisible = checkVisibility("barthelEx");
        if (values.continence) values.continence.isVisible = checkVisibility("continence");
        if (values.nba) values.nba.isVisible = checkVisibility("nba");
        if (this.sempaResult.icds) this.sempaResult.icds.isVisible = typeof this.analyserConfig?.settings?.showICDs === "boolean" ? this.analyserConfig.settings.showICDs : true;

        let result = ``;
        if (values.glasgow?.isVisible) {
            result = `       <!-- Glasgow Coma Skala / GCS / glasgow -->
                            ${this.generateGlasgowRow(values.glasgow)} `;
        }

        if (this.sempaResult.icdsc) {
            result += ` <!-- ISCDS value -->
                        ${this.generateRow(values.icdsc)}`;
        }

        if (values.pain?.isVisible) {
            result += `<!-- PAIN (NIT_SVAs_202) -->
            ${this.generateRow(values.pain)}`;
        }

        if (values.bps?.isVisible) {
            result += `<!-- Behavioral Pain Scale / BPS -->
                           ${this.generateRow(values.bps)} `;
        }

        if (values.bpsNI && values.bpsNI.isVisible) {
            result += `<!-- Behavioral Pain Scale not Intubated / BpsNI -->
                           ${this.generateRow(values.bpsNI)} `;
        }

        result += `<!-- Modifiziertes Schluckassessment (MSA) -->\n${this.generateRow(values.msa)}`;

        result += `     <!-- Pupillenreaktion / NIH -->
                        <!-- hidden due to planio #2331: 
                        ${this.generateRow(values.nih)} 
                        -->
                        
                        <!-- SozialDienst / PAV -->
                        ${this.generateRow(values.pav)}
                        
                        <!-- Diabetes Mellitus / diabetes -->
                        ${this.generateRow(values.diabetes)}
                        
                        <!-- Dekubitus -->
                        ${this.generateRow(values.decu)}
                        
                        <!-- Fall -->
                        ${this.generateRow(values.fall)}
                        
                        <!-- Pneumo -->
                        ${this.generateRow(values.pneumo)}
                        
                        <!-- VDD -->
                        ${this.generateRow(values.vdd)}                        
                        
                        <!-- MNA -->
                        ${this.generateRow(values.mna)}

                        <!-- NRS -->
                        ${this.generateRow(values.nrs)}
                        
                        <!-- Mobility / demmi -->
                        ${this.generateRow(values.demmi)}
                        
                        <!-- Barthel-Index -->
                        ${this.generateRow(values.barthelIndex)}
                        
                        <!-- Ext. Barthel-Index -->
                        ${this.generateRow(values.barthelIndexEx)}
                        
                        <!-- Kontinenz -->
                        ${this.generateRow(values.continence)}
                        
                        <!-- NBA -->
                        ${values.nba?.isVisible ? this.generateRow(values.nba) : '<!-- disabled -->'}
                        
                        <!-- extra rows -->
                        ${this.extraRows ? this.extraRows : ''}
                        
                        <!-- ICD List -->
                        ${values.icds?.isVisible ? this.generateRow(values.icds, true) : ''}
        `;

        return result;
    }

    protected generateHtmlScores(values: ISempaResult): string {
        // replaces PlanIO: #2248 with #2579 ("Ansicht Analyse Intensiv")

        const ncsIpSetting = ConfigService.GetFormSettings('analysis')?.settings?.hideNCSIP;
        let hideNCSIP = false;
        if (typeof ncsIpSetting === 'boolean') {
            hideNCSIP = ncsIpSetting;
        }

        let result: string = `<div class="row">`;
        result += `    <div class="col-sm-4"><span>${values.ncsA.tag}</span>: <b style="color:${values.ncsA.color}">${this.i18n.tr('carelevel')} ${values.ncsA.overview}</b></div>`; // NCS-A / aka "NursingCareScore -> NCS-A: Pflegeintensität erhöht

        // add ncsip column only if not disabled:
        if (!hideNCSIP) {
            result += `    <div class="col-sm-4 text-center"><span>Care of Critical Level</span>: <b style="color:${this.ncsip?.color};">${this.ncsip?.sum}</b></div>`; // IC -> Care of Critical Level: 1
        } else {
            result += `  <div class="col-sm-4"><!-- ncsip is hidden by setting --></div>`;
        }

        result += `    <div class="col-sm-4 text-right"><span>${values.ncsP.tag}: </span> <b style="color:${values.ncsP.color}; margin-right:-9px">${this.i18n.tr('carelevel')} ${values.ncsP.overview}</b></div>`; // PSYCH -> NCS-P: Pflegeintensität keine
        result += `</div>`;

        return result;
    }
}

export interface ISempaResultIntensiv extends ISempaResult {
    glasgow?: ISempaRow;
    bps?: ISempaRow;
    bpsNI?: ISempaRow;
    nih?: ISempaRow;
    icdsc?: ISempaRow;
    msa?: ISempaRow;
}

export interface INCSPResult {
    textAnalysisPage: string;
    textDetailsPage: string;
    sum: number;
    color: string;
    ncsp: number;
}
