import { NitTools } from "./NursitTools";
import {I18N} from "aurelia-i18n";

export class HumanName implements fhir3.HumanName {

    public static GetText(name, i18n? : I18N): string {
        if (NitTools.IsArray(name)) {
            name = name.find(o=>o.use === "official") || name.find(o=>o.use === "usual") || name[0];
        }

        if (!name) return '';

        let result = name.family||'';
        if (name.given) {
            let given = name.given?.join(' ');

            result += ', ' + given;
        }

        if (name.prefix) {
            if (i18n?.tr) {
                let prefixes = [];
                for (const p of name.prefix) {
                    prefixes.push(i18n.tr(p));
                }

                result = prefixes.join(' ') + " " + result;
            }
            else {
                result = name.prefix.join(' ') + " " + result;
            }
        }

        if (name.suffix) {
            result += ", " + name.suffix.join(' ');
        }

        return result ? result.trim() : '';
    }

    public static GetDiv(names: any[], i18n? : I18N): string {
        if (!names || names.length === 0) return '';
        let resultArray: string[] = [];

        // name to string into resultArray
        for (const name of names) {
            let s = HumanName.GetText(name, i18n);
            if (s && s.length > 3) {
                resultArray.push(s);
            }
        }

        let result = `<div xmlns="http://www.w3.org/1999/xhtml">
                            <ul>`; // '<div xmlns="http://www.w3.org/1999/xhtml">';
        for (let name of resultArray) {
            if (!name) continue;
            name = name.replace(/</g,'&lt;')
                       .replace(/>/g,'&gt;')
                       .replace(/"/g, '&quot;')
            result += `<li>${name}</li>`;
        }

        result += ` </ul>
                    </div>`;

        return result ? result.trim() : '';
    }
}
