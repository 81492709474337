import {autoinject, bindable} from "aurelia-framework";
import {PractitionerItem} from "./practitioner-item";
import {DialogController} from "aurelia-dialog";
import {DialogMessages} from "../../../resources/services/DialogMessages";

@autoinject
export class PractitionerDialogAdd {
    @bindable practitioner: PractitionerItem;

    constructor(protected controller: DialogController) {}

    activate(params: PractitionerItem) {
        this.practitioner = params;
    }
}
