import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {HttpClient} from "aurelia-http-client";
import {NitTools} from "../classes/NursitTools";
import {I18N} from "aurelia-i18n";
import {FhirService} from "../services/FhirService";

@autoinject
export class ModalCaveAdd {
    caption: string;
    title : string;
    text : string;
    type: 'C'|'V'|'E'; // the add type we are currently using. see that no A is possible, because Allergies are added in own control
    targetDate :Date;
    displayInTitle: boolean = false;
    listItems : string[] = [];
    search;

    constructor(protected controller:DialogController, protected i18n : I18N, protected fhirService : FhirService) {
    }

    popupItems : string[] = [];
    _showPopup : boolean = false;

    get showPopup() : boolean {
        return this._showPopup;
    }

    set showPopup(value: boolean) {
        if (this.listItems.length === 0) {
            this._showPopup = false;

            return;
        }

        if (this._showPopup == value) {
            return;
        }

        this._showPopup = value;
        if (!this._showPopup) return;

        // when displaying the list then move the popup to the right location
        const caveTitleInput = document.querySelector('#caveTitleInput');
        const cavePopupSelectList : HTMLDivElement = document.querySelector('#cavePopupSelectList');
        const caveAddForm : HTMLDivElement = document.querySelector('#caveAddForm');

        if (!caveTitleInput || !cavePopupSelectList || this.popupItems.length === 0 || !caveAddForm) {
            this.showPopup = false;
            this.popupItems = this.listItems;

            return;
        }

        const $offsetParent = $(caveTitleInput).parent()?.parent();
        const offsetElement = <HTMLDivElement>$offsetParent?.[0];
        if (!offsetElement) return;

        const offset = $offsetParent.position();
        let padding = parseInt(offsetElement.style.paddingLeft.replace('px', ''));
        if (isNaN(padding)) padding = 15;

        const editHeight = $(caveTitleInput).outerHeight(true);
        const editWidth = $(caveTitleInput).innerWidth();

        const popupTop = offset.top + editHeight;
        cavePopupSelectList.style.left = `${offset.left + padding}px`;
        cavePopupSelectList.style.top = `${popupTop}px`;
        cavePopupSelectList.style.width = `${editWidth}px`;

        const newHeight = ($(caveAddForm).height() - popupTop);
        if (!isNaN(newHeight)) {
            cavePopupSelectList.style.maxHeight = `${newHeight}px`;
        } else {
            cavePopupSelectList.style.maxHeight = undefined;
        }
    }

    togglePopup(shown ? : boolean) {
        if (typeof shown === "boolean") {
            this.showPopup = shown;
        } else {
            this.showPopup = !this.showPopup;
        }

        if (this._showPopup && this.popupItems.length === 0) {
            this.popupItems = this.listItems;
        }

        return true;
    }

    onKeyUp(event: KeyboardEvent) { {
        this.popupItems = this.listItems.filter(s => s.toUpperCase().indexOf(this.title?.toUpperCase()) > -1);
        this.showPopup = this.popupItems.length > 0;
    }}

    selectTitle(title) {
        this.title = title;
        this.showPopup = false;
    }

    itemBlurred(e) {
        // give the popup time to be clicked before hiding the popup
        window.setTimeout(() => {
            this.togglePopup(false);
        }, 250);
    }

    async activate(params) {
        Object.assign(this, params);

        try {
            let codeSystem : fhir4.CodeSystem;
            // try to get the CS from fhir
            try {
                codeSystem = await this.fhirService.get('CodeSystem/cave-values-cs');
            }
            catch (e) {
                codeSystem = undefined;
                console.warn(`Failed to fetch cave-values-cs from fhir`);
            }

            // if not found, load the fallback from data
            if (!codeSystem) {
                try {
                    const cs = (await (new HttpClient().get("data/cave-values-cs.json")))?.response;
                    codeSystem = JSON.parse(cs);
                }
                catch (e) {
                    codeSystem = undefined;
                    console.warn(`Failed to fetch data/cave-values-cs.json`);
                }
            }

            if (codeSystem && NitTools.IsArray(codeSystem.concept)) {
                for (const concept of codeSystem.concept) {
                    // filter out the concept items that do not have a matching designation.value
                    if (NitTools.IsArray(concept.designation)) {
                        const designation = concept.designation.find(o=> String(o.value).toUpperCase() === this.type?.toUpperCase());
                        if (!designation) {
                            continue;
                        }
                    }

                    let txt = concept.display;

                    // when no display property is present but a code then try to translate the code
                    if (!txt && concept.code && this.i18n.tr) {
                        txt = this.i18n.tr(concept.code);
                        if (txt === concept.code) {
                            // do not display the raw code to the user when translation fails
                            txt = undefined;
                        }
                    }

                    // only add non-empty lines
                    if (txt) { // this may although be a line just with spaces. So do trimming and re-check
                        txt = txt.trim();

                        if (txt) {
                            this.listItems.push(txt);
                        }
                    }
                }
            }

            this.listItems = this.popupItems = this.listItems.sort();
        }
        catch (e) {
            console.warn(e);
        }
    }

    async submit() {
        await this.controller.ok(this);
    }
}
