import { autoinject, observable } from 'aurelia-framework';

import { BasicForm } from "../../resources/elements/BasicForm";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../../resources/services/ConfigService";
import { PatientService } from 'resources/services/PatientService';
import { ModalBodyMap } from "resources/elements/modal-body-map";
import { Modal3dBody } from "resources/elements/modal-3dbody";
import * as environment from "../../../config/environment.json";
import { FhirService } from "../../resources/services/FhirService";
import { UserService } from "../../resources/services/UserService";
import { DialogService } from 'aurelia-dialog';
import {Router} from "aurelia-router";
import { QuestionnaireDialogContainer } from 'resources/elements/questionnaire/questionnaire-dialog-container';
import { QuestionnaireService } from 'resources/services/QuestionnaireService';
import {translations} from "resources/classes/translations";
import { fhirEnums } from 'resources/classes/fhir-enums';
import { PermissionService } from 'resources/services/PermissionService';
import { RuntimeInfo } from 'resources/classes/RuntimeInfo';
import { MedboardQuestionnaireContainer } from 'resources/elements/questionnaire/medboard-questionnaire-container';
import { ModalQRenderer } from 'resources/elements/modal-q-renderer';

@autoinject
export class PatientCurve {
    @observable patient;
    patientService: PatientService;
    i18n: I18N;
    userService: UserService;
    dialogService: DialogService;
    router: Router;
    fhirService: FhirService;
    permissionService: PermissionService;
    config;
    encounterId: string = undefined;
    container;
    iframeUrl = '';
    iframeContainer;
    iframeOrigin;
    iframeListener;
    is3dBody: boolean = false;

    constructor(i18n: I18N, patientService: PatientService, userService: UserService, dialogService: DialogService, router: Router, fhirService: FhirService, permissionService: PermissionService) { 
        this.patientService = patientService;
        this.i18n = i18n;
        this.userService = userService;
        this.dialogService = dialogService;
        this.router = router;
        this.fhirService = fhirService;
        this.permissionService = permissionService;

        const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
        this.is3dBody = Boolean(config.settings?.body3d?.enabled);

        this.iframeListener = async (event) => {
            if (event.origin === this.iframeOrigin) {
                const data = event.data.data;

                switch (event.data.name) {
                    case 'action': {
                        if (data === "graphixx") {
                            await this.dialogService
                                .open({
                                    viewModel: this.is3dBody ? Modal3dBody : ModalBodyMap,
                                    model: {
                                        patient: this.patient,
                                    },
                                    centerHorizontalOnly: this.is3dBody,
                                })
                                .whenClosed((result) => {
                                    if (!result.wasCancelled) {
                                    }
                                });
                        }
                        break;
                    }
                    case 'openQuestionnaireResponse': {
                        this.router.navigate(`#/encounter/${this.encounterId}/questionnaire/${data.qId}/${data.qName}/${data.qrId}`);
                        break;
                    }
                    case 'openQuestionnaireDialog': {
                        try {
                            let responseBody = data?.qrBody ? JSON.parse(data.qrBody) : undefined;
                            if (!responseBody && data?.qrId) {
                                responseBody = this.patient.questionnaireResponses.find(o=> o.id === data.qrId);
                            }

                            if (!responseBody) {
                                throw new Error(`No Response with the given id "${data.qrId}" found.`);
                            }

                            await this.openModalAnalyseWindow(data.qId, responseBody);
                        } catch (e) {
                            console.error(e);
                        }
                        break;
                    }
                }
            }
        };
    }

    protected async openModalAnalyseWindow(questionnaireId, questionnaireResponse) {
        if (this.patient.isOffline) return;
        if (!questionnaireResponse) {
            console.warn(`No Response given!`);
            return;
        }

        const currentTitle = BasicForm.pageTitle;

        let currentQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(questionnaireId);

        let hint : string = undefined;
        // when the questionnaire has not been already loaded, then get it from Fhir:
        if (!currentQuestionnaire) {
            currentQuestionnaire = await this.fhirService.get(`Questionnaire/${questionnaireId}`);

            // when it has been found then add it to the QuestionnaireService
            if (currentQuestionnaire) {
                if (currentQuestionnaire.status != 'active') {
                    // change the status locally to active, so it may be found later
                    hint = 'Q-Status: ' + currentQuestionnaire.status;
                    console.warn(`Loaded Questionnaire "${currentQuestionnaire.name||currentQuestionnaire.title}" but it has status "${currentQuestionnaire.status}"`);
                    currentQuestionnaire.status = 'active';
                }

                // finally add the resulting Q.
                QuestionnaireService.__questionnaires.push(currentQuestionnaire);
            }
        }

        const currentStatus = translations.translate(questionnaireResponse.status);

        const dialogSettings = {
            grouplist: false,
            hintText: hint || '',
            response: questionnaireResponse,
            questionnaire: currentQuestionnaire,
            patient: this.patient,
            encounter: this.patient.encounter,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            status: currentStatus,
            saveText: this.i18n.tr("save"),
            abortText: this.i18n.tr("abort"),
            forcedReadonly: false,
            advancedSaveButton: true,
            needsEditButton: true,            
            patientService: this.patientService,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            data: {
                title: currentQuestionnaire.title || currentQuestionnaire.name
            }
        };

        const qSupportsNewRenderer = currentQuestionnaire?.meta?.profile?.includes("http://hl7.org/fhir/uv/sdc/StructureDefinition/sdc-questionnaire-render")
        const viewModel = RuntimeInfo.Features.qRenderer?.enabled && qSupportsNewRenderer ? ModalQRenderer : MedboardQuestionnaireContainer;

        return this.dialogService
            .open({viewModel, model: dialogSettings, lock: true})
            .whenClosed(async (result) => {
                BasicForm.pageTitle = currentTitle;

                if (!result.wasCancelled) {
                    if (UserService.Practitioner) {
                        let display = undefined;
                        if (UserService.Practitioner.name) {
                            let name = UserService.Practitioner.name.find(o => o.use === 'official');
                            if (!name) name = UserService.Practitioner.name.find(o => o.use === 'usual');
                            if (!name) name = UserService.Practitioner.name[0];
                            if (name) {
                                display = name.family + ', ' + name.given?.join(' ');
                            }
                        }
            
                        result.output.author = {
                            reference: `Practitioner/${UserService.Practitioner.id}`,
                            display: display
                        };
                    }

                    this.iframeContainer.contentWindow.postMessage({
                        name: 'questionnaire-dialog-closed',
                        data: JSON.stringify(result.output)
                    }, this.iframeUrl);
                } else {
                    this.iframeContainer.contentWindow.postMessage({
                        name: 'questionnaire-dialog-closed',
                        data: null
                    }, this.iframeUrl);
                }
            })
            .catch(e => {
                console.warn(e);
            });
    }

    async activate(params) {
        this.encounterId = params.id;
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
        document.body.classList.remove("hide-process-arrows");

        window.removeEventListener("message", this.iframeListener);
    }

    async attached() {
        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.DoctorOverview);
        this.patient = await this.patientService.fetch(this.encounterId);

        const loginData = sessionStorage.getItem(environment.sessionName);

        const query:any = {
            login: loginData,
            server: FhirService.Endpoint,
            origin: window.location.origin,
            encounterId: this.patient.encounterId,
            patientId: this.patient.id,
            practitionerId: '',
            lang: RuntimeInfo.Language
        };

        if (this.config.settings.iframe.layout) {
            query.layout = this.config.settings.iframe.layout;
        }

        if (this.permissionService.isRolesEnabled) {
            query.roleId = this.permissionService.activeUserRole?.id;
        }

        if (this.userService.practitioner) {
            query.practitionerId = this.userService.practitioner.id;
        }

        this.iframeUrl = `${this.config.settings && this.config.settings.iframe && this.config.settings.iframe.url}?` + Object.keys(query).map((key) => {
            return `${key}=${encodeURIComponent(query[key])}`;
        }).join('&');
        this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';

        if (this.config) {
            BasicForm.pageTitle = this.i18n.tr(this.config.title);
        }

        document.body.classList.add("no-toolbar-window");
        document.body.classList.add("hide-process-arrows");

        window.addEventListener("message", this.iframeListener);
    }
}
