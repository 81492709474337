import { autoinject, bindable, TaskQueue } from "aurelia-framework";
import { DialogService } from "aurelia-dialog";
import { Questionnaire } from "./questionnaire";
import { translations } from "../../classes/translations";
import { PatientItem } from "../../classes/Patient/PatientItem";
import { NitTools } from "../../classes/NursitTools";
import { FormBaseClass } from "../FormBaseClass";
import { QuestionnaireService } from "resources/services/QuestionnaireService";
import { fhirEnums } from "../../classes/fhir-enums";
import { Tools } from "../../classes/FhirModules/Fhir";
import { PatientService } from "../../services/PatientService";
import { IQuestionnaireListItem } from "../../classes/IQuestionnaireListItem";
import { ConfigService } from "../../services/ConfigService";
import { UserService } from "../../services/UserService";
import { I18N } from "aurelia-i18n";
import { Router } from "aurelia-router";
import { PatientChangeNotifier } from "resources/services/PatientChangeNotifier";
import { AnalyzeService } from "resources/services/analyzeService";
import { DialogMessages } from "resources/services/DialogMessages";
import { IdLogikService } from "resources/services/IdLogikService";
import { qChangeNotifier } from "./q-changeNotifier";
import { PermissionService } from "resources/services/PermissionService";
import { LogoutService } from "../../services/LogoutService";

const moment = require("moment");
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import { ReportService } from "resources/services/ReportService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {PromptInput} from "../prompt-input";

@autoinject
export class QuestionnaireDialogContainer extends FormBaseClass {
    constructor(protected i18n: I18N,
        router: Router, queue: TaskQueue, notifier: PatientChangeNotifier, dialogService: DialogService,
        analyzeService: AnalyzeService,
        protected patientService: PatientService,
        protected dialogMessages: DialogMessages,
        protected idLogikService: IdLogikService,
        protected responseNotifier: qChangeNotifier,
        protected permissionService: PermissionService,
        protected logoutService: LogoutService) {
        super(i18n, router, queue, notifier, dialogService, analyzeService, patientService, dialogMessages, idLogikService, responseNotifier, permissionService, logoutService);
        this.responseNotifierSubScriberId = this.responseNotifier.subscribe((item) => this.checkGroupVisibilites(item));
    }

    @bindable grouplist: boolean;
    @bindable item: any[];
    // dateFormat: string = "d";
    reAssignResponses = false;
    questionnaireName = "CareITDiagnosis";
    questionnaireTitle = '';
    status: string;
    dialogService: DialogService;
    showToolbar: boolean = false;
    settings: IQuestionnaireDialogSettings = undefined;

    /** is called after the user changed the date of the current response. When this is specified, no other action is taken after submitting the new date in the dialogue */
    afterDateEdited : Function = undefined;
    @bindable questionnaireClass: Questionnaire;
    maxAge: number = 9999999999;
    allowAddNew: boolean = false;
    saveText: string = undefined;
    abortText: string = undefined;
    data: any = undefined;
    showSelection: boolean = true;
    tooold: boolean = false;
    uxBody: any;
    isDebug: boolean = ConfigService.Debug;
    forcedReadonly: boolean = false;
    isTrainee: boolean = false;

    async createNewDocument() {
        if (!this.questionnaire || !this.allowAddNew) return;
        let tmpNewItem = Tools.SubstituteDefaultQRSkeleton(this.patient, this.questionnaire.id, QuestionnaireResponseStatus.inProgress);
        let newItem = <any>await this.fhirService.create(tmpNewItem);
        PatientService.AddQuestionnaireResponse(this.patient, newItem, false);
        let tmp = this.patient.questionnaireResponses.filter(o => o.questionnaire && o.questionnaire.reference && o.questionnaire.reference.indexOf('/' + this.questionnaire.id) > -1);
        this.responses = tmp.sort((r1, r2) => {
            return new Date(r1.authored).valueOf() - new Date(r2.authored).valueOf();
        });

        this.selectLastResponse();
    }

    async abortButtonClicked() {
        this.close(true);
    }

    /**
     * a function that ensures a valid report name is present instead of undefined.
     * This is needed because in some cases the response or reportname is not set by userclick and my be undefined
     */
    ensureReportName(): string {
        if (!this.setting)
            this.setting = ConfigService.GetFormSettings(this.route);
        if (!this.setting)
            this.setting = ConfigService.cfg?.forms?.find(o => o.questionnaireName === this.questionnaire?.name);

        if (this.setting) {
            let s = this.setting?.report?.name;
            if (s)
                this.report = s;
        }

        return this.report;
    }

    /** get the current print settings for this questionnaire */
    getPrintSettings(): { valid: boolean, reportName: string, bodyPart: string } {
        const result = {
            valid: true,
            reportName: undefined,
            bodyPart: undefined
        };

        if (!this.response || !ReportService.ReportServer) {
            result.valid = false;
            return;
        }

        result.reportName = this.ensureReportName();

        result.valid = !!result.reportName;

        return result;
    }

    /*** check if the current form uses a report for auto report generation */
    private checkAutoSave() {
        try {
            if (!this.response || !this.questionnaire) {
                console.warn("No Response or no Questionnaire", this.response, this.questionnaire);

                return;
            }

            if (!this.setting && this.questionnaire.name)
                this.setting = ConfigService.GetFormSettings(this.questionnaire?.name);

            if (!this.setting)
                this.setting = ConfigService.cfg?.forms.find(o => o.questionnaireName && o.questionnaireName.toUpperCase() === this.questionnaire.name.toUpperCase());

            if (!this.setting) {
                console.warn(`Could not find any route that has the questionnaireName property set to "${this.questionnaire.name}"`);

                return;
            }

            const statusOk = ['amended', 'completed'].indexOf(this.response.status) > -1;
            const arrayOk = NitTools.IsArray(this.setting?.report?.autoSaveReportName);

            if (statusOk && arrayOk) {
                for (const name of this.setting?.report?.autoSaveReportName) {
                    const printSettings = this.getPrintSettings();
                    if (!printSettings.valid || printSettings.reportName !== name) {
                        if (ConfigService.Debug) {
                            console.info(`Skipping autoSave-Report with name "${printSettings.reportName}"`);
                            debugger;
                        }

                        continue;
                    }

                    ReportService.SendAutoSave(this.response.id, name, this.patient, printSettings.bodyPart)
                        .catch(e => console.warn(e));
                }
            } else {
                if (ConfigService.Debug) {
                    console.info(`No autosave triggered`);
                }
            }
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    saveButtonClicked(status = undefined, closeForm : boolean = true) {
        if (this.readonly && closeForm) {
            this.close(true);
            return;
        }

        if (status) {
            // don't allow to fall back to in-progress when already finalized
            if (status === 'in-progress' && ['completed', 'amended'].indexOf(this.response.status) > 1) {
                console.warn(`Could not fall back from status "${this.response.status}" to ${status}! Aborting save`);
                return;
            }

            // when response is already in completed status set it to amended
            if (status === 'completed' && this.response.status === 'completed')
                this.response.status = 'amended';
            else 
                this.response.status = status;

            console.warn(`Saving in state "${this.response.status}"`);
        } else {
            // head over to the next questionnaire status.
            // in-progress -> completed
            // completed -> amended
            // anything other -> completed
            switch (this.response?.status) {
                case fhirEnums.QuestionnaireResponseStatus.inProgress:
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                    break;
                case fhirEnums.QuestionnaireResponseStatus.completed:
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                    break;
                default:
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
            }
        }

        // the Fhir-Server should update this himself, but if not be on the save side
        if (this.response?.meta) {
            this.response.meta.lastUpdated = new Date().toJSON();
        }

        // tripple calculation to be extremely sure even the dependend value match
        const count = ConfigService.cfg?.maxQuestionnaireCalculationTrips || 6;
        for (let i = 0; i < count; i++) {
            this.questionnaireClass.calculateFieldValues();
        }
        
        // check for autosave a report to the server        
        this.checkAutoSave();

        PatientService.AddQuestionnaireResponse(this.patient, this.response, true);
        if (closeForm || this.settings?.closeOnSave === true) {
            this.close(false);
        }
    }

    close(canceled: boolean) {
        let dialogController = this.dialogService.controllers[0];
        if (!dialogController) {
            this.dialogService.closeAll();
        } else {
            if (canceled) {
                this.response.item = NitTools.Clone(this.responseBackup);
                dialogController.cancel(this.response);
            } else {
                dialogController.ok(this.response);
            }
        }
    }

    debug(msg: string) {
        if (ConfigService.Debug) {
            console.debug(`[QuestionnaireDialogContainer] - ${msg}`);
        }
    }

    selectLastResponse() {
        if (this.settings.selectLastResponse === false) return;
        super.selectLastResponse();
    }

    async questionnaireClassChanged() {
        if (!this.questionnaireClass) {
            // this.debug("No questionnaireClass");
        } else {
            // this.debug("questionnaireClassChanged() triggered, calculating form values");
            await PatientItem.Load(this.settings.encounter.id)
                .catch(err => {
                    console.warn(err);
                });

            if (!this.response) {
                this.questionnaireClass.readonly = true;
            } else {
                this.questionnaireClass.preFieldCalculationFunction = this.settings.preFieldCalculationFunction;
                this.questionnaireClass.calculateFieldValues();
            }
        }
    }

    override getQuestionnaireListItem(response): IQuestionnaireListItem {
        let questionnaire = undefined;
        try {
            questionnaire = QuestionnaireService.GetQuestionnaireDirect(response?.questionnaire);
        } catch {
            // noop
        }

        let r = {
            id: response.id,
            text: `${questionnaire ? (questionnaire.name || questionnaire.title) : ''} @ ${moment(response.authored).format(this.dateFormat)}`,
            date: moment(response.authored).toDate()
        };

        r.text = `#${this.responses.length + 1} ${r.text}`;

        return r;
    }

    override async attached(): Promise<void> {
        await super.attached();
        this.uxBody.style.height = `${window.innerHeight - 165}px`;
    }

    async openDateEditDialog() {
        if (!this.settings.showDateEdit || !this.response) return; // only change date for currently selected tab!

        let oldVal = moment(this.response.authored).format(RuntimeInfo.DateTimeFormat);
        this.dialogService.open({
            viewModel: PromptInput,
            model: {
                message: this.i18n.tr("tab_header_enter_date"),
                title: translations.translate('information'),
                yesText: translations.translate("ok"),
                noText: translations.translate('abort'),
                inputType: "date",
                value: this.response.authored||moment(new Date()).toJSON()
            },
            lock: true
        })
        .whenClosed(async dialogResult => {
            if (dialogResult.wasCancelled) return;

            let newDate = RuntimeInfo.IsMobile ? new Date(dialogResult.output).toJSON() : moment(dialogResult.output, RuntimeInfo.DateTimeFormat).toJSON();
            try {
                if (typeof this.afterDateEdited === 'function') {
                    this.afterDateEdited(newDate);

                    return;
                }

                RuntimeInfo.IsLoading = true;
                this.response.authored = this.response.authored || newDate;
                let existing = this.responses.find(o => o.id === this.response.id);
                if (existing) {
                    existing.authored = this.response.authored;
                }

                if (this.patient) {
                    try {
                        this.patient.questionnaireResponses.find(o => o.id === this.response.id).authored = newDate;
                    } catch (e) {
                        console.warn(e.message || e);
                    }
                }

                let newVal = moment(this.response.authored).format(RuntimeInfo.DateTimeFormat);
                if (newVal === oldVal) {
                    RuntimeInfo.IsLoading = false;
                    if (ConfigService.Debug) {
                        console.debug("Value has not been changed, no need to update response", this.response);
                    }

                    return;
                }

                await this.fhirService.update(this.response);
                PatientService.AddQuestionnaireResponse(this.patient, this.response, true);
                RuntimeInfo.IsLoading = false;
            } catch (e) {
                RuntimeInfo.IsLoading = false;
                console.warn("Error when updating authored date:", e.message || e);
            }
        });
    }

    override async activate(settings?: IQuestionnaireDialogSettings) {
        if (ConfigService.Debug)
            window["dialog"] = this;

        this.patient = settings?.patient;
        this.settings = settings;

        if (settings) {
            if (settings.showToolbar) {
                this.dateFormat = translations.translate("date_time_format_short");
            }

            if (typeof settings.selectLastResponse === "undefined") settings.selectLastResponse = true;
            this.data = settings.data;
            this.isTrainee = settings["isTrainee"] || UserService.UserRole === 'trainee';
            this.saveText = settings.saveText || this.i18n.tr("save");
            this.abortText = settings.abortText || this.i18n.tr("abort");
            this.tooold = settings.tooold === true;
            this.showSelection = typeof settings.showSelection !== "undefined" ? settings.showSelection : true;
            this.grouplist = settings.grouplist;
            if (typeof settings.forcedReadonly === 'boolean')
                this.forcedReadonly = settings.forcedReadonly;

            this.questionnaireTitle = settings.questionnaire.title || settings.questionnaire.name;
            this.dialogService = settings.dialogService;

            this.questionnaire = settings.questionnaire;
            this.status = settings.status;
            this.showToolbar = settings.showToolbar;
            this.allowAddNew = settings.allowAddNew;
            this.responseBackup = NitTools.Clone(settings.response ? settings.response.item : undefined);
            this.removeNoToolbarWindow = typeof settings.removeNoToolbarWindow !== "undefined" ? settings.removeNoToolbarWindow : true;
            this.response = settings.response;

            if (!this.questionnaire && this.response?.questionnaire) {
                this.questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.response?.questionnaire);
                this.questionnaireName = this.questionnaire?.name || 'Questionnaire not found!';

                this.item = this.questionnaire.item;
            }

            if (settings.needsEditButton && !settings.forcedReadonly && this.response?.status!='in-progress') {
                this.readonly = true;
            }
        }
    }
}

export interface IQuestionnaireDialogSettings {
    closeOnSave? : boolean;
    /** Gets or sets a value indicating whether the date-picker button for response.authored should be displayed */
    showDateEdit? : boolean;
    patient?: PatientItem;
    grouplist: boolean;
    /** additional warning text, displayed bottom left in red - keep it short! */
    hintText?: string;
    response: any;
    questionnaire: any;
    encounter: any;
    tooold: boolean;
    haschanges: boolean;
    dialogService: DialogService;
    status?: string;
    showToolbar?: boolean;
    allowAddNew?: boolean;
    showSelection?: boolean;
    patientService?: PatientService;
    data?: any;
    saveText?: string;
    abortText?: string;
    selectLastResponse?: boolean;
    stati?: any;
    removeNoToolbarWindow?: boolean;
    preFieldCalculationFunction?: any;
    forcedReadonly?: boolean;
    advancedSaveButton?: boolean;
    needsEditButton?: boolean;
}
