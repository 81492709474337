import {autoinject} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {FhirService} from "../services/FhirService";
import { Moment } from "moment";
import {ConfigService} from "../services/ConfigService";
const moment = require('moment');

@autoinject
export class ModalLooseImageEditDialog {
    _media: any;
    text: string;
    print: boolean = false;
    showPrint : boolean = true;
    showActiveCheckbox : boolean = false;
    isActive : boolean;
    validDueToDate : Date;
    showDeleteButton : boolean = true; // FhirService.FhirVersion > 3;

    get media() {
        return this._media;
    }

    set media(value) {
        this._media = value;
        if (this._media) {
            if (this._media.occurrencePeriod?.end) {
                const end : Moment = moment(this._media.occurrencePeriod.end);
                if (end.isBefore(new Date())) {
                    this.isActive = false;
                } else {
                    this.isActive = true;
                }
            } else {
                this.isActive = true;
            }

            this.text = this.media.content?.title;
            
            if (this._media.identifier) {
                const ident = this._media.identifier.find(o => o.system.endsWith(("/PrintImage")));
                if (ident) {
                    this.print = ident.value == "true";
                }
            }
        }
    }    

    ok() {
        this.controller.ok({delete: false, text: this.text, print: this.print, media: this.media, 
            isActive: this.showActiveCheckbox ? this.isActive : true,
            validDueToDate : moment(this.validDueToDate).toJSON()
         });
    }

    delete() {
        this.controller.ok({delete: true, media: this.media});
    }

    constructor(protected controller: DialogController) {
    }

    dpOptions = {
        format: 'DD.MM.YYYY',
        locale: 'de-DE',
        showClear: true,
        showClose: true,
        widgetPositioning: {
            horizontal: 'left',
            vertical: 'auto'
        },
        focusOnShow: true,
        keepInvalid: false,
        useCurrent: false,
        defaultDate: false
    };

    async activate(data) {
        this.media = data?.media;
        this.showActiveCheckbox = data?.showActiveCheckbox;
        this.isActive = data?.showActiveCheckbox ? data?.isActive : true;

        if (this.media?.occurrencePeriod?.end)
            this.validDueToDate = moment(this.media?.occurrencePeriod?.end);

        const subtypeName = FhirService.FhirVersion > 3 ? 'modality' : 'subtype';

        if (this.media[subtypeName]?.coding) {
            // no print checkbox for order-images
            this.showPrint = typeof this.media[subtypeName].coding.find(o=>o.code === 'order-image') === "undefined";
        }
    }
}
