import {bindable, inject} from "aurelia-framework";
import {qChangeNotifier} from "./q-changeNotifier";
import {I18N} from "aurelia-i18n";

@inject(qChangeNotifier, I18N)
export class qBase {
    @bindable response: any;
    @bindable previousresponse: any;
    @bindable item: any;
    @bindable changed: Function;
    @bindable readonly;

    i18n: I18N;
    notifier: qChangeNotifier;
    subscriberId: string;

    constructor(notifier: qChangeNotifier, i18n: I18N) {
        this.notifier = notifier;
        this.i18n = i18n;

        this.subscriberId = notifier.subscribe((item) => {
            if (!this.item) return;
            if (item.linkId === this.item.linkId) {
                this.onItemValueChangeNotified(item);
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    onItemValueChangeNotified(item) {
    }

    detached() {
        this.notifier.unsubscribe(this.subscriberId);
    }
}
