import {bindable, inject} from "aurelia-framework";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {qChangeNotifier} from "./q-changeNotifier";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {ConfigService} from "../../services/ConfigService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {FhirService} from "../../services/FhirService";
import { NitTools } from "resources/classes/NursitTools";

@inject(qChangeNotifier)
export class qGroupListItem {
    notifier: qChangeNotifier;

    constructor(notifier: qChangeNotifier) {
        this.notifier = notifier;
    }

    @bindable isInLightBox : boolean;
    /** the name of the increased CareLevel group in the questionnaire */
    @bindable pkmsGroupName: string;

    /** the group to create this item from */
    @bindable group: any;

    /** gets or sets a value indicating whether this questionnaire is kind of assessment */
    @bindable isassessment: boolean;

    /** the current generated id for the questionnaire form. Needed for the scrolling-stuff */
    @bindable formid: string;

    /** the current Questionnaire Response this group belongs to */
    @bindable response: any;

    /** gets or sets a value indicating whether the user is able to toggle a groups' display */
    @bindable readonly: boolean;

    /** id of assessment questionnaire id, to indicate what switch-style to display */
    @bindable assessmentid: string;

    /** the previous answers. Is needed for the /!\ Icons */
    @bindable previousresponse: any;

    @bindable onCheckedChanged : any;

    /** indicates that a group should be displayed, which means the patient has problems regarding that specific group */
    @bindable isGroupVisible: boolean;
    isGroupVisibleChanged(value) {        
        this.notifier.notify(this.group);
    }

    /** gets or sets a value indicating whether the /!\  should be displayed */
    showPreviousResponseAlert: boolean = false;

    /** gets or sets a value indicating whether this group has at least one enableWhen items */
    isEnableWhen: boolean = false;

    /** recalculates the display of the alert sign */
    updateAlertDisplay() {
        let visibleExtension = this.getVisibleExtension();
        if (visibleExtension && visibleExtension.valueBoolean || !this.previousresponse) {
            this.showPreviousResponseAlert = false;
            return;
        }

        // get the previous visible item
        let prevVisible = this.getVisibleExtension(this.previousresponse);

        // no previous stored item, but the current group is hidden? Alert and bail out
        if (!prevVisible && !this.isGroupVisible) {
            this.showPreviousResponseAlert = true;
            return;
        }

        let ext1 = this.getVisibleExtension(this.previousresponse);
        let v1 = ext1 ? ext1.valueBoolean : true;

        let ext2 = this.getVisibleExtension(this.response);
        let v2 = ext2 ? ext2.valueBoolean : true;

        this.showPreviousResponseAlert = v1 !== v2;
    }

    previousresponseChanged() {
        this.updateAlertDisplay();
    }

    responseChanged() {
        this.updateGroupVisibleDisplay();
        this.updateAlertDisplay();
    }

    isEnableWhenItem(): boolean {
        if (!this.group || !this.group.enableWhen || this.group.enableWhen.length === 0) return false;
        return true;
    }

    /** gets a value indicating whether this item has all enableWhen properties satisifed. If not it should not be displayed */
    get isEnableWhenSatisfied(): boolean {
        if (!this.group || typeof this.group.enableWhen === "undefined" || this.group.enableWhen.length === 0) return true;
        return Fhir.Tools.IsEnableWhenSatisfied(this.group, this.response);
    }

    /** is called when the current assessment response in the questionnaire is updated */
    assessmentidChanged() {
        if (!this.response) {
            this.isassessment = false;
            return;
        }

        if (FhirService.FhirVersion > 3) {
            this.isassessment = String(this.response.questionnaire).toUpperCase().indexOf(`/${PatientItem.LastLoadedPatient.assessmentName.toUpperCase()}`) > -1;
        } else {
            this.isassessment = this.response.questionnaire.reference.indexOf("Questionnaire/" + this.assessmentid) > -1;
        }
    }

    /** gets or creates the visible extension for this group in the response extensions section */
    getVisibleExtension(response?: any): any {
        if (!response) response = this.response;
        let url = `questionnaire-group-visible/${this.group.linkId}`;
        let extension = Fhir.Tools.GetOrCreateExtension(response, url, true);
        if (extension && typeof extension.valueBoolean === "undefined") {
            extension.valueBoolean = true;
        }

        return extension;
    }

    /** updates the display value for the current group item */
    updateGroupVisibleDisplay(): boolean {
        if (!this.response) {
            this.isassessment = false;
            return true;
        }

        if (this.group.enableWhen && this.group.enableWhen.length > 0) {
            if (Fhir.Tools.IsEnableWhenSatisfied(this.group, this.response)) {
                this.isEnableWhen = false;
            } else {
                this.isEnableWhen = true;
            }
        }

        let ext = this.getVisibleExtension(this.response);
        if (!ext || typeof ext.valueBoolean === "undefined") {
            this.isGroupVisible = true;
        } else {
            try {
                this.isGroupVisible = this.getVisibleExtension(this.response).valueBoolean;
            } catch (e) {
                console.warn(e.message || JSON.stringify(e));
                this.isGroupVisible = true;
            }
        }
    }

    /** scrolls the questionnaire form to the group with the specified link-id */
    scrollToGroup(linkId: string) {
        if (!this.isGroupVisible) return;
        const grp = document.querySelector(`[data-link-id="${linkId}"]`);
        if (grp && this.isInLightBox) {
            grp.scrollIntoView();
        } else {
            this.scroll(linkId).then(() =>
                this.scroll(linkId)
                    .catch(error => console.warn(error))
            );
        }
    }

    /** toggles the visible status in the associated Fhir-Item for storing the display */
    updateGroupStatus() {
        if (this.readonly) return;

        let newVisible = !this.isGroupVisible;

        if (!newVisible && !this.isassessment) {
            this.setDefaultGroupValues();
        }
        
        this.getVisibleExtension(this.response).valueBoolean = newVisible;

        if (this.response && this.response.item) {
            // add a valid questionnaire item, which is moved out later from checking, just to indicate changes
            let changedItem: any = this.response.item.find(o => o && o.linkId === '_groupsChanged');
            if (!changedItem) {
                changedItem = {
                    linkId: '_groupsChanged'
                };

                this.response.item.push(changedItem);
            }

            changedItem.answer = [{valueDateTime: Fhir.Tools.GetTimeStamp(new Date())}];
        } 
        
        this.isGroupVisible = newVisible;
        
        this.updateAlertDisplay();
        
        if (typeof this.onCheckedChanged === "function")
            this.onCheckedChanged(this.group);

        this.notifier.notify(this.group);
    }

    getInitialValue(questionnaire: any, linkId: string): any {
        let item = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
        if (!item) return undefined;
        if (NitTools.IsArray(item.initial)) return item.initial;
        if (item.initialCoding) return item.initialCoding.code;
        if (item.initialString) return item.initialString;
        if (item.initialTime) return item.initialTime;
        if (item.initialInteger) return item.initialInteger;
        if (item.initialDecimal) return item.initialDecimal;        
    }

    setDefaultGroupValuesforGroup(targetGroup, questionnaire) {        
        if (!targetGroup || !this.isassessment) return;

        for (const subItem of targetGroup.item) {
            if (subItem.type === "group") {
                console.warn("Setting sub group values:");
                this.setDefaultGroupValuesforGroup(subItem, questionnaire);
                continue;
            }

            if (!subItem.readOnly) {
                let _newValue = this.getInitialValue(questionnaire, subItem.linkId);

                let valueItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, subItem.linkId);
                if (valueItem) {                    
                    if (!_newValue) {
                        valueItem.answer = [];

                        this.notifier.notify(valueItem);
                        continue;
                    }

                    if (NitTools.IsArray(_newValue)) {
                        // R4 version - initial value is an array
                        valueItem.answer = _newValue;
                    } else {
                        // DSTU3 Version
                        valueItem.answer = [
                            {
                                valueCoding: {
                                    code: _newValue
                                }
                            }
                        ];
                    }

                    this.notifier.notify(valueItem);
                }
            }
        }

        this.getVisibleExtension(this.response).valueBoolean = false;    
    }

    setDefaultGroupValues() {
        if (!this.isassessment) return;
        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(this.response?.questionnaire);
        if (!this.group || !questionnaire) return;

        this.setDefaultGroupValuesforGroup(this.group, questionnaire);
    }

    /** scrolls the form to the given linkid */
    private scroll(linkId: string): Promise<void> {
        return new Promise<void>((resolve) => {
            let checkBox = $(`[data-target-link-id="${linkId}"]`);
            if (checkBox.hasClass('collapsed')) {
                resolve();
                return;
            }

            let $ele = $(`[data-link-id="${linkId}"]`);
            if (!$ele.is(":visible") || $ele.length === 0) {
                resolve();
                return;
            }
            let pos = $ele.position();
            if (!pos) {
                resolve();
                return;
            }

            let $p = $(`#${this.formid}`);
            $p.animate({scrollTop: pos.top}, () => {
                resolve();
            });
        });
    }
}
