import * as environment from '../../../../config/environment.json'
import * as Fhir from '../../../resources/classes/FhirModules/Fhir'
import {QuestionnaireResponse} from '../../../resources/classes/FhirModules/Fhir'
import {ModalBodyMap} from '../../../resources/elements/modal-body-map'
import {NitTools} from '../../../resources/classes/NursitTools'
import {bindable, TaskQueue} from 'aurelia-framework'
import {GrafixxItem, IGrafixxItem} from '../../../resources/classes/Grafixx-item'
import {translations} from '../../../resources/classes/translations'
import {woundImageDialog} from './wound-image-dialog'
import {IDrawingObject, woundDraw} from '../draw'
import {BasicForm} from '../../../resources/elements/BasicForm'
import {saveButton, saveButtonState} from '../../../resources/elements/save-button'
import {PatientService} from 'resources/services/PatientService'
import {qGrafixx} from '../../../resources/elements/questionnaire/grafixx/q-grafixx'
import {fhirEnums} from '../../../resources/classes/fhir-enums'
import {ConfigService} from '../../../resources/services/ConfigService'
import {ReportService} from '../../../resources/services/ReportService'
import {HttpClient} from 'aurelia-http-client'
import {FormBaseClass} from '../../../resources/elements/FormBaseClass'
import {RuntimeInfo} from '../../../resources/classes/RuntimeInfo'
import {WoundDateEdit} from './wound-date-edit'
import {Questionnaire} from '../../../resources/elements/questionnaire/questionnaire'
import {UserService} from '../../../resources/services/UserService'
import {PromptInput} from '../../../resources/elements/prompt-input'
import {ImagesHistory} from '../../../resources/elements/wounds/images-history'
import {WoundDataHandler} from './wound-data-handler'
import {FhirService} from '../../../resources/services/FhirService'
import {Modal3dBody} from '../../../resources/elements/modal-3dbody'
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {QuestionnaireService} from "../../../resources/services/QuestionnaireService";

const moment = require('moment');
const Viewer = require('viewerjs');

export class woundMain extends BasicForm {
    printId: string;
    printSystem = 'http://nursit-institute.com/fhir/StructureDefinition/PrintImage';
    wounds: IWoundListItem[] = [];
    progressReportName: string;
    hasProgressReport: boolean = false;
    currentWoundResponses: any[] = [];
    contentDiv: HTMLDivElement;
    hasResponses: boolean = false;
    useQuestionnaireStatusForSave: boolean = FormBaseClass.UseQuestionnaireStatusForSave;
    showSaveButton: boolean = true;
    showPrintButton: boolean = false;
    groups: WoundGroup[] = [];
    woundTabParent: HTMLDivElement;
    currentQuestionnaire: any;
    scrollOffset: number = 200;
    isWoundGroupSelected: boolean = false;
    /** all the observations stored in Fhir */
    @bindable observations: any[];
    questionnaireResponseList: any;
    noPatientProblemsText: string;
    woundIcons: { name: string; source: string }[] = [];
    taskQueue: TaskQueue;
    wound;
    woundGroupLists: any[] = [];
    responseBackup: any[];
    noResponsesText: string;
    thumbnails: IThumbGroup[] = [];
    fileUpload: HTMLInputElement;
    isNewResponse: boolean = false;
    saveButton: saveButton;
    observationsBundled: boolean = false;
    rotations = {
        1: 0, 3: 180, 6: 90, 8: 270,
    };
    newImageWidth: number;
    newImageHeight: number;
    viewer;
    uploadForm: HTMLFormElement;
    currentMediaId: string;
    tooOldText: string = 'Formular ist zu alt um bearbeitet zu werden';
    editable: boolean = false;
    currentBodyPart: string;
    notifierId: string = undefined;
    debugging: boolean;
    processing: boolean = true;
    currentQuestionnaireId: string;
    showApplyButton: boolean = false;
    is3dBody: boolean = false;
    imageCache: any = undefined;
    protected dataHandler: WoundDataHandler;

    _isReadonly: boolean = false;

    get isReadonly(): boolean {
        return this._isReadonly;
    }

    set isReadonly(value: boolean) {
        this._isReadonly = value;
        this.updateHeaderButtonClassName(this.response, value === false ? 'mdi-edit' : undefined);
    }

    @bindable private _selectedId: string;

    get selectedId(): string {
        return this._selectedId;
    }

    set selectedId(value: string) {
        this._selectedId = value;
        this.selectedIdChanged(value);
    }

    _selectedWoundId: string = undefined;

    get selectedWoundId(): string {
        return this._selectedWoundId;
    }

    set selectedWoundId(value: string) {
        this._selectedWoundId = value;
        this._selectedWound = this.observations ? this.observations.find((o) => o.id === value) : undefined;
    }

    _selectedWound: any;

    get selectedWound() {
        return this._selectedWound;
    }

    _collapseState: number = 0;

    get collapseState(): number {
        return this._collapseState;
    }

    set collapseState(value: number) {
        if (value > 2) value = 2;
        if (value < 0) value = 0;
        this._collapseState = value;
    }

    get saveButtonClass(): string {
        return this.hasChanges ? 'btn-warning' : 'btn-default';
    }

    get saveButtonIconClass(): string {
        let result = '';

        if (this.response) {
            if (this.tooOld) result = 'mdi-alarm'; else {
                if (this.isReadonly) {
                    result = 'mdi-edit';
                } else {
                    if (this.response.status === 'in-progress') {
                        result = 'mdi-done-all';
                    } else {
                        result = 'mdi-save';
                    }
                }
            }
        }

        return result;
    }

    get hasWounds(): boolean {
        return this.groups && this.groups.length > 0;
    }

    get saveButtonText(): string {
        if (!this.tooOld && this.isReadonly) {
            return this.i18n.tr('edit');
        } else {
            return this.i18n.tr(this.tooOld ? 'edit' : 'save');
        }
    }

    incState(inc) {
        this.collapseState += inc;
    }

    // -------------------------------------------

    async attached() {
        if (ConfigService.Debug) window['wounds'] = this;

        this.debugging = ConfigService.Debug;
        this.route = ConfigService.FormNames.Wounds;

        const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
        this.is3dBody = Boolean(config.settings?.body3d?.enabled);
        await this.dataHandler.ensure3dCodeSystem();

        await this.questionnaireService.fetch(true);
        await super.attached();
        await GrafixxItem.Init();
        if (ReportService.ReportServer) {
            try {
                await ReportService.Fetch();
            } catch (e) {
                console.warn(e);
            }
        }

        this.noPatientProblemsText = this.i18n.tr('no_patient_problems_default');
        this.printId = `${NitTools.IncludeTrailingSlash(environment.nursItStructureDefinition,)}PrintImage`;
        this.notifierId = this.notifier.subscribe(() => {
            this.currentWoundResponses.sort((a, b) => {
                let d1 = new Date(a.authored);
                let d2 = new Date(b.authored);
                return d1.valueOf() - d2.valueOf();
            });
        });

        this.tooOldText = this.i18n
            .tr('no_edit_after_24h_info')
            .replace('%HOURS%', this.setting.expiration.default.toString());
        this.noResponsesText = this.i18n
            .tr('no_current_docu')
            .replace('%NAME%-', '');

        this.isLoading = true;

        this.questionnaireResponseList = await this.getListResource();

        document.body.classList.add('no-toolbar-window');
        await this.loadWounds();

        if (!this.observations || this.observations.length === 0) {
            this.taskQueue.queueTask(() => {
                this.showAllWounds();
            });
        }

        this.isLoading = false;

        if (this.groups && this.groups[0] && this.groups[0].children && this.groups[0].children[0] && this.groups[0].children[0].item) {
            this.taskQueue.queueTask(async () => await this.selectWound(this.groups[0].children[0].item),);
        }

        this.showPrintButton = !!ReportService.ReportServer;

        if (this.setting) {
            this.showApplyButton = NitTools.ParseBool(this.setting['showApplyButton']);
        }

        if (!this.showApplyButton) {
            this.showApplyButton = environment.testing && ConfigService.Debug;
        }

        this.updateReport();

        if (UserService.IsReadonly) {
            this.showApplyButton = this.showSaveButton = false;
            this.forcedReadonly = this.forceNoChange = this.readonly = this.isReadonly = true;
        }
    }

    formChanged(sender: Questionnaire) {
        if (typeof sender === 'undefined') return;
        let s1 = JSON.stringify(this.response.item);
        let s2 = JSON.stringify(this.responseBackup);
        let changeCheck = false;

        if (s1 != s2) {
            let allIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire,);
            let backupResponse: any = {
                resourceType: 'QuestionnaireResponse', status: 'this-is-invalid', item: this.responseBackup,
            };

            allIds.forEach((id) => {
                let qItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, id,);
                if (qItem) {
                    let isCalc = (qItem && qItem.extension && typeof qItem.extension.find((o) => o.url.endsWith('/questionnaire-calculated-field'),) === 'undefined') || (qItem.initialCoding && qItem.initialCoding.code && qItem.initialCoding.code.indexOf('=') === 0);
                    if (!isCalc) {
                        let origItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, id,);
                        let backupItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(backupResponse, id,);
                        if (origItem && backupItem) {
                            let v1 = QuestionnaireResponse.GetResponseItemValue(origItem);
                            let v2 = QuestionnaireResponse.GetResponseItemValue(backupItem);
                            if (v1 != v2) {
                                //this.debug("Changed:" + id + " from \n'" + v1 + "' to  '" + v2 + "'");
                                changeCheck = true;
                            }
                        } else {
                            // either no orig or no new value
                            changeCheck = true;
                        }
                    }
                }
            });
        }

        this.hasChanges = changeCheck;
    }

    debug(str, ...items) {
        if (ConfigService.Debug) {
            if (typeof (str === 'string')) {
                console.debug('[Wound-main.ts] - ' + str, items);
            } else {
                console.debug('[Wound-main.ts]', str, items);
            }
        }
    }

    async printVerlegungsbericht() {
        // immer das letzte freigegebene drucken
        if (!this.response || !this.selectedWound) return;
        let reportName = this.ensureReportName();

        if (this.hasChanges && this.saveButton && this.saveButton.buttonState === 'save') {
            this.showSaveFirstDialog();
            return;
        }

        let completed = this.currentWoundResponses.filter((o) => o.status === fhirEnums.QuestionnaireResponseStatus.completed || o.status === fhirEnums.QuestionnaireResponseStatus.amended,);
        if (completed.length === 0) {
            this.dialogMessages.prompt(this.i18n.tr('no_completed_wound_for_transfer_report'), this.i18n.tr('information'), false,);
            return;
        }

        let last = completed[completed.length - 1];
        if (!last) {
            return;
        } else {
            let bodyPart = '';
            if (this.selectedWound && this.selectedWound.bodySite && this.selectedWound.bodySite.coding && this.selectedWound.bodySite.coding[0]) {
                bodyPart = this.selectedWound.bodySite.coding[0].display;
            }

            ReportService.Preview(this.response.id, `${reportName}${this.setting.report.transferSuffix}`, bodyPart,);
        }
    }

    async printVerlaufsbericht() {
        // immer das letzte freigegebene drucken
        if (!this.response || !this.selectedWound || !this.progressReportName) return;

        if (this.hasChanges && this.saveButton && this.saveButton.buttonState === 'save') {
            this.showSaveFirstDialog();
            return;
        }

        let completed = this.currentWoundResponses.filter((o) => o.status === fhirEnums.QuestionnaireResponseStatus.completed || o.status === fhirEnums.QuestionnaireResponseStatus.amended,);
        if (completed.length === 0) {
            this.dialogMessages.prompt(this.i18n.tr('no_completed_wound_for_transfer_report'), this.i18n.tr('information'), false,);
            return;
        }

        let last = completed[completed.length - 1];
        if (!last) {
            return;
        } else {
            let bodyPart = '';
            if (this.selectedWound && this.selectedWound.bodySite && this.selectedWound.bodySite.coding && this.selectedWound.bodySite.coding[0]) {
                bodyPart = this.selectedWound.bodySite.coding[0].display;
            }

            ReportService.Preview(this.response.id, `${this.progressReportName}`, bodyPart,);
        }
    }

    showSaveFirstDialog() {
        this.dialogMessages.prompt(this.i18n.tr('print_unsaved_changes'), this.i18n.tr('information'), false,);
    }

    ensureWoundExtensions(response: any): boolean {
        // result indicates whether the response needs to be updated
        let result = false;

        if (!response.extension) response.extension = [];
        let extWoundId = response.extension.find((o) => o.url.endsWith('wound-id'));
        if (!extWoundId) {
            response.extension.push({
                url: 'http://nursit-institute.com/StructureDefinition/wound-id', valueId: this.selectedWound.listId || this.selectedWound.id,
            });

            result = true;
        }

        let extIsGroupItem = response.extension.find((o) => o.url.endsWith('is-wound-group-item'),);
        if (!extIsGroupItem) {
            response.extension.push({
                url: 'http://nursit-institute.com/StructureDefinition/is-wound-group-item', valueId: this.isWoundGroupSelected ? 'yes' : 'no',
            });

            result = true;
        }

        return result;
    }

    /**
     * a function that ensures a valid report name is present instead of undefined.
     * This is needed because in some cases the response or reportname is not set by userclick and my be undefined
     */
    ensureReportName(): string {
        let s = this.setting.report.other[this.questionnaire.name.toLowerCase()];
        if (s && s !== this.report) this.report = s;

        return this.report;
    }

    getPrintSettings(multiPrint: boolean = false): { valid: boolean, reportName: string, bodyPart: string } {
        const result = {
            valid: true,
            reportName: undefined,
            bodyPart: undefined
        };

        if (!this.response || !this.selectedWound || !ReportService.ReportServer) {
            result.valid = false;
            return;
        }

        result.reportName = this.ensureReportName();
        try {
            result.bodyPart = this.selectedWound.bodySite.coding[0].display;
            result.reportName = `${result.reportName + (multiPrint ? this.setting.report.progressSuffix : '')}`;
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
        }

        result.valid = !!result.reportName;

        return result;
    }

    async printDocument(multiPrint: boolean) {
        const printSettings = this.getPrintSettings(multiPrint);
        if (!printSettings.valid) return;

        if (this.hasChanges) {
            this.showSaveFirstDialog();
            return;
        }

        try {
            this.isLoading = true;
            ReportService.Preview(this.response.id, printSettings.reportName, printSettings.bodyPart);
        } catch (ex) {
            console.warn(ex);
        } finally {
            this.isLoading = false;
        }
    }

    /*** check if the current form uses a report for auto report generation */
    checkAutoSave() {
        try {
            const statusOk = ['amended', 'completed'].indexOf(this.response.status) > -1;
            const arrayOk = NitTools.IsArray(this.setting.report?.autoSaveReportName);
            if (statusOk && arrayOk) {
                for (const name of this.setting.report?.autoSaveReportName) {
                    const printSettings = this.getPrintSettings(false);
                    if (!printSettings.valid || printSettings.reportName?.toUpperCase() !== name.toUpperCase()) continue;

                    ReportService.SendAutoSave(this.response.id, name, this.patient, printSettings.bodyPart)
                        .catch(e => console.warn(e));
                }
            }
        } catch (ex) {
            console.warn(ex);
        }
    }

    showImageHistory() {
        this.dialogService.open({
            viewModel: ImagesHistory, model: {
                patient: this.patient, encounterId: this.patient.encounterId, woundId: this.selectedWoundId,
            },
        });
    }

    async saveButtonClick() {
        if (!this.response || this.tooOld) return;
        if (this.isReadonly && !this.tooOld) {
            // just enable writing
            this.isReadonly = false;
            return;
        }

        if (!this.isReadonly && !this.tooOld) {
            RuntimeInfo.IsLoading = true;
            try {
                switch (this.response.status) {
                    case fhirEnums.QuestionnaireResponseStatus.inProgress:
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                        break;
                    case fhirEnums.QuestionnaireResponseStatus.completed:
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                        break;
                }

                let bundle = [];
                // is editable and writeable, so ..

                // .. update on server ..
                bundle.push(this.response);
                await this.fhirService.bundle(bundle, HTTPVerb.put, BundleType.batch);

                // .. and make readonly.
                this.isReadonly = this.response.status === 'completed' || this.response.status === 'amended';

                if (this.response.status === fhirEnums.QuestionnaireResponseStatus.amended || this.response.status === fhirEnums.QuestionnaireResponseStatus.completed) {
                    let report = this.setting.report.autoSaveReportName || this.report;
                    ReportService.SendAutoSave(this.response.id, report, this.patient);
                }

                this.isReadonly = true;
                this.hasChanges = false;
                this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined,);

                this.checkAutoSave();
            } catch (e) {
                this.dialogMessages.prompt(e.message || JSON.stringify(e), this.i18n.tr('warning'), true,);
            } finally {
                this.hasChanges = false;
                this.patientService.addQuestionnaireResponse(this.patient, this.response, true,);
                let idx = this.currentWoundResponses.findIndex((o) => o.id === this.response.id,);

                if (idx > -1) {
                    this.currentWoundResponses[idx] = this.response;
                }

                this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined,);
                RuntimeInfo.IsLoading = false;
            }
        }
    }

    async saveButtonStateChanged(sender: saveButton) {
        if (!this.saveButton) this.saveButton = sender;
        this.isReadonly = sender.buttonState != 'save'; // !sender.isEditable || sender.isTooOld;
        this.tooOld = sender.isTooOld;

        this.updateHeaderButtonClassName(this.response);
    }

    async headerSaveButtonClicked(status: string) {
        try {
            if (this.response) {
                Fhir.Tools.UpdateAuthor(this.response, this.userService.practitioner);

                if (['completed', 'amended'].indexOf(status) > -1) {
                    const validationResult = this.validateResponse(); // => { valid: boolean, missing: string[] }
                    const responseIsValid = validationResult.valid;
                    if (!responseIsValid) {
                        this.isLoading = false;
                        this.dialogMessages
                            .prompt(this.i18n.tr('not_all_required_fields') + '<br />' + validationResult.missing.join('<br />'), this.i18n.tr('warning'), true,)
                            .whenClosed(() => (this.readonly = false))
                            .catch((err) => console.warn(err));
                        return;
                    }
                }

                this.ensureWoundExtensions(this.response);
                this.isLoading = true;

                switch (status) {
                    default:
                    case 'in-progress':
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.inProgress;
                        break;
                    case 'amended':
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                        //this.mayBeEdited = false;
                        //this.isReadonly = true;
                        break;
                    case 'completed':
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                        //this.mayBeEdited = false;
                        //this.isReadonly = true;
                        break;
                }

                // check whether the wound has been moved. If so, move the extension value in all responses to the current bodySite
                let extensionUrl = 'wound-bodySite';
                let updateBundle: any[] = [];
                if (this.selectedWound && this.selectedWound.bodySite && this.selectedWound.bodySite.coding && this.selectedWound.bodySite.coding[0]) {
                    let bodySite = this.selectedWound.bodySite.coding[0].display;
                    for (let i = 0; i < this.currentWoundResponses.length; i++) {
                        let extension = Fhir.Tools.GetOrCreateExtension(this.currentWoundResponses[i], extensionUrl, true,);
                        let extensionValue = Fhir.Tools.GetExtensionValue(extension);
                        if (!extensionValue || extensionValue !== bodySite) {
                            Fhir.Tools.SetExtension(this.currentWoundResponses[i], extensionUrl, bodySite,);
                            updateBundle.push(this.currentWoundResponses[i]);
                        }
                    }
                } else {
                    console.warn('No BodySite found in selectedWound: ', this.selectedWound,);
                }

                if (!updateBundle.find((o) => o.id === this.response.id)) {
                    updateBundle.push(this.response);
                }

                let bundle = await this.fhirService.bundle(updateBundle, HTTPVerb.put, BundleType.transaction, false,);
                bundle.entry.forEach((entry) => {
                    if (entry.resource && entry.resource?.resourceType === 'QuestionnaireResponse') {
                        let resource = <any>entry.resource;
                        PatientService.AddQuestionnaireResponse(this.patient, resource, true,);
                        let existing = this.currentWoundResponses.find((o) => o.id === resource.id,);
                        if (existing) {
                            let idx = this.currentWoundResponses.findIndex((o) => o.id === resource.id,);
                            if (idx > -1) {
                                this.currentWoundResponses[idx] = resource;
                            }
                        }
                    }
                });

                /* let newResponse = bundle.entry.find(o => o.resource && o.resource.id === this.response.id);
                if (newResponse) {
                    // dont just set the response, because that would change the questionnaire, instead set the properties
                    newResponse = Fhir.Questionnaire.EnsureStructuredResponse(this.questionnaire, <any>newResponse);
                    let newResponseQ = <any>newResponse;
                    this.response.item = newResponseQ.item;
                    this.response.meta = newResponseQ.meta;
                    this.response.extension = newResponseQ.extension;
                } */

                this.hasChanges = false;
                this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined,);

                this.checkAutoSave();
            }

            // return Promise.resolve();
        } finally {
            this.isLoading = false;
        }
    }

    selectedIdChanged(id: string) {
        if (!id || (this.response && this.response.id === id)) {
        } else {
            RuntimeInfo.IsLoading = true;
            this.fhirService
                .get(`QuestionnaireResponse/${id}`)
                .then(async (result: any) => {
                    let r = this.currentWoundResponses.findIndex((o) => o.id === id);
                    if (r) {
                        this.currentWoundResponses[r] = result;
                    }

                    await this.selectResponse(result);
                    this.questionnaire = this.questionnaireService.getQuestionnaireDirect(result.questionnaire,);
                    this.response = result;
                    RuntimeInfo.IsLoading = false;
                })
                .catch((error) => {
                    console.warn(error);
                    RuntimeInfo.IsLoading = false;
                });
        }
    }

    scrollRight() {
        $(this.woundTabParent).animate({
            scrollLeft: this.woundTabParent.scrollLeft + this.scrollOffset,
        });
    }

    scrollLeft() {
        $(this.woundTabParent).animate({
            scrollLeft: this.woundTabParent.scrollLeft - this.scrollOffset,
        });
    }

    async selectWound(wound: any) {
        //if (this.selectedWoundId && wound.id && this.selectedWoundId === wound.id) return;
        if (this.hasChanges) {
            this.dialogMessages
                .dialog(this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true,)
                .whenClosed((result) => {
                    if (!result.wasCancelled) {
                        this.response.item = NitTools.Clone(this.responseBackup);
                        this.hasChanges = false;
                        this.selectWound(wound);
                    }
                })
                .catch((err) => console.warn(err));

            return;
        }

        if (!wound || !this.groups) return;
        this.isWoundGroupSelected = false;

        for (const group of this.groups) {
            //if (!this.questionnaire) {
            for (const child of group.children) {
                if ((wound && wound.id && child.item && child.item.id === wound.id) || (wound && wound.listId && child.item && child.item.listId === wound.listId)) {
                    this.isWoundGroupSelected = !!wound.listId;
                    this.wound = child;
                    this.noResponsesText = this.i18n
                        .tr('no_current_docu')
                        .replace('%NAME%-', '');
                    if (this.isWoundGroupSelected) {
                        // assign to _selectedWoundId when in woundGroup-Mode, every other attempt will fail
                        this._selectedWound = wound;
                        this._selectedWoundId = wound.listId;
                    } else {
                        this.selectedWoundId = wound.id;
                    }

                    await this.getResponsesForSelectedWoundId();

                    if (this.currentWoundResponses.length === 0 && RuntimeInfo.Features.woundAutomaticResponseCreation) {
                        // console.warn("CREATE!");
                        await this.addNewResponse();
                    }

                    await this.selectResponse(this.currentWoundResponses[this.currentWoundResponses.length - 1],);

                    this.updateReport();
                    this.updateDebugInfo();
                    await this.loadThumbNails();

                    if (this.currentWoundResponses && this.currentWoundResponses[this.currentWoundResponses.length - 1]) {
                        this.response = this.currentWoundResponses[this.currentWoundResponses.length - 1];

                        this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined,);

                        this.taskQueue.queueTask(() => {
                            if (this.woundTabParent) {
                                this.woundTabParent.scrollLeft = this.woundTabParent.scrollWidth;
                            }
                        });

                        this.hasChanges = false;
                    }
                }
            }
        }
    }

    updateReport() {
        this.report = '';
        if (!this.questionnaire || !this.setting) return;

        let woundName = this.questionnaire.name.toLowerCase();
        let reportName = this.setting.report.other[woundName];
        if (!reportName) {
            let s = this.i18n.tr('error_report_not_found');
            s = s.replace(/%FORMNAME%/gi, woundName);
            s = s.replace('%REPORT%', '');
            this.dialogMessages.prompt(s, this.i18n.tr('warning'), true);
            return;
        }

        this.report = reportName;

        if (reportName && this.setting.report.progressSuffix) {
            this.progressReportName = `${reportName}${this.setting.report.progressSuffix}`;
            ReportService.GetReportByName(this.progressReportName)
                .then((name) => {
                    this.hasProgressReport = !!name;
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }

    async renameGroup(group) {
        this.isLoading = true;
        try {
            let list = <any>await this.fhirService.get(`List/${group.listId}`);

            this.dialogService
                .open({
                    viewModel: PromptInput, model: {
                        title: 'Gruppierung benennen',
                        message: 'Geben Sie einen Namen für diese Gruppierung ein',
                        noText: this.i18n.tr('abort'),
                        yesText: this.i18n.tr('save'),
                        value: list.title || '',
                    },
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled) {
                        this.isLoading = true;
                        list.title = String(result.output).trim();
                        await this.fhirService.update(list);
                        group.title = list.title;
                        let div: HTMLDivElement = document.querySelector("[data-id='" + group.listId + "']",);
                        if (div) div.innerText = group.title;

                        this.isLoading = false;

                        console.warn('Umbenennen zu: "' + result.output + '"');
                    }
                });
        } catch (e) {
            console.warn(e.message);
        } finally {
            this.isLoading = false;
        }
    }

    /** Gets the created Thumbnails from the server for the current patient + selected wound */
    async loadThumbNails() {
        // const w = new WoundDataHandler(this.fhirService, this.i18n);
        this.thumbnails = await this.dataHandler.loadThumbNails(this.patient.encounterId, this.wound,);
    }

    async showUpload() {
        const cameraSetup: ICameraSetup = ConfigService.cfg?.features?.camera || {
            useCameraApi: false,
        };

        const isHttps = String(window.location.protocol).toUpperCase().startsWith('HTTPS') || String(window.location.hostname).toUpperCase() === 'LOCALHOST';
        // make a check for localhost too, because that has special handling and does not need https to access the camera
        if (!isHttps || RuntimeInfo.IsMobile || cameraSetup.useCameraApi !== true || !screen || !navigator?.mediaDevices?.getUserMedia) {
            if (!RuntimeInfo.IsMobile && cameraSetup.useCameraApi) {
                const msgs = [];
                if (!isHttps) msgs.push('- Media-Api only on https');
                if (!screen) msgs.push('- screen property not found');
                if (!navigator?.mediaDevices?.getUserMedia) msgs.push('- navigator.mediaDevices.getUserMedia not supported');

                const msg = msgs.join('\n');
                console.warn(msg);
                if (ConfigService.Debug) alert(msg);
            }

            $(this.fileUpload).trigger('click');

            return;
        }

        try {
            let mediaStream: MediaStream;
            let streaming = false;

            const takePhotoTable = <HTMLDivElement>(document.getElementById('takePhotoTable'));
            takePhotoTable.style.display = 'block';
            takePhotoTable.innerHTML = '';

            const w = cameraSetup.captureWidth || 1984;
            const h = cameraSetup.captureHeight || 1116;
            const video: HTMLVideoElement = document.createElement('video'); // <HTMLVideoElement>document.getElementById('video');
            video.setAttribute('id', 'video');
            video.setAttribute('disablepictureinpicture', 'disablepictureinpicture');
            video.setAttribute('playsinline', 'playsinline');

            takePhotoTable.appendChild(video);

            const canvas: HTMLCanvasElement = document.createElement('canvas'); // <HTMLCanvasElement>document.getElementById('cnv');
            canvas.setAttribute('id', 'cnv');
            takePhotoTable.appendChild(canvas);

            const photo: HTMLImageElement = document.createElement('img'); //<HTMLImageElement>document.getElementById('photo');
            photo.setAttribute('id', 'photo');
            takePhotoTable.appendChild(photo);

            const start: HTMLButtonElement = document.createElement('button'); // <HTMLButtonElement>document.getElementById('startButton');
            start.setAttribute('type', 'button');
            start.setAttribute('id', 'startButton');
            start.setAttribute('title', 'Click!');
            start.classList.add('take-photo-button');
            start.innerHTML = '<i class="mdi mdi-2x mdi-camera"></i>';
            takePhotoTable.appendChild(start);

            const stop: HTMLButtonElement = document.createElement('button'); // <HTMLButtonElement>document.getElementById('closeButton');
            stop.setAttribute('type', 'button');
            stop.setAttribute('id', 'closeButton');
            stop.classList.add('close-photo-button');
            stop.innerHTML = '<i class="mdi mdi-2x mdi-close"></i>';
            takePhotoTable.appendChild(stop);

            const lbl = document.createElement('label');
            if (ConfigService.Debug) {
                lbl.innerText = `${w}px X ${h}px`;
                lbl.classList.add('photo-debug-size');
                takePhotoTable.appendChild(lbl);
            }

            const setVideoDimensions = function (width: number, height: number) {
                if (screen.width > screen.height) {
                    // hor
                    video.setAttribute('width', `${width}`);
                    video.setAttribute('height', `${height}`);
                    video.style.maxWidth = `${width}px`;
                    video.style.maxHeight = `${height}px`;
                } else {
                    // vert
                    video.setAttribute('width', `${height}`);
                    video.setAttribute('height', `${width}`);
                    video.style.maxWidth = `${height}px`;
                    video.style.maxHeight = `${width}px`;
                }

                const vWidth = video.getAttribute('width');
                const vHeight = video.getAttribute('height');
                canvas.setAttribute('width', vWidth);
                canvas.setAttribute('height', vHeight);
                lbl.innerText = `${vWidth}px X ${vHeight}px`;

                if (ConfigService.Debug) console.debug(`Updated Capture resolution to: ${video.getAttribute('width',)} x ${video.getAttribute('height')}`,);
            };

            setVideoDimensions(w, h);

            const takePicture = async (ev) => {
                ev.preventDefault();
                RuntimeInfo.IsLoading = true;

                const context = canvas.getContext('2d');
                video.pause();
                context.drawImage(video, 0, 0, canvas.width, canvas.height);
                const data = canvas.toDataURL('image/png');
                photo.setAttribute('src', data);

                const base64String = data.substr(data.indexOf(',') + 1);
                await this.uploadFile('IMAGE', new Date(), base64String, 0, 'png');

                closeVideoClicked();
            };

            start.addEventListener('click', takePicture, false);

            const closeVideoClicked = () => {
                takePhotoTable.style.display = 'none';
                streaming = false;
                try {
                    video.pause();
                } catch {
                }

                try {
                    for (const track of mediaStream?.getTracks()) {
                        try {
                            if (ConfigService.Debug) (console.debug || console.info)(`Stopping Video track`, track);

                            track.stop();
                        } catch {
                        }
                    }
                } catch (ex) {
                    console.debug('not able to stop stream');
                }

                video.srcObject = undefined;

                video.remove();
                start.remove();
                stop.remove();
                canvas.remove();
                photo.remove();

                takePhotoTable.innerHTML = '';
                RuntimeInfo.IsLoading = false;
            };

            stop.addEventListener('click', closeVideoClicked);

            video.addEventListener('canplay', () => {
                if (!streaming) {
                    //canvas.setAttribute("width", video.getAttribute('width'));
                    //canvas.setAttribute("height", video.getAttribute('height'));

                    streaming = true;
                }
            }, false,);

            navigator.mediaDevices
                .getUserMedia({video: true, audio: false})
                .then((stream) => {
                    if (!stream) {
                        console.warn('Could not acquire video stream. Using File Upload instead');

                        if (ConfigService.cfg?.features?.camera) ConfigService.cfg.features.camera.useCameraApi = false;
                        return;
                    }

                    mediaStream = stream;

                    if (stream?.getVideoTracks && stream.getVideoTracks()?.[0]?.getCapabilities) {
                        console.warn('stream.getVideoTracks()[0].getCapabilities() ', stream.getVideoTracks()[0].getCapabilities(),);
                        const caps = stream.getVideoTracks()[0].getCapabilities();
                        if (caps.width?.max && caps.height?.max) {
                            setVideoDimensions(caps.width.max, caps.height.max);
                        }
                    }

                    try {
                        video.srcObject = mediaStream;
                        video.play();
                    } catch (err) {
                        console.warn(err);
                        closeVideoClicked();
                        if (ConfigService.cfg?.features?.camera) ConfigService.cfg.features.camera.useCameraApi = false;
                        return;
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    closeVideoClicked();
                    if (ConfigService.cfg?.features?.camera) ConfigService.cfg.features.camera.useCameraApi = false;

                    return;
                });
        } catch (e) {
            console.warn(e);
        }

        if (ConfigService.cfg?.features?.camera?.useCameraApi === false) {
            alert('Camera-Api wurde disabled da der Aufruf nicht erfolgreich war');
        }
    }

    /** Reads the EXIF-Orientation from the given File */
    orientation(file): Promise<number> {
        return new Promise<number>((resolve) => {
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                //let base64img = "data:" + file.type + ";base64," + this._arrayBufferToBase64(fileReader.result);
                let scanner = new DataView(<ArrayBuffer>fileReader.result);
                let idx = 0;
                let value = 1; // Non-rotated is the default
                if ((<ArrayBuffer>fileReader.result).byteLength < 2 || scanner.getUint16(idx) != 0xffd8) {
                    // Not a JPEG
                    resolve(value);
                    return;
                }

                idx += 2;
                let maxBytes = scanner.byteLength;
                while (idx < maxBytes - 2) {
                    let uint16 = scanner.getUint16(idx);
                    idx += 2;
                    switch (uint16) {
                        case 0xffe1: // Start of EXIF
                            let exifLength = scanner.getUint16(idx);
                            maxBytes = exifLength - idx;
                            idx += 2;
                            break;
                        case 0x0112: // Orientation tag
                            // Read the value, its 6 bytes further out
                            // See page 102 at the following URL
                            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
                            value = scanner.getUint16(idx + 6, false);
                            maxBytes = 0; // Stop scanning
                            break;
                    }
                }

                resolve(value);
            };

            fileReader.readAsArrayBuffer(file);
        });
    }

    /** gets the file as base64 encoded image source. Could be stored as-is in the Media Content.Data property */
    getBase64(file): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.toString());
            reader.onerror = (error) => reject(error);
        });
    }

    rotate(canvas: HTMLCanvasElement, degrees: number): Promise<string> {
        return new Promise<string>((resolve) => {
            // store current data to an image
            let myImageData = new Image();

            myImageData.onload = function () {
                let context = canvas.getContext('2d');
                if (degrees === 0 || degrees === 360) {
                    context.drawImage(myImageData, 0, 0);
                } else {
                    // reset the canvas with new dimensions
                    let ch = canvas.height;
                    let cw = canvas.width;
                    canvas.width = ch;
                    canvas.height = cw;
                    cw = canvas.width;
                    ch = canvas.height;

                    context.save();
                    // translate and rotate
                    context.translate(cw, ch / cw);
                    context.rotate(NitTools.GradToRad(degrees));
                    // draw the previows image, now rotated
                    context.drawImage(myImageData, 0, 0);
                    context.restore();
                }

                // clear the temporary image
                myImageData = null;

                resolve(canvas.toDataURL('image/jpeg'));
            };

            myImageData.src = canvas.toDataURL();
        });
    }

    _getIcon(source): Promise<string> {
        return new Promise<string>((resolve) => {
            let img = document.createElement('img');
            img.style.display = 'block';

            img.onload = () => {
                let cnv = document.createElement('canvas');
                cnv.width = 100;
                cnv.height = 100;

                let f: number;
                ////////////////////
                if (img.width > img.height || img.width === img.height) {
                    f = img.width / cnv.width;
                } else {
                    f = img.height / cnv.height;
                }

                let dw = img.width / f;
                let dh = img.height / f;
                let tx = cnv.width / 2 - dw / 2;
                let ty = cnv.height / 2 - dh / 2;
                if (tx < 0) tx = 0;
                if (ty < 0) ty = 0;
                let ctx = cnv.getContext('2d');
                ctx.drawImage(img, 0, 0, img.width, img.height, tx, ty, dw, dh);

                resolve(cnv.toDataURL('image/png'));
            };

            if (source.indexOf('data:image/') === -1) source = 'data:image/jpg;base64,' + source;
            img.src = source;
        });
    }

    /** Gets an 100x80 Thumbnail base64 string from the given image source "source" which is although a base64 string */
    getIcon(source: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            try {
                this._getIcon(source).then((result) => resolve(String(result)));
                /**********
                 let rotationValue = this.rotations[rotation];
                 if (rotationValue) {
                 let i = document.createElement("img");
                 i.onload = () => {
                 let c = document.createElement("canvas");
                 c.width = i.width;
                 c.height = i.height;
                 c.getContext("2d").drawImage(i, 0, 0);
                 this.newImageHeight = i.height;
                 this.newImageWidth = i.width;

                 this.rotate(c, rotationValue).then(result => {
                 source = result;
                 this._getIcon(source).then((result) => resolve(String(result)));
                 });
                 };

                 if (source.indexOf('data:image/') === -1) source = "data:image/jpg;base64," + source;
                 i.src = source;
                 } else {
                 this._getIcon(source).then((result) => resolve(String(result)));
                 } ***********/
            } catch (e) {
                console.warn(e.message);
                reject('Could not create Thumbnail of image: ' + e.message);
            }
        });
    }

    applyDrawingToThumb(thumb: any): Promise<string> {
        return new Promise<string>((resolve) => {
            let drawingObjects: IDrawingObject[] = [];
            ///// get drawing objects
            if (thumb && thumb.text && thumb.text.div) {
                let ele = document.createElement('div');
                ele.innerHTML = thumb.text.div;
                let innerDiv = <HTMLElement>ele.querySelector('#imageSourceScript');
                if (innerDiv) {
                    let data = JSON.parse(innerDiv.innerText);
                    drawingObjects = data.drawingObjects || [];
                    //      calibrationInfo = data.calibrationInfo || undefined;
                }
            }

            if (!drawingObjects || drawingObjects.length === 0) {
                resolve(null);
                return;
            }

            /////////////////////////
            let div = document.querySelector('.thumb-image-parent')[0];
            if (!div) {
                resolve(null);
                return;
            }

            let cw = div.clientWidth;
            let ch = div.clientHeight;

            let cnv: HTMLCanvasElement = document.createElement('canvas');
            let img: HTMLImageElement = document.createElement('img');
            img.onload = () => {
                cnv.width = img.width || img.naturalWidth;
                cnv.height = img.height || img.naturalHeight;
                woundDraw.zoom = 1 / Math.max(cnv.width / cw, cnv.height / ch);

                let ctx = cnv.getContext('2d');
                ctx.drawImage(img, 0, 0);

                drawingObjects.forEach((obj) => woundDraw.updateCanvas(ctx, obj));

                let imageEdited = cnv.toDataURL('image/jpeg', 100);

                resolve(imageEdited);
            };

            img.src = 'data:' + thumb.content.contentType + ';base64,' + thumb.content.data;
        });
    }

    /** opens a dialog window containing the original image */
    async showImage(thumb: IThumb) {
        delete window['__drawingMedia'];
        const that = this;
        RuntimeInfo.IsLoading = true;
        
        if (!thumb.imageUrl && NitTools.IsArray(thumb.resource?.identifier)) {
             const sourceIdentifier = thumb.resource.identifier.find(o=>String(o.system).endsWith("media-source-id") && o.value);
             if (sourceIdentifier) {
                thumb.imageUrl = `Media/${sourceIdentifier.value}`;
             }
        }
        if (!thumb.imageUrl) {
            console.warn(thumb);
            alert('No image-url found in thumbnail!');
        }

        let media = <any>await this.fhirService.get(thumb.imageUrl);
        this.currentMediaId = media.id;
        const data = await woundDraw.LoadDrawingObjects(media, this.fhirService);
        if (data.media) window['__drawingMedia'] = NitTools.Clone(data.media);

        let drawingObjects: IDrawingObject[] = data?.drawingObjects;

        this.dialogService
            .open({
                viewModel: woundImageDialog, model: {
                    imageSource: (<any>media.content).data,
                    title: translations.translate('information'),
                    media: <any>media,
                    thumbnail: <IThumb>thumb,
                    mediaId: this.currentMediaId,
                    drawingObjects: drawingObjects,
                    onOpenDraw: () => {
                        that.router.navigateToRoute('draw', {
                            id: that.currentMediaId, encounter: that.encounterId,
                        });
                    },
                    onDeleted: () => {
                        for (const thumbGroup of this.thumbnails) {
                            let existingId = thumbGroup.items.findIndex((o) => o.resource.id === (<IThumb>thumb).resource.id,);
                            if (existingId > -1) {
                                thumbGroup.items.splice(existingId, 1);
                            }
                        }

                        that.thumbnails = that.thumbnails.filter((o) => o.items?.length > 0);
                    },
                }, lock: true,
            })
            .then(() => {
                const img: HTMLImageElement = document.querySelector('.touch-image');
                try {
                    if (!img) {
                        // noinspection ExceptionCaughtLocallyJS
                        throw "Image Element not found!";
                    }

                    let optionShow: any = {show: true, size: 'large'};
                    let options = {
                        prev: false,
                        play: false,
                        next: false,
                        oneToOne: false,
                        flipHorizontal: optionShow,
                        flipVertical: optionShow,
                        reset: optionShow,
                        rotateLeft: optionShow,
                        rotateRight: optionShow,
                        zoomIn: optionShow,
                        zoomOut: optionShow,
                    };

                    const instance: any = Viewer || window["Viewer"];

                    if (!instance) { // noinspection ExceptionCaughtLocallyJS
                        throw "No instance of Viewer found.";
                    }

                    if (ConfigService.Debug) {
                        if (Viewer) console.log(`Viewer instance exists`);
                        if (window["Viewer"]) {
                            console.log(`window["Viewer"] exists`);
                        } else if (Viewer) { // add window["Viewer"] for debugging purposes
                            console.debug('adding window["Viewer"] for debugging purposes');
                            window["Viewer"] = Viewer;
                        }
                    }

                    that.viewer = new instance(img, {
                        inline: true,
                        fullscreen: false,
                        navbar: false,
                        transition: false,
                        title: false,
                        minZoomRatio: 0.5,
                        maxZoomRatio: 5,
                        toolbar: options,
                    });

                    that.viewer.view();
                } catch (e) {
                    if (img) {
                        img.style.display = 'block';
                    }

                    console.warn(e.message);
                } finally {
                    RuntimeInfo.IsLoading = false;
                }
            });
    }

    /** generates a thumbnail- and image-Media Resource for the currently selected file and uploads them to Fhir */
    async processImageFile() {
        if (!this.selectedWound) return;

        try {
            let files: FileList = this.fileUpload.files;
            let numFiles = files ? files.length : 0;
            if (!this.fileUpload.value || numFiles === 0) return;

            let label = this.fileUpload.value
                .replace(/\\/g, '/')
                .replace(/.*\//, '')
                .replace('.jpg', '')
                .replace('.JPG', '');

            let authored = new Date();
            try {
                if (this.fileUpload.files[0]) {
                    if (this.fileUpload.files[0]['lastModifiedDate']) {
                        authored = <Date>this.fileUpload.files[0]['lastModifiedDate'];
                    } else if (this.fileUpload.files[0]['lastModified']) {
                        authored = new Date(this.fileUpload.files[0]['lastModified']);
                    }
                }
            } catch (err) {
                console.warn(err.message);
                authored = new Date();
            }

            this.dialogService
                .open({
                    viewModel: WoundDateEdit, model: {
                        date: authored,
                    },
                })
                .whenClosed(async (result) => {
                    if (result.wasCancelled) {
                        this.fileUpload.value = '';
                        return;
                    } else {
                        try {
                            RuntimeInfo.IsLoading = true;
                            let base64String: string = await this.getBase64(files[0]);
                            base64String = base64String.substr(base64String.indexOf(',') + 1);
                            let angle = await this.orientation(files[0]);
                            await this.uploadFile(label, new Date(result.output.date), base64String, angle, files[0].type,);
                        } finally {
                            RuntimeInfo.IsLoading = false;
                            this.fileUpload.value = '';
                        }
                    }
                });
        } catch (error) {
            console.warn(error.message);
            this.dialogMessages.prompt('Error when getting File Infos', 'Error when Uploading', true,);
        }
    }

    /* Uploads an image to the server, any */
    async uploadFile(label: string, authored: Date, base64String: string, angle: number, type: string,) {
        try {
            let thumbNailBase64 = await this.getIcon(base64String);
            thumbNailBase64 = thumbNailBase64.substr(thumbNailBase64.indexOf(',') + 1);

            this.currentBodyPart = this.wound.bodyPart;
            let bodyCode = this.isWoundGroupSelected ? this.wound.item.observations
                .filter((o) => o.bodySite && o.bodySite.coding && o.bodySite.coding[0] && o.bodySite.coding[0].display,)
                .map((o) => o.bodySite.coding[0].display)
                .join(',') : this.wound.item.bodySite.coding[0].display;

            let woundText = this.isWoundGroupSelected ? this.wound.hintText : this.wound.longText;

            if (woundText.length >= 195) {
                woundText = woundText.substr(0, 193) + '..';
            }

            let woundBodySite: any = {
                text: woundText, coding: [{
                    code: bodyCode, display: woundText, system: 'https://www.xototechnology.com/body-part',
                },],
            };

            if (this.isWoundGroupSelected) {
                woundBodySite.coding = [];
                let arr = this.wound.item.observations
                    .filter((o) => o.bodySite && o.bodySite.coding && o.bodySite.coding[0],)
                    .map((o) => {
                        try {
                            return o.bodySite.coding[0].display;
                        } catch (error) {
                            console.warn(error.message || error);
                            return undefined;
                        }
                    });

                arr = arr.filter((o) => typeof o !== 'undefined');

                arr.forEach((s) => {
                    woundBodySite.coding.push({
                        code: s, display: this.i18n.tr(s), system: 'https://www.xototechnology.com/body-part',
                    });
                });
            }

            let observationIdentifier = {
                system: `${NitTools.ExcludeTrailingSlash(environment.systemHeader)}/${this.isWoundGroupSelected ? 'wound-group-id' : 'observation-id'}`, value: this.selectedWoundId,
            };

            let printIdentifier = {
                system: this.printId, use: 'official', value: 'no',
            };
            let operator = {
                reference: 'Practitioner/' + UserService.Practitioner.id, display: UserService.UserLastName + (UserService.UserFirstName ? ', ' + UserService.UserFirstName : ''),
            };

            let encounterName = 'context';
            let subtypeName = 'subtype';
            let typeName = 'type';

            if (FhirService.FhirVersion > 3) {
                encounterName = 'encounter';
                subtypeName = 'modality';
            }

            let image: any = {
                resourceType: fhirEnums.ResourceType.media, id: NitTools.Uid(), operator: operator, text: {
                    status: 'generated', div: `<div xmlns="http://www.w3.org/1999/xhtml">${label}</div>`,
                }, identifier: [printIdentifier, observationIdentifier], subject: {
                    reference: fhirEnums.ResourceType.patient + '/' + this.patient.id,
                }, bodySite: woundBodySite, device: {
                    display: RuntimeInfo.IsMobile ? 'IPad' : 'Computer',
                }, frames: 1, content: {
                    id: NitTools.Uid(), contentType: type, data: base64String, creation: authored.toJSON(), title: label,
                }, height: this.newImageHeight, width: this.newImageWidth,
            };

            if (FhirService.FhirVersion >= 4) {
                // R4
                image[typeName] = {
                    coding: [{
                        system: 'http://terminology.hl7.org/CodeSystem/media-type', code: 'image', display: 'Image',
                    },],
                };
                image.type = "image"
            } else {
                // R3
                image.type = "photo";
                image[typeName] = 'photo';
            }

            image[encounterName] = {
                reference: `Encounter/${this.patient.encounterId}`,
            };
            image[subtypeName] = {
                coding: [{
                    system: 'http://snomed.info/sct', code: '37312005', // External ocular photography for medical evaluation and documentation
                },],
            };

            let newMedia = await this.fhirService.create(image);

            let thumbnailContent: any = {
                contentType: type, data: thumbNailBase64, creation: authored.toJSON(), title: label, url: fhirEnums.ResourceType.media + '/' + newMedia.id,
            };

            let icon: any = {
                resourceType: fhirEnums.ResourceType.media, id: NitTools.Uid(), text: {
                    status: 'generated', div: `<div xmlns="http://www.w3.org/1999/xhtml">${label}</div>`,
                }, operator: operator, bodySite: woundBodySite, type: FhirService.FhirVersion >= 4 ? 'image' : 'photo', identifier: [{
                    system: RuntimeInfo.SystemHeader + '/media-source-id', value: newMedia.id,
                }, printIdentifier, observationIdentifier,], subject: {
                    reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`,
                }, device: {
                    display: RuntimeInfo.IsMobile ? 'IPad' : 'Computer',
                }, frames: 1, content: thumbnailContent, width: 100, height: 80,
            };

            icon[encounterName] = {
                reference: `Encounter/${this.patient.encounterId}`,
            };
            icon[subtypeName] = {
                coding: [{
                    system: RuntimeInfo.SystemHeader + '/image-thumbnail', code: 'thumbnail', // External ocular photography for medical evaluation and documentation
                },],
            };
            icon[typeName] = NitTools.Clone(image[typeName]);

            await this.fhirService.create(icon);
            await this.loadThumbNails();
            this.fileUpload.value = '';
        } catch (e) {
            console.warn(e.message);
            alert(e.message);
        }
    }

    async getResponsesForSelectedWoundId(): Promise<any[]> {
        RuntimeInfo.IsLoading = true;
        // temporary storage
        let result: any[] = [];
        // let o = this.observations.find(o=>o.id === this.selectedWoundId);

        let q = this.selectedWound && this.selectedWound.category ? this.questionnaireService.getQuestionnaireByNameDirect(`CAREITWOUND_${this.selectedWound.category[0].text}`) : undefined;

        if (!q) {
            let list = this.woundGroupLists.find((o) => o.listId === this.selectedWoundId);
            q = list ? this.questionnaireService.getQuestionnaireByNameDirect(`CAREITWOUND_${list.type}`) : undefined;
        }

        if (q) this.questionnaire = q;

        if (!this.questionnaire) console.warn('No Questionnaire assigned in wound-main.ts::ln1284');

        let all = this.questionnaire ? this.patient.questionnaireResponses.filter((o) => (typeof o.questionnaire === 'string' && String(o.questionnaire).endsWith('/' + this.questionnaire.name,)) || (o.questionnaire?.reference && o.questionnaire.reference.indexOf('Questionnaire/' + this.questionnaire.id,) > -1),) : [];

        if (this.questionnaireResponseList && this.questionnaireResponseList.entry) {
            // get all entries from the list which flag ..
            for (const listEntry of this.questionnaireResponseList.entry.filter(// .. exist ...
                (o) => o.flag && o.flag.coding && // ... are for wounds ...
                    o.flag.coding[0] && o.flag.coding[0].system && o.flag.coding[0].code && (o.flag.coding[0].system.endsWith('/wound-id') || o.flag.coding[0].system.endsWith('/group-id')) && // ... and point to the selected wound (observation) Id
                    o.flag.coding[0].code === this.selectedWoundId,)) {
                // and from this list (which contains the response-ids for the selected wound) ...
                let responseId = listEntry.item.reference;
                if (responseId.indexOf('/') > -1) {
                    responseId = responseId.split('/')[1];
                }

                // ... get the Response from the current patient.questionnaireResponses pre-filtered in "all"
                let questionnaireResponse = all.find((o) => o.id === responseId);
                if (!questionnaireResponse) questionnaireResponse = this.patient.questionnaireResponses.find((o) => o.id === responseId,);
                if (!questionnaireResponse) {
                    // ok, we found an item in the list which is not loaded yet, so load it from server again
                    const tmpBundle = <any[]>(await this.fhirService.fetch(`QuestionnaireResponse?_id=${responseId}&status=in-progress,completed,amended`,));
                    if (tmpBundle.length >= 1) {
                        questionnaireResponse = tmpBundle[0];
                        this.patientService.addQuestionnaireResponse(this.patient, questionnaireResponse, true,);
                        all.push(questionnaireResponse);
                    }
                }

                if (questionnaireResponse && ['in-progress', 'completed', 'amended'].indexOf(questionnaireResponse.status,) > -1) {
                    result.push(questionnaireResponse);
                }
            }

            // when this is a woundGroup, iterate through the list-resource and gather the responses for all items in the list too.
            // This should be the outdatet ones, but we have to show them:
            if (this.isWoundGroupSelected) {
                this.selectedWound.observations.forEach((observation: any) => {
                    this.questionnaireResponseList.entry
                        .filter(// .. exist ...
                            (o) => o.flag && o.flag.coding && // .. are for wounds ...
                                o.flag.coding[0].system === `${RuntimeInfo.SystemHeader}/wound-id` && // .. and point to the selected wound (observation) Id
                                o.flag.coding[0].code === observation.id,)
                        .forEach(async (listEntry) => {
                            if (listEntry.item && listEntry.item.reference) {
                                const qrId = listEntry.item.reference.split('/')[1];
                                let questionnaireResponse = this.patient.questionnaireResponses.find((o) => o.id === qrId,);

                                if (!questionnaireResponse) questionnaireResponse = this.patient.questionnaireResponses.find((o) => o.id === qrId,);
                                if (!questionnaireResponse) {
                                    // ok, we found an item in the list which is not loaded yet, so load it from server again
                                    const tmpBundle = <any[]>(await this.fhirService.fetch(`QuestionnaireResponse?_id=${qrId}&status=in-progress,completed,amended`,));
                                    if (tmpBundle.length >= 1) {
                                        questionnaireResponse = tmpBundle[0];
                                        this.patientService.addQuestionnaireResponse(this.patient, questionnaireResponse, true,);
                                        all.push(questionnaireResponse);
                                    }
                                }

                                if (questionnaireResponse && ['in-progress', 'completed', 'amended'].indexOf(questionnaireResponse.status,) > -1) {
                                    result.push(questionnaireResponse);
                                }
                            }
                        });
                },);
            }

            result.sort((a: any, b: any) => {
                let d1 = new Date(a.authored);
                let d2 = new Date(b.authored);
                return d1.valueOf() - d2.valueOf();
            },);

            // move the proxy to be the current responses
            this.currentWoundResponses = result;
            this.hasResponses = result.length > 0;

            this.taskQueue.queueTask(() => {
                for (const r of this.currentWoundResponses) {
                    this.updateHeaderButtonClassName(r);
                }
            });
        } else {
            this.hasResponses = false;
            this.currentWoundResponses = [];
            result = [];
        }

        RuntimeInfo.IsLoading = false;

        return result;
    }

    editResponseDate(qResponse: any) {
        if (!qResponse || qResponse.id !== this.response.id || this.isReadonly) return;

        let dateChangeable: boolean;
        let tooOld: boolean;
        let maxAge = this.setting ? this.setting.expiration && this.setting.expiration.default ? this.setting.expiration.default : 24 : 24;
        if (this.setting && qResponse) {
            let age = moment(new Date()).diff(qResponse.authored, 'hours');
            if (age >= maxAge) {
                tooOld = qResponse.status !== 'in-progress';
            } else {
                tooOld = false;
            }
        } else {
            tooOld = false;
        }

        dateChangeable = !tooOld;

        if (this.patient.isOffline || !qResponse || !dateChangeable) return;

        this.dialogService
            .open({
                viewModel: WoundDateEdit, model: {
                    date: moment(qResponse.authored).toDate(), title: this.i18n.tr('response_recorded_at'),
                },
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    let qr = this.patient.questionnaireResponses.find((o) => o.id === qResponse.id,);
                    let dateString = moment(response.output.date).toJSON();
                    qr.authored = dateString;
                    qResponse.authored = dateString;

                    await this.fhirService.update(qr);

                    await this.getResponsesForSelectedWoundId();
                    await this.selectResponse(qResponse);
                }
            })
            .catch((error) => console.warn(error));
    }

    editImageDate(image) {
        if (!image) return;

        let thumbs = [];
        this.thumbnails
            .map((o) => o.items)
            .forEach((g) => {
                g.forEach((i) => thumbs.push(i.resource));
            });

        this.dialogService
            .open({
                viewModel: WoundDateEdit, model: {
                    date: moment((<any>image.resource.content).creation,).toDate(), title: this.i18n.tr('picture_taken_at'),
                },
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    let d = new Date(response.output.date);
                    let img: any = thumbs.find((o) => o.id === image.resource.id);
                    img.content.creation = d.toJSON();

                    this.dataHandler
                        .createThumbGrouping(thumbs)
                        // this.createThumbGrouping(thumbs)
                        .then(async (groupedThumbs) => {
                            this.thumbnails = groupedThumbs;
                            let photo = <any>(await this.fhirService.get(image.imageUrl));
                            photo.content.creation = img.content.creation;

                            await this.fhirService.bundle([img, photo], HTTPVerb.put, BundleType.transaction,);
                        });
                }
            })
            .catch((error) => console.warn(error));
    }

    displayResponseInfo() {
        super.showResponseInfo(this.response);
        // this.showResponseInfo(this.response);
    }

    afterResponseStopped(response: any) {
        super.afterResponseStopped(response);

        this.getResponsesForSelectedWoundId().catch((error) => console.warn(error));
    }

    async selectResponse(response: any) {
        if (this.hasChanges && this.saveButton && this.saveButton.buttonState === 'save' && this.showSaveButton) {
            this.dialogMessages
                .dialog(this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true,)
                .whenClosed((result) => {
                    if (!result.wasCancelled) {
                        this.response.item = NitTools.Clone(this.responseBackup);
                        this.hasChanges = false;
                        this.selectResponse(response);
                    }
                });

            return;
        }

        this.isReadonly = true;

        this.editable = false;
        this.previousResponse = undefined;
        this.showEditButtons = false;
        this.responseBackup = undefined;
        this.response = undefined;

        if (response) {
            if (this.questionnaire && this.response?.questionnaire) {
                let q = this.questionnaireService.getQuestionnaireDirect(response?.questionnaire,);
                if (q && q?.id == this.questionnaire?.id) {
                    this.questionnaire = q;
                    this.currentQuestionnaireId = this.questionnaire.id;
                }
            }

            this.responseBackup = NitTools.Clone(response ? response.item : undefined);

            if (typeof this.questionnaire !== 'undefined') {
                this.report = undefined;
                if (this.selectedWound?.category?.[0]) {
                    let setting = ConfigService.GetFormSettings('wounds');
                    if (setting) {
                        let others = setting.report['other'];
                        if (others) {
                            this.report = others[`careitwound_${this.selectedWound.category[0].text}`] || '';
                        }
                    }
                }
            }

            let idx = this.currentWoundResponses.indexOf(response);
            if (idx > 0) {
                this.previousResponse = this.currentWoundResponses[idx - 1];
            }

            if (response && response.status !== 'in-progress') {
                let config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds,);
                if (config) {
                    let age = moment(new Date()).diff(response.authored, 'hours');
                    this.tooOld = age >= config.expiration.default;
                }
            } else {
                this.tooOld = false;
            }

            this.response = response;

            if ((!this.tooOld || response.status === 'in-progress') && response.status !== 'completed' && response.status !== 'amended') this.reEnableEditing();

            idx = this.currentWoundResponses?.indexOf(this.response);
            if (idx > -1 && this.woundTabParent?.parentElement?.clientWidth) {
                try {
                    let x = 170 * idx;
                    let w = this.woundTabParent.parentElement.clientWidth / 2;
                    x -= w / 2 + 170 / 2;
                    $(this.woundTabParent).animate({scrollLeft: x});
                } catch (e) {
                    console.warn(e);
                }
            }
        }

        this.updateHeaderButtonClassName(this.response);
        this.taskQueue.queueTask(() => {
            this.hasChanges = false;
        });
    }

    saveResponse(status) {
        if (!this.response) return;
        this.isLoading = true;

        if (status === 'completed') {
            if (this.response.status === 'completed') {
                status = fhirEnums.QuestionnaireResponseStatus.amended;
                this.editable = false;
            }
        }

        this.response.status = status;
        this.fhirService
            .update(this.response)
            .then(() => {
                this.isReadonly = true; // this.response.status === fhirEnums.QuestionnaireResponseStatus.amended || this.response.status === fhirEnums.QuestionnaireResponseStatus.completed;
            })
            .catch((error) => {
                this.dialogMessages.showHttpError(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async observationsChanged() {
        await this.selectWound(this._selectedWound);
    }

    activate(params) {
        document.body.classList.add('no-toolbar-window');
        this.encounterId = params['id'];
        this.dataHandler = new WoundDataHandler(this.i18n);
    }

    /** just scrolls the form view to top */
    scroll0() {
        if (!this.contentDiv) return;
        (<HTMLDivElement>this.contentDiv).scrollTop = 0;
    }

    /** load the observations  by calling loadObservations() from the server and filter only active groups */
    async loadWounds() {
        this.groups = await this.dataHandler.loadWounds(this.patient?.encounterId);
        this.woundGroupLists = this.dataHandler.woundGroupLists;
        this.observations = this.dataHandler.observations;
        this.processing = false;
    }

    getObservationById(id: string): any {
        if (typeof id === 'object') return id;

        if (id.indexOf('/') > -1) id = id.split('/')[1];
        let obs = this.observations.find((o) => o.id === id);

        return obs || id;
    }

    bundleObservationsToGroups() {
        //if (this.observationsBundled) return;
        this.observationsBundled = true;
        this.groups
            .filter((g) => g.item && g.item.type)
            .forEach((group) => {
                let woundType = group.item.type;
                let listGroupsForGroup = this.woundGroupLists.filter((o) => o.type === woundType,);
                listGroupsForGroup.forEach((listGroup) => {
                    for (let i = 0; i < listGroup.observations.length; i++) {
                        let observationId = listGroup.observations[i];
                        let obs = this.observations.find((o) => o.id === observationId);

                        // move the observation from observations into the group item
                        if (obs) {
                            let idx = this.observations.indexOf(obs);
                            listGroup.observations[i] = NitTools.Clone(obs);
                            // yyyyyy this.observations.splice(idx, 1);

                            // observation exists and has been moved to the listGroup, so now remove the item from the group
                            let existing = group.children.find((o) => o.item && o.item.id === observationId,);
                            if (existing) {
                                idx = group.children.indexOf(existing);
                                group.children.splice(idx, 1);
                            }
                        }
                    }

                    /* just to be sure solve the references from the listGroup.observations once more */
                    if (!listGroup.observations) listGroup.observations = [];

                    let s = '';
                    for (let i = 0; i < listGroup.observations.length; i++) {
                        listGroup.observations[i] = this.getObservationById(listGroup.observations[i],);

                        try {
                            if (typeof listGroup.observations[i] === 'object' && listGroup.observations[i].bodySite && listGroup.observations[i].bodySite.coding) s += listGroup.observations[i].bodySite.coding[0].display;
                        } catch (e) {
                            console.warn(e.message);
                        }
                    }

                    let arr = listGroup.observations.map((obs: any) => {
                        return obs && obs.bodySite ? this.i18n.tr(obs.bodySite.coding[0].code || obs.bodySite.coding[0].display,) : '';
                    });

                    // noinspection RegExpRepeatedSpace
                    listGroup.title = listGroup.title || arr
                        .join(' + ')
                        .replace(/ \+  \+ /g, '')
                        .trim();
                    let hintText = listGroup.title;

                    if (listGroup.title.length > 42) {
                        listGroup.title = listGroup.title.substr(0, 40) + '..';
                    }

                    // now add a new item to the group to display the grouped list.
                    let newGroupItem: WoundGroupItem = {
                        bodyPart: 'area',
                        image: 'body-2.0',
                        imagePart: '-',
                        listId: listGroup.listId,
                        item: listGroup,
                        left: -1,
                        longText: listGroup.title || 'Area',
                        hintText: hintText,
                        shortText: 'area',
                        top: -1,
                        valid: true,
                    };

                    if (typeof group.children.find((o) => o.listId === listGroup.listId) === 'undefined') {
                        group.children.push(newGroupItem);
                    }
                });
            });
    }

    async addNewResponse(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (this.patient.isOffline) return reject('Patient is offline');

            if (!this.selectedWound || !this.questionnaire) {
                let msg = this.i18n.tr('no_wound_selected');
                if (!this.questionnaire)
                    msg = this.i18n.tr('no_questionnaire');
                else if (!this.selectedWound)
                    msg = this.i18n.tr('no_wound');

                this.dialogMessages.prompt(msg, this.i18n.tr('information'), false,);

                return reject(msg);
            }

            if (this.hasChanges) {
                this.dialogMessages
                    .dialog(this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true,)
                    .whenClosed((result) => {
                        if (!result.wasCancelled) {
                            if (this.response) this.response.item = NitTools.Clone(this.responseBackup);
                            this.hasChanges = false;
                            this.addNewResponse();
                        }
                    })
                    .catch((r) => console.warn(r));

                return reject('Change notifier');
            }

            if (!this.questionnaireResponseList) {
                // create a new list to store the QResponses for the current wound
                let tmp: any = {
                    emptyReason: {
                        coding: [{code: 'unavailable', display: 'Unavailable'}],
                    }, resourceType: 'List', encounter: {
                        reference: `${fhirEnums.ResourceType.encounter}/${this.encounterId}`,
                    }, subject: {
                        reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`,
                    }, mode: fhirEnums.ListMode.working, entry: [], source: {
                        reference: `${fhirEnums.ResourceType.patient}/${this.patient.id}`,
                    }, status: fhirEnums.ListStatus.current, identifier: [{
                        system: NitTools.ExcludeTrailingSlash(environment.systemHeader) + '/wound-response-list',
                    }]
                };

                this.questionnaireResponseList = <any>(await this.fhirService.create(tmp));
            }

            let q; // holds the temporary Questionnaire
            // get the Questionnaire from CodeSystem - in best case. Yes, this step-by-step IFing is intended to be able to notify about any missing part in the CS
            if (this.is3dBody) {
                const cs = await this.dataHandler.ensure3dCodeSystem();
                if (cs) {
                    const category = this.isWoundGroupSelected ? this.selectedWound.type : this.selectedWound.category?.[0]?.text;
                    if (category) {
                        const csConcept = cs.concept.find(o => o.code?.toUpperCase() === category.toUpperCase());
                        if (csConcept) {
                            if (NitTools.IsArray(csConcept.property)) {
                                const questionnaireProperty = csConcept.property.find(o => o.code === "questionnaire");
                                if (questionnaireProperty) {
                                    if (questionnaireProperty.valueString) {
                                        q = QuestionnaireService.GetQuestionnaireDirect(questionnaireProperty.valueString);
                                        console.warn(`found Questionnaire from 3d: "${q?.name}"`);
                                    } else {
                                        console.warn(`No valueString for Questionnaire Property in`, questionnaireProperty);
                                    }
                                } else {
                                    console.warn(`No ConceptProperty found with code="questionnaire" in`, csConcept.property);
                                }
                            } else {
                                console.warn(`No "property" Property found in CodeSystem.Concept`, csConcept);
                            }
                        } else {
                            console.warn(`No Concept with Code="${category}" found in Body-Markers CodeSystem`);
                        }
                    } else {
                        console.warn('No category found in ', this.selectWound);
                    }
                }
            }

            let search;
            if (!q) {
                if (this.wound?.questionnaireUrl) {
                    search = this.wound.questionnaireUrl;
                    q = QuestionnaireService.GetQuestionnaireDirect(search);
                } else {
                    search = `CareItWound_${this.isWoundGroupSelected ? this.selectedWound.type : this.selectedWound.category?.[0]?.text}`;
                    q = QuestionnaireService.GetQuestionnaireByNameDirect(search);
                }
            }

            if (!q) {
                console.warn(`NO QUESTIONNAIRE FOUND that matches the search "${search}"!`);
                return;
            }

            let response: any = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, q.id, fhirEnums.QuestionnaireResponseStatus.inProgress,);
            let existingResponses = await this.getResponsesForSelectedWoundId();
            let latestResponse: any;
            if (existingResponses && existingResponses.length > 0) {
                latestResponse = existingResponses[existingResponses.length - 1];
            }

            // ensure a valid response
            Fhir.Questionnaire.EnsureStructuredResponse(q, response);

            // just to be sure ..
            if (!response.context && this.patient && this.patient.encounterId) {
                response.context = {
                    reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`,
                };
            }

            //#region set default values 1st from questionnaire, then from previous response:
            let itemIds = Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire,);
            itemIds.forEach((id) => {
                let questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, id,);

                if (questionnaireItem && questionnaireItem.type !== 'group') {
                    let rItem = QuestionnaireResponse.GetResponseItemByLinkId(response, id,);
                    // set value from initial-coding ...
                    if (rItem && questionnaireItem.option && questionnaireItem.initialCoding) {
                        if (rItem && (!rItem.answer || rItem.answer.length === 0)) {
                            let code = questionnaireItem.initialCoding.code;
                            if (code.indexOf('=') !== 0) {
                                let display = questionnaireItem.initialCoding.display;
                                if (questionnaireItem.option) {
                                    let displayOption = questionnaireItem.option.find((o) => o.valueCoding && o.valueCoding.code === code,);
                                    if (displayOption) {
                                        display = displayOption.valueCoding.display;
                                    }
                                }

                                rItem.answer = [{
                                    valueCoding: {
                                        code: code, display: display,
                                    },
                                },];
                            }
                        }
                    }

                    // .. then overwrite with the previous value if existent
                    let previousItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(latestResponse, id, false,);
                    if (rItem && previousItem && previousItem.answer && previousItem.answer.length > 0) {
                        rItem.answer = NitTools.Clone(previousItem.answer);
                    }
                }
            });
            //#endregion

            this.isNewResponse = true;

            this.processMappings(response, true, false, true);

            if (this.isWoundGroupSelected) {
                if (!response.extension) response.extension = [];
                response.extension.push({
                    url: 'http://nursit-institute.com/StructureDefinition/wound-id', valueId: this.selectedWound.listId || this.selectedWound.id,
                });

                response.extension.push({
                    url: 'http://nursit-institute.com/StructureDefinition/is-wound-group-item', valueId: this.isWoundGroupSelected ? 'yes' : 'no',
                });
            }

            if (!response.extension) response.extension = [];
            let woundIdIdentifier = response.extension.find((o) => o.url.endsWith('/wound-id'),);
            if (!woundIdIdentifier) {
                woundIdIdentifier = {
                    url: 'http://nursit-institute.com/StructureDefinition/wound-id',
                };

                response.extension.push(woundIdIdentifier);
            }
            woundIdIdentifier.valueId = this.selectedWound.listId || this.selectedWound.id;

            this.debug('After mapping Values:', response);

            response = <any>(await this.fhirService.create(response));

            PatientService.AddQuestionnaireResponse(this.patient, response);
            this.currentWoundResponses.push(response);

            if (!this.questionnaireResponseList.entry) {
                this.questionnaireResponseList.entry = [];
            }

            this.questionnaireResponseList.entry.push({
                flag: {
                    coding: [{
                        system: `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader,)}/${this.isWoundGroupSelected ? 'group-id' : 'wound-id'}`,
                        code: this.selectedWoundId,
                        display: this.selectedWound && this.selectedWound.category ? this.selectedWound.category[0].text : (<any>this.selectedWound).type ? (<any>this.selectedWound).type : this.selectedWoundId,
                    },],
                }, item: {
                    reference: `QuestionnaireResponse/${response.id}`,
                },
            });

            await this.fhirService.update(this.questionnaireResponseList);

            await this.getResponsesForSelectedWoundId();

            this.response = undefined;
            this.taskQueue.queueTask(() => {
                if (this.woundTabParent) this.woundTabParent.scrollLeft = this.woundTabParent.scrollWidth;
                this.selectResponse(response);

                this.updateAllHeaderButtonClassNames();
            });

            return resolve(response);
        });
    }

    displayStopDialog() {
        this.showStopDialog(this.response);
    }

    updateDebugInfo() {
        return; /*
        let err = ''
        if (!this.selectedWound) {
            err += 'Keine Wunde gewählt'
        }
        if (!this.response) {
            err += 'Kein Response gewählt'
        }

        if ((this.selectedWound && !this.selectedWound.bodySite) || !this.selectedWound.bodySite.coding || !this.selectedWound.bodySite.coding[0] || !this.selectedWound.bodySite.coding[0].display) {
            err += 'Gewählte Wunde ungültig!'
        }

        if (err !== '') {
            this.extraDebugResponseInfo = `<div>Verlaufs-DataSource: ${err}`
            return
        }

        let bodyPart = this.wound.item.bodySite.coding[0].display // this.selectedWound.bodySite.coding[0].display; // .split('_')[1];
        this.currentBodyPart = bodyPart
        let summaryUrl = `${NitTools.IncludeTrailingSlash(ReportService.ReportServer,)}api/Wounds/${this.encounterId}/${this.selectedWoundId}/${bodyPart}`
        this.extraDebugResponseInfo = `<div>Verlaufs-DataSource: <a target="_blank" href="${summaryUrl}">${summaryUrl}</a>` */
    }

    deactivate() {
        super.deactivate();
        this.selectedWoundId = undefined;
        this._selectedId = undefined;
        this._selectedWound = undefined;
        this.__selectedId = undefined;
    }

    async getListResource(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let result: any = undefined;
                let tmp: any[] = await this.fhirService.fetch(`List?encounter=${this.encounterId}`, true,);
                if (tmp.length > 0) {
                    let tmpResult = tmp.filter((o) => o.entry);
                    for (const list of tmpResult) {
                        if (list.entry.length === 0) continue;

                        const isWoundList = list.entry.find((o) => o.flag?.coding && o.flag.coding.find((coding) => coding.system.endsWith('wound-id'),),);
                        if (isWoundList) {
                            result = list;
                            break;
                        }
                    }
                }

                resolve(result);
            } catch (e) {
                reject(e);
            }
        });
    }

    async replaceSvgChildren(svg) {
        if (!this.imageCache) this.imageCache = {};

        // split the svg-string into own lines at each closing > Tag
        let txt = svg
            .replace(/ au-target-id="[0-9]*"/, '')
            .replace(/ ref="[0-9a-zA-Z]*"/g, '')
            .replace(/>/g, '>\n')
            .replace(/\n\n/g, '\n');
        // make a string-array of the source
        let arr = txt.split('\n');

        for (let i = 0; i < arr.length; i++) {
            // remove inkscape, dc and sodipodi declarations from the svg elements
            let s = arr[i].trim().substr(1, arr[i].length - 2); // remove leading and training < >
            if (i === 0) {
                // s.indexOf("svg ") === 0) {
                let s2 = arr[i].trim();
                if (!/viewBox=/.test(s2)) {
                    let w = /width="[0-9.,]*"/.exec(s2)[0];
                    let h = /height="[0-9.,]*"/.exec(s2)[0];
                    if (w && h) {
                        let wPx = parseFloat(w.split('"')[1]);
                        let hPx = parseFloat(h.split('"')[1]);
                        wPx = wPx * 2 - 200;
                        hPx = hPx * 2 - 170;

                        if (w && h) {
                            s2 = s2.replace('>', ` viewBox="0 0 ${wPx} ${hPx}">`);
                            s2 = s2
                                .replace(/ width="[0-9,.]*"/, '')
                                .replace(/ height="[0-9,.]*"/, '');
                        }
                    }
                }

                arr[i] = s2;
            } else if (s.indexOf('image ') === 0) {
                // move referenced svg-images directly into the resulting svg
                let props = s.split(' ');
                for (let p = 0; p < props.length; p++) {
                    if (props[p].indexOf('=') > -1) {
                        let key = props[p].split('=')[0];
                        let value = props[p].split('=')[1].replace(/"/g, '').trim();
                        if (/href/gi.test(key)) {
                            let rep: any = undefined;
                            if (this.imageCache[value]) {
                                rep = {
                                    response: this.imageCache[value],
                                };
                            } else {
                                rep = await new HttpClient().get(value);
                                this.imageCache[value] = rep.response;
                            }

                            let d = document.createElement('div');
                            d.innerHTML = rep.response;
                            arr[i] = $(d)[0]
                                .innerHTML /*find("svg")[0].innerHTML*/
                                .replace(/\r\n/g, '')
                                .replace(/\n/g, '');
                        }
                    }
                }
            } else if (arr[i].indexOf('</image>') === 0) {
                arr[i] = '';
            }

            /*arr[i] = arr[i].replace('data-type="', 'sodipodi:data-type="')
                .replace('data-index="', 'sodipodi:data-index="')
                .replace('data-name="', 'sodipodi:data-name="') */
        }

        return arr.join('\n');
    }

    createOrUpdateMedia(imageSource) {
        let attachment: any = {
            contentType: 'image/svg+xml', data: btoa(imageSource),
        };

        let media: any;

        this.fhirService
            .get(`Media?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${this.encounterId}&identifier=body-image`,)
            .then(async (result) => {
                let exists = false;
                if ((<any>result).total === 0) {
                    media = {
                        id: NitTools.Uid(), 
                        resourceType: 'Media', 
                        identifier: [{
                            system: 'http://nursit-institute.com/fhir/StructureDefinition/BodyImage', value: this.encounterId,
                        }], 
                        subject: {
                            reference: `Patient/${this.patient.id}`
                        }, 
                        content: attachment,
                    };

                    let encounterName = 'context';
                    let subtypeName = 'subtype';
                    if (FhirService.FhirVersion >= 4) {
                        encounterName = 'encounter';
                        subtypeName = 'modality';
                        media['status'] = 'completed';
                        media.type = 'image';
                    } else {
                        media.type = 'photo';
                    }

                    media[encounterName] = {
                        reference: `Encounter/${this.patient.encounterId}`,
                    };
                    media[subtypeName] = {
                        coding: [{
                            system: 'http://hl7.org/fhir/media-subtype', // see: https://hl7.org/fhir/STU3/codesystem-digital-media-subtype.html
                            code: 'diagram',
                        },],
                    };
                } else {
                    exists = true;
                    media = <any>(<any>result).entry[0].resource;
                }

                media.content = attachment;
                if (exists) {
                    this.debug('Updating existing BodyMap image');
                    media = <any>await this.fhirService.update(media);
                } else {
                    this.debug('Creating new BodyMap image');
                    media = <any>await this.fhirService.create(media);
                }
            });
    }

    async storeBodyMedia(svg) {
        svg = await this.replaceSvgChildren(svg);
        this.createOrUpdateMedia(svg);
    }

    /** strips the svg image from the Grafixx-Application to be used in Reports */
    getWoundsSvg() {
        try {
            this.dialogService
                .open({
                    viewModel: ModalBodyMap, model: {
                        patient: this.patient, exportMap: true,
                    },
                })
                .whenClosed((result) => {
                    if (!result.wasCancelled && result.output && result.output.svg) {
                        /*
                    result.output.svg.setAttributeNS(null, 'viewBox', '0 0 ' + result.output.width.baseVal.valueAsString + ' ' + result.output.height.baseVal.valueAsString);
                    result.output.svg.removeAttribute('width');
                    result.output.svg.removeAttribute('height');
                    */
                        let mediaSource = (<HTMLOrSVGImageElement>result.output.svg).outerHTML;
                        this.storeBodyMedia(mediaSource).catch((error) => {
                            console.warn(JSON.stringify(error));
                        });
                    }
                })
                .catch((error) => {
                    console.warn(JSON.stringify(error));
                });
        } catch (ex) {
            console.warn('in getWoundsSvg:', ex.message || JSON.stringify(ex));
        }
    }

    /** when the user clicked on "show all" */
    showAllWounds() {
        if (this.patient?.isOffline === true) return;
        try {
            this.dialogService
                .open({
                    viewModel: this.is3dBody ? Modal3dBody : ModalBodyMap, model: {
                        patient: this.patient,
                    }, centerHorizontalOnly: this.is3dBody,
                })
                .whenClosed(async (result) => {
                    if (!result.wasCancelled || this.is3dBody) {
                        try {
                            this.processing = true;
                            if (this.is3dBody) {
                                await this.loadWounds();
                                this.processing = false;
                                return;
                            }

                            this.observations = await this.dataHandler.loadObservations(this.patient.encounterId, true,);
                            // await this.loadObservations(true);
                            // could be optimized, but for easier debugging we are doing it this way:
                            // purpose: remove the cancelled observations (aka wounds) from the list if not already done.
                            // (cancelled observations should not be loaded in loadObservations() any more,
                            //  but Fhir could be caching or maybe not even finished processing, so do this to ensure remove)
                            //#region remove existing but cancelled observations
                            Object.keys(result.output).forEach((key) => {
                                let grafixxGroup: any[] = result.output[key];

                                grafixxGroup.forEach((grafixxItem) => {
                                    if (grafixxItem.status === 'cancelled') {
                                        let observation = this.dataHandler.observations.find((o) => o.id === grafixxItem.id,);
                                        if (observation) {
                                            this.dataHandler.observations.splice(this.dataHandler.observations.indexOf(observation), 1,);
                                        }
                                    }
                                });
                            });
                            //#endregion

                            this.observations = this.dataHandler.observations;
                            // use the notification of changed observation to rebuild the groups and clean the items in the woundlist
                            this.groups = await this.dataHandler.generateGroups();
                            await this.observationsChanged();
                            this.applyWound(true);
                            this.getWoundsSvg();
                            this.woundGroupLists = await this.dataHandler.loadWoundGroups(this.patient.encounterId,);
                        } finally {
                            this.processing = false;
                        }
                    }
                });
        } catch (ex) {
            console.warn('in showAllWounds:', ex.message || JSON.stringify(ex));
        }
    }

    reEnableEditing() {
        this.isReadonly = false;
        this.tooOld = false;
        this.readonly = false;
        this.editable = true;
        if (this.saveButton) this.saveButton.buttonState = saveButtonState.save;
    }

    applyWound(isNewWound: boolean = false) {
        if (this.currentWoundResponses && !isNewWound) {
            let idx = this.response ? this.currentWoundResponses.indexOf(this.response) : -1000;
            if (this.response && this.questionnaire && (idx === this.currentWoundResponses.length - 1 || idx === -1) && !this.isReadonly) {
                // show dialog, asking to apply the form values
                this.dialogMessages
                    .dialog(this.i18n.tr('confirm_apply_wound_values'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true,)
                    .whenClosed(async (result) => {
                        if (!result.wasCancelled) {
                            let temp = NitTools.Clone(this.response);
                            this.response = undefined;
                            this.taskQueue.queueTask(() => {
                                temp.item = this.processMappings(temp, false, false, true);
                                this.response = NitTools.Clone(temp);
                                this.patientService.addQuestionnaireResponse(this.patient, this.response, true,);
                                this.reEnableEditing();
                            });
                        }
                    });
            }
        }
    }

    async updatePrint(item: IThumb) {
        RuntimeInfo.IsLoading = true;
        try {
            if (!item || !item.resource) return;

            let printTagValue : boolean = NitTools.ParseBool(this.fhirService.tags.value(item.resource, this.printSystem));

            // create a dummy media that points to the large image
            const dummyMedia = {
                id: NitTools.StripId(item.imageUrl),
                resourceType: 'Media',
                meta: {
                    tag: []
                }
            };

            // remove all PrintImage tags from both resources
            if (item.resource?.meta?.tag) {
                for (const tag of item.resource?.meta?.tag) {
                    if (tag.system?.endsWith('PrintImage')) {
                        item.resource.meta.tag = await this.fhirService.tags.delete(item.resource, tag);
                        await this.fhirService.tags.delete(dummyMedia, tag);
                    }
                }
            }

            // update item print
            printTagValue = !printTagValue;
            item.print = printTagValue;

            // re-add the icon print property
            await this.fhirService.tags.add(item.resource, {
                system: this.printSystem,
                code: item.print ? 'yes' : 'no',
            });

            // re-add the image print property
            await this.fhirService.tags.add(dummyMedia, {
                system: this.printSystem,
                code: item.print ? 'yes' : 'no',
            });
        } catch (e) {
            console.warn(e.message);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    /**
     *  read the mappings for the selected wound type from body-map (stored in qGrafixx), and push the data into the current response,
     *  if the response is the latest and is writeable.
     */
    processMappings(response: any, isNewResponse: boolean, assignResponse: boolean = true, force = false,) {
        let questionnaire = this.questionnaireService.getQuestionnaireDirect(response.questionnaire,);
        // exit if it is readonly
        if (!isNewResponse && !force) {
            if (!response) {
                return;
            }

            // exit if this is not the last response
            if (this.currentWoundResponses.length === 0 || (this.currentWoundResponses.length > 0 && this.currentWoundResponses.indexOf(response) != this.currentWoundResponses.length - 1)) {
                return;
            }

            if (this.readonly) {
                return;
            }
        }

        let wound = this.selectedWound;
        if (this.isWoundGroupSelected && this.selectedWound && this.selectedWound.observations) {
            wound = this.selectedWound.observations[0];
        }

        if (!wound) return;

        // exit if no json object for this wound-type was found in grafixx
        if (!qGrafixx.mapping || !wound.category || !wound.category[0]) {
            return;
        }

        // get the start date of the wound
        let startDate = wound.effectiveDateTime;
        if (wound.effectivePeriod?.start) {
            startDate = wound.effectivePeriod.start;
        }

        // the name of the location
        let location: string;

        // in this fields we store the different sets of items
        let level0Items: any[] = []; // mapped from grafixx level 0
        let level1Items: any[] = []; // mapped from grafixx level 1
        let level2Items: any[] = []; // mapped from grafixx level 2
        let level3Items: any[] = []; // mapped from grafixx level 3
        let mappedItems: any[] = []; // mapped from grafixx mapping
        let timeType = 'origin_date';
        let timeFrameText = '';
        let timeFrameInt = 0;
        let healDate = wound.effectivePeriod?.end ? wound.effectivePeriod.end : undefined;
        let isHealed = typeof healDate !== 'undefined';

        if (wound.effectivePeriod?.extension) {
            const timeFrameExtension = wound.effectivePeriod.extension.find((ext) => ext.url === environment.nursItStructureDefinition + 'timeframe-days',);

            if (timeFrameExtension) {
                timeFrameInt = timeFrameExtension.valueInteger == -1 ? 0 : timeFrameExtension.valueInteger;
                timeFrameText = this.i18n.tr('timeframe_' + timeFrameExtension.valueInteger,);
                timeType = 'origin_frame';
            }
        }

        let wasAvailableAtAdmission = false;
        if (wound.extension) {
            const availableAtAdmissionExtension = wound.extension.find((ext) => ext.url === environment.nursItStructureDefinition + 'available-at-admission',);

            if (availableAtAdmissionExtension && availableAtAdmissionExtension.valueBoolean) {
                // availableAtAdmission = 'dek_301_00'
                wasAvailableAtAdmission = true;
            }
        }

        let subType;
        let subTypeText: string;
        let item;
        let itemText: string;
        let cat;
        let catText: string;

        let comment = wound.comment || undefined;
        // wound.note is in Fhir R4 the fhir4.Annotation[] replacing STU3 wound.comment field
        if (!comment && NitTools.IsArray(wound.note) && wound.note.length > 0) {
            comment = wound.note.filter(o => o.text).map(o => o.text).join('\n');
        }

        // get all IDs aviable in the Questionnaire
        // now iterate over all Questionnaire fields (by name)
        for (const id of Fhir.Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire)) {
            let item = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, id);

            // decide if this is a field that get a mapping
            if (item?.extension) {
                // check whether it is a mapping from a grafixx-level [1-3]
                let levelExtension = item.extension.find((o) => o.url.indexOf('questionnaire-grafixx-level') > -1,);
                if (levelExtension) {
                    let level = parseInt(levelExtension.valueInteger);
                    if (typeof level === "number") {
                        switch (level) {
                            case 0:
                                level0Items.push(item);
                                break;
                            case 1:
                                level1Items.push(item);
                                break;
                            case 2:
                                level2Items.push(item);
                                break;
                            case 3:
                                level3Items.push(item);
                                break;
                            default:
                                break;
                        }
                    }
                }

                // for mappings like location, description etc. use grafixx-mapping extension
                let mappedExtension = item.extension.find((o) => o.url.indexOf('questionnaire-grafixx-mapping') > -1,);
                if (mappedExtension) {
                    mappedItems.push(item);
                }
            }
        }

        if (this.is3dBody) {
            if (wound.bodySite?.coding?.[0].display) {
                location = wound.bodySite.coding[0].display;
            } else {
                const locationDisplay = wound.valueCodeableConcept?.coding?.[0]?.display;
                if (locationDisplay)
                    location = locationDisplay;
                else
                    location = wound.valueCodeableConcept?.coding?.[0]?.code ? this.i18n.tr(wound.valueCodeableConcept.coding[0].code) : "?";
            }

            // get the category:
            cat = wound.component?.[0]?.code?.coding?.[0]?.code || "?";
            catText = wound.component?.[0]?.code?.coding?.[0]?.display || this.i18n.tr(cat);

            // get the sub type (level 0)
            subType = wound.component?.[1]?.code?.coding?.[0]?.code || "?";
            subTypeText = wound.component?.[1]?.code?.coding?.[0]?.display || this.i18n.tr(subType);

            item = wound.component?.[2]?.code?.coding?.[0]?.code || "?";
            itemText = wound.component?.[2]?.code?.coding?.[0]?.display || this.i18n.tr(item);
        } else {
            // .. and then the mapping
            cat = wound.category?.[0]?.text
            catText = this.i18n.tr(cat);

            let bodyMapSetting = qGrafixx.mapping['wounds'].find((o) => o.name.toUpperCase() === cat.toUpperCase());

            if (bodyMapSetting /* && bodyMapSetting.mapping*/) {
                // get the category..


                // get the mapping values from the wound
                if (!this.isWoundGroupSelected) {
                    location = wound.item ? this.i18n.tr(wound.item.bodySite.coding[0].display) : this.i18n.tr(wound.bodySite.coding[0].display);
                } else {
                    let locations = this.selectedWound.observations
                        .filter((o) => o.bodySite && o.bodySite.coding && o.bodySite.coding[0],)
                        .map((m) => m.bodySite.coding[0].display);

                    for (let i = 0; i < locations.length; i++) {
                        locations[i] = this.i18n.tr(locations[i]);
                    }

                    location = locations.join(', ');
                }

                subType = wound.component?.[0] ? wound.component[0].code.text : undefined;
                subTypeText = this.i18n.tr(subType);

                item = wound.component?.[1] ? wound.component[1].code.text : undefined;
                if (!item && wound.component && wound.component[0]) {
                    item = wound.component[0].code.text;
                }

                itemText = this.i18n.tr(item);
            }
        }

        // ----- NEW MAPPING -----
        //.. get all QuestionnaireItems that contain the grafixx-level and grafixx-mapping extension
        // form id [subtypes,name]
        // questionnaire-grafixx-mapping: location
        // questionnaire-grafixx-level

        //#region map using grafixx-levels
        // map level 0 items from "subType"
        for (let i = 0; i < level0Items.length; i++) {
            let level0ResponseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, level0Items[i].linkId, true,);
            if (level0ResponseItem) {
                level0ResponseItem.text = level2Items[i].text;
                const code = (wound.component || wound.category)?.[0]?.code?.coding?.[0]?.code;
                const display = (wound.component || wound.category)?.[0]?.code?.coding?.[0]?.display || this.i18n.tr(code);

                if (level0Items[i].type === "string" || level2Items[i].type === "text") {
                    level0ResponseItem.answer = [{valueString: display}];
                } else {
                    level0ResponseItem.answer = [{
                        valueCoding: {
                            code: code,
                            display: display
                        },
                    }];
                }
            }
        }

        // map level 1 items from type/category
        for (let i = 0; i < level1Items.length; i++) {
            let level1ResponseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, level1Items[i].linkId, true,);
            if (level1ResponseItem) {
                level1ResponseItem.text = level1Items[i].text;
                if (level1Items[i].type === "string" || level1Items[i].type === "text") {
                    level1ResponseItem.answer = [{valueString: catText}];
                } else {
                    level1ResponseItem.answer = [{
                        valueCoding: {
                            code: cat,
                            display: catText
                        },
                    }];
                }
            }
        }

        // map level 2 items from "subType"
        for (let i = 0; i < level2Items.length; i++) {
            let level2ResponseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, level2Items[i].linkId, true,);
            if (level2ResponseItem) {
                level2ResponseItem.text = level2Items[i].text;

                if (level2Items[i].type === "string" || level2Items[i].type === "text") {
                    level2ResponseItem.answer = [{valueString: subTypeText}];
                } else {
                    level2ResponseItem.answer = [{
                        valueCoding: {
                            code: subType, display: subTypeText,
                        },
                    }];
                }
            }
        }

        // map level 3 items from "item"
        for (let i = 0; i < level3Items.length; i++) {
            let level3ResponseItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, level3Items[i].linkId, true,);
            if (level3ResponseItem) {
                level3ResponseItem.text = level3Items[i].text;
                if (level3Items[i].type === "string" || level3Items[i].type === "text") {
                    level3ResponseItem.answer = [{valueString: itemText}];
                } else {
                    level3ResponseItem.answer = [{
                        valueCoding: {
                            code: item, display: itemText,
                        },
                    }];
                }
            }
        }
        //#endregion

        //#region check the mapped fields like location, description,...
        for (let i = 0; i < mappedItems.length; i++) {
            let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, mappedItems[i].linkId, true,);
            let extension = mappedItems[i].extension.find((o) => o.url.indexOf('questionnaire-grafixx-mapping') > -1,);

            if (item && extension) {
                item.text = mappedItems[i].text;
                let source = extension.valueString;

                switch (source) {
                    case 'date-origin':
                        // get the correct display text from the QuestionnaireItem.Option
                        let doDisplay = undefined;
                        let doOpts = mappedItems[i].option;
                        if (doOpts) {
                            let doOpt = doOpts.find((o) => o.valueCoding && o.valueCoding.code === timeType,);
                            if (doOpt) {
                                doDisplay = doOpt.valueCoding.display;
                            }
                        }

                        item.answer = [{
                            valueCoding: {
                                code: timeType,
                            },
                        },];

                        if (doDisplay) {
                            item.answer[0].valueCoding.display = doDisplay;
                        }
                        break;
                    case 'location':
                        item.answer = [{
                            /* valueCoding: {
                                display: this.i18n.tr(this.selectedWound.bodySite.coding[0].display),
                                code: this.selectedWound.bodySite.coding[0].display
                            } */
                            valueString: location,
                        }];
                        break;

                    case 'description':
                        item.answer = [{
                            valueString: comment,
                        }];
                        break;
                    case 'since-when':
                        if (startDate) {
                            item.answer = [{
                                valueDate: moment(startDate || new Date()).format('YYYY-MM-DD',),
                            }];
                        }
                        break;
                    case 'date-range':
                        if (timeFrameText) {
                            item.answer = [{
                                valueCoding: {
                                    code: `timeframe_${timeFrameInt}`, display: timeFrameText,
                                },
                            }];
                        }
                        break;
                    case 'existing-on-admission':
                        // get the correct display text from the QuestionnaireItem.Option
                        let exValue = `existing_${wasAvailableAtAdmission ? 'true' : 'false'}`;
                        let exDisplay = undefined;
                        let exOpts = mappedItems[i].option;
                        if (exOpts) {
                            let exOpt = exOpts.find((o) => o.valueCoding && o.valueCoding.code === exValue,);
                            if (exOpt) {
                                exDisplay = exOpt.valueCoding.display;
                            }
                        }

                        item.answer = [{
                            valueCoding: {
                                code: exValue,
                            },
                        },];

                        if (exDisplay) {
                            item.answer[0].valueCoding.display = exDisplay;
                        }
                        break;
                    case 'healed':
                        item.answer = [{
                            valueCoding: {
                                code: `healed_${isHealed ? 'yes' : 'no'}`, display: this.i18n.tr(isHealed ? 'yes' : 'no'),
                            },
                        }];
                        break;
                    case 'heal-date':
                        item.answer = [{
                            valueDateTime: healDate,
                        }];
                        break;
                    default:
                        console.warn(`No grafixx-mapping for "${source}" defined`);
                        break;
                }
            }
        }
        //#endregion

        return NitTools.Clone(response.item);
    }

    private updateAllHeaderButtonClassNames() {
        if (!this.responses) return;
        for (const response of this.responses) {
            this.updateHeaderButtonClassName(response);
        }
    }

    private updateHeaderButtonClassName(response: any, forcedClass: string = undefined,) {
        if (!this.setting) this.setting = ConfigService.GetFormSettings('isolation');
        if (!this.setting || !response) return;

        const age = new moment(new Date()).diff(new Date(response.authored), 'h');

        let className = 'mdi-edit';
        if (this.setting.expiration && this.setting.expiration.default) {
            if (['amended', 'completed'].indexOf(response.status) > -1) {
                if (age > this.setting.expiration.default) {
                    className = 'mdi-lock';
                } else {
                    className = 'mdi-lock-open';
                }
            }

            className = forcedClass || className;
        }

        window.requestAnimationFrame(() => {
            const i = document.querySelector(`i.wound-date-edit-button[data-response-id="${response.id}"]`,);
            if (i) {
                i.setAttribute('class', 'wound-date-edit-button mdi ' + className);
            }
        });
    }
}

export interface IWoundListItem {
    id?: string
    title?: string
}

export class WoundGroupItem {
    item: any;
    shortText: string;
    longText: string;
    bodyPart: string;
    imagePart: string;
    image: string;
    listId?: string;
    valid: boolean = false;
    left: number = 0;
    top: number = 0;
    hintText?: string;
    thumbGroups?: IThumbGroup[];
    isHealed?: boolean = false;
    questionnaireUrl?: string;
    schemaIcon?: string;

    constructor(aItem: any) {
        this.item = aItem;

        if (aItem.identifier && aItem.identifier[0]) {
            this.shortText = aItem.identifier[0].value;
        } else {
            this.shortText = '?';
        }

        if (aItem.bodySite) {
            if (aItem.bodySite.coding && aItem.bodySite.coding[0]) {
                let sArr = aItem.bodySite.coding[0].display || aItem.bodySite.coding[0].code; // .split('_');
                if (sArr && sArr.length > 1) {
                    this.imagePart = sArr[0];
                    this.bodyPart = sArr[1];
                    this.valid = true;
                } else {
                    console.warn('No bodysite coding display|code found');
                }
            } else {
                console.warn('No bodySite coding found');
            }

            if (aItem.bodySite.text) {
                let sArr = aItem.bodySite.text.split('_');
                this.left = parseFloat(sArr[0]);
                this.top = parseFloat(sArr[1]);
                this.image = sArr[2] || 'body-1.0';
            } else {
                console.warn('No bodysite text found in ', aItem);
            }

            if (this.bodyPart) {
                this.longText = translations.translate(this.bodyPart, false);
                if (!this.longText || this.longText === this.bodyPart) {
                    if (aItem.bodySite.coding[0]) this.longText = translations.translate(aItem.bodySite.coding[0].display, true,);
                }
            } else {
                this.longText = this.shortText;
            }
        } else if (aItem.valueCodeableConcept?.coding[0]?.code) {
            this.bodyPart = aItem.valueCodeableConcept.coding[0].code;
            this.longText = this.bodyPart;

            aItem.bodySite = {
                coding: [{
                    code: this.bodyPart, display: this.bodyPart,
                },],
            };

            this.valid = true;
        } else {
            console.warn('No bodySite found');
        }

        if (aItem.category?.[0]?.text && WoundDataHandler.BodyMarkersCodeSystem) {
            const concept = WoundDataHandler.BodyMarkersCodeSystem.concept.find(c => c.code === aItem.category[0].text);
            if (concept?.property?.length > 0) {
                const questionnaireProperty = concept.property.find(p => p.code === "questionnaire" && p.valueString);
                this.questionnaireUrl = questionnaireProperty?.valueString;
                // console.warn(`QuestionnaireUrl set to: "${this.questionnaireUrl}" via CodeSystem`);
            } else {
                this.questionnaireUrl = `${QuestionnaireService.NitQuestionnairesUrl}/CareItWound_${aItem.category[0].text}`;
                console.warn(`using fallback QuestionnaireUrl: "${this.questionnaireUrl}"`);
            }
        }

        if (aItem.valueCodeableConcept?.coding?.length > 0) {
            const codingsWithExtensions = aItem.valueCodeableConcept.coding.filter(c => c.extension?.length > 0);
            for (const coding of codingsWithExtensions) {
                const markerImageExtension = coding.extension?.find(o => o.url.endsWith('marker-image') && o.valueAttachment?.data);
                if (markerImageExtension) {
                    this.schemaIcon = `data:${markerImageExtension.valueAttachment.contentType};base64,${markerImageExtension.valueAttachment.data}`;
                }
            }
        }
    }
}

export class WoundGroup {
    item: IGrafixxItem;
    children: WoundGroupItem[];
    title: string;
    visible: boolean = true;
    icon?: string;
    groupIcon: HTMLImageElement;

    constructor(aItem: IGrafixxItem) {
        this.children = [];
        this.item = aItem;

        this.title = translations.translate(aItem.type);
    }
}

/** holds the mapped data from a media resource */
export interface IThumb {
    title: string
    imageUrl: string
    resource: any
    print: boolean
    thumb: string
    thumbEdited: string
}

/** interface for a group of thumbnails. title should contain the date the media was taken */
export interface IThumbGroup {
    title: string
    items: IThumb[]
}

export interface ICameraSetup {
    useCameraApi: boolean
    captureWidth: number
    captureHeight: number
}
