import {bindable} from "aurelia-templating";
import {PatientItem} from "../Patient/PatientItem";
import {KeyValuePair} from "../Delphi";
import {QuestionnaireResponse} from "./QuestionnaireResponse";
import {NitTools} from "../NursitTools";
import * as Fhir from "./Fhir";
import {ConfigService} from "../../services/ConfigService";
import {FhirService} from "../../services/FhirService";
import {QuestionnaireService} from "../../services/QuestionnaireService";

export class Questionnaire {
    public static staAssessmentId: string;
    @bindable patient: PatientItem;
    @bindable encounter;
    @bindable response;
    @bindable questionnaire;

    public static GetCalculatedFields(questionnaire): any[] {
        let result: any[] = [];
        if (questionnaire && questionnaire.item) {
            questionnaire.item.forEach(item => {
                let tmp = this._getCalculatedFieldsFromItem(item);
                if (tmp) tmp.forEach(t => result.push(t));
            });
        }

        return result.length === 0 ? undefined : result;
    }

    private static _getCalculatedFieldsFromItem(questionnaireItem): any[] {
        let result: any[] = [];

        if (questionnaireItem) {
            if (questionnaireItem.item) {
                questionnaireItem.item.forEach(itm => {
                    let tmp = this._getCalculatedFieldsFromItem(itm);
                    if (tmp) tmp.forEach(t => result.push(t));
                });
            }

            if (questionnaireItem.extension) {
                let extCalc = questionnaireItem.extension.find(o => o.url.endsWith('questionnaire-calculated-field') && o.valueString);
                if (extCalc) {
                    let str = extCalc.valueString;
                    if (str.indexOf('=') !== 0) {
                        str = "=" + str;
                    }

                    questionnaireItem.initialCoding = {
                        code: str
                    };
                }
            }

            if (questionnaireItem.initialCoding
                && questionnaireItem.initialCoding.code) {
                let code = questionnaireItem.initialCoding.code.trim();
                if (code.indexOf("=") === 0) {
                    result.push(questionnaireItem);
                }
            }
        }

        return result.length > 0 ? result : undefined;
    }

    private static getDefaultQuestionnaireValuesFromItem(item): KeyValuePair[] {
        let result: KeyValuePair[] = [];

        if (item.item) {
            item.item.forEach(itm => {
                let arr = this.getDefaultQuestionnaireValuesFromItem(itm);
                arr.forEach(a => {
                    result.push(a);
                });
            });
        }

        if (item.initialCoding && item.initialCoding.code) {
            let code = item.initialCoding.code;
            if (code.indexOf("=") !== 0) { // does not start with "=" which indicates that it would be a formula
                result.push(new KeyValuePair(item.linkId, code));
            }
        }

        return result;
    }

    private static _findResponseItemByLinkId(linkId: string, item): any {
        if (item.linkId === linkId) return item;
        let result = undefined;
        if (item.item) {
            for (let i = 0; i < item.item.length; i++) {
                if (item.item[i].linkId === linkId) {
                    return item.item[i];
                }

                let tmp = this._findResponseItemByLinkId(linkId, item.item[i]);
                if (tmp) {
                    result = tmp;
                    break;
                }
            }
        }

        return result;
    }

    /**
     * Find the QuestionnaireResponseItem for a QuestionnaireItem in a QuestionnaireResponse or optionally creates it
     * @param item The QuestionnaireItem to find the ResponseItem for
     * @param response the QuestionnaireResponse to search for the user given answer
     * @param create If set to true, the item will be created
     */
    public static FindResponseItem(item, response, create: boolean = true): any {
        if (!item || !response) return;
        if (!response.item) response.item = [];

        let linkId = item.linkId;
        try {
            if (linkId.indexOf('VAR_') === 0) {
                linkId = linkId.substr(4);
            }
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
        }


        let result = undefined;
        for (let i = 0; i < response.item.length; i++) {
            let tmp = this._findResponseItemByLinkId(item.linkId, response.item[i]);
            if (tmp) {
                result = tmp;
                break;
            }
        }

        /* do not create. Everything has to be inside the questionnaire or beeing ignored
        if (!result && create) {
            result = {linkId: item.linkId, answer: [], text: ""};
            response.item.push(result);
        } */

        return result;
    }

    public static GetInintialItemValue(item: any): any[] {
        if (typeof item === "undefined") return [];
        return item.initial;
    }

    /**
     * Gets the initial value a control should display. The value is either taken from the given QuestionnaireResponse or taken from the QuestionnaireItem.initial[x] or is the defaultValue provided
     * @param questionnaireItem The QuestionnaireItem to read the initialValue from (read 2nd)
     * @param response The QuestionnaireResponse to read the current value from (read 1st)
     * @param defaultValue The defaultValue to return (read 3rd)
     * @return the default value to be displayed in a component
     */
    public static GetInitialValue(questionnaireItem, response, defaultValue?: any): any {
        if (!questionnaireItem) return undefined;
        if (response) {
            let responseItem = this.FindResponseItem(questionnaireItem, response, false);
            if (responseItem) {
                let val = QuestionnaireResponse.GetResponseItemValue(responseItem, undefined);
                if (typeof val !== "undefined") return val;
            }
        }

        let r4Value;
        if (questionnaireItem.initialCoding && questionnaireItem.initialCoding.code) { // R3
            try {
                let sVal = String(questionnaireItem.initialCoding.code);
                if (sVal && (sVal.indexOf('|') > -1 || sVal.indexOf('=') > -1 && sVal.indexOf('grafixx') > -1)) {
                    if (ConfigService.Debug) console.debug(`Found special meaning in field ${questionnaireItem.linkId}: ${sVal}`);
                } else {
                    return questionnaireItem.initialCoding.code;
                }
            } catch (e) {
                if (ConfigService.Debug) {
                    console.warn(e.message || JSON.stringify(e));
                }
            }
        } else if (questionnaireItem.initial) { // R4
            //response.answer = Questionnaire.GetInintialItemValue(questionnaireItem);
            r4Value = Questionnaire.GetInintialItemValue(questionnaireItem);
        }

        let initialKeys = Object.keys(questionnaireItem).filter(o => o.indexOf("initial") === 0);
        let key = initialKeys.length > 0 ? initialKeys[0] : undefined;
        if (key) {
            let val = questionnaireItem[key];
            return val;
        }

        return r4Value||defaultValue;
    }

    public static SetDefaultValues(questionnaire, target, previousResponse) {
        return QuestionnaireResponse.SetDefaultValues(target, previousResponse);
    }

    public static GetDefaultQuestionnaireValues(questionnaire): KeyValuePair[] {
        let result: KeyValuePair[] = [];
        questionnaire.item.forEach(itm => {
            this.getDefaultQuestionnaireValuesFromItem(itm).forEach(kvp => {
                result.push(kvp);
            });
        });

        return result;
    }

    public static GetDefaultQuestionnaireItemValueByLinkId(questionnaire, linkId : string): string {
        let result : string = undefined;
        const item = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
        if (item) {
            result = this.GetDefaultQuestionnaireItemValue(item);
        }

        return result;
    }

    public static GetDefaultQuestionnaireItemValue(item): string {
        let result: string = undefined;
        if (item?.initialCoding && item?.initialCoding?.code) {
            let code = item.initialCoding.code;
            if (code.indexOf("=") !== 0) { // does not start with "=" which indicates that it would be a formula
                result = code;
            }
        }

        return result;
    }

    // move optionExclusive from the modifierExtension to extention
     public static MoveMultiSelectModifierExtensionItem(item) {
         if (item.option) {
             item.option.forEach((option) => {
                 if (option.modifierExtension) {
                     let multiSelectExtension = option.modifierExtension.find(o=>o.url.endsWith('questionnaire-optionExclusive'));
                     if (multiSelectExtension) {
                         if (!option.extension) option.extension = [];
                         if (multiSelectExtension.valueBoolean === true)
                             option.extension.push(NitTools.Clone(multiSelectExtension));

                         item.repeats = true;
                         let idx = option.modifierExtension.indexOf(multiSelectExtension);
                         if (idx > -1) {
                             option.modifierExtension.splice(idx, 1);
                         }

                         if (option.modifierExtension.length === 0) delete option.modifierExtension;
                         if (option.extension.length === 0) delete option.extension;
                     }
                 }
             });
         } else if (item.item) {
             item.item.forEach(child => this.MoveMultiSelectModifierExtensionItem(child));
         }
    }

    public static MoveCalculatedFieldsItem(item) {
        if (item.initialCoding && item.initialCoding.code && item.initialCoding.code.indexOf('=') === 0) {
            Fhir.Tools.SetExtension(item, "questionnaire-calculated-field", item.initialCoding.code.substr(1));
        }

        if (item.item) {
            item.item.forEach(child => this.MoveCalculatedFieldsItem(child));
        }
    }

    public static MoveCalculatedFields(questionnaire) {
        if (!questionnaire || !questionnaire.item) return;
        questionnaire.item.forEach(item => this.MoveCalculatedFieldsItem(item));
    }

    public static MoveMultiSelectModifierExtension(questionnaire) {
        if (!questionnaire || !questionnaire.item) return;
        questionnaire.item.forEach(item => this.MoveMultiSelectModifierExtensionItem(item));
    }

    private static makeTwo(num: number): string {
        let s = String(num);
        if (s.length < 2) {
            s = "0" + s;
        }

        return s;
    }

    private static formatDate(date: Date): string {
        return `${date.getFullYear()}-${this.makeTwo(date.getMonth() + 1)}-${this.makeTwo(date.getDate())}`;
    }

    private static isDate(newValue): boolean {
        if (typeof newValue === "string")
            return /([123456789]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(newValue);
        else if (typeof newValue === "object") return true;
    };

    public static FixResponseAnswers(questionnaire, response) {
        let linkIds = Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire);
        let icdItem = QuestionnaireResponse.GetResponseItemByLinkId(response, '_icd_codes_');
        if (icdItem && icdItem.answer) {
            let icdsFixed: boolean = false;
            icdItem.answer.forEach(answer => {
                if (answer.valueReference) {
                    icdsFixed = true;
                    let ref = NitTools.Clone(answer.valueReference);
                    delete answer.valueReference;
                    answer.valueCoding = {
                        code: ref.id,
                        display: ref.display
                    };

                    if (ConfigService.Debug) console.debug("fixed ICD Answer from reference to coding", {previous: ref, updated: answer});
                }
            });

            if (icdsFixed) {
                if (ConfigService.Debug) console.debug("Moved ICD-Items to Coding in ", icdItem);
            }
        }

        linkIds.forEach(linkId => {
            let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId);
            if (responseItem && responseItem.answer) {
                let questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
                // just to be sure, set the text to the correct value from the questionnaire item
                if (questionnaireItem) {
                    responseItem.text = questionnaireItem.text;
                }

                if (responseItem.answer.length > 1) {
                    /*if (questionnaireItem && !questionnaireItem.repeats) { //&& questionnaireItem.type !== 'choice') {
                        console.warn('Item has more than 1 answer and item.repeats has not been set', {
                            responseItem: responseItem,
                            questionnaireItem: questionnaireItem
                        });
                    }*/
                } else if (responseItem && responseItem.answer && responseItem.answer.length === 1) {
                    let answer = responseItem.answer[0];
                    let valueKey = Object.keys(answer).find(o => o.indexOf('value') > -1);
                    let expectedKey: string = undefined;
                    if (questionnaireItem) {
                        switch (questionnaireItem.type) {
                            case 'boolean': {
                                expectedKey = 'valueBoolean';
                                if (expectedKey !== valueKey) {
                                    responseItem.answer = [{valueBoolean: NitTools.ParseBool(QuestionnaireResponse.GetResponseItemValue(responseItem))}];
                                }

                                break;
                            }
                            case 'date' : {
                                expectedKey = 'valueDate';
                                if (expectedKey !== valueKey) {
                                    let sVal = QuestionnaireResponse.GetResponseItemValue(responseItem);
                                    if (this.isDate(sVal)) {
                                        let newDate = new Date(sVal);
                                        responseItem.answer = [{valueDate: this.formatDate(newDate)}];
                                    }
                                }

                                break;
                            }
                            case 'time' : {
                                expectedKey = 'valueTime';
                                if (expectedKey !== valueKey) {
                                    let sVal = QuestionnaireResponse.GetResponseItemValue(responseItem);
                                    responseItem.answer = [{valueTime: sVal}];
                                }

                                break;
                            }
                            case 'text':
                            case 'string': {
                                expectedKey = 'valueString';
                                if (expectedKey !== valueKey) {
                                    let sVal = QuestionnaireResponse.GetResponseItemValue(responseItem, undefined);
                                    responseItem.answer = [{valueString: typeof (sVal) !== "undefined" ? String(sVal) : undefined}];
                                }

                                break;
                            }
                            case 'integer': {
                                expectedKey = 'valueInteger';
                                if (expectedKey !== valueKey) {
                                    let int = parseInt(QuestionnaireResponse.GetResponseItemValue(responseItem));
                                    responseItem.answer = [{valueInteger: isNaN(int) ? undefined : int}];
                                }

                                break;
                            }
                            case 'decimal':
                                expectedKey = 'valueDecimal';
                                if (expectedKey !== valueKey) {
                                    let flt = parseFloat(QuestionnaireResponse.GetResponseItemValue(responseItem));
                                    responseItem.answer = [{valueDecimal: isNaN(flt) ? undefined : flt}];
                                }

                                break;
                            case 'reference':
                                expectedKey = 'valueReference';
                                if (expectedKey !== valueKey) {
                                    responseItem.answer = [{valueReference: {reference: QuestionnaireResponse.GetResponseItemValue(responseItem)}}];
                                }

                                break;
                            case 'choice':
                                expectedKey = 'valueCoding';
                                if (expectedKey !== valueKey) {
                                    if (valueKey !== expectedKey) {
                                        responseItem.answer = [{valueCoding: {code: QuestionnaireResponse.GetResponseItemValue(responseItem)}}];
                                    }
                                }

                                break;
                        }

                        if (Fhir.Tools.IsEnableWhenSatisfied(questionnaireItem, response, true)) {
                            let questionnaireItemParent = Questionnaire.FindParent(questionnaire, questionnaireItem);
                            // look if maybe the parent group is hidden by enableWhen
                            if (questionnaireItemParent && questionnaireItemParent["type"] === "group") {
                                /* DO NOT DO THIS!  This will break the analysis, although it is not correct when using Fhir-Validation.

                                if (!Fhir.Tools.IsEnableWhenSatisfied(questionnaireItemParent, response, true)) {
                                    if (ConfigService.Debug) console.debug("Deleting answer because Parent Element does not satisfy enableWhen " + responseItem.linkId, {
                                        parent: questionnaireItemParent,
                                        questionnaireItem: questionnaireItem,
                                        responseItem: responseItem
                                    });
                                    delete responseItem.answer;
                                } */
                            }
                        } else {
                            // the item itself does not solve enableWhen
                            delete responseItem.answer;
                        }
                    } else {
                        delete responseItem.answer;
                    }

                    if (answer.valueCoding) {
                        if (typeof answer.valueCoding.code === "undefined" || answer.valueCoding.code === undefined) {
                            delete responseItem.answer;
                        } else {
                            // set the correct display value from the option
                            if (questionnaireItem && questionnaireItem.option && questionnaireItem.option.length > 0) {
                                let option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === answer.valueCoding.code);
                                if (option) {
                                    answer.valueCoding.display = option.valueCoding.display;
                                    if (answer.valueCoding.display && answer.valueCoding.display.indexOf('|') > -1) {
                                        answer.valueCoding.display = answer.valueCoding.display.split('|')[1];
                                    }
                                }
                            }
                        }
                    }

                    //re-check if answer exists, because it may have been deleted in the prior step
                    if (responseItem.answer && responseItem.answer[0]) {
                        answer = responseItem.answer[0];

                        valueKey = Object.keys(answer).find(o => o.indexOf('value') > -1);
                        if (valueKey && expectedKey && valueKey !== expectedKey) {
                            console.warn('Expected value key differs from existing valueKey.', {
                                expected: expectedKey,
                                existing: valueKey,
                                responseItem: responseItem,
                                questionnaireItem: questionnaireItem,
                                questionnaire: questionnaire,
                                response: response
                            });
                        }
                    }
                }
            }
        });

        return Fhir.Questionnaire;
    }

    public static EnsureStructuredResponse(questionnaire, response) {
        if (!questionnaire || !response) {
            console.warn("No " + (!questionnaire ? 'Questionnaire' : '') + " " + (!response ? 'Response' : '') + " given to process. Exiting.");
            return;
        }

        const responseLinks = Questionnaire.GetAllQuestionnaireItemLinkIds(response, true);
        const questionnaireLinks = Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire, true);
        if (responseLinks.length === questionnaireLinks.length) return;
        /* if (questionnaire.item && response.item && questionnaire.item.length === response.item.length) {
            return;
        } */

        let storedResponse = NitTools.Clone(response);

        //#region compatibility; move group_visible* fields into extension
        if (!storedResponse.item) storedResponse.item = [];
        let countItem = storedResponse.item.filter(o => o.linkId && o.linkId === "visible_group_count");
        if (countItem) {
            let ext = Fhir.Tools.GetOrCreateExtension(storedResponse, "questionnaire-visible-group-count", true);
            ext.valueInteger = QuestionnaireResponse.GetResponseItemValueInt(countItem);
            storedResponse.item = storedResponse.item.filter(o => o.linkId !== 'visible_group_count');
        }

        if (!storedResponse.item) storedResponse.item = [];
        let visibleFields = storedResponse.item.filter(o => o.linkId && o.linkId.indexOf('group_visible_') === 0);
        if (visibleFields.length > 0) {
            visibleFields.forEach(field => {
                let extension = Fhir.Tools.GetOrCreateExtension(storedResponse, 'questionnaire-group-visible', true);
                let val = Boolean(QuestionnaireResponse.GetResponseItemValue(field, true));
                extension.valueBoolean = val;
            });

            storedResponse.item = storedResponse.item.filter(o => o.linkId && o.linkId.indexOf('group_visible_') === -1);
        }
        //#endregion

        const linkIds = Questionnaire.GetAllQuestionnaireItemLinkIds(questionnaire); // Fhir.Tools.GetAllResponseLinkIds(storedResponse);
        let oldResponse = NitTools.Clone(storedResponse);

        storedResponse = Fhir.Tools.CreateStructuredResponse(questionnaire, storedResponse);

        for (const linkId of linkIds) {
            let oldResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(oldResponse, linkId, false);
            let questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
            if (oldResponseItem) {
                if (questionnaireItem) {
                    if (questionnaireItem && questionnaireItem.type === "group") {
                        delete oldResponseItem.answer;
                        // ensure the text from the Questionnaire
                        oldResponseItem.text = questionnaireItem.text;
                    } else {
                        let newResponseItem = QuestionnaireResponse.GetResponseItemByLinkId(storedResponse, linkId, false);
                        if (newResponseItem) {
                            newResponseItem.answer = NitTools.Clone(oldResponseItem.answer);
                            // ensure the text from the Questionnaire
                            newResponseItem.text = questionnaireItem.text;
                            newResponseItem.extension = NitTools.Clone(oldResponseItem.extension);
                        }
                    }
                }
            }
        }

        response.item = storedResponse.item;

        if (FhirService.FhirVersion >= 4) {
            response.questionnaire =  questionnaire.url || `${QuestionnaireService.NitQuestionnairesUrl}/${questionnaire.name}`;
        } else {
            response.questionnaire = {
                reference: `Questionnaire/${questionnaire.id}`
            };
        }

        return Fhir.Questionnaire;
    }

    private static _getQuestionnaireItemLinkIdsFromItem(item): string[] {
        let result: string[] = [];

        if (item.item) {
            item.item.forEach((itm) => {
                result.push(itm.linkId);

                let arr = this._getQuestionnaireItemLinkIdsFromItem(itm);
                arr.forEach(a => {
                    result.push(a);
                });
            });
        }

        return result;
    }

    public static GetAllQuestionnaireItemLinkIds(questionnaire, includeGroups : boolean = false): string[] {
        let result: string[] = [];
        if (!questionnaire?.item) return result;

        for (const itm of questionnaire.item) {
            if (itm.type === "group" && includeGroups)
                result.push(itm.linkId);
            else if (itm.type !== 'group')
                result.push(itm.linkId);

            this._getQuestionnaireItemLinkIdsFromItem(itm).forEach(key => {
                result.push(key);
            });
        }

        return result;
    }

    private static findQuestionnaireItem(item, linkId: string): any {
        if (!item?.linkId || !linkId) return undefined;
        if (item.linkId.toUpperCase() === linkId.toUpperCase()) return item;

        if (item.item && item.item.length > 0) {
            for (let i = 0; i < item.item.length; i++) {
                let itm = this.findQuestionnaireItem(item.item[i], linkId);
                if (itm) return itm;
            }
        }

        return undefined;
    }

    /**
     * Gets the parent element of an element
     * @param questionnaire The Questionnaire to search for the parent
     * @param item The item to find the parent if
     * @param currentNode The current node to process (internal use)
     * @return the parent element of an item
     */
    public static FindParent(questionnaire, item, currentNode?) {
        if (!item) return undefined;

        if (!currentNode) {
            for (let i = 0; i < questionnaire.item.length; i++) {
                if (questionnaire.item[i].linkId === item.linkId) return questionnaire;
                let result = this.FindParent(questionnaire, item, questionnaire.item[i]);
                if (result) return result;
            }
        } else {
            if (currentNode.item) {
                let result = currentNode.item.find(o => o.linkId === item.linkId);
                if (result) return currentNode;

                for (let i = 0; i < currentNode.item.length; i++) {
                    let result = this.FindParent(questionnaire, item, currentNode.item[i]);
                    if (result) return result;
                }
            }
        }

        return undefined;
    }

    public static GetItemHintTextById(item, optionValueId: string) : string {
        if (!item || !NitTools.IsArray(item.option))
            return undefined;

        const option = item.option.find(o=>o.valueCoding?.code === optionValueId && o.extension);
        if (option) {
            const extension = option.extension.find(o=>o.url.endsWith('questionnaire-option-hint'));
            if (extension && extension.valueString)
                return extension.valueString;
        }

        return undefined;
    }

    public static GetItemTextById(item, optionValueId: string) : string {
        const fixR4 = function(item) {
            if (item && !item.option && item["answerOption"])
                item.option = item["answerOption"];

            return item;
        };

        item = fixR4(item);
        if (!item || !NitTools.IsArray(item.option))
            return undefined;

        const option = item.option.find(o=>o.valueCoding?.code === optionValueId && o.extension);
        if (typeof option?.valueCoding?.display === "string") {
            return option.valueCoding.display;
        }

        return undefined;
    }

    public static GetQuestionnaireItemByLinkId(questionnaire, linkId: string): any {
        const fixR4 = function(item) {
            if (item && !item.option && item["answerOption"])
                item.option = item["answerOption"];

            return item;
        };

        if (!questionnaire || !linkId) return undefined;
        if (!questionnaire.item) {
            questionnaire.item = [];
        }

        let result = questionnaire.item.find(o => o.linkId && o.linkId.toUpperCase() === linkId.toUpperCase());
        if (typeof result !== "undefined") return fixR4(result);

        for (let i = 0; i < questionnaire.item.length; i++) {
            result = this.findQuestionnaireItem(questionnaire.item[i], linkId);
            if (typeof result !== 'undefined') {
                return fixR4(result);
            }
        }

        return undefined;
    }
}
