import { autoinject, bindable } from "aurelia-framework";
import { DialogController, DialogService } from "aurelia-dialog";
import { I18N } from "aurelia-i18n";
import { PatientItem } from "../../../resources/classes/Patient/PatientItem";
import { FhirService } from "../../../resources/services/FhirService";
import { ConfigService } from "../../../resources/services/ConfigService";
import { allergyMain } from "./allergy-main";
import { RuntimeInfo } from "../../../resources/classes/RuntimeInfo";
import { DialogMessages } from "../../../resources/services/DialogMessages";
import { NitTools } from "../../../resources/classes/NursitTools";
import { UserService } from "../../../resources/services/UserService";
import { IAllergy } from "../../../resources/classes/allergy";
import { HttpClient } from "aurelia-http-client";

const moment = require("moment");

@autoinject
export class allergyAdd {
    patient: PatientItem;

    displayInHeader : boolean = false;

    /** id of the valueset which contains the allergies */
    valueSetId: string;

    /** Gets or sets a value indicating whether the criticality is a Dräger custom */
    isIcmAllergyCriticality: boolean = false;

    /** Gets or sets the text to display for the criticality when its Dräger custom criticality*/
    icmAllergyCriticality: string;

    /** the allergies, loaded from the given id */
    static allergiesValueSet: any;

    /** the allergies, loaded from the given id */
    get allergies() {
        return allergyAdd.allergiesValueSet;
    }

    /** Preferred value set for AllergyIntolerance Clinical Status. from CodeSyste/allergyintolerance-clinical  */
    static allergyintoleranceClinical: any;

    /** Preferred value set for AllergyIntolerance Clinical Status. */
    get allergyintoleranceClinical() {
        return allergyAdd.allergyintoleranceClinical;
    }

    /** the verification status to support or decline the clinical status of the allergy or intolerance. from CodeSystem/allergyintolerance-verification */
    static allergyintoleranceVerification: any;

    /** the verification status to support or decline the clinical status of the allergy or intolerance. */
    get allergyintoleranceVerification() {
        return allergyAdd.allergyintoleranceVerification;
    }

    /** Clinical assessment of the severity of a reaction event as a whole, potentially considering multiple different manifestations. from CodeSystem/reaction-event-severity */
    static reactionEventSeverity: any;

    /** Clinical assessment of the severity of a reaction event as a whole, potentially considering multiple different manifestations. */
    get reactionEventSeverity() {
        return allergyAdd.reactionEventSeverity;
    }

    /** Estimate of the potential clinical harm, or seriousness, of a reaction to an identified substance. From CodeSystem/allergy-intolerance-criticality */
    static allergyIntoleranceCriticality: any;

    /** the possible reactions when the allergy comes to live */
    static allergyReactionsValueSet: any;

    get allergyReactionsValueSet() {
        return allergyAdd.allergyReactionsValueSet;
    }

    /** Estimate of the potential clinical harm, or seriousness, of a reaction to an identified substance. */
    get allergyIntoleranceCriticality() {
        return allergyAdd.allergyIntoleranceCriticality;
    }

    /** Category of an identified substance associated with allergies or intolerances. From CodeSystem/allergy-intolerance-category */
    static allergyIntoleranceCategory: any;

    /** Category of an identified substance associated with allergies or intolerances. */
    get allergyIntoleranceCategory() {
        return allergyAdd.allergyIntoleranceCategory;
    }

    static allergyIntoleranceTypes: any;
    get allergyIntoleranceTypes() {
        return allergyAdd.allergyIntoleranceTypes;
    }

    @bindable selected;

    /** the search string for a specific allergy */
    @bindable search: string;
    @bindable allergyConcepts: { code: string, display: string, system: string, search: string }[] = [];

    isR3Fhir : boolean = FhirService.FhirVersion <= 3;

    /** the currently filtered allergies */
    currentItems = [];

    /** the currently selected allergy */
    selectedItem = undefined;

    /** the currently selected verficiationState (required field according to fhir specification) */
    verificationState: IAllergyComboItem;

    /** the available clinical verification state as simple combo-bix datasource */
    verificationStates: IAllergyComboItem[] = [];

    /** the currently selected clinical status of the allergy */
    clinicalStatus: IAllergyComboItem;

    /** the available clinical status codes */
    clinicalStatusCodes: IAllergyComboItem[] = [];

    /** the currently selected allergy category */
    allergyCategory: IAllergyComboItem;

    /** the available categories */
    allergyCategories: IAllergyComboItem[] = [];

    /** the currently selected allergy criticality */
    criticality: IAllergyComboItem;

    /** the available Criticalities */
    criticalities: IAllergyComboItem[] = [];

    /** the currently selected allergy criticality */
    reaction: IAllergyComboItem;

    /** the available Criticalities */
    reactions: IAllergyComboItem[] = [];

    /** the currently seleted allergy severity */
    severity: IAllergyComboItem;

    /** the available Severities */
    severities: IAllergyComboItem[] = [];

    /** the currently selected intolerance type */
    intoleranceType: IAllergyComboItem;

    /** the avilable intolerance types */
    intoleranceTypes: IAllergyComboItem[] = [];

    /** the note the user has written in the allergy */
    note: string;

    /** the allergy that was provided as parameter */
    paramAllergy;

    /** gets or sets a value indicating whether the complete allergy list is displayed */
    listIsOpen: boolean = false;

    /** gets or sets a value indicating whether the form is editing an allergy or if it is a new item */
    isEditMode: boolean = false;

    /** gets or sets a value which indicates the id of the allergy to edit */
    editId: string;

    //#region view life-time functions
    constructor(protected controller: DialogController, protected service: DialogService, protected i18n: I18N) {
        if (ConfigService.Debug)
            window["allergyAdd"] = this;
    }

    async activate(params) {
        this.patient = params.patient;
        this.valueSetId = params.valueSetId;
        this.paramAllergy = params.allergy;
        this.displayInHeader = !!params.allergy?.displayInHeader;

        RuntimeInfo.IsLoading = true;
        try {
            await this.loadData(params.service, params.allergy);
        } catch (e) {
            console.warn(e);
            DialogMessages.Prompt(this.service, e.message || e, this.i18n.tr('error'), true);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    async attached() {
        const dlg = <HTMLDivElement>document.querySelector(".add-allergy-dialog");
        if (dlg) {
            dlg.style.width = `${Math.round(window.innerWidth * .8)}px`;
        }
    }

    //#endregion

    /** helper function for autocomplete in the search field */
    customLabel = concept => {
        if (typeof concept === 'string')
            return concept;

        return concept?.display || '';
    };

    /** Create the Allergy-List popup dialogue and display it */
    createAllergyList() {
        const dlg = document.querySelector(".add-allergy-dialog .ux-dialog-body");
        if (!dlg) return;

        if (this.listIsOpen) {
            const li = dlg.querySelector('.allergy-dropdown-list');
            if (li) {
                li.remove();
            }

            this.listIsOpen = false;
        } else {
            const listElement = document.createElement('ul');
            listElement.classList.add('allergy-dropdown-list');

            for (const concept of this.allergyConcepts) {
                const li = document.createElement('li');
                li.innerText = concept.display;
                li.addEventListener('click', () => {
                    this.search = concept.display;
                    this.selectedItem = concept;
                    this.selected = concept;

                    listElement.remove();
                    this.listIsOpen = false;

                    window.setTimeout(() => document.querySelector("auto-complete[name='allergyAutoComplete'] .dropdown.open")?.classList.remove('open'), 25);
                });

                listElement.appendChild(li);
            }

            dlg.appendChild(listElement);

            this.listIsOpen = true;
        }
    }

    /** Load all the needed CodeSystems and ValueSets */
    async loadData(service: FhirService, allergy: IAllergy) {
        //#region load valuesets and codesystems
        if (!allergyAdd.allergiesValueSet) {
            allergyAdd.allergiesValueSet = <any>(await service.get(`ValueSet/${this.valueSetId}`));
        }

        // clinical status (active / inactive)
        if (!allergyAdd.allergyintoleranceClinical) {
            allergyAdd.allergyintoleranceClinical = <any>(await service.get(`CodeSystem/allergyintolerance-clinical`));
        }

        // the verification status
        if (!allergyAdd.allergyintoleranceVerification) {
            allergyAdd.allergyintoleranceVerification = <any>(await service.get(`CodeSystem/allergyintolerance-verification`));
        }

        // the possible reactions
        if (!allergyAdd.allergyReactionsValueSet) {
            let reactionsVsId = "nursitreactions-vs";
            const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward);
            if (cfg) {
                const settings = ConfigService.GetFormSettings('allergy');
                if (settings?.settings?.reactionValueSetId) {
                    reactionsVsId = settings.settings.reactionValueSetId;
                } else {
                    console.warn('No setting for reactionValueSetId in settings found (route="allergy")');
                }
            } else {
                console.warn('Could not load config for allergies, route="allergy"');
            }

            try {
                allergyAdd.allergyReactionsValueSet = await service.get(`ValueSet/${reactionsVsId}`);
            }
            catch (e) {
                const jsonUrl = `config/${reactionsVsId}.json`;
                console.warn(`Could not load "ValueSet/${reactionsVsId}" from Fhir.
Ensure the ValueSet "${reactionsVsId}" exists or 
Check the config for route="allergy", settings -> reactionValueSetId.
Trying to fall back to json file "${jsonUrl}"`);
                const response = await (new HttpClient().get(jsonUrl));
                allergyAdd.allergyReactionsValueSet = JSON.parse(response.response);
            }            
        }

        // reaction severity (low, mild, severe)
        if (!allergyAdd.reactionEventSeverity)
            allergyAdd.reactionEventSeverity = <any>(await service.get('CodeSystem/reaction-event-severity'));

        // allergy criticality (low, high, unable-to-assess)
        if (!allergyAdd.allergyIntoleranceCriticality)
            allergyAdd.allergyIntoleranceCriticality = <any>(await service.get('CodeSystem/allergy-intolerance-criticality'));

        // allergy category
        if (!allergyAdd.allergyIntoleranceCategory)
            allergyAdd.allergyIntoleranceCategory = <any>(await service.get('CodeSystem/allergy-intolerance-category'));

        // allergy type (allergy / intolerance)
        if (!allergyAdd.allergyIntoleranceTypes)
            allergyAdd.allergyIntoleranceTypes = <any>(await service.get('CodeSystem/allergy-intolerance-type'));

        //#endregion

        //#region parse verification states
        if (this.allergyintoleranceVerification) {
            this.verificationStates = [];
            for (const concept of this.allergyintoleranceVerification.concept) {
                this.verificationStates.push(...this.conceptToComboItems(concept));
            }
        }
        //#endregion

        //#region parse clinical status
        if (this.allergyintoleranceClinical) {
            this.clinicalStatusCodes = [];
            for (const concept of this.allergyintoleranceClinical.concept)
                this.clinicalStatusCodes.push(...this.conceptToComboItems(concept));
        }
        //#endregion

        //#region parse allergyCategories
        if (this.allergyIntoleranceCategory) {
            this.allergyCategories = [];
            for (const concept of this.allergyIntoleranceCategory.concept)
                this.allergyCategories.push(...this.conceptToComboItems(concept));
        }
        //#endregion

        //#region parse allergy criticalitiy
        if (this.allergyIntoleranceCriticality) {
            this.criticalities = [];
            for (const concept of this.allergyIntoleranceCriticality.concept)
                this.criticalities.push(...this.conceptToComboItems(concept));
        }
        //#endregion

        //#region reactions
        if ((!this.reactions || this.reactions.length === 0) && allergyAdd.allergyReactionsValueSet) {
            const tmp = [];

            if (allergyAdd.allergyReactionsValueSet?.compose?.include) {
                for (const include of allergyAdd.allergyReactionsValueSet.compose.include.filter(o => o.concept)) {
                    for (const concept of include.concept.filter(o => o.code && o.display)) {
                        tmp.push({ code: concept.code, display: concept.display });
                    }
                }
            }

            this.reactions.push(...tmp);
        }
        //#endregion

        //#region parse reaction event severities
        if (this.reactionEventSeverity) {
            this.severities = [];
            for (const concept of allergyAdd.reactionEventSeverity.concept) {
                this.severities.push(...this.conceptToComboItems(concept));
            }
        }
        //#endregion

        //#region parse IntoleranceTypes
        for (const concept of this.allergyIntoleranceTypes?.concept)
            this.intoleranceTypes.push(...this.conceptToComboItems(concept));
        //#endregion

        //#region fill values for autocomplete
        // take from compose.include[] if exists
        if (this.allergies?.compose?.include) {
            for (const inc of this.allergies?.compose?.include.filter(o => o.concept)) {
                for (const conc of inc.concept) {
                    this.allergyConcepts.push({
                        code: conc.code,
                        display: conc.display,
                        system: this.allergies.url,
                        search: conc.code + conc.display.replace(/ /g, '').toUpperCase()
                    });
                }
            }
        }

        //take from expansion.contains[] node if exists
        if (this.allergies?.expansion?.contains) {
            for (const contain of this.allergies?.expansion?.contains?.filter(o => o.code)) {
                let allergyConcept = {
                    code: contain.code,
                    display: contain.display,
                    system: contain.system,
                    search: contain.code + contain.display.replace(/ /g, '').toUpperCase()
                };

                this.allergyConcepts.push(allergyConcept);
            }
        }
        //#endregion

        /*if (ConfigService.Debug) {
            console.debug("ValueSets and CS: ",
                allergyAdd.allergiesValueSet, allergyAdd.allergyintoleranceVerification, allergyAdd.allergyintoleranceClinical,
                allergyAdd.allergyIntoleranceCategory, allergyAdd.allergyIntoleranceCriticality, allergyAdd.reactionEventSeverity,
                allergyAdd.allergyReactionsValueSet
            );
        }*/

        if (allergy?.code || allergy?.text) { // when allergy.code or text exists, it is edit mode, else it is a new allergy
            this.isEditMode = true;
            this.editId = allergy.intoleranceResource.id;
            const found = this.allergyConcepts.find(o => o.code === allergy.code) || { display: allergy?.text };
            
            window.setTimeout(() => {
                this.selected = found;

                const resource = allergy.intoleranceResource;
                if (resource) {
                    // parse verificationStatus
                    if (resource.verificationStatus?.coding && resource.verificationStatus?.coding[0]?.code) {
                        const cnt = resource.verificationStatus?.coding.length - 1;
                        this.verificationState = this.verificationStates.find(o => o.code == resource.verificationStatus.coding[cnt].code);
                    }

                    // parse the allergy reaction
                    if (resource.reaction?.length > 0) {
                        const r = resource.reaction.find(o => o.severity);
                        if (r) {
                            this.severity = this.severities.find(o => o.code === r.severity);
                            if (r.manifestation?.length > 0) {
                                const m = r.manifestation.find(o => o.coding?.length > 0);
                                if (m) {
                                    const c = m.coding.find(o => o.code);
                                    if (c) {
                                        this.reaction = this.reactions.find(o => o.code === c.code);
                                        // as we do not know what exactly is the text of the reaction, trust the code and update the display
                                        if (this.reaction) {
                                            this.reaction.display = c.display;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // parse type of intolerance
                    if (resource.type) {
                        this.intoleranceType = this.intoleranceTypes.find(o => o.code === resource.type);
                    }

                    // parse the category
                    if (resource.category && resource.category[0]) {
                        const cnt = resource.category.length - 1;
                        this.allergyCategory = this.allergyCategories.find(o => o.code === resource.category[cnt]);
                    }

                    // parse the clinicalStatus
                    if (resource.clinicalStatus?.coding && resource.clinicalStatus?.coding[0]?.code) {
                        const cnt = resource.clinicalStatus?.coding.length - 1;
                        this.clinicalStatus = this.clinicalStatusCodes.find(o => o.code == resource.clinicalStatus?.coding[cnt]?.code);
                    }

                    // parse the criticality, including Dräger custom values
                    if (resource.criticality) {
                        this.criticality = this.criticalities.find(o => o.code == resource.criticality);

                        const _crit = resource._criticality?.extension?.find(e => e.url.endsWith('DetailedCriticality'));
                        if (_crit) {
                            this.isIcmAllergyCriticality = true;
                            this.icmAllergyCriticality = this.criticality?.display;

                            if (_crit?.valueString || _crit?.valueCode) {
                                const icmCriticality = this.criticalities.find(o => o.code == _crit.valueString || _crit?.valueCode);
                                if (icmCriticality) {
                                    this.icmAllergyCriticality = icmCriticality.display;
                                } else {
                                    this.icmAllergyCriticality = _crit.valueString;
                                }
                            } else if (_crit?.valueCoding?.display) {
                                this.icmAllergyCriticality = _crit.valueCoding.display;
                            }

                            // try to get the display value for the Dräger coding from i18n
                            if (this.icmAllergyCriticality) {
                                const translationId = `icm_criticality_${this.icmAllergyCriticality}`;
                                const translated = this.i18n.tr(translationId);
                                if (translated != translationId) // because i18n gives back the input string compare it to the given id
                                    this.icmAllergyCriticality = translated; // and when differs something should have been found
                            }
                        }
                    }

                    if (resource.note) {
                        this.note = resource.note.filter(o => o.text).map(o => o.text).join('\n');
                    }
                }

            }, 100);
        }
    }

    selectedChanged() {
        if (this.selected) {
            this.selectedItem = { code: this.selected.code, display: this.selected.display, system: this.selected.system };
            document.querySelector("auto-complete[name='allergyAutoComplete'] .dropdown.open")?.classList.remove('open');
        }
    }

    searchChanged() {
        this.selected = undefined;
    }

    /** Clear the AutoComplete Text */
    clearSearch() {
        this.selected = undefined;
        this.search = '';
        document.body.focus();
        window.setTimeout(() => (<HTMLInputElement>document.querySelector("input[name='allergyAutoComplete']"))?.focus(), 100);
    }

    /** converts a concept and it's sub-concepts into a simple list for combo boxes */
    conceptToComboItems(concept, parent?): IAllergyComboItem[] {
        const result: IAllergyComboItem[] = [];
        if (concept?.code && concept?.display && concept?.code !== "entered-in-error")
            result.push({ code: concept.code, display: this.i18n.tr(concept.display), parent: parent });

        if (concept?.concept) {
            for (const sub of concept.concept)
                result.push(...this.conceptToComboItems(sub, concept));
        }

        return result;
    }

    /** is executed when the user clicks on the save-button. Stores the allergy on fhir */
    async submit() {
        if (!this.selectedItem || this.selectedItem?.display != this.search) {
            if (this.search) {
                await DialogMessages.Dialog(this.service, this.i18n.tr('allergy_confirm_freetext_item'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('abort'), true)
                    .whenClosed(result => {
                        if (result.wasCancelled) return;
                        this.selectedItem = {
                            display: this.search
                        };

                        this.submit();
                    })

                return;
            } else if (!this.isEditMode) {  // when in editmode the selectedItem should be fine
                DialogMessages.Prompt(this.service, this.i18n.tr('no_allergy_selected'), this.i18n.tr('information'), true);
                return;
            }
        }

        if (!this.isEditMode && this.selectedItem?.code && this.patient?.allergies?.find(o => o.code === this.selectedItem?.code)) {
            DialogMessages.Prompt(this.service, this.i18n.tr("allergy_already_documented", { title: this.selectedItem.display }), this.i18n.tr('information'), true);
            return;
        }

        allergyMain.popupShowing = false;

        // flatten ClinicalStats (cs) to be any:
        let cs = NitTools.Clone(this.clinicalStatus);
        if (cs?.parent) {
            cs.code = cs.parent.code;
            cs.display = `${cs.parent.display} (${cs.display})`;
        }

        let result = {
            type: this.intoleranceType.code,
            resourceType: "AllergyIntolerance",
            patient: {
                reference: `Patient/${this.patient.id}`
            },
            id: this.isEditMode ? this.editId : NitTools.Uid(),
            code: {
                coding: [
                    {
                        display: this.selectedItem.display,
                        system: undefined,
                        code: undefined
                    }
                ],
                text: this.selectedItem.display,
            },
            recorder: `Practitioner/${UserService.Practitioner?.id}`,
            
            extension: [{
                url: "http://nursit.institute.com/source-codesystem-id",
                valueString: this.valueSetId
            }]
        };

        if (this.selectedItem?.code) {
            result.code.coding[0]["code"] = this.selectedItem.code;

            if (this.selectedItem?.system) {
                result.code.coding[0]["system"] = this.selectedItem.system || this.allergies.url;
            }

            if (this.displayInHeader) {
                result.code.coding.push({
                    system: "http://nursit-institute.de/StructureDefinition/cave/display-in-title",
                    code: "visible-in-header",
                    display: "Visible in Patient-Header"
                })
            }
        }

        if (this.displayInHeader && result.code?.coding) {
                result.code.coding.push({
                    system: "http://nursit-institute.de/StructureDefinition/cave/display-in-title",
                    code: "visible-in-header",
                    display: "Visible in Patient-Header"
                })
        }

        if (this.note) {
            result["note"] = [{ text: this.note }];
        }

        if (this.verificationState?.code) {
            let vs = NitTools.Clone(this.verificationState);
            if (vs?.parent) {
                vs.code = vs.parent.code;
                vs.display = `${vs.parent.display} (${vs.display})`;
            }
            if (vs?.code)
                result["verificationStatus"] = vs.code;
        }

        if (this.intoleranceType?.display && this.selectedItem?.display && !String(this.selectedItem?.display).startsWith(this.intoleranceType?.display)) {
            result.code.text = `${this.intoleranceType.display} - ${this.selectedItem.display}`;
        }

        if (this.criticality?.code) {
            result["criticality"] = this.criticality.code;
        }

        if (cs) {
            result["clinicalStatus"] = cs.code;
        }

        if (this.allergyCategory?.code) {
            result["category"] = [
                this.allergyCategory.code
            ];
        }

        if (this.reaction?.code) {
            const _reaction = {
                manifestation: [
                    {
                        coding: [
                            {
                                code: this.reaction.code,
                                display: this.reaction.display
                            }
                        ],
                        text: this.reaction.display
                    }
                ],
                description: this.reaction.display
            };

            if (this.severity?.code) {
                // @ts-ignore
                _reaction["severity"] = this.severity.code;
            }

            if (this.selectedItem?.code) {          
                _reaction["substance"] = {
                    coding: [
                        {
                            code: this.selectedItem.code,
                            display: this.selectedItem.display
                        }
                    ],
                    text: this.selectedItem.display
                };
            }

            result["reaction"] = [_reaction];
        } else {
            delete result["reaction"];
        }

        if (FhirService.FhirVersion > 3) {
            // make adjustments for R4 Version:

            result["recordedDate"] = moment(new Date()).toJSON();
            // difference between V3->4 clinicalStatus went from coding to codeableConcept
            result["clinicalStatus"] = { coding: [] };
            if (this.clinicalStatus.parent) {
                result["clinicalStatus"].coding.push({
                    system: this.allergyintoleranceClinical.url,
                    code: this.clinicalStatus.parent.code,
                    display: this.clinicalStatus.parent.display
                });

                result["clinicalStatus"].text = `${this.clinicalStatus.parent.display} (${this.clinicalStatus.display})`;
            } else {
                result["clinicalStatus"].text = this.clinicalStatus.display;
            }

            result["clinicalStatus"].coding.push({
                system: this.allergyintoleranceClinical.url,
                code: this.clinicalStatus.code,
                display: this.clinicalStatus.display
            });

            // difference between V3->4 verificationStatus went from coding to codeableConcept
            result["verificationStatus"] = {
                coding: []
            };

            if (this.verificationState.parent) {
                result["verificationStatus"].coding.push({
                    system: this.allergyintoleranceVerification.url,
                    code: this.verificationState.parent.code,
                    display: this.verificationState.parent.display
                });

                result["verificationStatus"].text = `${this.verificationState.parent.display} (${this.verificationState.display})`;
            } else {
                result["verificationStatus"].text = this.verificationState.display;
            }

            result["verificationStatus"].coding.push({
                system: this.allergyintoleranceVerification.url,
                code: this.verificationState.code,
                display: this.verificationState.display
            });
        } else {
            // R3 Version
            result["assertedDate"] = moment(new Date()).toJSON();
        }

        await this.controller.ok(result);
    }

    async cancel() {
        allergyMain.popupShowing = false;
        await this.controller.cancel();
    }
}

export interface IAllergyComboItem {
    code: string;
    display: string;

    parent?: any;
}
