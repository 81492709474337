import {bindable} from "aurelia-framework";
import {NitTools} from "../../classes/NursitTools";
import {qBase} from "./q-base";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {QuestionnaireResponse} from "../../classes/FhirModules/Fhir";
import {ConfigService} from "../../services/ConfigService";
import {fhirEnums} from "../../classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;


export class qMultiSelect extends qBase {
    @bindable item;
    @bindable response;
    @bindable previousresponse: any;
    @bindable changed: Function;
    @bindable readonly;

    options: MultiSelectOption[];
    responseItem: any = undefined;

    itemChanged(newVal) {
        if (!newVal) {
            this.options = [];
            return;
        }

        this.createOptions();
        this.getFormItems();
    }

    responseChanged(newVal) {
        if (!newVal) {
            this.responseItem = undefined;
            return;
        }

        this.getFormItems();
        this.updateCheckboxesFromResponse();
    }

    getFormItems() {
        if (this.item?.linkId && this.response) {
            this.responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, this.item.linkId);
        }
    }

    updateCheckboxesFromResponse() {
        if (this.options) {
            for (const option of this.options) {
                option.isChecked = false;
            }
        }

        if (!NitTools.IsArray(this.responseItem?.answer)) return;

        let fixAnswers = false;
        for (const item of this.responseItem.answer.filter(o=>o.valueCoding?.code)) {
            let codes = [];
            if (item.valueCoding.code.indexOf(',') > -1) {
                codes = item.valueCoding.code.split(',');
            } else {
                codes = [item.valueCoding.code];
            }

            for (const code of codes) {
                const option = this.options.find(o => o.value == code);
                if (option) {
                    option.isChecked = true;
                } else {
                    console.warn(`option not found for value ${code}`);
                }
            }

            if (!fixAnswers) fixAnswers = codes.length > 1;
        }

        // check if the selected option is exclusive. Have to do it here explicit again (although it sucks, I know):
        const exclusiveOption = this.options.find(o=>o.exclusive && o.isChecked);
        if (exclusiveOption) {
            // disable and uncheck all other options if exclusiveOption has been found
            for (const option of this.options.filter(o=> o.value != exclusiveOption.value)) {
                option.isChecked = false;
                option.isEnabled = false;
            }
        }

        if (fixAnswers) {
            this.responseItem.answer = [];
            for (const option of this.options.filter(o=>o.isChecked)) {
                this.responseItem.answer.push({
                    valueCoding: {
                        code: option.value,
                        display: option.text
                    }
                })
            }

            if (ConfigService.Debug) {
                console.warn(`FIXES ANSWERS in RESPONSE-ITEM!`, this.responseItem);
            }
        }
    }

    createOptions() {
        const optionsSource = this.item?.answerOption || this.item?.option;
        if (!NitTools.IsArray(optionsSource)) return;

        this.options = [];

        for (const item of optionsSource.filter(o => o.valueCoding?.code)) {
            let isHidden: boolean = false;
            let isExclusive: boolean = false;
            if (item.extension) {
                let hiddenExt: any = item.extension.find(o => o.url.endsWith('questionnaire-hidden'));
                if (hiddenExt) {
                    isHidden = hiddenExt.valueBoolean;
                }

                let exclusiveExt: any = item.extension.find(o => o.url.endsWith('questionnaire-optionExclusive'));
                if (exclusiveExt) {
                    isExclusive = exclusiveExt.valueBoolean;
                }
            }

            let newOption: MultiSelectOption = new MultiSelectOption();
            newOption.value = item.valueCoding.code;
            newOption.text = item.valueCoding.display || "";
            newOption.hint = item.valueCoding.display || "";
            newOption.hidden = isHidden;
            newOption.exclusive = isExclusive;
            newOption.isEnabled = !this.readonly;
            newOption.controlId = NitTools.UidName();
            newOption.isChecked = false;

            if (newOption.text.indexOf('|') > -1) {
                let arr = newOption.text.split('|');
                newOption.text = arr[0];
                newOption.hint = arr[1];
            }

            this.options.push(newOption);
        }
    }

    toggleOption(option : MultiSelectOption   ) {
        if (!option || !option.isEnabled || this.readonly) return;
        option.isChecked = !option.isChecked;

        if (option.exclusive) {
            for (const other of this.options.filter(o => o.value != option.value)) {
                if (option.isChecked) {
                    other.isChecked = false;
                    other.isEnabled = false;
                } else {
                    other.isEnabled = true;
                }
            }
        }

        if (!this.responseItem) return;
        this.responseItem.answer = [];

        for (const option of this.options.filter(o=> o.isChecked)) {
            this.responseItem.answer.push({
                valueCoding: {
                    code: option.value,
                    display: option.text
                }
            });
        }

        if (typeof this.changed === 'function' && this.responseItem) {
            this.changed(this.responseItem)
        }
    }

    /*
    onResponseItemValueChangedFromBehindChanged() {
    } */
}


export class MultiSelectOption {
    controlId?: string;
    value: any;
    text: string;
    hint?: string;
    hidden?: boolean;
    exclusive: boolean;
    isEnabled: boolean;
    isChecked?: boolean;
}
