import {FhirService} from "../../../resources/services/FhirService";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {DialogMessages} from "../../../resources/services/DialogMessages";
import {SmileService} from "../../../resources/services/SmileService";
import {autoinject, bindable} from "aurelia-framework";
import {NitTools} from "../../../resources/classes/NursitTools";
import {HumanName} from "../../../resources/classes/HumanName";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {PractitionerItem} from "./practitioner-item";
import {DialogService} from "aurelia-dialog";
import {PractitionerDialogAdd} from "./practitioner-dialog-add";
import {PractitionerDialogCsv} from "./practitioner-dialog-csv";

@autoinject
export class PractitionerList {
    protected smileService: SmileService;
    protected practitioners : PractitionerItem[];
    protected filteredPractitioners : PractitionerItem[];
    @bindable searchText : string;

    /** Creates a new instance of the PractitionerList class with autoinjection */
    constructor(protected dialogMessages: DialogMessages, protected dialogService: DialogService, protected i18n: I18N, protected fhirService: FhirService) {
        this.smileService = new SmileService();
    }

    async loadPractitioners() {
        const tmp : fhir4.Practitioner[] = await this.fhirService.fetch("Practitioner?_count=200&_sort=name");
        for (const prac of tmp) {
            // exclude Smile Users
            if (typeof prac.identifier?.find(o=>o.system?.endsWith("smile-account-id")) !== "undefined") {
                continue;
            }

            const item = this.practitionerItemFromPractitioner(prac);
            if (item?.id && item?.html) {
                this.practitioners.push(item);
            }
        }

        this.filteredPractitioners = this.practitioners;
    }

    /** Is called the view is attached, loads the existing practitioners from fhir Fhir-Server */
    async attached() {
        this.practitioners = this.filteredPractitioners = [];
        await this.loadPractitioners();
    }

    /** uses string.split() to convert a string into an array but adds trimming and ignores empty entries */
    splitStringToArrayByComma(inputString : string, divider: string = ',') : string[] {
        if (!inputString?.trim()) return undefined;

        const sa = [];
        for (const part of inputString.split(divider)) {
            if (!part?.trim()) continue;
            sa.push(part.trim());
        }

        return sa;
    }

    /** Creates a display summary string for the given practitioner */
    getDisplay(fhirPractitioner : fhir4.Practitioner) {
        let name = '';
        if (fhirPractitioner.name?.[0]) {
            if (fhirPractitioner.name[0].prefix) {
                let prefixes = [];
                for (const prfx of fhirPractitioner.name[0].prefix) {
                    prefixes.push(this.i18n.tr(prfx));
                }

                name += prefixes.join(' ');
            }

            name += ' <b>';
            name += fhirPractitioner.name[0].family || '???';
            if (NitTools.IsArray(fhirPractitioner.name[0].given)) {
                name += ', ' + fhirPractitioner.name[0].given.join(',');
            }
            name += '</b>';

            if (fhirPractitioner.name[0].suffix)
                name += ' ' + fhirPractitioner.name[0].suffix.join('\n');
        }

        return (name||'Ohne Namen').trim();
    }

    /** Creates a dummy practitioner and opens the edit dialogue */
    newPractitioner() {
        const pr : fhir4.Practitioner = {
            id: NitTools.Uid(),
            resourceType: 'Practitioner',
            name: [
                {
                    use: 'official',
                    family: 'family',
                    given: ['first'],
                    prefix: [],
                    suffix: []
                }
            ],
            address: [{ }],
            active: true
        }

        const item = this.practitionerItemFromPractitioner(pr);

        this.dialogService.open({
            viewModel: PractitionerDialogAdd,
            model: item,
            lock: true
        })
            .whenClosed(result => {
                if (result.wasCancelled) return;
                console.warn(result.output);

                const saved = this.updatePractitioner(result.output);
                if (saved) {
                    this.practitioners.push(result.output);
                }
            })
    }

    /** Tries to delete a practitioner from the Fhir-Server */
    deletePractitioner(pracItem : PractitionerItem) {
        this.dialogMessages.dialog(`Really delete Practitioner ${pracItem.html}?`, this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true)
            .whenClosed(async result => {
                if (result.wasCancelled) return;
                try {
                    await this.fhirService.delete(pracItem.practitioner);
                    this.filteredPractitioners = this.filteredPractitioners.filter(practitioner => practitioner.id !== pracItem.id);
                }
                catch (error) {
                    console.error(error);
                    this.dialogMessages.prompt('Error deleting Practitioner: ' + error, this.i18n.tr('error'), true);
                }
            })
    }

    /** Updates the local fhir4.Practitioner which is used in the pracItem.practitioner-property and writes it back to the Fhir-Server */
    async updatePractitioner(pracItem : PractitionerItem) : Promise<boolean> {
        let hasError = false;
        try {
            if (!NitTools.IsArray(pracItem.practitioner.address)) pracItem.practitioner.address = [];
            pracItem.isSaving = true;
            pracItem.buttonText = 'Resource Updated';

            const adr: fhir4.Address = {
                type: 'both',
                line: NitTools.SplitStringToArrayByComma(pracItem.adrLine, '\n'),
                city: pracItem.adrCity,
                district: pracItem.adrDistrict,
                state: pracItem.adrState,
                postalCode: pracItem.adrPostalCode,
                country: pracItem.adrCountry
            }

            if (!pracItem.practitioner.name) pracItem.practitioner.name = [];
            if (!pracItem.practitioner.name[0]) pracItem.practitioner.name.push({ });

            if (pracItem.practitioner.name[0]) {
                pracItem.practitioner.name[0].family = pracItem.nameFamily;
                pracItem.practitioner.name[0].given = this.splitStringToArrayByComma(pracItem.nameGiven, ',')
                pracItem.practitioner.name[0].prefix = this.splitStringToArrayByComma(pracItem.nameTitle, ',');
                pracItem.practitioner.name[0].suffix = this.splitStringToArrayByComma(pracItem.nameSuffix, ',');
            }

            if (!pracItem.practitioner.address) pracItem.practitioner.address = [];
            if (!pracItem.practitioner.address[0])
                pracItem.practitioner.address.push(adr);
            else
                pracItem.practitioner.address[0] = adr;

            if (!NitTools.IsArray(pracItem.practitioner.identifier)) pracItem.practitioner.identifier = [];
            let writerIdentifier = pracItem.practitioner.identifier.find(o=>o.system?.endsWith("/carewriter-receiver"));
            if (!writerIdentifier) {
                writerIdentifier = {
                    "system": "http://nursit-institute.com/carewriter-receiver"
                }

                pracItem.practitioner.identifier.push(writerIdentifier);
            }

            writerIdentifier.value = pracItem.receiver;

            pracItem.html = this.getDisplay(pracItem.practitioner);
            if (pracItem.practitioner.name) {
                pracItem.practitioner.text = {
                    status: 'generated',
                    div: HumanName.GetDiv(pracItem.practitioner.name, this.i18n)
                };
            } else {
                delete pracItem.practitioner.text;
            }

            await this.fhirService.update(pracItem.practitioner);
        }
        catch (error) {
            console.warn(error);
            await this.dialogMessages.prompt('Error when saving Practitioner, look at the console and network tab for details', this.i18n.tr('error'), true);
            pracItem.isSaving = false;
            pracItem.buttonText = "ERROR!";
            hasError = true;
        }
        finally {
            RuntimeInfo.IsLoading = false;
            if (pracItem.isSaving) {
                window.setTimeout(() => {
                    pracItem.isSaving = false;
                    window.setTimeout(() => pracItem.buttonText = this.i18n.tr('save'), 1000);
                }, 1000);
            }
        }

        return !hasError;
    }

    /** Generates a PractitionerItem from a fhir4.Practitioner-Resource */
    practitionerItemFromPractitioner (fhirPractitioner : fhir4.Practitioner): PractitionerItem {
        if (!fhirPractitioner) return undefined;

        // ensure a neat text.div - just to be sure
        if (!fhirPractitioner.text?.div && fhirPractitioner.name) {
            if (!NitTools.IsArray(fhirPractitioner.name) || fhirPractitioner.name.length === 0) {
                fhirPractitioner.name = [
                    {
                        family: 'Unnamed',
                        given: ['Ohne']
                    }
                ]
            }

            let html = HumanName.GetText(fhirPractitioner.name, this.i18n);
            if (!fhirPractitioner.text) {
                fhirPractitioner.text = {
                    div: html,
                    status: 'generated'
                }
            } else {
                fhirPractitioner.text.div = html;
            }
        }

        // get the receiver identifier
        let currentReceiver : string = '';
        const writerIdentifier = fhirPractitioner.identifier?.find(o=>o.system?.endsWith("/carewriter-receiver"));
        if (writerIdentifier?.value) {
            currentReceiver = writerIdentifier.value;
        }

        // ensure an existing address array
        if (!fhirPractitioner.address) fhirPractitioner.address = [];
        if (!fhirPractitioner.address[0]) {
            fhirPractitioner.address.push({})
        }

        // construct the display
        const display = this.getDisplay(fhirPractitioner);
        if (!display) return undefined;

        return {
            nameTitle: fhirPractitioner.name?.[0]?.prefix?.join(','),
            nameSuffix: fhirPractitioner.name?.[0]?.suffix?.join(','),
            id: fhirPractitioner.id,
            html: display,
            practitioner: fhirPractitioner,
            receiver: currentReceiver,
            adrCity: fhirPractitioner.address[0].city,
            adrCountry: fhirPractitioner.address[0].country,
            adrDistrict: fhirPractitioner.address[0].district,
            adrLine: fhirPractitioner.address[0].line?.join('\n'),
            adrPostalCode: fhirPractitioner.address[0].postalCode,
            adrType: 'both',
            adrText: fhirPractitioner.address[0].text,
            adrState: fhirPractitioner.address[0].state,
            nameGiven: fhirPractitioner.name?.[0]?.given?.join(','),
            nameFamily: fhirPractitioner.name?.[0]?.family,
            buttonText: this.i18n.tr('save')
        }
    }

    importCsvFile() {
        this.dialogService.open({
                viewModel: PractitionerDialogCsv,
                lock: true
            })
                .whenClosed(async result => {
                    if (result.wasCancelled || !NitTools.IsArray(result.output)) return;

                    let importedCount = 0;
                    for (const practitioner of result.output) {
                        const item = this.practitionerItemFromPractitioner(practitioner);
                        if (!item) continue;
                        const saveResult = await this.updatePractitioner(item);

                        if (saveResult) {
                            importedCount++;
                            this.filteredPractitioners.push(item);
                        }
                    }

                    await this.dialogMessages.prompt(`${importedCount} Practitioners have been imported`);
                })
    }
}
