import {inject, bindable} from 'aurelia-framework';
import {DialogController, DialogService} from 'aurelia-dialog';
import {ConfigService} from "../services/ConfigService";
import { Questionnaire } from './questionnaire/questionnaire';
import { UserService } from 'resources/services/UserService';
import { RuntimeInfo } from 'resources/classes/RuntimeInfo';
import { NitTools } from 'resources/classes/NursitTools';
import { fhirEnums } from 'resources/classes/fhir-enums';
import { PatientItem } from 'resources/classes/Patient/PatientItem';
import { PatientService } from 'resources/services/PatientService';
import { ReportService } from 'resources/services/ReportService';
import { IFormSetting } from 'resources/classes/IFormSettings';
import { I18N } from 'aurelia-i18n';
import { DialogMessages } from 'resources/services/DialogMessages';

@inject(I18N, UserService, DialogController, DialogService, Element)

export class ModalQRenderer {
    i18n: I18N;
    userService: UserService;
    controller: DialogController;
    dialogService: DialogService;
    iframeContainer;
    iframeUrl;
    element;
    tokenUpdateListenerRemove;
    iframeListener;
    iframeOrigin;
    questionnaire;
    response
    responseBackup;
    saveText: string = undefined;
    abortText: string = undefined;
    status: string;
    advancedSaveButton: boolean = false;
    forcedReadonly: boolean = false;
    readonly: boolean = false;
    patient;
    encounter;
    preFieldCalculationFunction;
    setting: IFormSetting = undefined;
    report: string = undefined;
    questionnaireTitle: string = undefined;
    showSaveButton : boolean = true;
    /** Gets or sets the name of the report to print */
    reportName: string = undefined;
    /** Gets or sets the message to be displayed in the bound title property of the print-button */
    reportError : string = '';
    /** Gets or sets a value indicating whether printing a report is possible at all (like report has been found) */
    reportPossible : boolean = false;
    /** Gets or sets a value indicating whether the print-button is enabled. Combines from reportPossible and response status */
    reportEnabled : boolean = false;

    constructor(i18n: I18N, userService: UserService, dialogController: DialogController, dialogService: DialogService, element: Element) {
        this.i18n = i18n;
        this.userService = userService;
        this.controller = dialogController;
        this.dialogService = dialogService;
        this.element = element;

        this.iframeListener = async (event) => {
                    if (event.origin === this.iframeOrigin) {
                        const data = event.data.data;
        
                        switch (event.data.name) {
                            case 'ready': {
                                this.iframeContainer.contentWindow.postMessage({
                                    name: 'feed-data',
                                    data: {
                                        questionnaire: JSON.stringify(this.questionnaire),
                                        questionnaireResponse: JSON.stringify(this.response)
                                    }
                                }, this.iframeUrl);
                            }
                            case 'data': {
                                try {
                                    this.response = JSON.parse(data);
                                } catch {}
                                break;
                            }
                        }
                    }
                };
    }

    async activate(settings) {
        this.encounter = settings.encounter;
        this.patient = settings.patient;
        this.questionnaire = settings.questionnaire;
        this.response = settings.response;
        this.responseBackup = NitTools.Clone(settings.response ? settings.response.item : undefined);
        this.saveText = settings.saveText;
        this.abortText = settings.abortText;
        this.status = settings.status;
        this.advancedSaveButton = settings.advancedSaveButton;
        this.preFieldCalculationFunction = settings.preFieldCalculationFunction;
        this.questionnaireTitle = settings.questionnaire.title || settings.questionnaire.name;

        if (typeof settings.forcedReadonly === 'boolean') {
            this.forcedReadonly = settings.forcedReadonly;
        }

        if (UserService.IsReadonly) {
            this.forcedReadonly = true;
        }

        if (settings.needsEditButton && !this.forcedReadonly && !settings.forcedReadonly && this.response?.status != 'in-progress') {
            this.readonly = true;
        }

        if (!settings?.questionnaire?.name) return;
        const questionnaireName = settings.questionnaire?.name?.toUpperCase();

        // try to get the configuration for the selected questionnaire by name or route
        const config = ConfigService.cfg?.forms?.find(o => o.questionnaireName?.toUpperCase() === questionnaireName || o.route.toUpperCase() === questionnaireName);

        // either use the configured report.name or the questionnairename as report name
        this.reportName = String(config?.report?.name || settings.questionnaire.name).toUpperCase();

        // ReportServer returns just the filenames, which always have the frx extension. So add it to our reportName if not exists
        if (this.reportName.indexOf('.FRX') === -1)
            this.reportName += '.FRX';

        const reports = await ReportService.Fetch();
        if (!NitTools.IsArray(reports?.items) || reports.items.length === 0) return;

        // check if the estimated reportName exists in the reports the Server knows
        if (typeof reports.items.find(o=>String(o).toUpperCase() === this.reportName) !== "undefined") {
            this.reportError = this.i18n.tr("print");
            this.reportPossible = true;
        } else {
            this.reportError = `No suitable Report found for this Questionnaire`;
            this.reportPossible = false;
        }


        window.addEventListener("message", this.iframeListener);
    }

    async attached() {
            const query = {
                origin: window.location.origin,
                lang: RuntimeInfo.Language
            };

            this.showSaveButton = !UserService.IsReadonly;

            this.iframeUrl = `${RuntimeInfo.Features.qRenderer.url}?` + Object.keys(query).map((key) => {
                return `${key}=${encodeURIComponent(query[key])}`;
            }).join('&');
            this.iframeOrigin = this.iframeUrl ? this.iframeUrl.match(/^https?\:\/\/([^\/?#]+)/i)[0] : '';
        
        this.element.style.width = '100%';
    }

    detached() {
        window.removeEventListener("message", this.iframeListener);
    }

    async abortButtonClicked() {
        this.close(true);
    }

    close(canceled: boolean) {
        let dialogController = this.dialogService.controllers[0];
        if (!dialogController) {
            this.dialogService.closeAll();
        } else {
            if (canceled) {
                this.response.item = NitTools.Clone(this.responseBackup);
                dialogController.cancel(this.response);
            } else {
                dialogController.ok(this.response);
            }
        }
    }

    saveButtonClicked(status = undefined, closeForm : boolean = true) {
        if (this.readonly && closeForm) {
            this.close(true);
            return;
        }

        if (status) {
            // don't allow to fall back to in-progress when already finalized
            if (status === 'in-progress' && ['completed', 'amended'].indexOf(this.response.status) > 1) {
                console.warn(`Could not fall back from status "${this.response.status}" to ${status}! Aborting save`);
                return;
            }

            // when response is already in completed status set it to amended
            if (status === 'completed' && this.response.status === 'completed')
                this.response.status = 'amended';
            else 
                this.response.status = status;

            console.warn(`Saving in state "${this.response.status}"`);
        } else {
            // head over to the next questionnaire status.
            // in-progress -> completed
            // completed -> amended
            // anything other -> completed
            switch (this.response?.status) {
                case fhirEnums.QuestionnaireResponseStatus.inProgress:
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                    break;
                case fhirEnums.QuestionnaireResponseStatus.completed:
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                    break;
                default:
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
            }
        }

        // the Fhir-Server should update this himself, but if not be on the save side
        if (this.response?.meta) {
            this.response.meta.lastUpdated = new Date().toJSON();
        }

        // check for autosave a report to the server        
        this.checkAutoSave();

        PatientService.AddQuestionnaireResponse(this.patient, this.response, true);
        if (closeForm)
            this.close(false);
    }

    async printButtonClicked(isMultiPrint?: boolean, openPfd?: boolean) {
        // Don't use: await super.printButtonClicked(isMultiPrint, openPfd);

        if (!this.reportPossible) {
            DialogMessages.Prompt(this.dialogService, "Es wurde kein passender Report gefunden", this.i18n.tr("information"), true);
            this.setPrintButtonStatus(false);
            return;
        }

        ReportService.Preview(this.response.id, this.reportName, undefined, !!openPfd);
    }

    setPrintButtonStatus(enabled: boolean) {
        const buttons = document.querySelectorAll(`[data-context="print"]`);
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            if (enabled) {
                button.removeAttribute("disabled");
            } else {
                button.setAttribute("disabled", "disabled");
            }
        }
    }

    private checkAutoSave() {
        try {
            if (!this.response || !this.questionnaire) {
                console.warn("No Response or no Questionnaire", this.response, this.questionnaire);

                return;
            }
            
            if (!this.setting && this.questionnaire.name)
                this.setting = ConfigService.GetFormSettings(this.questionnaire?.name);

            if (!this.setting)
                this.setting = ConfigService.cfg?.forms.find(o => o.questionnaireName && o.questionnaireName.toUpperCase() === this.questionnaire.name.toUpperCase());

            if (!this.setting) {
                console.warn(`Could not find any route that has the questionnaireName property set to "${this.questionnaire.name}"`);

                return;
            }

            const statusOk = ['amended', 'completed'].indexOf(this.response.status) > -1;
            const arrayOk = NitTools.IsArray(this.setting?.report?.autoSaveReportName);

            if (statusOk && arrayOk) {
                for (const name of this.setting?.report?.autoSaveReportName) {
                    const printSettings = this.getPrintSettings();
                    if (!printSettings.valid || printSettings.reportName !== name) {
                        if (ConfigService.Debug) {
                            console.info(`Skipping autoSave-Report with name "${printSettings.reportName}"`);
                            debugger;
                        }

                        continue;
                    }

                    ReportService.SendAutoSave(this.response.id, name, this.patient, printSettings.bodyPart)
                        .catch(e => console.warn(e));
                }
            } else {
                if (ConfigService.Debug) {
                    console.info(`No autosave triggered`);
                }
            }
        }
        catch (ex) {
            console.warn(ex);
        }
    }

    
    /** get the current print settings for this questionnaire */
    getPrintSettings(): { valid: boolean, reportName: string, bodyPart: string } {
        const result = {
            valid: true,
            reportName: undefined,
            bodyPart: undefined
        };

        if (!this.response || !ReportService.ReportServer) {
            result.valid = false;
            return;
        }

        result.reportName = this.ensureReportName();

        result.valid = !!result.reportName;

        return result;
    }

    ensureReportName(): string {
        if (!this.setting)
            this.setting = ConfigService.cfg?.forms?.find(o => o.questionnaireName === this.questionnaire?.name);

        if (this.setting) {
            let s = this.setting?.report?.name;
            if (s)
                this.report = s;
        }

        return this.report;
    }
}
