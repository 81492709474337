import {PractitionerItem} from "./practitioner-item";
import {autoinject, bindable} from "aurelia-framework";
import {DialogMessages} from "../../../resources/services/DialogMessages";
import {I18N} from "aurelia-i18n";
import {FhirService} from "../../../resources/services/FhirService";

@autoinject
export class PractitionerEdit {
    @bindable practitioner:  PractitionerItem;
}
