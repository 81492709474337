// noinspection ReturnInsideFinallyBlockJS

import * as environment from "../../../../config/environment.json";
import { HttpClient, HttpResponseMessage } from "aurelia-http-client";
import { fhirEnums } from "../fhir-enums";
import { QuestionnaireService } from "../../services/QuestionnaireService";
import { translations } from "../translations";
import { Tools } from "./Tools";
import { QuestionnaireResponse } from "./QuestionnaireResponse";
import { FhirService } from "../../services/FhirService";
import { UserService } from "../../services/UserService";
import { NitTools } from "../NursitTools";
import { ConfigService } from "../../services/ConfigService";
import BundleType = fhirEnums.BundleType;

export class Rest {
    private static _hash = '';

    private static transformUrl(url: string): string {
        let orig = url;
        try {
            if (url.indexOf('?') > -1 && url.indexOf('&') > -1) {
                let parts1 = url.split('?');
                let queryParts = parts1[1].split('&');
                for (let i = 0; i < queryParts.length; i++) {
                    let query = queryParts[i].split('=');
                    if (query[1]) {
                        query[1] = encodeURIComponent(query[1]);
                        queryParts[i] = query.join('=');
                    }
                }

                let urlQuery = queryParts.join('&');
                url = `${parts1[0]}?${urlQuery}`;
            }

            if (url.indexOf("://") === -1) {
                let ep = FhirService.Endpoint;
                if (ep.endsWith('/') || url.indexOf('/') === 0) {
                    url = FhirService.Endpoint + url;
                } else {
                    url = FhirService.Endpoint + '/' + url;
                }
            }

            return url;
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
            return orig;
        }
    }

    public static async TestServer() {
        let client = this.GetClient();
        try {
            await client.createRequest(FhirService.Endpoint).asOptions().send();

            return true;
        } catch (error) {
            return error.statusCode > 0 && error.statusCode < 500;
        }
    }

    public static Hash: string;

    /** returns a new httpclient */
    public static GetClient(hashString?: string, baseUrl?: string): HttpClient {
        let client = new HttpClient();
        // if (!this._hash)
        this._hash = hashString || sessionStorage.getItem(environment.sessionName);

        client.configure((x) => {
            x.withHeader('Accept', 'application/fhir+json');
            x.withHeader('Content-Type', 'application/fhir+json');

            if (ConfigService.Debug) {
                x.withInterceptor({
                    request(request) {
                        try {
                            if (ConfigService.DebugFhirRequests) {
                                let message = `FhirClient: Requesting ${request.method} ${request.url}`;
                                if (request.url.indexOf(fhirEnums.ResourceType.questionnaireResponse) > -1 && request.method.toUpperCase() === "PUT" || request.method.toUpperCase() === "POST") {
                                    let r = request.content;
                                    if (r.questionnaire) {
                                        let q = QuestionnaireService.GetQuestionnaireDirect(r.questionnaire);
                                        if (q) {
                                            message = `FhirClient: "${request.method}" QuestionnaireResponse with Questionnaire "${q.name || q.title}" to "${request.url}"`;
                                        }
                                    }
                                }

                                console.debug(message);
                            }
                        } finally {
                            return request;
                        }
                    },
                    response(response) {
                        try {
                            if (ConfigService.Debug && ConfigService.DebugFhirResponses) {
                                console.debug(`FhirClient: Received ${response.statusCode} ${response.requestMessage.baseUrl}`);
                            }

                            if (response.statusCode >= 400) {
                                console.warn("StatusCode >= 400. Response:", response);
                            }
                        } finally {
                            return response;
                        }
                    }
                });
            }

            if (ConfigService.UseOAuth2) {
                x.withHeader('Authorization', `Bearer ${ConfigService.AccessToken}`);
            } else {
                if (ConfigService.Tiplu.enabled && ConfigService.Tiplu.token) {
                    x.withHeader('Authorization', `Basic ${ConfigService.Tiplu.token}`);
                } else {
                    if (this._hash) {
                        if (UserService.Login.usePrincipa) {
                            x.withHeader('Authorization', `${this._hash}`);
                        } else {
                            x.withHeader('Authorization', `Basic ${this._hash}`);
                        }
                    }
                }
            }

            x.withBaseUrl(baseUrl || FhirService.Endpoint);
        });

        return client;
    }

    /** Deletes a Fhir Resource on the Server */
    public static async Delete(resource): Promise<any> {
        if (!resource) {
            return Promise.reject("No resource given to delete");
        }

        try {
            let url;
            if (typeof resource === 'string') {
                url = String(resource).trim();
            } else {
                if (typeof resource.id === "undefined" || resource.id === "undefined") {
                    return Promise.reject("Resource has not a valid Id");
                }

                url = `${resource.resourceType}/${resource.id}`;
            }

            let client = this.GetClient();
            await client.delete(url);
            return Promise.resolve(true);
        } catch (error) {
            if (error.response) {
                try {
                    error.message = JSON.parse(error.response).issue.map(o => o.diagnostics).join('\n +');
                } catch {
                }
            }

            console.warn(error.message);
            return Promise.reject(error.message);
        }
    }

    public static GetBundle(resources: any[], method: fhirEnums.HTTPVerb, bundleType: fhirEnums.BundleType = fhirEnums.BundleType.transaction): any {
        return Tools.GetBundle(resources, method, bundleType);
    }

    public static async Bundle(resources: any[], method: fhirEnums.HTTPVerb, type: BundleType = BundleType.transaction): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let bundle = this.GetBundle(resources, method, type);
            let client = this.GetClient();
            client.post(FhirService.Endpoint, bundle)
                .then(result => {
                    let resultObject = JSON.parse(result.response);
                    return resolve(resultObject);
                })
                .catch(error => {
                    let js = JSON.parse(error.response);
                    let msg = "";
                    js.issue.forEach(issue => {
                        msg += issue.diagnostics + "<br />\n";
                    });

                    reject(msg);
                });
        });
    }

    public static async GetResourceTypes(): Promise<string[]> {
        const client = this.GetClient(sessionStorage.getItem(environment.sessionName));
        const result = await client.createRequest(FhirService.Endpoint).asOptions().send();
        const response = JSON.parse(result.content);

        if (response && response.rest) {
            const ele = response.rest.find(o => o.mode === 'server' && o.resource);
            if (ele && ele.resource) {
                return ele.resource.map(o => o.type);
            }
        }

        return undefined;
    }

    /***
     * Sends the request to reindex specific resource-types or all resources to the fhir server. Maintains Smile-Cdr Version to send the correct version of the request
     * @param resourceTypes The resourceTypes to be reindexed
     * @param everything If set to true, all resources will be reindexed and ignores given ResourceTypes
     * @param batchSize If set, inserts the size of the batch jobs into the request
     */
    public static async $Reindex(resourceTypes?: string[], everything: boolean = false, batchSize: number = NaN): Promise<HttpResponseMessage> {
        const params: any = {
            "resourceType": "Parameters",
            parameter: []
        };

        if (!everything && (!resourceTypes || resourceTypes.length === 0)) {
            throw 'No Resources specified and everything is not set to true';
        }

        const client = this.GetClient(sessionStorage.getItem(environment.sessionName));
        const result = await client.createRequest(FhirService.Endpoint).asOptions().send();
        let oldVersion = true;

        /* if (response && response.software && response.software.version) {
            const [major, minor, release] = response.software.version.split('.');
            if (parseInt(major) >= 2021 && parseInt(minor) >= 8)
                oldVersion = false;
            else {
                console.warn(`You are using a rather old Version of ${response.software.name}. Your Version: ${response.software.version}`);
            }
        } */

        if (!everything) {
            if (resourceTypes && resourceTypes.length > 0) {
                for (const type of resourceTypes) {
                    params.parameter.push({
                        name: oldVersion ? 'type' : 'url',
                        valueString: type
                    });
                }
            } else throw 'No Resources specified to index in parameter';
        } else {
            if (!oldVersion) {  // the old version indexes everything if no "type" parameter is present
                params.parameter.push({
                    name: "everything",
                    valueBoolean: true
                });
            }
        }

        // when not the old version a batch size could be specified
        if (!oldVersion && !isNaN(batchSize) && typeof batchSize === 'number') {
            params.parameter.push({
                name: "batchSize",
                valueDeximal: 1000
            });
        }

        return await client
            .createRequest(NitTools.ExcludeTrailingSlash(FhirService.Endpoint) + oldVersion ? '/$mark-all-resources-for-reindexing' : '/$reindex')
            .asPost()
            .withContent(params)
            .send();
    }

    public static async $Expunge(resourceType: string, hash?: string, targetServer?: string) {
        return await this.GetClient(hash).createRequest(NitTools.ExcludeTrailingSlash(targetServer) + '/' + resourceType + '/$expunge').asPost().withContent(
            {
                "resourceType": "Parameters",
                "parameter": [
                    {
                        "name": "expungeDeletedResources",
                        "valueBoolean": true
                    }
                ]
            }
        ).send();
    }

    public static CreateSearchParams() {
        return {
            base: ['RiskAssessment'],
            code: "status",
            description: "Search for Status in RiskAssessment",
            name: "RiskAssessment-Status",
            status: "draft",
            type: "string",
            experimental: true,
            date: new Date().toJSON(),
            publisher: "NursIT Institute",
            purpose: 'Need to search for status in RiskAssessment too for performance reasonse',
            url: 'http://hl7.org/fhir/SearchParameter/NursIT/RiskAssessmentStatus',
            expression: "RiskAssessment.status",
            xpath: "f:*/f:status",
            xpathUsage: 'normal',
            resourceType: 'SearchParameter'
        };
    }

    /** Creates a new Resource on the Server */
    public static async Create(resource, postToBase: boolean = false): Promise<any> {
        if (!resource) {
            return Promise.reject("No resource given to create");
        }

        try {
            let client = this.GetClient();
            let resultMessage: HttpResponseMessage = await client.post(postToBase ? '/' : resource.resourceType, resource);
            let resultObject = JSON.parse(resultMessage.response);

            return Promise.resolve(resultObject);
        } catch (error) {
            let msg = error.message || error.content || error;
            if (error.response) {
                try {
                    msg = JSON.parse(error.response).issue.map(o => o.diagnostics).join('\n +');
                } catch {
                }
            }

            console.warn(msg, resource);
            return Promise.reject(msg);
        }
    }

    /** Updates the given resource on the Server */
    public static async Update(resource: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            if (!resource) {
                reject("No resource given to update!");
                return;
            }

            if (typeof resource.id === "undefined" || resource.id === "undefined") {
                reject("Resource has not a valid Id");
                return;
            }

            try {
                let client = this.GetClient();
                if (resource.resourceType === fhirEnums.ResourceType.questionnaireResponse) {
                    let qr = resource;
                    // cleanup texts that end with _nil
                    try {
                        let nullTexts = qr.item.filter(o => typeof o !== "undefined" && o.text && o.text.endsWith('_nil'));
                        nullTexts.forEach(item => delete item.text);
                    } catch (e) {
                        console.warn(e.message);
                    }

                    if (FhirService.FhirVersion < 4 && qr.questionnaire?.reference?.indexOf(`Questionnaire/${QuestionnaireService.__listResult.QAssessmentId}`) > -1) {
                        let careLevelItem = QuestionnaireResponse.GetResponseItemByLinkId(qr, "CareLevel");
                        if (careLevelItem && (careLevelItem.text && careLevelItem.text.endsWith("undefined")) || !careLevelItem.text) {
                            if (!careLevelItem.answer || careLevelItem.answer.length === 0) {
                                careLevelItem.answer = [
                                    {
                                        valueCoding: {
                                            code: "0",
                                            display: translations.translate("carelevel0")
                                        }
                                    }
                                ];
                            }
                        }
                    }

                    if (NitTools.IsArray(qr.item)) {
                        for (const item of qr.item.filter(o => o.answer?.length > 0)) {
                            for (const answer of item.answer) {
                                if (answer.valueCoding?.code) {
                                    answer.valueCoding.code = String(answer.valueCoding.code);
                                }

                                if (answer.valueCoding?.display) {
                                    answer.valueCoding.display = String(answer.valueCoding.display);
                                }
                            }
                        }
                    }

                    // if assessment, correct the CareLevel-Thing
                    if (Tools.StripId(qr.questionnaire) === QuestionnaireService.__listResult.QAssessmentId) {
                        let clItem = QuestionnaireResponse.GetResponseItemByLinkId(qr, "CareLevel", false);
                        if (typeof clItem?.answer?.[0]?.valueInteger == "number") {
                            clItem.linkId = "CareLevel";  //just to be sure it's not uppercased
                            const val = QuestionnaireResponse.GetResponseItemValue(clItem);
                            if (typeof val != "undefined") {
                                clItem.text = translations.translate(`carelevel${val}`);
                                clItem.answer = [
                                    {
                                        valueInteger: val
                                    }
                                ];
                            }
                        }
                    }
                }

                let resultMessage: HttpResponseMessage = await client.put(`${resource.resourceType}/${resource.id}`, resource);
                if (ConfigService.IsTest)
                    return resolve(resource);
                
                let resultObject = resultMessage?.response ? JSON.parse(resultMessage.response) : resource;

                resolve(resultObject);
            } catch (error) {
                console.warn(error.message || error);
                if (ConfigService.Debug) throw (error);
                reject(error.message || error);
            }
        });
    }

    /** gets a single resource from the url */
    public static async Get(url: string, encodeUrl: boolean = true): Promise<any> {
        if (!url) return undefined;
        if (ConfigService.Debug && ConfigService.DebugFhirRequests) console.debug("FHIR started get " + url);

        try {
            if (typeof url === "undefined") {
                console.warn('URL is Empty!', "Stack:", new Error('').stack);
            }

            let client = this.GetClient();
            if (encodeUrl) url = this.transformUrl(url);

            let resultMessage: HttpResponseMessage = await client.get(url);
            let resultObject = JSON.parse(resultMessage.response);

            /* let base = FhirService.Endpoint;
            if (url.indexOf('/') === -1 && !base.endsWith('/')) base += "/";
            let resultObject = await $.getJSON(base + url); */

            if (ConfigService.Debug && ConfigService.DebugFhirResponses) console.debug("FHIR finished get " + url);

            return Promise.resolve(resultObject);
        } catch (error) {
            if (error.response) {
                let js = JSON.parse(error.response);
                let msg = "";
                js.issue.forEach(issue => {
                    msg += issue.diagnostics + "<br />\n";
                });

                error.message = msg;
            }

            return Promise.reject(error.message);
        }
    }

    /** Fetches ALL Resources that match the query and returns the bundle resources as an array */
    public static async Fetch(url: string, encodeUrl: boolean = true, hash: string = undefined): Promise<any[]> {
        if (ConfigService.Debug && ConfigService.DebugFhirRequests) {
            console.debug("FHIR started fetching " + url);
        }

        try {
            let client = this.GetClient(hash);
            let result: any[] = [];

            if (encodeUrl) url = this.transformUrl(url);
            let nextLink: any = { relation: fhirEnums.LinkRelation.self, url: url }; //  bundle.link.find((o : ILink) => o.relation === fhirEnums.LinkRelation.next);
            while (nextLink) {
                let nextUrl = nextLink.url;

                try {
                    let nextMessage: HttpResponseMessage = await client.get(nextUrl);
                    let nextBundle = JSON.parse(nextMessage.response);
                    if (nextBundle.entry) {
                        nextBundle.entry.forEach(entry => {
                            if (entry.resource) {
                                let res: any = entry.resource;
                                result.push(res);
                            }
                        });
                    }

                    nextLink = nextBundle?.link?.find(o => o.relation === fhirEnums.LinkRelation.next);
                } catch (e) {
                    console.error("Error when loading: " + nextUrl, e);
                    nextLink = undefined;
                    throw (e.statusText || e);
                }
            }

            if (ConfigService.Debug && ConfigService.DebugFhirResponses) {
                console.debug("Finished fetching " + url);
            }

            return result;
        } catch (error) {
            console.warn(error.message || error);
            return await Promise.reject(error.message || error);
        }
    }
}
