export class QualificationCodes  {
    public static DGKP: string  = "Diplom Gesundheits- und Krankenpfleger/in";
    public static PFA: string = "Pflegefach Assistenz";
    public static PA: string = "Pflegeassistenz";
    public static HH: string = "Heimhilfe";
    public static SAB: string = "Soziale Alltagsbegleitung";
    public static "24H": string = "24h Betreuungskräfte";
    public static PPL : string = "Pflegeplanung";
    public static SSL: string = "Sozialstationsleitung";
    public static AA: string = "Assistenzarzt";
    public static OA: string = "Oberarzt";
    public static CA: string = "Chefarzt";
}
