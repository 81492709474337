import {translations} from 'resources/classes/translations';
import {bindable, inject} from 'aurelia-framework';
import * as Fhir from "../../classes/FhirModules/Fhir";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {I18N} from "aurelia-i18n";
import {IDiagnosis} from "../../classes/IDiagnosis";
import {ConfigService} from "../../services/ConfigService";
import {IFormSetting} from "../../classes/IFormSettings";
import {IQuestionnaireList, QuestionnaireService} from 'resources/services/QuestionnaireService';
import {DialogMessages} from 'resources/services/DialogMessages';
import {fhirEnums} from "../../classes/fhir-enums";
import {AnalyzeService} from "../../services/analyzeService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {DialogService} from "aurelia-dialog";
import {ReleasePatient} from "../../../views/patient/release";
import {FhirService} from "../../services/FhirService";
import {PatientService} from "../../services/PatientService";
import {NitTools} from "../../classes/NursitTools";
import {Router} from "aurelia-router";
import {modalHistorySearch} from "../modal-history-search";

const moment = require("moment");
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {QuestionnaireResponse} from "../../classes/FhirModules/Fhir";

@inject(I18N, DialogMessages, DialogService, Router)
export class PatientInformation {
    @bindable patient: PatientItem;
    @bindable showAnalyzer: boolean;
    dialogMessages: DialogMessages;
    dialogService: DialogService;
    showDedicatedPhotosPage: boolean = false;
    showBudgetsPage: boolean = false;
    careLevelColor: string = "black";
    showDeleteButton: boolean = false;
    analyzerText : string;
    embedded: boolean;
    showTakeOffline: boolean = true;
    showCaseStatus: boolean;
    showCaseId: boolean;
    showPkms: boolean;
    showPatientBirthdate: boolean = true;
    qList: IQuestionnaireList;
    showHistory: boolean = false;
    i18n: I18N;
    caseId: string = "";
    static _showPKMSInfo: boolean = undefined;
    public static MainDiagnosisRank: number = 11;
    public spi: number;
    /** Gets or sets the selected care-grade from the latest finalized anamnesis as configured in forms -> route: "details" -> settings -> careGradeField: <questionnaire>.<linkId>.
     * Displaying this row is indicated by showCareGrade.
     */
    careGradeInfo : string = undefined;

    /** Gets or sets a value indicating whether the caregrade taken from the latest finalized anamnesis should be displayed. The value source is configured in careGradeInfo */
    showCareGrade : boolean = false;

    showReleaseDate = false;
    mainDiagnosis: IDiagnosis[] = [];
    encounterStatusClass: string = "";
    statusInfo: string;
    encounterDates: string;
    setting: IFormSetting;
    showInsurance: boolean = false;
    showDwellTime: boolean = false;
    attendanceSettings = {
        enabled: false,
        offsetDaysFromDwellTime: 0
    };
    showMainDiagnosis: boolean = false;
    showDepartment : boolean = false;

    localStatus = {
        text: '-',
        totalPages: -1,
        currentPage: -1
    };
    remoteStatus = {
        text: '-',
        totalPages: -1,
        currentPage: -1
    };
    warningMessage: string[] = [];
    // cache translated yes/no values, to avoid multiple translations
    y: string = translations.translate("yes");
    n: string = translations.translate("no");

    /** Gets or sets the careLevel (not to mess up with careGRADE) to be displayed in the details. Is taken from the Analysis/Risks */
    careLevelString: string = "-";
    dwellTime: number;

    get pkmsText(): string {
        if (!this.patient) return "";
        return this.showPkms ? this.y : this.n;
    }

    get gender() {
        if (typeof this.patient === "undefined") return "";
        let id = "gender_" + this.patient.gender;
        let result = this.tr(id);
        if (result === "gender_" + this.patient.gender) {
            result = "";
        }

        return result;
    }

    get showReleaseButton(): boolean {
        if (RuntimeInfo.DataProxy.enabled) return false;
        if (this.patient && this.patient.encounter && (this.patient.encounter.status === "finished" || this.patient.encounter.status === "cancelled")) return false;
        return RuntimeInfo.Features.allowReleasePatient;
    }

    get showPKMSInfo() {
        // patient.encounter. 31.12.2020
        // return RuntimeInfo.PkmsRelevanceStart > -1;
        if (!this.patient || !this.patient.encounter) return false;
        return this.patient.isEncounterDuringPkmsPeriod;
    }

    constructor(i18n: I18N, dialogMessages: DialogMessages, dialogService: DialogService, public router: Router) {
        this.i18n = i18n;
        this.dialogMessages = dialogMessages;
        this.dialogService = dialogService;
        this.showDedicatedPhotosPage = RuntimeInfo.Features["dedicatedPhotosPage"] !== false;
        this.showHistory = typeof RuntimeInfo.Features.allowCasesHistorySearch === "boolean" ? RuntimeInfo.Features.allowCasesHistorySearch : false;
        this.embedded = RuntimeInfo.Embedded;
    }

    showPatientNumber : boolean = false;
    async attached() {
        this.showDeleteButton = ConfigService.Debug && window.location.search.indexOf('_UITest=1') > -1;
        this.i18n.i18next.loadResources();
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        this.setting = ConfigService.GetFormSettings(ConfigService.FormNames.Details);
        this.showBudgetsPage = ConfigService.cfg?.features?.showBudgets === true;

        if (this.setting?.settings) {
            this.showInsurance = typeof this.setting.settings["showInsuranceInDetails"] ? this.setting.settings["showInsuranceInDetails"] : true;
            this.showDwellTime = typeof this.setting.settings["showDwellTime"] === "boolean" ? this.setting.settings["showDwellTime"] : true;
            this.showMainDiagnosis = typeof this.setting.settings["showMainDiagnosisInDetails"] === "boolean" ? this.setting.settings["showMainDiagnosisInDetails"] : true;
            this.showCaseId = typeof this.setting.settings["showCaseId"] === "boolean" ? this.setting.settings["showCaseId"] : true;
            this.showCaseStatus = typeof this.setting.settings["showCaseStatus"] === "boolean" ? this.setting.settings["showCaseStatus"] : true;
            this.showPatientNumber = typeof this.setting.settings["showPatientNumber"] === "boolean" ? this.setting.settings["showPatientNumber"] : false;
            this.showDepartment = typeof this.setting.settings["showDepartment"] === "boolean" ? this.setting.settings["showDepartment"] : false;
            this.showPatientBirthdate = typeof this.setting.settings["showPatientBirthdate"] === "boolean" ? this.setting.settings["showPatientBirthdate"] : true;
            if (this.setting.settings["attendanceTime"])
                this.attendanceSettings = this.setting.settings["attendanceTime"];
        }

        QuestionnaireService.GetQuestionnaireIds()
            .then((result: IQuestionnaireList) => {
                this.qList = result;
                this.getSpi();
            });

        if (ConfigService.Debug) {
            window["patientDetails"] = this;
        }
        // wenn Fhir.Rest.TestServer() erfolgreich dann take local button anzeigen
    }

    async removeUITestPatient() {
        try {
            if (!window.confirm(`Are you sure to delete patient "${this.patient.display}" ?`))
                return;

            RuntimeInfo.IsLoading = true;
            console.debug("Getting Patient..");
            const p = await Fhir.Rest.Get(`Patient/${this.patient.id}`);

            console.debug("Setting encounter status to finished to remove it from list..");
            this.patient.encounter.status = 'finished';
            await Fhir.Rest.Update(this.patient.encounter);

            console.debug("Executing PatientItem.Clear");
            await PatientItem.Clear(this.patient);

            console.debug("Trying to delete Patient and Encounter");
            await Fhir.Rest.Delete(this.patient.encounter);
            await Fhir.Rest.Delete(p);
        }
        catch (e) {
            console.warn(e);
        }
        finally {
            if (window["patientList"]) {
                await window["patientList"].refreshWard(true);
            }

            RuntimeInfo.IsLoading = false;
        }
    }

    goToImages() {
        this.router.navigateToRoute("images", { patientId: this.patient.id });
    }

    goToBudgets() {
        this.router.navigateToRoute("budgets", { patientId: this.patient.id });
    }

    openHistorySearch() {
        this.dialogService.open({
            viewModel: modalHistorySearch,
            model: {
                lastName: this.patient.name[0] ? this.patient.name[0].family : '',
                firstName: this.patient.name[0] && this.patient.name[0].given ? this.patient.name[0].given[0] : '',
                born: moment(this.patient.birthDate).toDate(),
                patientId: this.patient.id
            },
            lock: true
        });
    }

    showReleaseDetails() {
        this.dialogService.open({
            viewModel: ReleasePatient,
            lock: true,
            model: { patient: this.patient, controller: this.dialogService.controllers[0] }
        })
            .whenClosed(async result => {
                if (!result.wasCancelled && result.output) {
                    try {
                        RuntimeInfo.IsLoading = true;
                        let fs = new FhirService();
                        let encounter = <any>await fs.get('Encounter/' + this.patient.encounterId);
                        if (encounter) {
                            if (!encounter.period) encounter.period = {};
                            encounter.period.end = new Date(result.output).toJSON();
                            encounter.status = 'finished';
                            try {
                                this.patient.encounter = <any>await fs.update(encounter);
                                this.patientChanged(this.patient);
                                if (PatientService.LatestPatient && PatientService.LatestPatient.id === this.patient.id) {
                                    PatientService.LatestPatient.encounter = this.patient.encounter;
                                }
                            } catch (e) {
                                console.warn(e.message || JSON.stringify(e));
                                this.dialogMessages.prompt(this.i18n.tr('error_updating_encounter'), this.i18n.tr('warning'), true);
                            } finally {
                                RuntimeInfo.IsLoading = false;
                            }
                        } else {
                            RuntimeInfo.IsLoading = false;
                            this.dialogMessages.prompt(this.i18n.tr('error_loading_encounter'), this.i18n.tr('warning'), true);
                        }
                    } catch (error) {
                        console.warn(error.message || JSON.stringify(error));
                        this.dialogMessages.prompt(this.i18n.tr('error_loading_encounter'), this.i18n.tr('warning'), true);
                    } finally {
                        RuntimeInfo.IsLoading = false;
                    }
                }
            });
    }

    showRelease() {
        this.dialogMessages.dialog(this.i18n.tr('confirm_release_patient'), this.i18n.tr('confirm'), this.i18n.tr('yes'), this.i18n.tr('no'), true)
            .whenClosed(result => {
                if (!result.wasCancelled) this.showReleaseDetails();
            });
    }

    protected searchForSpi() {
        if (!this.patient) return;

        let predNCSA = undefined;
        let predSPI = undefined;
        if (this.patient.currentRisks && this.patient.currentRisks.prediction && NitTools.IsArray(this.patient.currentRisks.prediction)) {
            for (const prediction of this.patient.currentRisks.prediction) {
                if (prediction.outcome.coding) {
                    if (!predNCSA)
                        predNCSA = prediction.outcome.coding.find(o => o.system.endsWith('/risk_ncsa_overview'));
                    if (!predSPI)
                        predSPI = prediction.outcome.coding.find(o => o.system.endsWith('/risk_spi_sum'));
                }
            }
        }

        const cfgAssessment = ConfigService.GetFormSettings('assessment');
        
        if (predNCSA && cfgAssessment && cfgAssessment.analyzer && !cfgAssessment.analyzer.toUpperCase().startsWith('EPA')) {
            this.spi = parseInt(predNCSA.code);
            this.careLevelString = `NCS-A, ${predNCSA.display}`;
        } else {
            if (predNCSA) {
                this.spi = parseInt(predNCSA.code);
                this.careLevelString = `NCS-A, ${predNCSA.display}`;
            } else if (predSPI) {
                this.spi = parseInt(predSPI.code);

                this.careLevelString = `${predSPI.display}`;
                if (!isNaN(this.spi))
                    this.careLevelString += ` (SPI: ${predSPI.code})`;
            }
        }

        // then try to get the spi from encounter
        if (!this.careLevelString && this.patient && this.patient.encounter) {
            try {
                const spiMeta = FhirService.Tags.get(this.patient.encounter, '/SPI');
                if (spiMeta && spiMeta.code) {
                    let spiValue = parseInt(spiMeta.code);
                    if (spiValue) {
                        this.spi = spiValue;
                        this.showPkms = spiValue <= AnalyzeService.PkmsRelevanceStart;
                    }
                }
            }
            catch (e) {
                if (ConfigService.Debug)
                    console.info(e.message || e);
            }
        }

        return this.careLevelString || '-';
    }

    ageDisplay : string = "?";
    formattedBirthDate : string = '?';
    async patientChanged(newPatient: PatientItem) {
        const tmpSetting = (await ConfigService.LoadConfigOverride(newPatient?.ward, newPatient))?.forms?.find(o=>o.route==="details");
        if (tmpSetting) {
            this.setting = tmpSetting;
        }

        this.spi = undefined;
        this.caseId = undefined;
        this.careLevelString = undefined;
        this.showReleaseDate = false;
        this.encounterDates = '';
        this.careGradeInfo = '';
        this.showCareGrade = this.setting?.settings?.showCareGrade === true;
        if (newPatient) {

            // display CareGrade if configured correctly
            if (this.showCareGrade) {
                // try to parse the configured questionaire/linkid
                const fieldConfig = this.setting?.settings?.careGradeField;
                if (typeof fieldConfig === 'string' && fieldConfig.indexOf('.') > 1) {
                    const [qName, linkId] = this.setting?.settings?.careGradeField?.split('.');
                    if (qName && linkId) {
                        // get the source questionnaire
                        const qAnamnesis = QuestionnaireService.GetQuestionnaireDirect(qName);
                        if (qAnamnesis) {
                            // get the latest anamnesis
                            const response = QuestionnaireService.GetLatestResponseOfType(newPatient, qAnamnesis.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                            if (response) {
                                // get the item display
                                const item = QuestionnaireResponse.GetResponseItemByLinkId(response, linkId, false);
                                if (NitTools.IsArray(item?.answer)) {
                                    this.careGradeInfo = QuestionnaireResponse.GetResponseItemDisplay(item);
                                } else {
                                    if (!item) {
                                        console.warn(`PatientDetails could not find an item with linkId "${linkId}" in questionnaire "${qAnamnesis.name}"`);
                                    }
                                }
                            }  // don't warn about not existing Anamnesis response because that may always be the case
                        } else {
                            console.warn(`Could not find configured Anamnesis Questionnaire "${qName}" from settings.careGradeField.`)
                        }
                    } else {
                        console.warn(`Questionnaire or LinkId could not be parsed from "${fieldConfig}" from settings.careGradeField.`)
                    }
                } else {
                    console.warn(`settings.careGradeField has been specified but "${fieldConfig}" is not a valid value`);
                }
            }

            if (newPatient.birthDateDisplay)
                this.careLevelColor = 'black';

            if (newPatient.flags?.code?.coding) {
                const colorFlag = newPatient.flags.code.coding.find(o => o.system.endsWith('/CareLevelColor'));
                if (colorFlag && colorFlag.code) {
                    if (colorFlag.code === "#fff908") // move from much-to-bright-to-read-yellow to dirtier-darker-yellow
                        colorFlag.code = 'rgb(167, 167, 42)';
                    else if (colorFlag.code === '#c9c9c9') // move from very-light-gray to black
                        colorFlag.code = 'black';

                    this.careLevelColor = colorFlag.code;
                }

                const careLevelFlag = newPatient.flags.code.coding.find(o => o.system.endsWith('/CareLevelString'));
                if (careLevelFlag && careLevelFlag.code) {
                    this.careLevelString = this.i18n.tr(careLevelFlag.code);

                    // when this is not the sempa analyzer, we need to put the spi value manually behind
                    const analyzerVersion: string = await AnalyzeService.GetAnalyzerVersion(newPatient, newPatient.latestAssessment);
                    if (analyzerVersion.indexOf("SEMPA") === -1) {
                        // but check first if there is not already a number or the string "SPI:" itself
                        if (!/\d/.test(this.careLevelString) && !/.*SPI.*\d/.test(this.careLevelString)) {
                            const spiFlag = newPatient.flags.code.coding.find(o => o.system.endsWith('/SPI'));
                            if (spiFlag) {
                                const _spi = spiFlag.code;
                                if (_spi)
                                    this.careLevelString += ` (SPI: ${_spi})`;
                            }
                        }
                    }
                }
            }
            
            if (!this.careLevelString || String(this.careLevelString).toUpperCase().indexOf('SPI') > -1) {
                this.careLevelString = this.searchForSpi();
            }

            // locate the visit number
            if (newPatient.encounter?.identifier?.length > 0) {
                let identifiers = newPatient.encounter.identifier.filter(o => o.system?.indexOf('/visitNumber') > -1);

                let idElement = identifiers.find(o => o.use === "official");

                if (!idElement) {
                    idElement = identifiers.find(o => o.use === "usual");
                }

                if (!idElement) {
                    idElement = identifiers.find(o => o.use === "secondary");
                }

                if (!idElement) {
                    idElement = identifiers[0];
                }

                if (idElement) {
                    this.caseId = idElement.value;
                }

                this.dwellTime = undefined;
                if (newPatient.encounter && newPatient.encounter.period && newPatient.encounter.period.start) {
                    this.dwellTime = moment(newPatient.encounter.period.end || new Date()).diff(newPatient.encounter.period.start, "d");
                }
            }

            this.statusInfo = newPatient?.encounter ? this.i18n.tr(newPatient.encounter.status) : '';
            this.encounterStatusClass = "";

            // get the correct encounter status from the patient to highlight the status in the correct color
            if (newPatient.encounter?.status) {
                this.statusInfo = this.i18n.tr(newPatient.encounter.status);
                switch (newPatient.encounter.status) {
                    case fhirEnums.EncounterStatus.inProgress:
                    case fhirEnums.EncounterStatus.arrived:
                    case fhirEnums.EncounterStatus.onLeave:
                    case fhirEnums.EncounterStatus.planned:
                        this.encounterStatusClass = "text-success";
                        break;
                    default:
                        this.encounterStatusClass = "text-danger";
                        if (newPatient.encounter?.period) {
                            let startDate = '';
                            let endDate = '';
                            if (this.patient.encounter.period.start) {
                                try {
                                    startDate = moment(newPatient.encounter.period.start).format(RuntimeInfo.DateFormat);
                                }
                                catch (e) {
                                    console.warn(e.message || e);
                                }
                            }

                            if (this.patient.encounter.period.end) {
                                try {
                                    endDate = moment(newPatient.encounter.period.end).format(RuntimeInfo.DateFormat);
                                }
                                catch (e) {
                                    console.warn(e.message || e);
                                }
                            }

                            if (startDate || endDate)
                                this.encounterDates = `<table>
                                    <tr>
                                        <td>${this.i18n.tr('date_from')}:</td>
                                        <td>${startDate || '?'}</td>
                                    </tr>
                                    <tr>
                                        <td>${this.i18n.tr('date_to')}:</td>
                                        <td>${endDate || '?'}</td>
                                    </tr>
                                </table>`;
                            else
                                this.encounterDates = this.i18n.tr("n_a");

                            this.showReleaseDate = true;
                        }
                        break;
                }
            }

            // get the diagnosis
            this.mainDiagnosis = [];
            if (newPatient.diagnosis) {
                // make the display of the diagnosis distinct
                let tmp = newPatient.diagnosis.filter(o => o.display && o.rank === PatientInformation.MainDiagnosisRank);
                tmp.forEach((diag: IDiagnosis) => {
                    let existing = this.mainDiagnosis.find(o => o.display && o.display.trim().toUpperCase() === diag.display.trim().toUpperCase());
                    if (!existing) {
                        this.mainDiagnosis.push(diag);
                    }
                });
            }

            // fill the correct age string
            if (newPatient.birthDate) {
                if (newPatient.days >= 365)
                    this.ageDisplay = `${Math.trunc(newPatient.years).toString()} ${this.i18n.tr("years")}`;
                else if (newPatient.days < 30) {
                    this.ageDisplay = `${newPatient.days.toString()} ${this.i18n.tr("days")}`;
                } else if (newPatient.days >= 30 && newPatient.days < 365) {
                    this.ageDisplay = `${Math.trunc((newPatient.days / 30)).toString()} ${this.i18n.tr("month")}`;
                }
            }
        }

        this.getSpi();

        const analyzerVersion  = await AnalyzeService.GetAnalyzerVersion(this.patient, this.patient?.latestAssessment);
        const analyzer = AnalyzeService.GetAnalyzer(analyzerVersion);
        this.analyzerText = analyzer?.description;
    }

    getSpi() {
        if (!this.patient || !this.qList) {
            this.showPkms = false;
            return;
        }

        // first try to get the spi from encounter
        if (this.patient?.flags?.code?.coding) {
            const spiFlag = this.patient.flags.code.coding.find(o => o.system.endsWith('/SPI'));
            if (spiFlag && spiFlag.code) {
                let spiValue = parseInt(spiFlag.code);
                if (spiValue) {
                    this.showPkms = spiValue <= AnalyzeService.PkmsRelevanceStart;
                    return;
                }
            }
        }

        // get the latest finalized assessment to update the SPI-Value and PKMS-display
        let latestAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, this.qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.completed, fhirEnums.QuestionnaireResponseStatus.amended]);
        if (latestAssessment) {
            let spiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(latestAssessment, "risk_spi_sum", false);
            if (spiItem) {
                let spiValue = Fhir.QuestionnaireResponse.GetResponseItemValueInt(spiItem, 999);
                this.showPkms = spiValue <= AnalyzeService.PkmsRelevanceStart;
            } else {
                console.warn("[Patient-Information]  Keine PKMS-Info gefunden");
                this.showPkms = false;
            }
        }
    }

    showDiagnoseDetails() {
        if (!this.patient.diagnosis || this.patient.diagnosis.length === 0) return;
        let h = `<table class="table">\
              <tr>\
                <th>${this.i18n.tr("diagnosis_code")}</th>\
                <th>${this.i18n.tr("diagnosis_text")}</th>\
                <th>${this.i18n.tr("diagnosis_role")}</th>\
              </tr>`;

        this.patient.diagnosis.forEach((d: IDiagnosis) => {
            h += `<tr>\
              <td>${d.code}</td>
              <td>${d.display}</td>\
              <td>${d.roleText}</td>\
            </tr>`;
        });

        h += '</table>';

        this.dialogMessages.prompt(h, this.i18n.tr("diagnosis_info_title"), false);
    }

    public tr(id): string {
        return translations.translate(id);
    }
}
