import { Login } from 'views/login';
import { RouteConfig, Router, RouterConfiguration } from "aurelia-router";
import { bindable, inject, PLATFORM } from "aurelia-framework";
import { BindingEngine, Disposable } from 'aurelia-binding';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { DialogService } from "aurelia-dialog";
import { Prompt } from "../resources/elements/prompt";
import { PatientItem } from "../resources/classes/Patient/PatientItem";
import { I18N } from "aurelia-i18n";
import { UserService } from 'resources/services/UserService';
import { FhirService } from "../resources/services/FhirService";
import { ConfigService } from "../resources/services/ConfigService";
import { BasicForm } from "../resources/elements/BasicForm";
import { PatientChangeNotifier } from "resources/services/PatientChangeNotifier";
import { PatientService } from "resources/services/PatientService";
import { QuestionnaireService } from "../resources/services/QuestionnaireService";
import { ReportService } from "../resources/services/ReportService";
import { RuntimeInfo } from "../resources/classes/RuntimeInfo";
import { ApplicationInfoDialogContent } from "./application-info-dialog-content";
import { AddPatient } from "./patient/add";
import { LocationService } from "../resources/services/LocationService";
import { PractitionerEdit } from "./practitioner-edit";
import { ChangePassword } from "./changePassword";
import * as environment from "../../config/environment.json";
import { DialogMessages } from "../resources/services/DialogMessages";
import { ChatWrapper } from "../resources/services/ChatWrapper";
import * as Fhir from "../resources/classes/FhirModules/Fhir";
import { HttpClient } from "aurelia-http-client";
import { NitTools } from "../resources/classes/NursitTools";
import { App } from "../app";
import { modalHistorySearch } from "../resources/elements/modal-history-search";
import { AnalyzeService } from "resources/services/analyzeService";
import { CarePlanService } from "../resources/services/CarePlanService";
import { ModalSubdelegationList } from "../resources/elements/modal-subdelegation-list";
import { IEncounterViewDefinition, IEncounterViewDefinitionArrow } from "./encounter-view-definition";
import { PermissionService } from 'resources/services/PermissionService';
import { CaveView } from './patient/cave-view';
import { fhirEnums } from "../resources/classes/fhir-enums";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import { IAllergy } from "../resources/classes/allergy";

const moment = require("moment"); // maybe another way to include possible?

@inject(PatientChangeNotifier, DialogService, I18N, UserService, PatientService, QuestionnaireService, DialogMessages, ChatWrapper, App, CarePlanService, BindingEngine, EventAggregator, PermissionService)
export class EncounterView {
    @bindable encounterId: string;
    router: Router;
    id: string;
    fhirService: FhirService;
    private static autoNavigated: boolean = false;
    subscriberId: string = undefined; // now we get the patient from the detail-view
    routerConfig: RouterConfiguration;
    iframe: HTMLIFrameElement;
    chatWrapper;
    offLineEnabled: boolean = false;
    version: string;
    userMenu: HTMLDivElement;
    allowChangePassword: boolean = false;
    firstCall: boolean = true;
    configs: {};
    showOfflineInfo: boolean = true;
    __arrowPercent: string = undefined;
    unmappedDotQuestionnaires: IUnmappedQuestionnaire[] = [];
    unmappedBottomQuestionnaires: IUnmappedQuestionnaire[] = [];
    unmappedTopQuestionnaires: IUnmappedQuestionnaire[] = [];
    _userInfoTitle: string = "";
    rightPopup: HTMLDivElement;
    wardId: string;
    isLoading: boolean = false;
    showBedOverview: boolean = true;
    showWardShiftReport: boolean = false;
    showWardOverview: boolean = false;
    patientLatestAssessmentSubscription: Disposable;
    patientCarePlanOutdatedCheckSubscription: Subscription;
    isLatestCarePlanAssessmentOutdated: boolean = false;
    showCirs: boolean = false;
    displayCaseIdentifier: boolean = false;
    displayUserMenu: boolean = true;
    navMaps;
    pingPId: number = undefined;
    pingIntervall: number = 10000;
    fhirServiceWarningShown: boolean = false;
    pingFailed: boolean = false;
    uaCheck: any;
    isSubDelegationEnabled = false;
    showGlobalSubDelegationList: boolean = false;
    showChangeRole: boolean = false;
    debugQuestionnaires: IUnmappedQuestionnaire[] = [];
    topRoutes: any[] = [];
    showHistory: boolean = false;
    useViewConfig: boolean;
    viewDefinition: IEncounterViewDefinition;
    documentationUrl: string = undefined;

    /** Gets or sets a value indicating whether the fall count should be displayed in the header. Only possible if route "fall" is enabled and has a valid Questionnaire */
    displayFallCount: boolean = false;
    careInConfig: {
        enabled: boolean,
        url: string,
        title: string
    };

    offlineInfo: {
        offline: boolean,
        since: string,
        due: string,
        by: {
            userId: string,
            name: string
        }
    };

    appHeaderConfig = {
        displayAllergies: true,
        displayDiagnosis: true,
        displayBirthDate: true,
        displayCaseId: true,
        displayTreatmentDays: true,
        displayGender: true,
        allergiesColor: "rgb(255,113,113)",
        displayLogo: true
    };

    trimName: boolean = true;
    diagnosisText: string;

    constructor(protected patientChangeNotifier: PatientChangeNotifier, protected dialogService: DialogService, protected i18n: I18N,
        protected userService: UserService, protected patientService: PatientService, protected questionnaireService: QuestionnaireService,
        protected dialogMessages: DialogMessages, protected chatServiceWrapper: ChatWrapper, protected app: App,
        protected carePlanService: CarePlanService, protected bindingEngine: BindingEngine, protected eventAggregator: EventAggregator,
        protected permissionService: PermissionService) {

        this.fhirService = new FhirService();

        this.subscriberId = this.patientChangeNotifier?.subscribe(() => {
            this.patientService?.updatePatientDischargePct(this.patient);
            this.updateNames();
        });

        // PatientIndexView.PatientViewRouter = this.router;
        this.chatServiceWrapper?.init();

        this.showHistory = typeof RuntimeInfo.Features.allowCasesHistorySearch === "boolean" ? RuntimeInfo.Features.allowCasesHistorySearch : false;

        this.displayCaseIdentifier = ConfigService.displayCaseIdentifier;
        this.displayUserMenu = ConfigService.displayUserMenu;
    }

    canRoute(routeName: string) {
        const permissionRoute = routeName === "curve" ? "chart" : routeName;
        // if (PermissionService.Instance?.canAlert(PermissionService.FEATURES.CAREIT.NAVIGATION, {ROUTES: permissionRoute}))
        this.router.navigateToRoute(routeName);
    }

    public configureRouter(config, router) {
        this.routerConfig = config;

        this.navMaps = [
            // main route - always first!
            {
                route: ['details', '', 'home', 'index'],
                name: 'details',
                moduleId: PLATFORM.moduleName('./patient/details'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 0
                }
            },
            {
                route: ['doctor-overview'],
                name: 'doctor-overview',
                moduleId: PLATFORM.moduleName('./patient/doctor-overview'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 1
                }
            },
            {
                route: ['therapy-overview'],
                name: 'therapy-overview',
                moduleId: PLATFORM.moduleName('./patient/therapy-overview'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 1
                }
            },
            //#region arrow buttons
            {
                route: ['anamnesis', 'anamnesis/:responseId'],
                name: 'anamnesis',
                moduleId: PLATFORM.moduleName('./patient/pflegeplanung/anamnese'),
                nav: true,
                settings: {
                    location: "top",
                    visible: true,
                    index: 1
                }
            },
            {
                route: ['assessment', 'assessment/:responseId'],
                name: 'assessment',
                moduleId: PLATFORM.moduleName('./patient/pflegeplanung/view-assessment'),
                nav: true,
                settings: {
                    location: "top",
                    index: 2
                }
            },
            {
                route: 'analysis',
                name: 'analysis',
                moduleId: PLATFORM.moduleName('./patient/pflegeplanung/analyse'),
                nav: true,
                settings: {
                    location: "top",
                    index: 3
                }
            },
            {
                route: 'images/:patientId',
                name: 'images',
                moduleId: PLATFORM.moduleName('./patient/other-images'),
                nav: false,
                settings: {
                    location: "top",
                    index: 4
                }
            },
            {
                route: ['budgets', 'budgets/:patientId'],
                name: 'budgets',
                moduleId: PLATFORM.moduleName('./patient/budgets'),
                nav: false,
                settings: {
                    location: "menu",
                    index: 5
                }
            },
            {
                route: 'planning',
                name: 'planning',
                // moduleId: PLATFORM.moduleName('./patient/pflegeplanung/planung'),
                moduleId: PLATFORM.moduleName('./patient/planning'),
                nav: true,
                settings: {
                    location: "top",
                    index: 5
                }
            },
            {
                route: 'evaluation',
                name: 'evaluation',
                moduleId: PLATFORM.moduleName('./patient/pflegeplanung/evaluation'),
                nav: true,
                settings: {
                    location: "top",
                    index: 6
                }
            },
            //#endregion
            //#region misc routes
            {
                route: 'add',
                name: 'add',
                moduleId: PLATFORM.moduleName('./patient/add'),
                title: this.i18n.tr("add_patient")
            },
            {
                route: ['cave', 'cave/:path'],
                name: 'cave',
                moduleId: PLATFORM.moduleName('./patient/cave-view'),
                title: "CAVE"
            },
            {
                route: 'info',
                name: 'info',
                moduleId: PLATFORM.moduleName('./info-dialog'),
                title: this.i18n.tr("infos")
            },
            {
                route: 'draw/:id/:encounter',
                name: 'draw',
                moduleId: PLATFORM.moduleName('./patient/draw'),
                title: this.i18n.tr("draw")
            },
            {
                route: ['discharge', 'discharge/:responseId'],
                name: 'discharge',
                moduleId: PLATFORM.moduleName('./patient/discharge'),
                title: this.i18n.tr("release_management")
            },
            {
                route: ['ppr2', 'ppr2/:responseId'],
                name: 'ppr2',
                moduleId: PLATFORM.moduleName('./patient/ppr2'),
                title: this.i18n.tr("ppr2")
            },
            {
                route: ['questionnaire/:questionnaireId/:name', 'questionnaire/:questionnaireId/:name/:responseId'],
                name: 'questionnaire',
                moduleId: PLATFORM.moduleName('./patient/questionnaire-default')
            },
            {
                route: ['loadform'],
                name: 'loadform',
                moduleId: PLATFORM.moduleName('./patient/loadform'),
                title: this.i18n.tr("loading"),
                nav: false
            },
            //#endregion

            //#region Buttons under "..."
            {
                route: ['fall', 'fall/:responseId'],
                name: 'fall',
                moduleId: PLATFORM.moduleName('./patient/fall'),
                nav: true,
                settings: {
                    location: "menu",
                    index: 7
                }
            },
            {
                route: ['isolation', 'isolation/:responseId'],
                name: 'isolation',
                moduleId: PLATFORM.moduleName('./patient/isolation'),
                nav: true,
                settings: {
                    location: "menu",
                    index: 8
                }
            },
            {
                route: ['transfer', 'transfer/:responseId'],
                name: 'transfer',
                moduleId: PLATFORM.moduleName('./patient/verlegung'),
                nav: true,
                settings: {
                    location: "menu",
                    index: 9
                }
            },
            {
                route: ['nursingvisit', 'nursingvisit/:responseId'],
                name: 'nursingvisit',
                moduleId: PLATFORM.moduleName('./patient/nursing-visits-protocol'),
                nav: true,
                settings: {
                    location: "menu",
                    index: 10
                }
            },
            {
                route: ['multiform', 'multiform/:definitionId'],
                name: 'multiform',
                moduleId: PLATFORM.moduleName('./patient/multi-form'),
                nav: false,
                settings: {
                    location: "none"
                }
            },
            {
                route: ['subdelegation', 'subdelegation/:responseId'],
                name: 'subdelegation',
                moduleId: PLATFORM.moduleName('./patient/subdelegation'),
                nav: true,
                settings: {
                    location: "menu",
                    index: 11
                }
            },
            //#endregion

            //#region bottom toolbar, title of the tabs is acquired by config.json/overidden config.json
            {
                route: 'careprocess',
                name: 'careprocess',
                moduleId: PLATFORM.moduleName('./patient/pflegeplan'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 11
                }
            },
            {
                route: ['wounds', 'wounds/:responseId'],
                name: 'wounds',
                moduleId: PLATFORM.moduleName('./patient/wunden/wound-main'),
                // moduleId: PLATFORM.moduleName('./patient/wunden/new-wound-main'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 12
                }
            },
            {
                route: 'curve',
                name: 'curve',
                moduleId: PLATFORM.moduleName('./patient/curve'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 13
                }
            },
            {
                route: 'carefiles',
                name: 'carefiles',
                moduleId: PLATFORM.moduleName('./patient/pflegeakte'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 14
                }
            },
            {
                route: 'medications',
                name: 'medications',
                moduleId: PLATFORM.moduleName('./patient/medications'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 15
                }
            },
            {
                route: 'allergy',
                name: 'allergy',
                moduleId: PLATFORM.moduleName('./patient/allergy/allergy-main'),
                nav: true,
                settings: {
                    location: "bottom",
                    index: 16
                }
            },
            {
                route: 'medication-provision',
                name: 'medication-provision',
                moduleId: PLATFORM.moduleName('./patient/Medications/medication-provision'),
                nav: true,
                settings: {
                    location: "menu",
                    index: 17
                }
            }
            //#endregion
        ];

        this.routerConfig.map(this.navMaps);

        this.router = router;
    }

    public async navigateToRoute(href: string) {
        this.rightPopup?.classList.remove('in');

        const routeItem = this.router.navigation.find(o => o.relativeHref.startsWith(href));
        if (!routeItem) {
            // try to found out if a questionnaire-route has been linked
            const questionnaireRoute = ConfigService.cfg?.forms.find(o => o.route === href);
            if (questionnaireRoute?.questionnaireName) {
                const q = QuestionnaireService.GetQuestionnaireDirect(questionnaireRoute.questionnaireName);
                this.router.navigateToRoute("questionnaire", { questionnaireId: q.id, name: q.name });
            } else {
                await DialogMessages.Prompt(this.dialogService, `Configured Route "${href}" does not exist`, this.i18n.tr('warning'), true);
            }
        } else {
            this.router.navigateToRoute(href);
        }
    }

    // should be executed from the arrows- and toolbar-items when this.useViewConfig is true
    public async navigateToRouteDefinition(arrow: IEncounterViewDefinitionArrow) {
        if (!arrow) return;
        // when it's something like the "..." menu, display the content in the right popup
        if (arrow.items?.length > 0) {
            this.showRightPopup(arrow);
            return;
        }

        // when only one questionnaire is specified, move to the correct view
        if (NitTools.IsArray(arrow.questionnaires) && arrow.questionnaires.length === 1) {
            arrow.questionnaire = arrow.questionnaires[0];
            arrow.route ??= ConfigService.cfg.forms.find(o => o.questionnaireName?.toUpperCase() === arrow.questionnaire.toUpperCase())?.route;
            delete arrow.questionnaires;
        }

        if (NitTools.IsArray(arrow.questionnaires)) {
            console.warn('Multiple Questionnaires found. Redirecting to multiView');
            if (!arrow.uniqueId) {
                alert(`UniqueId not specified in route with title "${arrow.title}" for userRole "${UserService.UserRole}"`);
                return;
            } else {
                this.router.navigate(`multiform/${arrow.uniqueId}`);
                return;
            }
        }

        // check if the route already exists in the router..
        let route = this.navMaps.find(o => o.route === arrow.route || o.route.indexOf(arrow.route) > -1 || o.name == arrow.route);
        if (route) {
            // .. if so, navigate to that
            if (PermissionService.Instance?.canAlert(route.route))
                this.router.navigateToRoute(NitTools.IsArray(route.route) ? route.route[0] : route.route, { definitionId: arrow.uniqueId });

            return;
        }

        // get the questionnaire name from config when not explicitly set
        if (!arrow.questionnaire) {
            const cfg = ConfigService.GetFormSettings(arrow.route, this.patient);
            if (cfg?.questionnaireName)
                arrow.questionnaire = cfg.questionnaireName;
        }

        // ensure the QuestionnaireService already has its Questionnaires cached
        await this.questionnaireService.fetch();

        // get the Questionnaire and display it
        const questionnaire = this.questionnaireService.questionnaires.find(o => o.name?.toUpperCase() === String(arrow.questionnaire || arrow.route).toUpperCase());
        if (questionnaire) {
            if (PermissionService.Instance?.canAlert(PermissionService.FEATURES.CAREIT.FORMS, { QUESTIONNAIRES: questionnaire.url + (questionnaire.version ? `|${questionnaire.version}` : '') }))
                this.router.navigateToRoute("questionnaire", { questionnaireId: questionnaire.id, name: questionnaire.name });
        } else {
            console.warn(`Questionnaire with name "${arrow.questionnaire || arrow.route}" ` +
                `not found as specified in ViewDefinitions for route: ${UserService.UserRole}=>${arrow.route}`);
        }
    }

    async attached() {
        if (ConfigService.Debug) window["encounterView"] = this;

        const isModuleVisible = function (moduleRoute: string) {
            let cfg = ConfigService.GetFormSettings(moduleRoute);
            if (!cfg) return true;
            if (typeof cfg.enabled === "boolean") return cfg.enabled;
            else return true;
        };

        await this.ping();

        this.allowChangePassword = RuntimeInfo.Features.allowEditUserSettings;
        this.offLineEnabled = FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled === true;
        this.isSubDelegationEnabled = isModuleVisible('subdelegation');

        let cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        if (cfg) {
            this.documentationUrl = cfg["documentationUrl"];
            if (typeof cfg.features?.displayFallCount === "boolean") {
                this.displayFallCount = cfg.features.displayFallCount;
            }

            if (cfg.features?.appHeader) {
                const ahc = cfg.features?.appHeader;
                if (typeof ahc.displayAllergies === "boolean") this.appHeaderConfig.displayAllergies = ahc.displayAllergies;
                if (typeof ahc.displayDiagnosis === "boolean") this.appHeaderConfig.displayDiagnosis = ahc.displayDiagnosis;
                if (typeof ahc.displayBirthDate === "boolean") this.appHeaderConfig.displayBirthDate = ahc.displayBirthDate;
                if (typeof ahc.displayCaseId === "boolean") this.appHeaderConfig.displayCaseId = ahc.displayCaseId;
                if (typeof ahc.displayTreatmentDays === "boolean") this.appHeaderConfig.displayTreatmentDays = ahc.displayTreatmentDays;
                if (typeof ahc.displayGender === "boolean") this.appHeaderConfig.displayGender = ahc.displayGender;
                if (typeof ahc.displayLogo === "boolean") this.appHeaderConfig.displayLogo = ahc.displayLogo;
                if (typeof ahc.allergiesColor === "string" && ahc.allergiesColor) this.appHeaderConfig.allergiesColor = ahc.allergiesColor;
            }

            if (window.location.href.indexOf('logo=') > -1) {
                this.appHeaderConfig.displayLogo = window.location.href.indexOf('logo=1') > -1;
            }

            this.trimName = this.appHeaderConfig.displayGender || this.appHeaderConfig.displayBirthDate || this.appHeaderConfig.displayCaseId || this.appHeaderConfig.displayTreatmentDays;

            this.showCirs = cfg?.features?.cirs?.enabled === true;
            if (NitTools.IsArray(cfg?.forms)) {
                for (const setting of cfg.forms) {
                    if (!setting.route) continue;
                    let route = this.router.routes.find(o => o.name === setting.route && o.nav);
                    if (route) {
                        route.nav = setting.enabled;

                        route.settings["visible"] = setting.enabled;
                        route["title"] = this.i18n.tr(setting.title);
                        route.settings["title"] = this.i18n.tr(setting.title);
                        if (route.settings.title && route.settings.title.indexOf('/') > -1) {
                            [route.settings.shortTitle, route.settings.longTitle] = route.settings.title.split('/');
                            route.settings.longTitle = ` / ${route.settings.longTitle}`;
                        }

                        if (!route.settings.shortTitle)
                            route.settings.shortTitle = route.settings.title;

                        if (setting.location) {
                            route.settings.location = setting.location;
                        }

                        route.settings.enabled = setting.location !== 'none' && setting.enabled;
                    }
                }
            }
        }

        this.version = RuntimeInfo.AppVersion;
        this.showBedOverview = ConfigService.IsFormEnabled(ConfigService.FormNames.BedOverview);
        this.showWardShiftReport = ConfigService.IsFormEnabled(ConfigService.FormNames.WardShiftReport);
        this.showWardOverview = ConfigService.IsFormEnabled(ConfigService.FormNames.WardOverview);

        let careInSetting = ConfigService.GetFormSettings(ConfigService.FormNames.CareIn);
        if (careInSetting) {
            this.careInConfig = {
                enabled: careInSetting.enabled,
                title: this.i18n.tr(careInSetting.title),
                url: `${careInSetting.settings["url"]}?sessionId=${FhirService.Hash}`
            };
        }

        ////////////////////////////////////////////
        // try to get the view layout from config //
        ////////////////////////////////////////////
        this.viewDefinition = cfg?.roleViews?.find(o => o.role === UserService.UserRole);
        this.useViewConfig = !!this.viewDefinition;

        /*if (this.useViewConfig) {
            console.warn("USING VIEW CONFIG FOR GENERATING ROLE BASED VIEW! this.viewDefinition:", this.viewDefinition);
        } */
        /////////////////////////////////////////

        if (typeof this.uaCheck === "undefined") this.uaCheck = ConfigService.ParseUAString(navigator.userAgent);

        this.chatServiceWrapper.attach(this.chatWrapper, this.encounterId);

        this.topRoutes = this.getRoutes('top');

        await this.getUnmappedQuestionnaires();

        if (!this.patient)
            this.patient = PatientItem.LastLoadedPatient;

        if (this.patient?.encounterId) {
            this.encounterIdChanged(this.patient.encounterId)
                .finally(() => RuntimeInfo.IsLoading = false);
        }

        if (this.firstCall) {
            this.firstCall = false;
            await this.encounterIdChanged(this.encounterId);
        }

        /* const nextPage = sessionStorage.getItem('nextPage'); // this could iE have been written by visitExtended. Points to the route to move finally.
        if (nextPage) {
            sessionStorage.removeItem('nextPage');

            if (!this.router?.routes?.find(o => o.route == nextPage))
                alert(`Route ${nextPage} does not exist!`);
            else
                this.router.navigate(nextPage);
        } */

        RuntimeInfo.IsLoading = false;
    }

    header: HTMLDivElement;

    async activate(params) {
        if (params?.id) {
            await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
            RuntimeInfo.SelectedEncounter = params.id;
            this.encounterId = params.id;
        }
    }

    detached() {
        if (this.patientLatestAssessmentSubscription) {
            this.patientLatestAssessmentSubscription.dispose();
        }

        if (this.patientCarePlanOutdatedCheckSubscription) {
            this.patientCarePlanOutdatedCheckSubscription.dispose();
        }

        this.patientChangeNotifier.unsubscribe(this.subscriberId);
    }

    public static SwitchClient() {
        let url = EncounterView.IsOffline ? FhirService.OfflineClientSettings.remote.url : FhirService.OfflineClientSettings.local.url;
        sessionStorage.clear();
        sessionStorage.setItem(environment.sessionName, FhirService.Hash);
        window.location.href = url;
    }

    public static get IsOffline(): boolean {
        return FhirService.OfflineClientSettings ? FhirService.OfflineClientSettings.isOffline : false;
    }

    get isOffline(): boolean {
        return FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled ? FhirService.OfflineClientSettings.isOffline : false;
    }

    get isEmbedded() {
        return RuntimeInfo.Embedded;
    }

    get showFooter(): boolean {
        if (window.location?.href?.indexOf('showFooter=0') > -1) return false;
        return RuntimeInfo.Features.bottomTabs;
    }

    get showArrows(): boolean {
        if (window.location?.href?.indexOf('showArrows=0') > -1) return false;
        return RuntimeInfo.Features.processArrows;
    }

    get showHeader(): boolean {
        if (/[\?&]header=0/i.test(window.location.href)) {
            return false;
        } else if (/[\?&]header=1/i.test(window.location.href)) {
            return true;
        }

        return RuntimeInfo.Features.applicationHeader;
    }

    get displayClasses(): string {
        let s = "";
        const arr = [
            `config-${this.showHeader ? 'show' : 'no'}-header`,
            `config-${this.showArrows ? 'show' : 'no'}-arrows`,
            `config-${this.showFooter ? 'show' : 'no'}-footer`,
            `has-${this.useViewConfig && !this.hasViewDefinitionArrows ? 'no' : 'some'}-arrows`,
            `has-${this.useViewConfig && !this.hasViewDefinitionFooterTabs ? 'no' : 'some'}-tabs`
        ];

        /*if (!this.showHeader) s += ' config-no-header'; else s += ' config-show-header';
        if (!this.showArrows) s += ' config-no-arrows'; else s += ' config-show-arrows';
        if (!this.showFooter) s += ' config-no-footer'; else s += ' config-show-footer';

        if (this.useViewConfig && !this.hasViewDefinitionArrows)
            s += ' has-no-arrows';

        return s.trim(); */

        return arr.join(' ');
    }

    fallCount: any = '';
    @bindable patient: PatientItem;

    async patientChanged(patient: PatientItem) {
        this.updateNames();
        this.fallCount = '';

        if (!patient) return;
        const cfg = ConfigService.GetFormSettings('fall');
        if (!cfg?.questionnaireName || !cfg.enabled) return;
        const q = QuestionnaireService.GetQuestionnaireDirect(cfg.questionnaireName);
        if (!q) return;

        this.fallCount = QuestionnaireService.GetResponsesOfType(patient, q.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]).length;
    }

    openFall() {
        this.router.navigateToRoute('fall');
    }

    handleResize(detail) {
        this.cw = detail?.width;
        this.bw = window.document.body.clientWidth;

        /*const eles = document.querySelectorAll(".patient-page-title .one-line-div");
        for (let i = 0; i < eles.length; i++) {
            (<HTMLDivElement>eles[i]).style.width = `${detail.width}px`;
        }*/

        this.updateNames();

        /*console.log("width: " + detail.width);
        console.log("height: " + detail.height);
        console.log("old width: " + detail.widthOld);
        console.log("old height: " + detail.heightOld); */
    }

    displayName: string;
    titleName: string;
    lastName: string;
    firstName: string;
    firstNameInitial: string;
    caveHeaderString: string;
    // dwellDaysText : string = '';
    dwellDays: number = 0;
    caseIdText: string = '';
    allergiesText: string = '';
    isSmall: boolean = RuntimeInfo.IsMobile;

    private async updateNames() {
        this.caveHeaderString = '';

        if (this.patient?.id) {
            const obs = await Fhir.Rest.Fetch(`Observation?patient=${this.patient.id}&code=visible-in-header`);
            let items = [];
            for (const ob of obs) {
                const item = CaveView.CaveItemFromObservation(ob);
                items.push(item);
            }

            if (items.length > 0) {
                items = items.sort((a, b) => String(a.path).localeCompare(String(b.path)));
                this.caveHeaderString = items.map(o => o.title).join(', ');
            }
        }

        this.lastName = '';
        this.firstName = '';
        this.firstNameInitial = '';
        this.dwellDays = 0;
        // this.dwellDaysText = '';
        this.caseIdText = '';
        this.ageString = '';
        this.allergiesText = '';
        this.diagnosisText = '';

        const w = this.header?.clientWidth || 940;
        this.isSmall = w < 1100;

        if (!this.patient)
            return;

        if (this.patient?.encounter?.period) {
            this.dwellDays = moment(this.patient.encounter.period.end || new Date()).diff(this.patient.encounter.period.start, "d") + this.attendanceSettingsOffset;
            // this.dwellDaysText = `${isSmall ? 'Beh.-tage' : 'Behandlungstage'}: ${this.dwellDays}`;
        }

        const date = moment(this.patient?.birthDate).format(RuntimeInfo.DateFormat);
        this.ageString = `${date}, ${this.patient?.years}${this.isSmall ? 'J.' : ' ' + this.i18n.tr('years')}`;

        // this.caseIdText = `${isSmall ? 'F.-Nr' : 'Fall-Nr'}.: ${this.patient.caseId}`;
        this.caseIdText = this.patient?.caseId ? String(this.patient.caseId).trim() : '';
        let allergies = this.patient?.allergies?.filter(o => o?.isActive && o.text && o.intoleranceResource);
        const list: IAllergy[] = [];

        for (const a of allergies) {
            const all: fhir4.AllergyIntolerance = <fhir4.AllergyIntolerance>a.intoleranceResource;
            if (!!all.code?.coding?.find(o => o.system?.endsWith('/display-in-title'))) {
                list.push(a);
            }
        }

        this.allergiesText = list.map(o => o.text).join(',');

        // TODO: fill in working diagnosis if we know where this will come from

        //#region name
        if (NitTools.IsArray(this.patient?.name)) {
            let name = this.patient.name.find(o => o.use === 'official') || this.patient.name.find(o => o.use === 'usual') || this.patient.name[0];
            if (name) {
                this.lastName = name.family;
                this.firstName = NitTools.IsArray(name.given) ? name.given.join(' ') : '';
                if (this.firstName) {
                    this.firstNameInitial = String(this.firstName[0]).toUpperCase();
                }

                this.titleName = `${this.lastName}${this.firstName ? ', ' + this.firstName : ''}`.trim();
                this.displayName = this.isSmall && this.trimName ? `${this.lastName}${this.firstNameInitial ? ', ' + this.firstNameInitial : ''}.`.trim() : this.titleName;
            }
        }
        //#endregion
    }

    /*get patient(): PatientItem {
        return PatientItem.SelectedPatient || PatientItem.LastLoadedPatient;
    } */

    get isDebug() {
        return ConfigService.Debug;
    }

    get bottomRoutes(): any[] {
        return this.getRoutes("bottom");
    }

    get menuRoutes(): any[] {
        return this.getRoutes("menu");
    }

    cw: number = 0;
    bw: number = 0;

    get bottomTabs() {
        return this.router.navigation.filter(o => {
            return o["location"] === "bottom";
        });
    }

    get arrowPercent() {
        if (!this.__arrowPercent) {
            let routes = this.topRoutes;
            if (routes.length > 0) {
                this.__arrowPercent = `${100 / (routes.length + 1)}%`; // +1 because of "..." Button
            }
        }

        return this.__arrowPercent;
    }

    get showAddPatient() {
        return typeof RuntimeInfo.Features.allowAddPatients === "boolean" ? RuntimeInfo.Features.allowAddPatients : false;
    }

    get env() {
        return RuntimeInfo;
    }

    get userInfo() {
        return `${this.userService.username}` || this.i18n.tr("logout");
    }

    get userInfoTitle() {
        if (this._userInfoTitle) return this._userInfoTitle;

        let sa = [];
        if (this.userService.lastName) sa.push(this.userService.lastName);
        if (this.userService.firstName) sa.push(this.userService.firstName);

        if (sa.length === 0) this._userInfoTitle = this.i18n.tr("unnamed");

        if (ConfigService.Debug)
            sa.push(...UserService.Qualifications);

        if (this.permissionService.isRolesEnabled) {
            const role = this.permissionService.activeUserRole;
            if (role?.name) {
                sa.push(role.name);
            }
        }

        this._userInfoTitle = sa.join(', ');

        return this._userInfoTitle;
    }

    get pageTitle(): string {
        return BasicForm.pageTitle;
    }

    //#region Cave Properties
    get caveEnabled() {
        return RuntimeInfo.Features.cave?.enabled === true;
    }

    get allergiesCount() {
        return this.patient?.allergies?.length;
    }

    get equipmentCount() {
        return this.patient?.equipmentCount;
    }

    get valuesCount() {
        return this.patient?.valuesCount;
    }

    get contraindicationsCount() {
        return this.patient?.contraindicationsCount;
    }

    //#endregion
    attendanceSettingsOffset: number = 0;
    dotMenuList: any[] = [];
    useDefaultDotMenu: boolean = true;
    ageString: string = '';

    /***
     gets or sets a value indicating wheter there is at least one arrow configured in the view-defintion
     */
    hasViewDefinitionArrows: boolean;

    /***
     gets or sets a value indicating wheter there is at least one bottom tab configured in the view-defintion
     */
    hasViewDefinitionFooterTabs: boolean;

    isRedirecting: boolean = false;
    async encounterIdChanged(id: string, oldValue?: string) {
        if (id === oldValue) return;

        this.chatServiceWrapper.encounterChanged(id);

        if (window.location.href.indexOf('nextPage=') > -1 && !sessionStorage.getItem('nextPage')) {
            var pms = NitTools.GetUrlParams(window.location.href);
            sessionStorage.setItem("nextPage", pms.nextPage);
            console.warn("NextPage", sessionStorage.getItem('nextPage'), this.router);
        }

        if (!id) return;
        try {
            RuntimeInfo.IsLoading = true;
            // RuntimeInfo.MessageText = 'Loading Patient Informations';
            this.patient = await PatientItem.Load(this.encounterId, true);
            PatientItem.SelectedPatient = this.patient;

            if (this.patient?.ward) {
                const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);

                const settings = ConfigService.GetFormSettings('details');
                if (settings) {
                    this.attendanceSettingsOffset = cfg.settings?.["attendanceTime"]?.offsetDaysFromDwellTime || 0;
                }

                for (const route of this.router.routes.filter(o => o.name)) {
                    const formSetting = cfg.forms?.find(o => o.route?.toUpperCase() === route.name?.toUpperCase()); // ConfigService.GetFormSettings(route.name);
                    if (formSetting) {
                        const displayInDots = formSetting.displayInDotsMenu === true || formSetting.location === "menu";
                        const title = formSetting.title ? this.i18n.tr(formSetting.title) : route.title;
                        let isVisible = formSetting.enabled !== false;
                        const defaultLocation = ConfigService.DefaultViewLocations.find(o => o.route.toUpperCase() === formSetting.route.toUpperCase());
                        let location = formSetting.location || (defaultLocation ? defaultLocation.location : undefined);
                        if (route.name?.toUpperCase() === "DETAILS") {
                            location = "bottom";
                            isVisible = true;
                        }

                        if (typeof route.settings === "undefined")
                            route.settings = {};
                        if (typeof route.settings.index === "undefined")
                            route.settings.index = 1000;

                        if (location)
                            route.settings.location = location;

                        route.settings.visible = isVisible;
                        route.settings.displayInDotsMenu = displayInDots;
                        route.settings.title = title;

                        const navis = this.router.navigation.filter(o => o.config?.name?.toUpperCase() === formSetting.route?.toUpperCase());
                        for (const navi of navis) {
                            navi.settings.visible = isVisible;
                            if (location)
                                navi.settings.location = location;
                            navi.settings.displayInDotsMenu = displayInDots;
                            navi.settings.title = title;
                            navi.title = title;
                        }

                        const navMap = this.navMaps.find(o => o.name?.toUpperCase() === formSetting?.route?.toUpperCase());
                        if (!navMap.settings) {
                            navMap.settings = {};
                        }

                        if (location)
                            navMap.settings.location = location;

                        navMap.settings.visible = isVisible;
                        navMap.nav = isVisible;

                        navMap.settings.displayInDotsMenu = displayInDots;
                        navMap.settings.title = title;
                        navMap.title = title;
                    }
                }

                this.routerConfig.map(this.navMaps);

                await this.getUnmappedQuestionnaires();
            }


            if (this.useViewConfig) {
                this.hasViewDefinitionArrows = this.viewDefinition?.arrows?.length > 0;
                this.hasViewDefinitionFooterTabs = this.viewDefinition?.toolbar?.length > 0;

                const dotSettings = this.viewDefinition?.arrows?.find(o => o.route === "...");
                if (NitTools.IsArray(dotSettings?.items)) {
                    this.dotMenuList = dotSettings.items;
                    this.useDefaultDotMenu = false;
                } else {
                    this.useDefaultDotMenu = true;
                }
            } else {
                this.useDefaultDotMenu = true;
            }

            this.trackLatestCarePlanAssessmentOutdated(this.patient);
            const nextPage = sessionStorage.getItem("nextPage");
            const currentRoute = undefined; // it was intented to use this: this.router.currentInstruction?.config?.name || 'details'; but then the navigation does not work always properly. Maybe fix it later

            // when the user is a doctor, "simply" skip to the curve
            if (EncounterView.autoNavigated === false) {
                if (nextPage) {
                    if (ConfigService.Debug) {
                        console.warn(`Skipping to nextPage: ${nextPage}`);
                    }

                    sessionStorage.removeItem('nextPage');

                    const route = this.router?.routes?.find(o => String(o.route).toUpperCase() == nextPage.toUpperCase());
                    if (!route) {
                        alert(`Route ${nextPage} does not exist!`);
                    }
                    else {
                        this.isRedirecting = true;
                        if (nextPage != currentRoute) {
                            if (ConfigService.Debug) {
                                console.debug(`Navigating from "${currentRoute}" to "${nextPage}"`);
                            }

                            this.isRedirecting = !this.router.navigate(nextPage);
                        }
                    }

                    EncounterView.autoNavigated = true;
                } else {
                    if (!this.useViewConfig && UserService.UserRole === 'doctor' && this.encounterId) {
                        // gets the next available (aka enabled) route from the current used setup.json. When none is found, just the details is returned
                        const accumulateNextPage = function (...routes: string[]): string {
                            let result: string = 'details';
                            try {
                                for (const route of routes) {
                                    const currentConfig = ConfigService.GetFormSettings(route);
                                    if (currentConfig?.enabled === true) {
                                        result = route;

                                        break;
                                    }
                                }
                            }
                            catch (e) {
                                console.warn(e);
                            }

                            return result;
                        }

                        const nextRoute = accumulateNextPage('doctor-overview', 'curve');

                        if (nextRoute != currentRoute) {
                            if (ConfigService.Debug) {
                                console.debug(`Navigating from "${currentRoute}" to "${nextRoute}"`);
                            }

                            this.isRedirecting = !this.router.navigateToRoute(nextRoute);
                        }
                    } else if (this.viewDefinition?.startPageRoute && this.encounterId) {
                        if (this.viewDefinition.startPageRoute != currentRoute) {
                            if (ConfigService.Debug) {
                                console.debug(`Navigating from "${currentRoute}" to "${this.viewDefinition.startPageRoute}"`);
                            }

                            this.isRedirecting = !this.router.navigateToRoute(this.viewDefinition.startPageRoute);
                        }
                    } else {
                        if (currentRoute != 'details') {
                            this.isRedirecting = !this.router.navigateToRoute('details');
                        }
                    }
                }
            }

            if (!this.isRedirecting) {
                this.offlineInfo = Fhir.Tools.GetOfflineInfo(this.patient?.encounter);

                if (this.offlineInfo && this.offlineInfo.offline) {
                    document.body.classList.add("offline-patient");
                    this.showOfflineInfo = true;
                } else {
                    document.body.classList.remove("offline-patient");
                }
            }
        } catch (ex) {
            console.warn(ex);
        } finally {
            RuntimeInfo.IsLoading = false;
        }
    }

    public gotoCave(part) {
        this.app.openCave(this.patient?.encounterId, part);
    }

    public navigateToOtherClient() {
        EncounterView.SwitchClient();
    }

    public async ping() {
        if (ConfigService.DisablePing || ConfigService.Debug) return; // don't do the ping when it's debug

        let client = new HttpClient();
        client.configure(x => {
            x.withTimeout(this.pingIntervall);
        });

        let resultObject = { success: true, message: undefined };

        let url = `${NitTools.ExcludeTrailingSlash(FhirService.Endpoint)}/endpoint-health`;
        resultObject.message = url;

        try {
            await client.get(url);
            resultObject.success = true;
            this.fhirServiceWarningShown = false;
            this.pingFailed = false;
        } catch (httpError) {
            resultObject.success = false;
            this.pingFailed = true;
            console.warn(resultObject.message + ' not reacheable');
        }

        if (!resultObject.success && !this.fhirServiceWarningShown) {
            this.fhirServiceWarningShown = true;
            RuntimeInfo.ShowInfo(this.i18n.tr("fhir_not_reachable_alert_text"), true);
        }

        window.setTimeout(() => this.ping(), this.pingIntervall);
    }

    public async executeCIRS() {
        const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        if (cfg && cfg.features && cfg.features.cirs && cfg.features.cirs.enabled === true) {
            if (window["callCirs"])
                window["callCirs"](this.app, this.patient, cfg.features.cirs);
            else {
                DialogMessages.Prompt(this.dialogService, 'No "window.callCirs()" defined.\n', this.i18n.tr("error"));
            }
        }
    }

    public showVersion() {
        window["toggleVisibility"] = function (selector) {
            let items = document.querySelectorAll(selector);
            for (let i = 0; i < items.length; i++) {
                items[i].classList.toggle('hidden');
            }

            return false;
        };

        let a = document.createElement('a');
        a.setAttribute('href', FhirService.Endpoint);
        //let fhir = `${a.hostname}:${a.port}`;
        a.setAttribute('href', ReportService.ReportServer + "api");
        //let repport = `${a.hostname}`;

        this.dialogService.open({
            viewModel: ApplicationInfoDialogContent,
            model: {},
            lock: true
        });
    }

    public openHistorySearch() {
        this.dialogService.open({
            viewModel: modalHistorySearch,
            model: {},
            lock: true
        });
    }

    public cancelClickEvent(e) {
        e.cancelBubble = true;
        e.preventDefault();
        return false;
    }

    public hideUserMenu() {
        if (!this.userMenu) return;
        this.userMenu.style.display = 'none';
    }

    public openSdWindow(onlyCurrentUser) {
        this.dialogService.open({
            viewModel: ModalSubdelegationList,
            model: {
                onlyCurrentUser: onlyCurrentUser
            },
            centerHorizontalOnly: true
        });
    }

    public showUserMenu() {
        if (!this.allowChangePassword && !this.isSubDelegationEnabled) {
            this.logoutUser();
        } else {
            if (!this.userMenu) return;
            this.showGlobalSubDelegationList = UserService.UserHasQualification(['DGKP', 'SSL', 'PPL']);
            this.showChangeRole = this.permissionService.isRolesEnabled && this.permissionService.numAvailableRoles > 1;
            this.userMenu.style.display = 'block';
        }
    }

    public logoutUser() {
        Login.LoginRunning = false;
        if (this.userService.noLogin) {
            return;
        }

        this.dialogService.open({
            viewModel: Prompt,
            model: {
                message: this.i18n.tr("confirm_logoff"),
                title: this.i18n.tr('confirm'),
                yesText: this.i18n.tr("yes"),
                noText: this.i18n.tr('no')
            },
            lock: true
        }).whenClosed(response => {
            if (!response.wasCancelled) {
                this.userService.logout(true);
            }
        });
    }

    public showUserSettings() {
        if (this.userService.noLogin) {
            return;
        }

        this.dialogService.open({
            viewModel: PractitionerEdit,
            lock: true
        })
            .whenClosed(result => {
                if (!result.wasCancelled) this._userInfoTitle = result.output;
            });
    }

    public trackLatestCarePlanAssessmentOutdated(patient) {
        const fn = async () => {
            const latestActiveCarePlan = CarePlanService.getLatestActiveCarePlan(await this.carePlanService.loadCarePlans(this.encounterId));
            const latestAssessment = await this.carePlanService.loadLatestAssessment(patient);
            const carePlanAssessment = await this.carePlanService.loadCarePlanAssessment(latestActiveCarePlan, latestAssessment);

            this.isLatestCarePlanAssessmentOutdated = CarePlanService.isDiagnosesOutdated(latestActiveCarePlan, carePlanAssessment, latestAssessment);
        };

        if (this.patientLatestAssessmentSubscription) {
            this.patientLatestAssessmentSubscription.dispose();
        }

        if (this.patientCarePlanOutdatedCheckSubscription) {
            this.patientCarePlanOutdatedCheckSubscription.dispose();
        }

        if (this.patient && typeof fn === "function") {
            this.patientLatestAssessmentSubscription = this.bindingEngine?.propertyObserver(this.patient, '_latestAssessment').subscribe(fn);
            this.patientCarePlanOutdatedCheckSubscription = this.eventAggregator?.subscribe('carePlanOutdatedCheck', fn);
        }

        if (typeof fn === "function")
            fn();
    }

    public changePassword() {
        this.dialogService.open({
            viewModel: ChangePassword,
            lock: true
        });
    }

    public navigateToView(id) {
        this.router.navigate(`#/encounter/${this.id}/${id}`);
    }

    public gotoPage(pageId) {
        let $button = $(`.navbar-arrow[data-page='${pageId}']`);
        if ($button.hasClass("disabled")) return;

        let url = `#/encounter/${this.id}/${pageId}`;
        if (window.location.hash === url) return;

        $("[data-page]").removeClass("active");
        this.router.navigate(url);
        $(`.navbar-arrow[data-page='${pageId}'], [data-page='pflegeplanung']`).addClass("active");
    }

    public gotoMasterPage(pageId) {
        let url = `#/encounter/${this.id}/${pageId}`;
        if (window.location.hash === url) return;
        this.router.navigate(url);
    }

    public getRoutes(location: string): any[] {
        if (!this.router || !this.router.navigation) return [];
        const r: RouteConfig[] = this.router.routes.filter(o => o.settings && o.settings.location === location && o.settings.visible);
        r.sort((a, b) => {
            if (typeof a.settings.index === "undefined") a.settings.index = 1000;
            if (typeof b.settings.index === "undefined") b.settings.index = 1000;
            return a.settings.index - b.settings.index;
        });

        // make distinct
        const result: RouteConfig[] = [];
        for (const route of r) {
            if (typeof result.find(o => o.name == route.name) === "undefined")
                result.push(route);
        }

        return result;
    }

    public navigateToForm(form: { id, title, name, url, version }) {
        if (!PermissionService.Instance?.canAlert(PermissionService.FEATURES.CAREIT.FORMS, { QUESTIONNAIRES: form.url + (form.version ? `|${form.version}` : '') })) return;
        this.rightPopup?.classList.remove('in');
        this.router.navigate('loadform');
        window.setTimeout(() => this.router.navigateToRoute('questionnaire', { questionnaireId: form.id, name: (form.name || form.title) }), 250);
        // this.router.navigate(`questionnaire/${form.id}/${form.name||form.title}`);
    }

    dotMenuRoutes: IUnmappedQuestionnaire[] = [];
    dotMenuGroups = [];

    public async getUnmappedQuestionnaires() {
        let questionnaires = <any[]>await this.questionnaireService.fetch();
        this.unmappedDotQuestionnaires = [];
        this.unmappedBottomQuestionnaires = [];
        this.unmappedTopQuestionnaires = [];
        this.dotMenuRoutes = [];
        this.dotMenuGroups = [];

        const topRoutes = this.getRoutes('top').map(o => o.route);
        const bottomRoutes = this.getRoutes('bottom').map(o => o.route);
        const menuRoutes = this.getRoutes('menu').map(o => o.route);

        if (this.patient) {
            const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
            if (NitTools.IsArray(cfg?.forms)) {
                for (const setting of cfg?.forms?.filter(o => o.questionnaireName && !o["$IS_INFO"])) {
                    const q = this.questionnaireService.getQuestionnaireByNameDirect(setting.questionnaireName);
                    if (q) {
                        const f: IUnmappedQuestionnaire = {
                            id: q.id,
                            title: setting.title ? this.i18n.tr(setting.title) : this.i18n.tr(q.title),
                            name: this.i18n.tr(q.name),
                            route: setting.route,
                            location: setting.location, //|| 'menu',
                            index: typeof setting.index === "number" ? setting.index : 1000,
                            group: setting.group
                        };

                        if (f.location && setting.enabled && !this.unmappedDotQuestionnaires.find(o => o.id === q.id)) {
                            switch (f.location) {
                                case undefined:
                                case "none":
                                    break;
                                case "bottom":
                                    if (bottomRoutes.indexOf(f.route) === -1)
                                        this.unmappedBottomQuestionnaires.push(f);
                                    break;
                                case "top":
                                    if (topRoutes.indexOf(f.route) === -1)
                                        this.unmappedTopQuestionnaires.push(f);
                                    break;
                                case "menu":
                                    if (!this.dotMenuRoutes.find(o => o.id === q.id))
                                        this.dotMenuRoutes.push(f);
                                    break;
                                default:
                                    if (menuRoutes.indexOf(f.route) === -1)
                                        this.unmappedDotQuestionnaires.push(f);
                                    break;
                            }
                        }
                    }
                }

                if (this.patient && ConfigService.EncounterTypeChoice.enabled) {
                    let analyzerVersion = await AnalyzeService.GetAnalyzerVersion(this.patient);
                    if (analyzerVersion) {
                        analyzerVersion = analyzerVersion.toUpperCase();

                        const choice = ConfigService.EncounterTypeChoice.choices.find(o => typeof o.analyzer === "string" && o.analyzer.toUpperCase() === analyzerVersion);
                        if (choice && choice.forms && choice.forms.additionalMenu) {
                            console.warn("ADD:", choice.forms.additionalMenu);
                            for (const additional of choice.forms.additionalMenu) {
                                const q = QuestionnaireService.GetQuestionnaireByNameDirect(additional.questionnaireName);
                                if (q) {
                                    const f: IUnmappedQuestionnaire = {
                                        id: q.id,
                                        title: additional.title ? this.i18n.tr(additional.title) : this.i18n.tr(q.title),
                                        name: this.i18n.tr(q.name),
                                        route: 'questionnaire',
                                        location: additional.location || 'menu',
                                        index: typeof additional.index === "number" ? additional.index : 2000
                                    };

                                    switch (f.location) {
                                        case "bottom":
                                            this.unmappedBottomQuestionnaires.push(f);
                                            break;
                                        case "top":
                                            this.unmappedTopQuestionnaires.push(f);
                                            break;
                                        case "menu":
                                        default:
                                            this.unmappedDotQuestionnaires.push(f);
                                            break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // get the groups
        const groupNames = <string[]>NitTools.Distinct(this.dotMenuRoutes.filter(o => o.group).map(o => o.group)).sort();
        this.dotMenuGroups = [];
        for (const grpName of groupNames) {
            const group = {
                id: NitTools.UidName(),
                title: this.i18n.tr(grpName),
                items: this.dotMenuRoutes.filter(o => o.group === grpName)
            };

            this.dotMenuGroups.push(group);
        }

        this.dotMenuRoutes = this.dotMenuRoutes.filter(o => !o.group);

        this.unmappedDotQuestionnaires.sort((a, b) => {
            if (typeof a.index === "undefined") a.index = 1000;
            if (typeof b.index === "undefined") b.index = 1000;
            return a.index - b.index;
        });

        this.unmappedTopQuestionnaires.sort((a, b) => {
            if (typeof a.index === "undefined") a.index = 1000;
            if (typeof b.index === "undefined") b.index = 1000;
            return a.index - b.index;
        });

        this.unmappedBottomQuestionnaires.sort((a, b) => {
            if (typeof a.index === "undefined") a.index = 1000;
            if (typeof b.index === "undefined") b.index = 1000;
            return a.index - b.index;
        });

        let tmp: IUnmappedQuestionnaire[] = questionnaires.filter(o => o.status === "active" || o.status === "draft").map(o => {
            return { id: o.id, title: o.title, name: o.name, status: o.status };
        });

        //let mappings: IQuestionnaireList =
        await this.questionnaireService.getQuestionnaireIds();

        // let mapped = Object.values ? Object.values(mappings).filter(o => typeof o !== "undefined") : [];

        this.debugQuestionnaires = [];
        tmp.forEach(t => {
            /*           if (
                           typeof this.unmappedDotQuestionnaires.find(o => o.id === t.id) === "undefined" &&
                           mapped.indexOf(t.id) === -1 && (t.name || t.title).toUpperCase().indexOf("CAREITWOUND_") === -1) {
                           t.title = `${(t.title || t.name)} (${String(t.status).toUpperCase()[0]})`;
                           this.debugQuestionnaires.push(t);
                       }
           */
            if (t.status === 'active')
                this.debugQuestionnaires.push(t);
        });

        this.debugQuestionnaires.sort((a, b) => {
            return String(a.title || a.name).localeCompare(String(b.title || b.name));
        });
    }

    public isLocation(location: string) {
        this.router.navigation.forEach(row => {
            if (row.relativeHref === location) {
                return row.isActive;
            }
        });
    }

    public hideRightPopup(e) {
        let element = e.target || e.srcElement || e.toElement;
        if (!element) return;
        if (element.classList.contains('right-pop-up-list-background'))
            this.rightPopup?.classList.remove('in');

        let tagName = element.tagName;
        if (tagName && tagName.toUpperCase() === "A" || tagName === "button") {
            e.preventDefault();
            e.cancelBubble = true;
            //    this.router.navigate(element.getAttribute("href"));
        }
    }

    get displayDebugFormsList(): boolean {
        return ConfigService.cfg?.features?.displayDebugFormsList;
    }

    public showRightPopup(arrow?: IEncounterViewDefinitionArrow) {
        if (arrow && this.useViewConfig) {
            const $content: HTMLUListElement = document.querySelector("#rightFirstGroup");
            if (!$content) return;
            $content.innerHTML = "";
            for (const item of arrow.items) {
                const li = document.createElement("li");
                const a = document.createElement("a");
                a.onclick = () => {
                    this.navigateToRouteDefinition(item);
                };
                a.innerText = item.title;
                li.appendChild(a);
                $content.appendChild(li);
            }
        }

        this.rightPopup.classList.add('in');
        window.setTimeout(() => {
            const backgroundDiv = document.querySelector('.right-pop-up-list-background');
            if (backgroundDiv && backgroundDiv.clientHeight) {
                const parentDiv = <HTMLDivElement>backgroundDiv.querySelector('.right-pop-up-list-parent');
                if (parentDiv) {
                    parentDiv.style.height = `${backgroundDiv.clientHeight}px`;
                }
            }
        }, 500);
    }

    public openCareItBed() {
        if (!this.patient) {
            console.warn("No patient loaded");
            return;
        }

        if (!this.patient.wardId && !RuntimeInfo.CurrentWardId) {
            this.dialogService.open({
                viewModel: Prompt,
                model: {
                    message: this.i18n.tr("Keine aktuelle Station im ausgewählten Patienten vorhanden"),
                    title: this.i18n.tr('info'),
                    yesText: this.i18n.tr("yes"),
                    noText: this.i18n.tr('no'),
                    showNo: false
                },
                lock: true
            });

            return;
        }

        this.router.navigateToRoute('ward', { id: this.patient.wardId || RuntimeInfo.CurrentWardId });
    }

    public openWardShiftReport() {
        this.router.navigateToRoute('ward-shift-report', { id: this.patient.wardId || RuntimeInfo.CurrentWardId });
    }

    public openWardOverview() {
        this.router.navigateToRoute('ward-overview', { id: this.patient.wardId || RuntimeInfo.CurrentWardId });
    }

    public async openAddView() {
        // this.router.navigate('add');
        const l = new LocationService();
        await l.fetch(true);

        this.dialogService.open({
            viewModel: AddPatient,
            lock: true
        });
    }

    public tr(id) {
        return this.i18n.tr(id);
    }

    public async toggleChat() {
        this.chatServiceWrapper.toggleChat();
    }

    changeRole() {
        this.router.navigate('/login-rbac');
    }
}

export interface IUnmappedQuestionnaire {
    id: string;
    title?: string;
    name?: string;
    status?: string;
    route?: string;
    location?: string;
    index?: number;
    group?: string;
}
