import {autoinject, bindable} from "aurelia-framework";
import {DialogController} from "aurelia-dialog";
import {NitTools} from "../../../resources/classes/NursitTools";
import {ConfigService} from "../../../resources/services/ConfigService";

@autoinject
export class PractitionerDialogCsv {
    @bindable fileValue;
    file : HTMLInputElement;
    uploadValid: boolean = false;
    fileError : string = '';
    importResult : string = 'Select a csv file to import..';
    canBeImported : boolean = false;
    resultPractitioners : fhir4.Practitioner[] = [];

    constructor(protected controller: DialogController) {}

    fileValueChanged(newValue) {
        this.canBeImported = false;
        this.resultPractitioners = [];

        if (newValue) {
            if (!newValue?.toString()?.toLowerCase().endsWith('.csv')) {
                this.fileError = 'Only .csv files are allowed!';
                this.uploadValid = false;
            }
            else {
                this.fileError = '';
                this.uploadValid = true;
                this.importResult = ''; // '<span class="text text-danger">Press the Parse Button now</span>';
                this.startImport();
            }
        }
    }

    async startImport() {
        const f : File = this.file?.files?.[0];
        if (!f?.arrayBuffer() || !this.fileValue) {
            this.uploadValid = false;
            this.fileError = 'File has no ArrayBuffer!';
            this.canBeImported = false;
            return;
        }

        const decoder = new TextDecoder('utf-8'); // Adjust the encoding as needed
        const lines = decoder.decode(await f.arrayBuffer());

        this.resultPractitioners = this.parseCsv(lines);

        this.importResult = `${this.resultPractitioners?.length} Practitioners loaded`;
        this.canBeImported = this.resultPractitioners?.length > 0;
    }

    /** Creates a fhir4.Practitioner resource from the given csv-date */
    practitionerFromCsvData(csvData: IPractitionerCsvData) : fhir4.Practitioner {
        if (!csvData) return undefined;

        const result : fhir4.Practitioner = {
            resourceType: "Practitioner",
            id: NitTools.Uid(),
            gender : csvData.Gender,
            active: true
        }

        //#region create the name object if any applicable data
        if (csvData.FamilyName || csvData.GivenName || csvData.Prefix || csvData.Suffix) {
            result.name = [];
            const name : fhir4.HumanName = {
                family: csvData.FamilyName,
                use: 'official'
            };

            name.given = NitTools.SplitStringToArrayByComma(csvData.GivenName, ',');
            name.prefix = NitTools.SplitStringToArrayByComma(csvData.Prefix, ',');
            name.suffix = NitTools.SplitStringToArrayByComma(csvData.Suffix, ',');

            result.name.push(name);
        }
        //#endregion

        //#region create the address if any applicable field
        if (csvData.Line || csvData.District || csvData.Zip || csvData.Country) {
            result.address = [];
            const address : fhir4.Address = {};
            if (csvData.Line) {
                address.line = NitTools.SplitStringToArrayByComma(csvData.Line, '\\n');
            }

            // always check if a value exists and is not an empty string to avoid setting empty values
            if (csvData.District) address.district = csvData.District.trim();
            if (csvData.Zip) address.postalCode = csvData.Zip.trim();
            if (csvData.Country) address.country = csvData.Country.trim();

            result.address.push(address);
        }
        //#endregion

        //#region set the receiver-profile if applicable
        if (csvData.Receiver?.trim()) {
            result.identifier = [{
                system: "http://nursit-institute.com/carewriter-receiver",
                value: csvData.Receiver.trim()
            }];
        }
        //#endregion

        return result;
    }

    /** normalizes a csv line into an object of type IPractitionerCsvData if correct */
    parseCsvLine(line: string): IPractitionerCsvData {
        const values: string[] = [];
        let currentField = "";
        let inQuotes = false;
        let escapedQuote = false;

        for (let i = 0; i < line.length; i++) {
            const char = line[i];

            if (escapedQuote) {
                currentField += char;
                escapedQuote = false;
                continue;
            }

            if (char === '"') {
                inQuotes = !inQuotes;
                continue;
            }

            if (char === '\\' && line[i + 1] === '"') {
                escapedQuote = true;
                continue;
            }

            if (char === ';' && !inQuotes) {
                values.push(currentField);
                currentField = "";
                continue;
            }

            currentField += char;
        }

        values.push(currentField) // Add the final value

        if (values.length !== 11) {
            console.error(`Invalid CSV line: ${line}. Expected 11 fields, got ${values.length}. Found values: ${JSON.stringify(values)} Line: ${line}`);
            return undefined;
        }

        return {
            FamilyName: values[0],
            GivenName: values[1],
            Gender: this.stringToGender(values[2]),
            Prefix: values[3],
            Suffix: values[4],
            Line: values[5],
            District: values[6],
            Zip: values[7],
            Town: values[8],
            Country: values[9],
            Receiver: values[10]
        };
    }

    /** Parses a csv-file content as string into practitioners */
    parseCsv(csvContent: string): fhir4.Practitioner[] {
        const lines = csvContent.split('\n');
        const header = lines.shift(); //remove header line

        const data: fhir4.Practitioner[] = [];
        for (const line of lines) {
            if (line.trim() !== "") { // Skip empty lines
                const parsedLine : IPractitionerCsvData = this.parseCsvLine(line);
                if (parsedLine) {
                    const prac = this.practitionerFromCsvData(parsedLine);
                    if (prac) {
                        if (ConfigService.Debug)
                            console.log(`Imported CSV-Line:\n${line}\n into Practitioner:`, prac);

                        data.push(prac);
                    }
                }
            }
        }

        return data;
    }

    /** parses a string into a fhir-administrativeGender value */
    stringToGender(gender : string) : ('male'|'female'|'other'|'unknown') | undefined {
        if (!gender) return undefined;
        gender = gender.trim().toLowerCase();
        return gender === 'male' ? 'male' : gender === 'female' ? 'female' : gender === 'other' ? 'other' : gender === 'unknown' ? 'unknown' : undefined; // Type-safe gender assignment
    }
}

export interface IPractitionerCsvData {
    FamilyName: string;
    GivenName: string;
    Gender: ('male'|'female'|'other'|'unknown') | undefined;
    Prefix: string;
    Suffix: string;
    Line: string;
    District: string;
    Zip: string;
    Town: string;
    Country: string;
    Receiver : string;
}
