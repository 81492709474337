import { HttpClient } from "aurelia-http-client";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "../services/ConfigService";
import { NitTools } from "./NursitTools";
const Fhir = require("./FhirModules/Fhir");

export interface IGrafixxItem {
    type: string;
    sum: number;
    imageName: string;
    text?: string;
    svg?: string;
    svgHealed?: string;
    items?: any[];
    healedCount?: number;
    isWound?: boolean;
    toolTipActive?: string;
    toolTipHealed?: string;
}

export class GrafixxItem implements IGrafixxItem {
    imageName: string;
    sum: number;
    svg: string;
    text: string;
    type: string;
    healedCount: number = 0;

    get isWound(): boolean {
        return this.type === "wound";
    }

    public static Default: IGrafixxItem[] = undefined;
    public static Initialized: boolean = false;

    public static async Init(i18n?: I18N) {
        if (this.Default?.length > 0) {
            for (const d of this.Default) {
                d.sum = 0;
            }
        }

        if (!GrafixxItem.Initialized || ConfigService.Debug) {
            const config = ConfigService.GetFormSettings(ConfigService.FormNames.Wounds);
            const is3dBody = config?.settings?.body3d?.enabled === true;

            if (is3dBody) {
                try {
                    const csUrl = `CodeSystem?system=${encodeURIComponent('http://nursit-institute.com/fhir/StructureDefinition/body-markers')}`;
                    // const csUrl =  `CodeSystem?name=BodyMarkers,Body%20Markers`;
                    let all = (await Fhir.Rest.Fetch(csUrl, false));
                    

                    if (all?.length == 0) {
                        throw 'No CodeSystem "body-markers" found!';                        
                    }

                    this.parseCodeSystems(all.filter(o=>o.status === 'active' || o.status === 'draft'), i18n);
                }
                catch (ex) {
                    console.warn(ex);
                }
            } else {
                let response = await new HttpClient().get("./config/body-map-wounds.json?_=" + new Date().valueOf());
    
                if (response.statusCode === 200) {
                    let data = JSON.parse(response.response);
                    this.parseData(data, i18n);
                }
            }
        }

        return GrafixxItem.Default;
    }

    static parseCodeSystems(csArr: any[], i18n : I18N) {
        if (!NitTools.IsArray(csArr)) return;

        for (const cs of csArr.filter(o => o.concept)) {
            for (const concept of cs.concept.filter(o => o.code)) {
                if (concept.code == "wounds")
                    concept.code = "wound";

                let item: IGrafixxItem = {
                    type: concept.code,
                    sum: 0,
                    imageName: `${concept.code}`,
                    toolTipHealed: 'Abgeheilt/Gezogen',
                    text: i18n?.tr(concept.code)||concept.code
                };

                if (concept.display)
                    item.text = concept.display;

                if (!GrafixxItem.Default) GrafixxItem.Default = [];
                const idx = GrafixxItem.Default.findIndex(o => o.type === item.type);
                if (idx === -1) {
                    GrafixxItem.Default.push(item);
                } else {
                    GrafixxItem.Default[idx] = item;
                }
            }
        }
    }

    static parseData(data, i18n?) {
        if (data.wounds) {
            let defaults: IGrafixxItem[] = [];
            (<any[]>data.wounds).forEach((element) => {
                let item: IGrafixxItem = {
                    type: element.name,
                    sum: 0,
                    imageName: element.icon,
                    toolTipHealed: i18n ? i18n.tr(element.healedText) : element.healedText
                };

                const idx = defaults.findIndex(o => o.type === item.type);
                if (idx === -1) {
                    defaults.push(item);
                } else {
                    defaults[idx] = item;
                }
            });

            GrafixxItem.Default = defaults;

            return defaults;
        }
    }
}
