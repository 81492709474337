import {bindable} from "aurelia-templating";
import {ModalCaveAdd} from "../../resources/elements/modal-cave-add";
import {autoinject} from "aurelia-framework";
import {DialogService} from "aurelia-dialog";
import {I18N} from "aurelia-i18n";
import {UserService} from "../../resources/services/UserService";
import {FhirService} from "../../resources/services/FhirService";
import {DialogMessages} from "../../resources/services/DialogMessages";
import {PatientItem} from "../../resources/classes/Patient/PatientItem";
import {Tools} from "../../resources/classes/FhirModules/Tools";
import { NitTools } from "resources/classes/NursitTools";
import { CaveView } from "./cave-view";

@autoinject
export class CaveViewRow {
    @bindable items : any[];
    cleanedItems : any[];
    @bindable patient : PatientItem;
    readonly  : boolean = false;

    constructor(protected dialogService : DialogService, protected  i18n : I18N) {
        this.readonly = UserService.IsReadonly;
    }

    itemsChanged(newValue, oldValue) {
        this.cleanedItems = newValue;
        this.cleanUpItems();
    }

    async updateItem(item, caveAdd: ModalCaveAdd) {
        item.isLoading = true;

        // update item in the ui
        item.text = Tools.EncodeHtml(caveAdd.text);
        item.title = Tools.EncodeHtml(caveAdd.title);
        item.performer = UserService.UserName;
        item.when = caveAdd.targetDate;

        // update the visible in header property
        if (NitTools.IsArray(item.observation?.code?.coding)) {
            // first remove the visible in header information from the coding, if exists
            (<fhir4.Observation>item.observation).code.coding = (<fhir4.Observation>item.observation).code.coding.filter(o=>o.system != CaveView.HeaderVisibleUrl);
            
            // then add the code, when the item should be visible
            if (caveAdd.displayInTitle) {
                item.observation.code.coding.push({
                    system: CaveView.HeaderVisibleUrl,
                    code: 'visible-in-header'
                });
            }
        }
        
        // update the observation
        item.observation.text = {
            status: 'generated',
            div: `<div xmlns="http://www.w3.org/1999/xhtml"><div><b>${Tools.EncodeHtml(caveAdd.title)}</b></div><div>${Tools.EncodeHtml(caveAdd.text)}</div></div>`
        };

        item.observation.performer = [
            {
                reference: `Practitioner/${UserService.Practitioner?.id}`,
                display: UserService.UserName
            }
        ];

        item.observation.note = [
            {
                text: caveAdd.text,
                authorReference: {
                    reference: `Practitioner/${UserService.Practitioner?.id}`,
                    display: UserService.UserName
                }
            }
        ];

        item.observation.effectiveDateTime = (caveAdd.targetDate||new Date())?.toJSON();
        item.observation.valueString = Tools.EncodeHtml(caveAdd.title);

        // update observation on fhir
        const fs = new FhirService();
        await fs.update(item.observation);

        item.isLoading = false;
        
        this.cleanUpItems();
    }

    cleanUpItems() {
        if (!this.cleanedItems) return;
        for (const item of this.cleanedItems) {
            item.title = Tools.DecodeHtml(item.title);
            item.text = Tools.DecodeHtml(item.text);
        }
    }

    askDeleteItem(item) {
        const cat = this.i18n.tr('cave_' + item.path);
        let msg = this.i18n.tr("cave_delete", { category: cat, title: item.title});

        DialogMessages.Dialog(this.dialogService, msg, this.i18n.tr("confirm"), this.i18n.tr("delete"), this.i18n.tr("abort"), true )
            .whenClosed(async o=> {
                if (!o.wasCancelled && o.output === true) {
                    try {
                        switch (item.path) {
                            case 'C':
                                this.patient.contraindicationsCount--;
                                break;
                            case 'V':
                                this.patient.valuesCount--;
                                break;
                            case 'E':
                                this.patient.equipmentCount--;
                        }

                        const fs = new FhirService();
                        await fs.delete(item.observation);
                        item.isDeleted = true;
                    }
                    catch (e) {
                        console.warn(e);
                    }
                }
            })
            .catch(e => console.warn(e));
    }

    editItem(item) {
        if (!item) return;

        const cat = this.i18n.tr('cave_' + item.path);
        this.dialogService.open({
            viewModel: ModalCaveAdd,
            model: {
                caption: this.i18n.tr("cave_edit", { category: cat }),
                title: item.title,
                text: item.text,
                targetDate: item.when,
                displayInTitle: item.displayInTitle
            },
            lock: true
        })
            .whenClosed(e => {
                if (e.wasCancelled)
                    return;

                this.updateItem(item, e.output)
                    .catch(e => console.warn(e));
            })
            .catch(e => console.warn(e));
    }
}
