import {bindable} from "aurelia-framework";
import {QuestionnaireService} from "../../services/QuestionnaireService";
import {PatientItem} from "../../classes/Patient/PatientItem";

export class qGroupList {
    @bindable groups;
    @bindable showpkms: boolean;
    @bindable formid: string;
    @bindable response: any;
    @bindable readonly: boolean;
    @bindable previousresponse: any;
    @bindable pkmsGroupName: string;
    @bindable patient : PatientItem;
    @bindable onVisibilityChanged : any;
    @bindable isAssessment : boolean;
    @bindable isInLightBox : boolean;

    /**
     * This is the event handler when the user checked/unchecked a group in the group list
     * @param $event the QuestionnaireItem (here:group) that has been changed
     */
    checkedChanged($event)
    {
        if (typeof this.onVisibilityChanged === "function")
            this.onVisibilityChanged($event);
    }
}
