import {bindable, inject} from "aurelia-framework";

const moment = require("moment");
import {PatientItem} from "../classes/Patient/PatientItem";
import {ConfigService} from "../services/ConfigService";
import {QuestionnaireService} from "resources/services/QuestionnaireService";
import {fhirEnums} from "../classes/fhir-enums";
import {FormBaseClass} from "./FormBaseClass";
import {UserService} from "../services/UserService";

@inject(QuestionnaireService)
export class saveButton {
    @bindable onSave: Function;
    @bindable onStateChanged: Function;
    @bindable response: any;
    @bindable questionnaire: any;
    @bindable patient: PatientItem;
    @bindable route: string;
    @bindable buttonClass: string;
    @bindable showButtonComplete: boolean = true;
    forcedReadonly: boolean = false;

    get btnClassResult(): string {
        return this.buttonClass ? this.buttonClass : "btn-default";
    }

    isTrainee: boolean = false;
    isDoctor: boolean = false;

    routeChanged(newRoute) {
        this.updateFormValues();
    }

    configService: ConfigService;
    isEditable: boolean = false;
    isValid: boolean = false;
    @bindable simpleButton: boolean = false;

    buttonState: saveButtonState = saveButtonState.edit;
    age: number = 0;
    isTooOld: boolean = false;
    expiration: number = 24;
    questionnaireService: QuestionnaireService;

    constructor(q: QuestionnaireService) {
        this.questionnaireService = q;
        this.isTrainee = UserService.UserRole === 'trainee';
        this.isDoctor = UserService.UserRole === 'doctor';
    }

    getResponseAgeInHours(response?): number {
        if (!response) response = this.response;
        if (!response) return 0;
        let result = moment(new Date()).diff(response.authored, "hours");
        //if (ConfigService.Debug) console.debug("RESPONSE IS " + result + " hours old");
        return result;
    }

    patientChanged() {
        this.updateFormValues();
    }

/*************
    public static CalculateButtonState(response: any, questionnaire: any): { simpleButton: boolean, isTooOld: boolean, buttonState: saveButtonState, isEditable: boolean } {
        let age = moment(new Date()).diff(response.authored, "hours");
        let expiration = 24;
        const result = {
            simpleButton: true,
            isTooOld: false,
            buttonState: saveButtonState.edit,
            isEditable: false
        }

        const config = ConfigService.FormSettings.find(o => o.questionnaireName === questionnaire.name);
        if (config) {
            if (config.expiration && config.expiration.default) {
                expiration = config.expiration.default;
            } else {
                console.warn("No expiration found for questionnaire with name: " + questionnaire.name);
            }
        }

        if (!response.status) {
            result.simpleButton = true;
            result.isTooOld = false;
        } else {
            result.simpleButton = !FormBaseClass.UseQuestionnaireStatusForSave;
            if (response.status !== fhirEnums.QuestionnaireResponseStatus.inProgress)
                result.buttonState = saveButtonState.edit;
            else
                result.buttonState = saveButtonState.save;
        }

        result.isEditable = true;
        // using response status for save
        if (response.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
            if (expiration && age && age > expiration) {
                result.isTooOld = true;
            } else {
                result.isTooOld = false;
            }
        } else {
            result.isTooOld = false;
            result.buttonState = saveButtonState.save;
            result.isEditable = true;
        }

        return result;
    }
**************/

    updateFormValues() {
        if (!this.patient || !this.response ||
            (!this.questionnaire && this.route !== ConfigService.FormNames.Wounds && this.route !== ConfigService.FormNames.Isolation)
            // || !this.route
        ) {
            this.isValid = false;
            return;
        }

        this.isValid = true;
        this.age = this.getResponseAgeInHours();

        this.expiration = 24;
        let config = ConfigService.FormSettings.find(o => o.questionnaireName === this.questionnaire.name);
        if (config) {
            if (config.expiration && config.expiration.default) {
                this.expiration = config.expiration.default;
            } else {
                console.warn("No expiration found for questionnaire with name: " + this.questionnaire.name);
            }
        }

        if (!this.response.status) {
            this.simpleButton = true;
            this.isTooOld = false;
            return;
        } else {
            this.simpleButton = !FormBaseClass.UseQuestionnaireStatusForSave;
            if (this.response.status !== fhirEnums.QuestionnaireResponseStatus.inProgress)
                this.buttonState = saveButtonState.edit;
            else
                this.buttonState = saveButtonState.save;
        }

        if (!FormBaseClass.UseQuestionnaireStatusForSave && this.questionnaire && this.patient) {
            // simple saving
            let list = QuestionnaireService.GetResponsesOfType(this.patient, this.questionnaire.id, [fhirEnums.QuestionnaireResponseStatus.inProgress, fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

            // last item in list is editable...
            let idx = list.indexOf(this.response);
            /* if (ConfigService.Debug) {
                console.debug(`STATUS: ${this.response.status}, IDX: ${idx}, LEN: ${list.length -1}`);
            } */

            if (idx === list.length - 1) {
                // ... when it's not too old
                this.isTooOld = this.expiration && this.age && this.age > this.expiration;
                if (this.expiration === -1)
                    this.isTooOld = false;

                if (this.isTooOld) {
                    this.isEditable = false;
                    this.changeMode(saveButtonState.tooOld);
                } else {
                    this.isEditable = true;
                    this.changeMode(saveButtonState.save);
                }
            } else {
                // this.isTooOld = false;
                if (this.response.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
                    this.isTooOld = this.expiration && this.age && this.age > this.expiration;
                    if (this.expiration === -1)
                        this.isTooOld = false;

                    this.isEditable = !this.isTooOld;

                } else {
                    this.isTooOld = false;
                    this.isEditable = true;
                }

                this.changeMode(this.isTooOld ? saveButtonState.tooOld : saveButtonState.save);
            }
        } else {
            this.isEditable = true;
            // using response status for save
            if (this.response.status !== fhirEnums.QuestionnaireResponseStatus.inProgress) {
                if (this.expiration && this.age && this.age > this.expiration) {
                    this.isTooOld = true;
                    if (this.expiration === -1)
                        this.isTooOld = false;
                    else
                        this.changeMode(saveButtonState.tooOld);
                } else {
                    this.isTooOld = false;
                }
            } else {
                this.isTooOld = false;
                this.buttonState = saveButtonState.save;
                this.isEditable = true;
            }
        }

        if (typeof this.onStateChanged === "function") {
            this.onStateChanged(this);
        }
    }

    questionnaireChanged() {
        this.updateFormValues();
    }

    responseChanged(newValue: any) {
        this.updateFormValues();
    }

    saveToggleButton : HTMLButtonElement;

    changeMode(state: saveButtonState) {
        if (state == this.buttonState) {
            return;
        }

        if (this.isTrainee && this.saveToggleButton && this.saveToggleButton.disabled) {
            return;
        }

        this.buttonState = state;
        if (typeof this.onStateChanged === "function") {
            this.onStateChanged(this);
        }
    }

    /***
     * When the user clicked on the save button
     * @param responseState
     */
    async buttonClicked(responseState) {
        //this.response.status = responseState; // buttonState = state;
        if (typeof this.onSave === "function") {
            await this.onSave(responseState);
            this.updateFormValues();
        }
    }

    async attached() {
        this.simpleButton = !FormBaseClass.UseQuestionnaireStatusForSave;
        this.forcedReadonly = UserService.IsReadonly;
    }
}

export enum saveButtonState {
    edit = "edit",
    save = "save",
    tooOld = "tooOld"
}
